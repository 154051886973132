import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/fr.js";

const DatePickerProfile = ({ onChange, date, minDate }) => {
  const language = localStorage.getItem("i18nextLng").split("-")[0];
 
  return (
    <div className="date-picker-profile">
      <Flatpickr
        options={{
          closeOnSelect: true,
          mode: "single",
          locale: language,
          minDate: minDate&&minDate,
        }}
        onChange={onChange}
        value={date}
      />
    </div>
  );
};

export default DatePickerProfile;
