import React, { useEffect, useState } from "react";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import SpinnerSecondary from "../../../../components/SpinnerSecondary";
import { useTranslation } from "react-i18next";
import { getNumbers } from "../../../Numbers/Services/getNumbers";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import sendMessage from "../../Services/sendMessage";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const AddNewConversationModal = ({ closeModal }) => {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);

  const [body, setBody] = useState({
    receiver: "",
    sender: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);

  const changeReqBody = (item, value) => {
    setBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const fetchNumbers = async () => {
    try {
      const response = await getNumbers([1, 5]);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      if (result.total > 0) {
        const newData = [];
        result.data.map(
          (item) =>
            item._terms?.features?.includes("SMS OUT") &&
            newData.push({ number: item.number, country: item.country })
        );
        setNumbers(newData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchNumbers();
      }
    }
  };

  const refetchNumbers = async () => {
    try {
      const response = await getNumbers([1, 5]);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      if (result.total > 0) {
        const newData = [];
        result.data.map(
          (item) =>
            item._terms?.features?.includes("SMS OUT") &&
            newData.push(item.number)
        );
        setNumbers(newData);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      const response = await sendMessage(body);
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      closeModal();

      //   setSentMessages((prevD) => prevD + 1);

      //   updateConversation({
      //     id: result.data.message_draft.id,
      //     source: 2,
      //     date: new Date(),
      //     content: body.message,
      //   });

      //  confirmationSwal(() => {});
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  useEffect(() => {
    fetchNumbers();
  }, []);

  return (
    <div>
      <div className={`warning-del-number-container fade-in-down`}>
        {loading && <SpinnerDiv />}

        <div className="modal-show">
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("create_new_conversation")}</h3>
            </div>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className={`p-6 ${loading && "disabled-event"}`}>
            <div className="mb-6">
              <div>
                <label className="form-label mb-3">
                  <span>{t("phone_number")}</span>
                </label>
              </div>
              <div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <div
                        className={`currency-div position-relative`}
                        onClick={() => setMenuOpen(!menuOpen)}
                        onMouseLeave={() => setMenuOpen(false)}
                      >
                        {body.sender && (
                          <span className="d-flex align-items-center gap-2">
                            <img
                              className="flag-style h-11px w-15px obj-fit-cover"
                              src={`/assets/media/flags/${numbers
                                .find((item) => item.number === body.sender)
                                .country.toLowerCase()}.svg`}
                              alt={"FR"}
                            />
                            <span>{body.sender}</span>
                          </span>
                        )}
                        <svg
                          className="svg-menu-currency"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                              fill="#727272"
                            />
                          </g>
                        </svg>

                        {menuOpen && (
                          <div>
                            {numbers.map((item, key) => (
                              <span
                                key={key}
                                onClick={() => {
                                  changeReqBody("sender", item.number);
                                }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {" "}
                                  <img
                                    className="flag-style h-11px w-15px obj-fit-cover"
                                    src={`/assets/media/flags/${item.country.toLowerCase()}.svg`}
                                    alt={"FR"}
                                  />
                                  <span>{item.number}</span>
                                </div>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className={`mb-6 ${!body.sender && "disabled-event"}`}>
              <div>
                <label className="form-label mb-3">
                  <span>{t("destination")}</span>
                </label>
              </div>
              <div>
                <div className="position-relative">
                  <PhoneInput
                    value={body.receiver}
                    onChange={(value) => changeReqBody("receiver", value)}
                  />
                </div>
              </div>
            </div>

            <div className={!body.receiver && "disabled-event"}>
              <label className="form-label mb-3">
                <span>{t("message")}</span>
              </label>
            </div>
            <div className="row">
              <div className="d-flex">
                <textarea
                  value={body.message}
                  type="text"
                  className={`form-control form-control-sm form-control-solid`}
                  onChange={(e) => changeReqBody("message", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton title={t("cancel")} onClick={closeModal} />

            <button
              type="submit"
              className={`btn btn-primary-active btn-sm ${
                !body.message ? "disabled-event" : ""
              }`}
              onClick={handleSendMessage}
            >
              <span> {t("send")}</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default AddNewConversationModal;
