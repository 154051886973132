const deleteTicket = async (body) => {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/support/tickets/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });
  };
  
  export default deleteTicket;
  