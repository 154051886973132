import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

function DataTableComponent({
  columns,
  data,
  paramsData,
  setParamsData,
  load,
  header,
}) {
  const { t } = useTranslation();

  const getTotalPages = () => {
    return Math.ceil(paramsData.total / paramsData.limit);
  };

  const getCurrentPage = () => {
    return paramsData.skip / paramsData.limit + 1;
  };

  const canGoBack = () => {
    return paramsData.skip - paramsData.limit >= 0;
  };

  const canGoNext = () => {
    return getCurrentPage() < getTotalPages();
  };

  const previousStyle = `datatable-button ${
    !canGoBack() || !load ? " datatable-button-disabled" : ""
  }`;

  const nextStyle = `datatable-button ${
    !canGoNext() || !load ? " datatable-button-disabled" : ""
  }`;

  const handleFirstPage = () => {
    if (!canGoBack() || !load) return;
    setParamsData((prevData) => ({
      ...prevData,
      skip: 0,
    }));
  };

  const handleLastPage = () => {
    if (!data[0]?.id) return;
    const lastPage = Math.floor((paramsData.total - 1) / paramsData.limit);
    if (!canGoNext() || !load) return;
    setParamsData((prevData) => ({
      ...prevData,
      skip: Math.floor(lastPage * paramsData.limit),
    }));
  };

  const handleNextPage = () => {
    if (!data[0]?.id) return;
    if (!canGoNext() || !load) return;
    setParamsData((prevData) => ({
      ...prevData,
      skip: prevData.skip + prevData.limit,
    }));
  };

  const handlePrevioustPage = () => {
    if (!canGoBack() || !load) {
      setParamsData((prevData) => ({
        ...prevData,
        skip: 0,
      }));
    } else {
      setParamsData((prevData) => ({
        ...prevData,
        skip: prevData.skip - prevData.limit,
      }));
    }
  };

  const setLimitFunction = (newLimit) => {
    return new Promise((resolve, reject) => {
      try {
        setParamsData((prevData) => ({
          ...prevData,
          limit: newLimit,
          skip: 0,
        }));
        resolve("Limit set successfully");
      } catch (error) {
        reject(`Error setting limit: ${error.message}`);
      }
    });
  };

  const handleRowsPerPageChange = async (newLimit, currentPage) => {
    try {
      await setLimitFunction(newLimit);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="tab-content" id="myTabContent">
      <div className="tab-pane show active" id="kt_calls" role="tabpanel">
        <div className="card mb-6 mb-xl-9">
          <div className="card-header">
            <div className="card-title">
              <h2> {header}</h2>
            </div>
            <div className="card-toolbar m-0"></div>
          </div>
          <div>
            <div className="card-body">
              <div className="header-color"></div>
              <div className="header-bar-component"></div>
              <DataTable
                className="table align-middle table-row-dashed fs-6 fw-bold gy-5 datatable-style"
                columns={columns}
                data={data}
                fixedHeader
                pagination={data[0].id || load}
                sortable
                paginationPerPage={paramsData.limit}
                paginationRowsPerPageOptions={[5, 10, 50]}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationTotalPages={paramsData.total}
                paginationComponentOptions={{
                  rowsPerPageText: t("data_table.rows_per_page"),
                }}
              ></DataTable>

              {!data[0].id && load && (
                <p
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "192px",
                    zIndex: "2",
                    transform: "translateX(-50%)",
                    paddingTop: "1rem",
                  }}
                >
                  {t("data_table.no_records")}
                </p>
              )}

              {data[0].id && (
                <div className="dt-filters">
                  <div
                    style={{
                      minWidth: "fit-content",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div style={{ minWidth: "fit-content" }}>
                      {getCurrentPage() * paramsData.limit -
                        paramsData.limit +
                        1}
                      -
                      {getCurrentPage() * paramsData.limit >= paramsData.total
                        ? paramsData.total
                        : getCurrentPage() * paramsData.limit}{" "}
                      of {paramsData.total}
                    </div>

                    {/* <div className={previousStyle} onClick={handleFirstPage}>
                      <svg
                        className="svg-filters"
                        fill="#000000"
                        viewBox="0 0 512 512"
                        id="Layer_1"
                        version="1.1"
                        xmlSpace="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          <path d="M297.2,478l20.7-21.6L108.7,256L317.9,55.6L297.2,34L65.5,256L297.2,478z M194.1,256L425.8,34l20.7,21.6L237.3,256 l209.2,200.4L425.8,478L194.1,256z" />
                        </g>
                      </svg>
                    </div> */}
                    <div
                      className={previousStyle}
                      onClick={handlePrevioustPage}
                    >
                      <svg
                        className="svg-filters"
                        fill="#000000"
                        height="200px"
                        width="200px"
                        version="1.1"
                        id="XMLID_54_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 24 24"
                        xmlSpace="preserve"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="previous">
                            {" "}
                            <g>
                              {" "}
                              <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>

                    <div className={nextStyle} onClick={handleNextPage}>
                      <svg
                        className="svg-filters"
                        fill="#000000"
                        height="200px"
                        width="200px"
                        version="1.1"
                        id="XMLID_287_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 24 24"
                        xmlSpace="preserve"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="next">
                            {" "}
                            <g>
                              {" "}
                              <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                    {/* <div className={nextStyle} onClick={handleLastPage}>
                      <svg
                        fill="#000000"
                        className="svg-filters"
                        viewBox="0 0 512 512"
                        data-name="Layer 1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          <path d="M214.78,478l-20.67-21.57L403.27,256,194.11,55.57,214.78,34,446.46,256ZM317.89,256,86.22,34,65.54,55.57,274.7,256,65.54,456.43,86.22,478Z" />
                        </g>
                      </svg>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTableComponent;
