import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PerformanceChart from "./PerformanceChart";
import CostChart from "./CostChart";

const CostsCahrt = ({loading}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([
    [
      "date",
      "Dollar"
    ],
    [
      "0",
      0
    ],
    [
      "1",
      0
    ],
    [
      "2",
      0
    ],
    [
      "3",
      0
    ],
    [
      "4",
      0
    ],
    [
      "5",
      0
    ],
    [
      "6",
      0
    ],
    [
      "7",
      0
    ],
    [
      "8",
      0
    ],
    [
      "9",
      0
    ],
    [
      "10",
      0
    ],
    [
      "11",
      0
    ],
    [
      "12",
      0
    ],
    [
      "13",
      0
    ],
    [
      "14",
      0
    ],
    [
      "15",
      0
    ],
    [
      "16",
      0
    ],
    [
      "17",
      0
    ],
    [
      "18",
      0
    ],
    [
      "19",
      0
    ],
    [
      "20",
      0
    ],
    [
      "21",
      0
    ],
    [
      "22",
      0
    ],
    [
      "23",
      0
    ]
  ]);

  return (
    <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0 ">
      <div className="card card-flush overflow-hidden h-md-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              {t("cost")}
            </span>
            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
          </h3>
        </div>


        <div className="card-body h-31rem px-0">
        <CostChart
                          data={data}
                          load={loading}
                        />
        </div>
       

      
      </div>
    </div>
  );
};

export default CostsCahrt;
