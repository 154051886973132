import React, { useEffect, useState } from "react";
import fetchData from "../Service/geoService";
import Spinner from "../../../components/Spinner";
import BarChart from "../Components/BarChart";
import { useTranslation } from "react-i18next";
import { formatDate, initialDateFormat } from "../../../constants/Utils";
import DatePicker from "../../../components/reusables/DatePicker";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import errorSwal from "../../../components/Alerts/errorAlert";
import reconnectionSwal from "../../../components/Alerts/reconnectionAlert";
import DiffBarChart from "../Components/DiffBarChart";
import "../Styles/Reportings.css";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const ReportGeo = () => {
  const { t } = useTranslation();

  const [dataLoad, setDataLoad] = useState(false);

  const [displayData, setDisplayData] = useState(2);

  const [totalCountries, setTotalCountries] = useState(0);

  const [data, setData] = useState();

  const [durationData, setDurationData] = useState();

  const [avgData, setAvgData] = useState();

  const [reqBody, setReqBody] = useState({
    period: "today",
    dates: {
      start: "",
      end: "",
    },
  });

  const [activeBtn, setActiveBtn] = useState(1);

  const handleDate = (active, date) => {
    setActiveBtn(active);
    setReqBody((prevData) => ({ ...prevData, period: date }));
  };

  const convertSecondsToMinutes = (seconds) => {
    return seconds / 60; // Convert seconds to minutes
  };

  const getGeo = async () => {
    try {
      setDataLoad(false);

      setDisplayData(2);
      const response = await fetchData(JSON.stringify(reqBody));
      setDataLoad(true);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setDisplayData(fetchedData.data.geography.length === 0 ? 0 : 1);
      setTotalCountries(fetchedData.data.geography.length);
      if (fetchedData.data.geography.length > 0) {
        const newData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          entry.inbound.total,
          entry.outbound.total,
        ]);
        const newDurationData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          convertSecondsToMinutes(entry.inbound.duration),
          convertSecondsToMinutes(entry.outbound.duration),
        ]);

        const avgDurationData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          convertSecondsToMinutes(entry.inbound.average),
          convertSecondsToMinutes(entry.outbound.average),
        ]);

        setData([
          [t("country"), t("inbound_call"), t("outbound_call")],
          ...newData,
        ]);

        setDurationData([
          [t("country"), t("inbound_call"), t("outbound_call")],
          ...newDurationData,
        ]);

        setAvgData([
          [t("country"), t("inbound_call"), t("outbound_call")],
          ...avgDurationData,
        ]);
      }
    } catch (error) {
      if (error.message == 400) {
        errorSwal(t("errors.400"));
      }
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        // reconnectionSwal();
        getGeoSecondTry();
      }
    }
  };

  const getGeoSecondTry = async () => {
    try {
      setDataLoad(false);

      const response = await fetchData(JSON.stringify(reqBody));
      setDataLoad(true);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setTotalCountries(fetchedData.data.geography.length);
      if (fetchedData.data.geography.length > 0) {
        const newData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          entry.inbound.total,
          entry.outbound.total,
        ]);
        const newDurationData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          entry.inbound.duration,
          entry.outbound.duration,
        ]);

        const avgDurationData = fetchedData.data.geography.map((entry) => [
          t(`countries.${entry.country}`),
          entry.inbound.average,
          entry.outbound.average,
        ]);

        setData([[t("country"), t("inbound"), t("outbound")], ...newData]);
        setDurationData([
          [t("country"), t("inbound"), t("outbound")],
          ...newDurationData,
        ]);

        setAvgData([
          [t("country"), t("inbound"), t("outbound")],
          ...avgDurationData,
        ]);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    getGeo();
  }, [reqBody.period, reqBody.dates.start, reqBody.dates.end]);

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div
              id="kt_app_toolbar"
              className="app-toolbar align-items-center justify-content-between py-2 py-lg-6"
            >
              <div
                className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n10 mg-min10"
                id="kt_toolbar"
              >
                <div className="d-flex flex-column align-items-start me-3 gap-2">
                  <h1 className="d-flex text-dark fw-bold m-0 fs-3">
                    {t("geographic_stats")}
                    <span className="h-20px border-gray-400 border-start mx-3" />
                    <small className="text-gray-500 fs-7 fw-semibold my-1">
                      {t("you_have")} {totalCountries} {t("active_countries")}
                    </small>
                  </h1>
                </div>
                <div className="d-flex align-items-center py-2">
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 1
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(1, "today")}
                  >
                    {t("today")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 2
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(2, "yesterday")}
                  >
                    {t("yesterday")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 3
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(3, "current_month")}
                  >
                    {t("current_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 4
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(4, "previous_month")}
                  >
                    {t("previous_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex position-relative ${
                      activeBtn === 5
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-0`}
                  >
                    {t("custom")}{" "}
                    {activeBtn === 5 ? (
                      <span>
                        {" "}
                        &#160; | &#160; {reqBody.dates.start} -{" "}
                        {reqBody.dates.end}
                      </span>
                    ) : (
                      ""
                    )}
                    <DatePicker
                      onChange={(e) => {
                        if (e.length > 1) {
                          setActiveBtn(5);
                          setReqBody((prevData) => ({
                            ...prevData,
                            period: "custom",
                            dates: {
                              start: formatDate(e[0]),
                              end: formatDate(e[1]),
                            },
                          }));
                          setDataLoad(false);
                        }
                      }}
                      date={[]}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mt-8 p-0 collapse" id="kt_advanced_search_form">
                <div className="card"></div>
              </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="row mb-6">
                <div
                  className={`card card-flush h-lg-100 ${
                    !dataLoad ? "opacity-low" : ""
                  }`}
                >
                  {!dataLoad && <SpinnerDiv />}
                  <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        {t("calls_geography")}
                      </span>
                      {/* <span className="text-gray-400 pt-2 fw-semibold fs-6">
                    Updated 37 minutes ago
                  </span> */}
                    </h3>
                  </div>
                  <div className="card-body d-flex flex-center">
                    {displayData === 1 ? (
                      <BarChart data={data} />
                    ) : displayData === 0 ? (
                      <div className="width-100 display-flex flex-column gap-3">
                        <img
                          className="height-12"
                          src="/assets\media\illustrations\unitedpalms-1/no-data.svg"
                          alt="no-data"
                        />
                        <span className="no-data-title">{t("no_data")}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className={`card card-flush h-xl-100 ${
                    !dataLoad ? "opacity-low" : ""
                  }`}
                >
                  {!dataLoad && <SpinnerDiv />}
                  <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        {t("calls_duration_rep")}
                      </span>
                      <span className="text-gray-400 pt-2 fw-semibold fs-6">
                        {/* {t("stats_by_number")} */}
                      </span>
                    </h3>
                  </div>
                  <div className={`card-body pt-5`}>
                    {displayData === 1 ? (
                      <div className="flex-stats-center">
                        {" "}
                        <DiffBarChart
                          data={durationData}
                          title={t("total_duration_per_sec")}
                          name={t("total_duration_per_sec")}
                        />{" "}
                        <DiffBarChart
                          data={avgData}
                          title={t("avg_duration_per_sec")}
                          name={t("avg_duration_per_sec")}
                        />{" "}
                      </div>
                    ) : displayData === 0 ? (
                      <div className="width-100 display-flex flex-column gap-3">
                        <img
                          className="height-12"
                          src="/assets\media\illustrations\unitedpalms-1/no-data.svg"
                          alt="no-data"
                        />
                        <span className="no-data-title">{t("no_data")}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportGeo;
