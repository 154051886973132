import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultDateFormat } from "../../../constants/Utils";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import sendMessage from "../Services/sendMessage";
import moment from "moment";
import "moment/locale/fr";
import { Tooltip } from "react-tooltip";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const SideBar = ({
  sideBar,
  setSideBar,
  conversation,
  ticket_id,
  ticket_status,
  details,
}) => {
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng");

  if (language === "fr") {
    moment.locale("fr");
  } else {
    moment.locale("en");
  }

  useEffect(() => {
    setBody((prevData) => ({ ...prevData, ticket_id: ticket_id }));
  }, [ticket_id]);

  const textareaRef = useRef(null);

  const conversationRef = useRef(null);

  const autoResize = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "32px";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
  }, [conversation.length]);

  const [body, setBody] = useState({
    content: "",
    ticket_id: null,
    attachement: null,
  });

  useEffect(() => {
    setBody((prevData) => ({ ...prevData, content: "", attachement: null }));
  }, [sideBar]);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  // }, [conversation.length]);

  const [deleteFile, setDeleteFile] = useState(false);

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      const response = await sendMessage(body);
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      conversation.push({
        id: result.data[0].ticket_id,
        source: 1,
        source_id: result.data[0].source_id,
        content: body.content,
        status: 1,
        created_at: new Date(),
        attachements: result.data[0].attachments,
      });
      setBody((prevData) => ({ ...prevData, content: "", attachement: "" }));
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const openFileInNewWindow = (filePath) => {
    window.open(filePath, "_blank");
  };

  return (
    <div>
      <Tooltip id="my-tooltip" />
      <div
        className={`bg-body drawer drawer-end flex-drawer-export ${
          sideBar ? " drawer-on" : ""
        }`}
      >
        <div className="card-header d-flex sidebar-header px-9 justify-content-between align-items-center">
          <div className="py-3">
            <div className="d-flex gap-2 mw-75 flex-wrap">
              {details.class && (
                <div className="d-flex align-items-center gap-1">
                  <span className="fw-semibold fs-8 text-gray-500">
                    {t("class")}:
                  </span>
                  <span className="call-direction">
                    {" "}
                    {details.class === 1
                      ? t("tech_issues")
                      : details.class === 2
                      ? t("billing_text")
                      : details.class === 3
                      ? t("prod_inquiries")
                      : details.class === 4
                      ? t("feedback")
                      : ""}
                  </span>
                </div>
              )}

              {details.subject && (
                <div className="d-flex align-items-center gap-1">
                  <span className="fw-semibold fs-8 text-gray-500">
                    {t("subject")}:
                  </span>
                  <span className="call-direction">{details.subject}</span>
                </div>
              )}

              {details.created_at && (
                <div className="d-flex align-items-center gap-1">
                  <span className="fw-semibold fs-8 text-gray-500">
                    {t("date")}:
                  </span>
                  <span className="badge badge-light-primary fw-bold">
                    {defaultDateFormat(details.created_at)}
                  </span>
                </div>
              )}
              <div className="d-flex align-items-center gap-1">
                <span className="fw-semibold fs-8 text-gray-500">
                  {t("priority")}:
                </span>
                {details.priority === 1 ? (
                  <span className="badge badge-light-danger">{t("hight")}</span>
                ) : details.priority === 2 ? (
                  <span
                    className="badge badge-light-warning"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={t("priority")}
                  >
                    {t("medium")}
                  </span>
                ) : details.priority === 3 ? (
                  <span
                    className="badge badge-light-success"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={t("priority")}
                  >
                    {t("low")}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <svg
            onClick={() => {
              document.body.classList.remove("modal-open");
              setSideBar(false);
            }}
            viewBox="0 0 24 24"
            className="cursor-pointer"
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                opacity="0.5"
                d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22Z"
                fill="#cbcbcb"
              ></path>{" "}
              <path
                d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0607 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                fill="#3f4969"
              ></path>{" "}
            </g>
          </svg>
        </div>

        <div className="card-body p-6 h-75" id="kt_drawer_chat_messenger_body">
          <div
            className="scroll-y me-n5 pe-5 mh-100"
            data-kt-element="messages"
            ref={conversationRef}
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
            data-kt-scroll-offset="0px"
          >
            {conversation &&
              conversation.map((item) => (
                <div
                  className={`d-flex mb-10 ${
                    item.source === 1
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  <div
                    className={`d-flex flex-column ${
                      item.source === 1
                        ? "align-items-end"
                        : "align-items-start"
                    }`}
                  >
                    <div className="d-flex align-items-center mb-2">
                      <div
                        className={`d-flex gap-3 align-items-center ${
                          item.source === 1 ? "flex-row-reverse" : ""
                        }`}
                      >
                        <img
                          className="user-icon"
                          src={
                            item.source === 1
                              ? localStorage.getItem("avatar") != "null"
                                ? localStorage.getItem("avatar")
                                : "/assets/media/avatars/blank.png"
                              : item.avatar
                              ? item.avatar
                              : "/assets/media/avatars/blank.png"
                          }
                          alt="img"
                        />
                        <span className={`fs-5 fw-bold text-gray-900`}>
                          {item.source === 1 ? t("you") : item.full_name}
                        </span>
                        <span className="text-muted fs-7">
                          {new Date() - new Date(item.created_at) < 172800000
                            ? moment(item.created_at).fromNow()
                            : defaultDateFormat(item.created_at)}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`p-3 rounded text-gray-900 fw-semibold mw-lg-400px text-start ${
                        item.source === 1 ? "bg-light-primary" : "bg-light-info"
                      }`}
                      data-kt-element="message-text"
                    >
                      {item.content}
                    </div>
                    <div
                      className={`p-1 rounded text-gray-900 fw-semibold mt-1 cursor-pointer`}
                      data-kt-element="message-text"
                    >
                      {item.attachements.length > 0 &&
                        item.attachements.map((el) =>
                          el.document.endsWith("pdf") ? (
                            <svg
                              onClick={() => openFileInNewWindow(el.url)}
                              height={50}
                              width={50}
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 309.267 309.267"
                              xmlSpace="preserve"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g>
                                  {" "}
                                  <path
                                    style={{ fill: "#E2574C" }}
                                    d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658 c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"
                                  />{" "}
                                  <path
                                    style={{ fill: "#B53629" }}
                                    d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"
                                  />{" "}
                                  <path
                                    style={{ fill: "#FFFFFF" }}
                                    d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44 c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153 c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159 c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413 c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964 c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379 c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703 c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"
                                  />{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          ) : (
                            <svg
                              onClick={() => openFileInNewWindow(el.url)}
                              height={50}
                              width={50}
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M853.333333 960H170.666667V64h469.333333l213.333333 213.333333z"
                                  fill="#90CAF9"
                                ></path>
                                <path
                                  d="M821.333333 298.666667H618.666667V96z"
                                  fill="#E1F5FE"
                                ></path>
                                <path
                                  d="M448 490.666667l-149.333333 213.333333h298.666666z"
                                  fill="#1565C0"
                                ></path>
                                <path
                                  d="M597.333333 563.2L490.666667 704h213.333333z"
                                  fill="#1976D2"
                                ></path>
                                <path
                                  d="M672 522.666667m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
                                  fill="#1976D2"
                                ></path>
                              </g>
                            </svg>
                          )
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className={`card-footer p-6 sidebar-footer position-relative`}>
          {ticket_status === 4 ? (
            <span className="px-9 py-3 pe-none fw-bold c-grey">
              {t("ticket_closed_cant_send_message")}.
            </span>
          ) : (
            <div className="d-flex align-items-center gap-2">
              <div
                className={`d-flex align-items-center jutify-content-center gap-1 flex-column${
                  !body.attachement ? " d-none" : " uploaded-file2"
                }`}
              >
                <div className={`w-2em`}>
                  <div className="position-relative">
                    <svg
                      onMouseLeave={() => setDeleteFile(false)}
                      onClick={() =>
                        setBody((prevData) => ({
                          ...prevData,
                          attachement: "",
                        }))
                      }
                      className={`position-absolute cursor-pointer${
                        !deleteFile ? " d-none" : ""
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                          fill="#fd8181"
                        ></path>{" "}
                      </g>
                    </svg>

                    <svg
                      onMouseEnter={() => setDeleteFile(true)}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M7 0H2V16H14V7H7V0Z"
                          fill="#90CAF9"
                        ></path>{" "}
                        <path d="M9 0V5H14L9 0Z" fill="#90CAF9"></path>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
                <span className="fs-10px">
                  {body.attachement && body.attachement.name}
                </span>
              </div>
              <textarea
                ref={textareaRef}
                placeholder={t("type_a_message")}
                type="text"
                style={{ height: "32px" }}
                className="form-control form-control-sm form-control-solid autoResizeTextarea input-invisible"
                value={body.content}
                onChange={(e) =>
                  setBody((prevData) => ({
                    ...prevData,
                    content: e.target.value,
                  }))
                }
                onInput={autoResize}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    !e.shiftKey &&
                    body.content.length > 0
                  ) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              <label className="">
                <svg
                  viewBox="0 0 24 24"
                  className="cursor-pointer"
                  fill="none"
                  height={24}
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#7c5555"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M17.5 5.25581V16.5C17.5 19.5376 15.0376 22 12 22C8.96243 22 6.5 19.5376 6.5 16.5V5.66667C6.5 3.64162 8.14162 2 10.1667 2C12.1917 2 13.8333 3.64162 13.8333 5.66667V16.4457C13.8333 17.4583 13.0125 18.2791 12 18.2791C10.9875 18.2791 10.1667 17.4583 10.1667 16.4457V6.65116"
                      stroke="#737373"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
                <input
                  className="invisible-input"
                  type="file"
                  name="avatar"
                  accept="*"
                  onChange={(e) =>
                    setBody((prevData) => ({
                      ...prevData,
                      attachement: e.target.files[0],
                    }))
                  }
                />
              </label>

              {!loading ? (
                <svg
                  onClick={() => body.content && handleSendMessage()}
                  viewBox="0 0 15 15"
                  className="cursor-pointer"
                  height={20}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="matrix(1, 0, 0, 1, 0, 0)rotate(45)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M14.9541 0.709802C14.93 0.761862 14.8965 0.810638 14.8536 0.853553L5.40076 10.3064L8.07126 14.7573C8.16786 14.9183 8.34653 15.0116 8.53386 14.9989C8.72119 14.9862 8.88561 14.8696 8.95958 14.697L14.9541 0.709802Z"
                      fill="#00b2ff"
                    ></path>{" "}
                    <path
                      d="M4.69366 9.59931L0.242756 6.92876C0.0817496 6.83216 -0.0115621 6.65349 0.00115182 6.46616C0.0138657 6.27883 0.130462 6.11441 0.303045 6.04044L14.293 0.0447451C14.2399 0.0688812 14.1902 0.102782 14.1465 0.146447L4.69366 9.59931Z"
                      fill="#00b2ff"
                    ></path>
                  </g>
                </svg>
              ) : (
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {sideBar && (
        <div
          className="drawer-back"
          onClick={() => {
            document.body.classList.remove("modal-open");
            setSideBar(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default SideBar;
