import React from "react";
import { useTranslation } from "react-i18next";
import { convertCurrency } from "../../constants/Utils";

function DataTableSelectFilter({
  handleInputChange,
  column,
  placeholder,
  options,
  withAllOption = false,
}) {
  const { t } = useTranslation();
  
  return (
    <select
      className="search-input-dt search-input-dt-direction max-w-min-content"
      onChange={(e) => {
        handleInputChange(column, e.target.value);
      }}
    >
      <option disabled selected>
        {placeholder}
      </option>
      {withAllOption && (
        <option value="">{t("data_table.filters.select.all")}</option>
      )}
      {Object.entries(options).map(([key, value]) => (
        <option value={key} key={key}>
          {(key==="USD"|| key === "TND" || key === "EUR")?convertCurrency(key):key}
        </option>
      ))}
    </select>
  );
}

export default DataTableSelectFilter;
