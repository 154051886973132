import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerSecondary from "../../../../components/SpinnerSecondary";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import deleteTicket from "../../Services/deleteTicket";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const DeleteTicket = ({ closeModal, ticket_id, ticket_name, updateLine }) => {
  
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleDeleteTicket = async () => {
    try {
      setLoading(true);
      const response = await deleteTicket({ id: ticket_id, status: 4 });
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = response.json();

      successSwall(t("ticket_deleted"));

      closeModal();
      updateLine(ticket_id);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div>
      {loading && <SpinnerSecondary />}
      <div className={`warning-modal-container fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("close_ticket_confirmation_title")}</h3>
              <div className="fw-semibold fs-6 d-flex align-items-center text-gray-900 header-badge">
                <svg
                  viewBox="0 0 24 24"
                  className="me-1 svg-icon"
                  height={15}
                  fill="#a1a5b7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C21 19.6569 21 17.7712 21 14V10C21 6.22876 21 4.34315 19.8284 3.17157C18.6569 2 16.7712 2 13 2H11C7.22876 2 5.34315 2 4.17157 3.17157ZM7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.41421 16.4142 8.75 16 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8ZM7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12ZM8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H13C13.4142 16.75 13.75 16.4142 13.75 16C13.75 15.5858 13.4142 15.25 13 15.25H8Z"
                      fill="#a1a5b7"
                    ></path>
                  </g>
                </svg>{" "}
                {ticket_name}
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={closeModal}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="warning-container p-6">
            <div className="danger-drag-and-drop d-flex align-items-center p-6">
              <svg
                className="danger-color"
                width={50}
                height={50}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={14}
                  width={7}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 14)"
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={17}
                  width={2}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 17)"
                  fill="currentColor"
                />
              </svg>

              <div className="d-flex flex-column login-error-div">
                <h5 className="mb-1">{t("delete_confirmation")} ?</h5>
                <span className="danger-color">
                  {" "}
                  <span className="fw-bolder">
                    {t("note")}
                    {": "}
                  </span>
                  {t("ticket_delete_confirmation")}.
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton title={t("cancel")} onClick={closeModal} />
            <button
              type="submit"
              className="btn btn-disable btn-sm"
              onClick={() => confirmationSwal(handleDeleteTicket)}
            >
              
              <span> {t("close_ticket")}</span>

            </button>
          </div>
        </div>
      </div>
      <div className="modal-hide-div"
      //  onClick={closeModal}
       ></div>
    </div>
  );
};

export default DeleteTicket;
