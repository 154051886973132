import React, { useCallback, useRef, useState } from "react";

import { defaultDateFormat } from "../../../constants/Utils";

import moment from "moment";

import { useTranslation } from "react-i18next";

import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import "moment/locale/fr";
import { Tooltip } from "react-tooltip";
import DateTimePicker from "../../../components/reusables/DateTimePicker";

const Inbox = ({
  conversations,
  loading,
  showMore,
  changeConversation,
  index,
  changeIndex,
  changeFilter,
  filters,
}) => {
  const { t } = useTranslation();

  const scrollContainerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollContainerRef.current;

    if (scrollTop + clientHeight === scrollHeight) {
      showMore();
    }
  }, [showMore]);

  const language = localStorage.getItem("i18nextLng");

  if (language === "fr") {
    moment.locale("fr");
  } else {
    moment.locale("en");
  }

  const [dateFiltersVisibility, setDateFiltersVisibility] = useState(false);

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0 position-relative">
      {loading && <SpinnerDiv />}{" "}
      <div className={`card card-flush h-100 ${loading && "disabled-event"}`}>
        <Tooltip id="my-tooltip" />
        <div className="card-header pt-7" id="kt_chat_contacts_header">
          <div className="w-100 position-relative" autoComplete="off">
            <span className="svg-icon svg-icon-2 svg-icon-primary position-absolute top-50 translate-middle ms-6 sms-search-svg">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height={2}
                  rx={1}
                  transform="rotate(45 17.0365 15.1223)"
                  fill="currentColor"
                />
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <div className="d-flex gap-2">
              <input
                type="text"
                className="form-control form-control-solid px-11 lh-0 fs-1em"
                name="search"
                onInput={(e) => {
                  if (e.target.value.length === 0) {
                    setDateFiltersVisibility(false);
                    changeConversation("date_start", "");
                    changeConversation("date_end", "");
                  } else {
                    setDateFiltersVisibility(true);
                  }
                }}
                defaultValue=""
                placeholder={t("search_by_name_or_number")}
                onBlur={(e) => changeFilter("search", e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    changeFilter("search", e.target.value);
                  }
                }}
              />

              <div
                className={`d-flex align-items-center justify-content-center gap-2 ${
                  !dateFiltersVisibility && "disabled-event"
                }`}
              >
                <div
                  className="d-flex align-items-center justify-content-center cal-container cursor-pointer position-relative p-07 "
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={t("date_start")}
                >
                  <svg
                    height={16}
                    version={1.0}
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g>
                        {" "}
                        <path
                          fill="#5345db"
                          d="M60,4h-7V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H17V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H4 C1.789,4,0,5.789,0,8v52c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V8C64,5.789,62.211,4,60,4z M18,53c0,0.553-0.447,1-1,1h-6 c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M18,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5 c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M18,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6 c0.553,0,1,0.447,1,1V31z M30,53c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M30,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M30,31 c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V31z M42,53c0,0.553-0.447,1-1,1h-6 c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M42,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5 c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M42,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6 c0.553,0,1,0.447,1,1V31z M54,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M54,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V31z M62,15H2V8 c0-1.104,0.896-2,2-2h7v4c0,1.657,1.343,3,3,3s3-1.343,3-3V6h30v4c0,1.657,1.343,3,3,3s3-1.343,3-3V6h7c1.104,0,2,0.896,2,2V15z"
                        />{" "}
                      </g>{" "}
                    </g>
                  </svg>

                  <DateTimePicker
                    onChange={(e) => {
                      changeFilter("date_start", e[0]);
                    }}
                    date={filters.date_start}
                    maxDate={filters.date_end}
                  />
                </div>

                <div
                  className="d-flex align-items-center justify-content-center cal-container cursor-pointer position-relative p-07"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={t("date_end")}
                >
                  <svg
                    height={16}
                    version={1.0}
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g>
                        {" "}
                        <path
                          fill="#5345db"
                          d="M60,4h-7V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H17V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H4 C1.789,4,0,5.789,0,8v52c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V8C64,5.789,62.211,4,60,4z M18,53c0,0.553-0.447,1-1,1h-6 c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M18,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5 c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M18,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6 c0.553,0,1,0.447,1,1V31z M30,53c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M30,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M30,31 c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V31z M42,53c0,0.553-0.447,1-1,1h-6 c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V53z M42,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5 c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M42,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6 c0.553,0,1,0.447,1,1V31z M54,42c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V42z M54,31c0,0.553-0.447,1-1,1h-6c-0.553,0-1-0.447-1-1v-5c0-0.553,0.447-1,1-1h6c0.553,0,1,0.447,1,1V31z M62,15H2V8 c0-1.104,0.896-2,2-2h7v4c0,1.657,1.343,3,3,3s3-1.343,3-3V6h30v4c0,1.657,1.343,3,3,3s3-1.343,3-3V6h7c1.104,0,2,0.896,2,2V15z"
                        />{" "}
                      </g>{" "}
                    </g>
                  </svg>
                  <DateTimePicker
                    onChange={(e) => {
                      changeFilter("date_end", e[0]);
                    }}
                    date={filters.date_end}
                    minDate={filters.date_start}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="card-body my-3 p-7 overflow-scroll h-406px"
          id="kt_chat_contacts_body"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {conversations &&
            conversations.map((item, rowIndex) => (
              <div
                className={`d-flex flex-stack p-3 cursor-pointer conversation-container ${
                  index === rowIndex && "active-conversation"
                }`}
                onClick={() => {
                  changeIndex(rowIndex);
                  changeConversation(item.contact);
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="symbol  symbol-45px symbol-circle ">
                    <div class="flag-container">
                      <img
                        src={`assets/media/flags/${item.country.toLowerCase()}.svg`}
                        class="flag-style w-25px"
                        alt=""
                        style={{ borderRadius: 4 }}
                      />
                    </div>
                  </div>
                  <div className="ms-5 d-flex flex-column">
                    {/* <div className="d-flex align-items-center gap-2"> */}
                    <span className="fs-5 fw-bold text-gray-900 mb-2">
                      {item.contact}
                    </span>

                    {/* </div> */}
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-muted fs-8">
                        {item.last_message.direction == 2 && `${t("you")}:`}{" "}
                        {item.last_message.content.length < 21
                          ? item.last_message.content
                          : item.last_message.content.slice(0, 12) + "..."}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end ms-2">
                  <div className="mb-2">
                    {item.last_message.direction == 2 ? (
                      <svg
                        height={15}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <rect width="24" height="24" fill="white"></rect>{" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14V11.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L12.5858 10H10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8H14.6717C15.4054 8 16 8.59489 16 9.32837V14Z"
                            fill="#8d8d8d"
                          ></path>{" "}
                        </g>
                      </svg>
                    ) : item.last_message.direction == 1 ? (
                      <svg
                        height={15}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform="matrix(-1, 0, 0, -1, 0, 0)"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <rect width="24" height="24" fill="white"></rect>{" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14V11.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L12.5858 10H10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8H14.6717C15.4054 8 16 8.59489 16 9.32837V14Z"
                            fill="#8d8d8d"
                          ></path>{" "}
                        </g>
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="text-muted fs-8">
                    {new Date() - new Date(item.last_message.date) < 172800000
                      ? moment(item.last_message.date).fromNow()
                      : defaultDateFormat(item.last_message.date)}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Inbox;
