import React, { useEffect, useRef, useState } from "react";
import twoFactAuth from "./Services/verification.service";
import Spinner from "../components/Spinner";
import LoginService from "./login.service";
import { useTranslation } from "react-i18next";
import resendCode from "./Services/resendCode.service";
import SpinnerSecondary from "../components/SpinnerSecondary";

const Verification = () => {
  const { t } = useTranslation();

  const [resendLoad, setResendLoad] = useState(false);

  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);
  const fifthInputRef = useRef(null);
  const sixthInputRef = useRef(null);

  const handleInputChange = (currentInputRef, nextInputRef, item) => (e) => {
   
    if (e.target.value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
    setCode((prevData) => ({
      ...prevData,
      [item]: currentInputRef.current.value,
    }));
    setCodeSent("");
    setError(false);
  };

  const [load, setLoad] = useState(false);

  useEffect(() => {
    Promise.all([
      import("./Style/Login.css"),
      import("./Style/bootstrap.min.css"),
      import("./Style/all.min.css"),
    ]).then(() => {
      setLoad(true);
    });
  }, []);

  const fetchPreferences = async () => {
    try {
      const response = await LoginService.getPreferences();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();

      if (fetchedData) {
        localStorage.setItem("i18nextLng", fetchedData.data[0].language==="EN"?"en-EN":"fr-FR")
          handleLangChange(fetchedData.data[0].language==="EN"?"en-EN":"fr-FR");
        localStorage.setItem(
          "data",
          JSON.stringify({
            id: fetchedData.data[0].id,
            language: fetchedData.data[0].language?fetchedData.data[0].language:"null",
            timezone: fetchedData.data[0].timezone?fetchedData.data[0].timezone:"null",
            date_format: fetchedData.data[0].date_format?fetchedData.data[0].date_format:"null",
            time_format: fetchedData.data[0].time_format?fetchedData.data[0].time_format:"null",
            currency_format: fetchedData.data[0].currency_format?fetchedData.data[0].currency_format:"null",
            country_format: fetchedData.data[0].country_format?fetchedData.data[0].country_format:"null",
            default_view: fetchedData.data[0].default_view?fetchedData.data[0].default_view:"null",
          })
        );
      }
    } catch (error) {
      setError({ title: t("error"), message: t("errors.500") });

    }
  };

  const fetchData = async () => {
    setResendLoad(true)
    try {
      const response = await twoFactAuth(
        `${code.first}${code.second}${code.third}${code.fourth}${code.fifth}${code.sixth}`
      );
      if (!response.ok) {
        setResendLoad(false)
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      const username = await fetchedData.data[0].username;
      const firstname = await fetchedData.data[0].firstname;
      const lastname = await fetchedData.data[0].lastname;
      const avatar = await fetchedData.data[0].avatar;
      const email = await fetchedData.data[0].login;
      const role = await fetchedData.data[0].role;
      const customer_id = await fetchedData.data[0].customer_id;
      const total_numbers = await fetchedData.data[0].totale_numbers;
      const authentication = await fetchedData.code;

      const total_extensions = await fetchedData.data[0].totale_extensions;
      const source_id = await fetchedData.data[0].source_id_sms;
      const country = await fetchedData.data[0].country;

      const have_sms = await fetchedData.data[0].have_sms

      if (authentication == "authenticated") {
        localStorage.setItem("authenticated", true);
        localStorage.setItem("username", username);
        localStorage.setItem("firstname", firstname ? firstname : "");
        localStorage.setItem("lastname", lastname ? lastname : "");
        localStorage.setItem("avatar", avatar);
        localStorage.setItem("email", email ? email : "");
        localStorage.setItem("role", role ? role : "");
        localStorage.setItem("customer_id", customer_id);
        localStorage.setItem("total_numbers", total_numbers);
        localStorage.setItem("total_users",total_extensions )
        localStorage.setItem("source_id", source_id)
        localStorage.setItem("country", country)
        localStorage.setItem("have_sms", have_sms)


        await fetchPreferences(localStorage.getItem("token"));
        window.location.href = `${process.env.REACT_APP_LOCAL}/dashboard`;
      }
    } catch (error) {
      setError({ title: t("error"), message: t("invalid_provided_code") });
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await resendCode();
      setResendLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      setCodeSent("2fa_processing");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem("code", "code_already_sent");
  }, []);

  const [codeSent, setCodeSent] = useState(localStorage.getItem("code"));

  const [error, setError] = useState("");

  const [code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });

  const [menuLang, setMenuLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { i18n } = useTranslation();

  const handleLangChange = (item) => {
    setSelectedLang(item);
    setMenuLang(false);
    localStorage.setItem("i18nextLng", item);
    i18n.changeLanguage(item);
  };

  const menuRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuLang(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div id="main-wrapper" className="oxyy-login-register">
          {resendLoad && <SpinnerSecondary />}
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-7 d-flex flex-column order-2 order-md-1">
                <div className="lang-switcher" ref={menuRef}>
                  <span
                    className="login-selected-lang p-1 px-3"
                    onClick={() => setMenuLang(!menuLang)}
                  >
                    {selectedLang.includes("fr")
                      ? t("french")
                      : selectedLang.includes("en")
                      ? t("english")
                      : ""}
                    <img
                      className="login-flags"
                      src={`assets/media/flags/${
                        selectedLang.includes("fr")
                          ? "fr"
                          : selectedLang.includes("en")
                          ? "gb"
                          : ""
                      }.svg`}
                      alt=""
                    />{" "}
                  </span>
                  <div
                    className={`login-lang-container ${
                      menuLang && "container-show"
                    }`}
                  >
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("fr")}
                    >
                      <span>{t("french")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/fr.svg"
                          alt=""
                        />
                      </span>
                    </span>
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("en")}
                    >
                      <span> {t("english")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/gb.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="container my-auto py-5">
                  <div className="row mx-0">
                    <div className="col-11 col-lg-9 col-xl-8 mx-auto text-center">
                      <div className="logo mb-5 text-center">
                        
                          <img
                            src="/assets/media/myxtel.png"
                            alt="GoCall"
                            width={180}
                          />
                        
                      </div>
                      <h4 className="mb-5">{t("two_step_verification")}</h4>
                      <p>
                        <img
                          className="img-fluid"
                          src="/assets/media/icons/mail-icon.png"
                          alt="verification"
                        />
                      </p>
                      <p className="text-muted mb-4">
                        {t("enter_email_sent_to")} <br />
                        <span className="text-dark text-4">
                          {localStorage.getItem("sent_to")}
                        </span>
                      </p>

                      {error && (
                        <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  fill="currentColor"
                                />
                                <rect
                                  x="7"
                                  y="15.3137"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-45 7 15.3137)"
                                  fill="currentColor"
                                />
                                <rect
                                  x="8.41422"
                                  y="7"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 8.41422 7)"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </span>
                          <div className="d-flex flex-column login-error-div">
                            <h5 className="mb-1 display-flex-start">
                              {error.title}
                            </h5>
                            <span>{error.message}</span>
                          </div>
                        </div>
                      )}

                      {codeSent == "code_already_sent" ? (
                        <div className="alert alert-warning d-flex align-items-center pad-5 mb-10">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.3"
                                  d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                                  fill="currentColor"
                                />
                                <rect
                                  x="13.6993"
                                  y="13.6656"
                                  width="4.42828"
                                  height="1.73089"
                                  rx="0.865447"
                                  transform="rotate(45 13.6993 13.6656)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </span>
                          <div className="d-flex flex-column login-error-div">
                            <h5 className="mb-1 display-flex-start">
                              {t("email_already_sent_title")}
                            </h5>
                            <span>{t("email_already_sent_desc")}.</span>
                          </div>
                        </div>
                      ) : codeSent == "2fa_processing" ? (
                        <div className="alert alert-success d-flex align-items-center pad-5 mb-10">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                  fill="currentColor"
                                />
                                <path
                                  opacity="0.3"
                                  d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </span>
                          <div className="d-flex flex-column login-error-div">
                            <h5 className="mb-1 display-flex-start">
                              {t("email_sent_title")}
                            </h5>
                            <span>{t("email_sent_desc")}.</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <form
                        method="post"
                        onKeyDown={(e) => {
                          if (e.code?.includes("Enter")) {
                            fetchData();
                          }
                        }}
                      >
                        <div className="input-group">
                          <input
                            ref={firstInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.first}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              firstInputRef,
                              secondInputRef,
                              "first"
                            )}
                          />
                          <input
                            ref={secondInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.second}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              secondInputRef,
                              thirdInputRef,
                              "second"
                            )}
                          />
                          <input
                            ref={thirdInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.third}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              thirdInputRef,
                              fourthInputRef,
                              "third"
                            )}
                          />
                          <input
                            ref={fourthInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.fourth}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              fourthInputRef,
                              fifthInputRef,
                              "fourth"
                            )}
                          />

                          <input
                            ref={fifthInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.fifth}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              fifthInputRef,
                              sixthInputRef,
                              "fifth"
                            )}
                          />

                          <input
                            ref={sixthInputRef}
                            type="text"
                            className="form-control text-center text-6 py-2"
                            value={code.sixth}
                            maxLength={1}
                            required=""
                            autoComplete="off"
                            onChange={handleInputChange(
                              sixthInputRef,
                              null,
                              "sixth"
                            )}
                          />
                        </div>
                        <div className="d-grid my-4">
                          <span
                            className={`btn btn-primary shadow-none ${(!code.first || !code.second || !code.third || !code.fourth || !code.fifth || !code.sixth) &&"disabled-event"}`}
                            type="submit"
                            onClick={() => fetchData()}
                          >
                            {t("verify")}
                          </span>
                        </div>
                      </form>
                      <p className="text-center text-2 text-muted mb-0">
                        {t("not_received_code")}?{" "}
                        <a
                          href="javascript:"
                          onClick={() => {
                            setResendLoad(true);
                            setError(false);
                            handleResendCode();
                          }}
                        >
                          {t("resend_code")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container py-2">
                  <p className="text-2 text-muted text-center mb-0">
                    {t("copyrights")} © 2024 <span>ITwise</span>.{" "}
                    {t("copyrights_reserved")}.
                  </p>
                </div>
              </div>
              <div className="col-md-5 order-1 order-md-2">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div className="hero-mask opacity-8 bg-secondary" />
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{ backgroundImage: 'url("/assets/img/bg2.jpg")' }}
                  ></div>
                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto py-5">
                      <div className="col-10 col-lg-9 mx-auto">
                        <h1 className="text-10 text-white mb-4">
                          {t("account_security_parag")}.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verification;
