const unallocateNumbers = async (body) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/interface/allocate/numbers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        numbers: body.numbers,
        user_id: body.extension_id,
        operation_type: 2,
      }),
    }
  );
};
export default unallocateNumbers;