import ListingDatatable from "../Components/DataTable";
import { useTranslation } from "react-i18next";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ListSms = () => {
  const { t } = useTranslation();

  useEffect(()=>{localStorage.setItem("active", 5);
  document.dispatchEvent(new CustomEvent("activePage"))
},[])

  const [selectedList, setSelectedList] = useState(
    parseInt(localStorage.getItem("active"))
  );

  return (
    <div
      className="app-wrapper flex-column flex-row-fluid "
      id="kt_app_wrapper"
    >
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="row">
                <div className="flex-lg-row-fluid">
                  <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                    <li className="nav-item">
                      <Link to="/listing/calls"
                        className={`nav-link text-active-primary pb-4 ${
                          selectedList === 4 && "active show"
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => {
                          setSelectedList(4);
                          localStorage.setItem("active", 4);
                          document.dispatchEvent(new CustomEvent("activePage"))
                        }}
                      >
                        {t("calls")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/listing/sms"
                        className={`nav-link text-active-primary pb-4 ${
                          selectedList === 5 && "active show"
                        }`}
                        onClick={() => {
                          setSelectedList(5);
                          localStorage.setItem("active", 5);
                          document.dispatchEvent(new CustomEvent("activePage"))
                        }}
                        data-kt-countup-tabs="true"
                        data-bs-toggle="tab"
                      >
                        {t("sms")}{" "}
                      </Link>
                    </li>
                  </ul>
                  <ListingDatatable selectedList={selectedList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSms;
