import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { getNumberScopes } from "../../Services/getNumberScopes";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import CountriesAndContinents from "../../../../Data/CountriesAndContinents.json";
import { successSwall } from "../../../../components/Alerts/successAlert";
import { addOutboundScope } from "../../Services/addOutboundScope";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const AddOutboundCallingScope = ({
  closeModal,
  number,
  number_id,
  country,
  number_scopes,
  changeData,
  name,
}) => {
  const { t } = useTranslation();

  const [draggedItem, setDraggedItem] = useState(null);

  const [countries, setCountries] = useState({ countries: [], display: 0 });

  const [availableCountries, setAvailableCountries] = useState([]);

  const unselectCountry = (event) => {
    const [id, code, continent] = event.currentTarget.id.split(":");
    setCountries((prevData) => ({
      ...prevData,
      countries: [
        ...countries.countries,
        { id: JSON.parse(id), iso_code: code, continent: continent },
      ],
    }));
    setAvailableCountries(
      availableCountries.filter((countries) => countries.iso_code !== code)
    );
  };

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    if (draggedItem === null) return;
    const newCountries = [...countries.countries];
    const draggedItemContent = newCountries[draggedItem];
    newCountries.splice(draggedItem, 1);
    setCountries((prevData) => ({ ...prevData, countries: newCountries }));
    setAvailableCountries((prevAvailableCountries) => [
      ...prevAvailableCountries,
      draggedItemContent,
    ]);
    setDraggedItem(null);
  };

  const [loading, setLoading] = useState(false);

  const fetchNumberScopes = async () => {
    try {
      setLoading(true);
      const response = await getNumberScopes(number_id);
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      if (result.total > 0) {
        let newCountries = [];
        let existedCountries = [];
        result.data.forEach((element) => {
          if (
            !number_scopes.includes(
              element.id[0] || !number.scopes.includes(element.id[1])
            )
          ) {
            newCountries.push({
              iso_code: element.country,
              id: element.id,
              continent: CountriesAndContinents.find(
                (item) => item.code === element.country
              ).continent,
            });
          } else {
            existedCountries.push({
              iso_code: element.country,
              id: element.id,
              continent: CountriesAndContinents.find(
                (item) => item.code === element.country
              ).continent,
            });
          }
        });
        setCountries({ display: 1, countries: newCountries });
        setAvailableCountries(existedCountries);
      } else {
        setCountries({ display: 2, countries: [] });
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchNumberScopes();
      }
    }
  };

  const refetchNumberScopes = async () => {
    try {
      setLoading(true);
      const response = await getNumberScopes(number_id);
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      if (result.total > 0) {
        let newCountries = [];
        let existedCountries = [];
        result.data.forEach((element) => {
          if (
            !number_scopes.includes(
              element.id[0] || !number.scopes.includes(element.id[1])
            )
          ) {
            newCountries.push({
              iso_code: element.country,
              id: element.id,
              continent: CountriesAndContinents.find(
                (item) => item.code === element.country
              ).continent,
            });
          } else {
            existedCountries.push({
              iso_code: element.country,
              id: element.id,
              continent: CountriesAndContinents.find(
                (item) => item.code === element.country
              ).continent,
            });
          }
        });
        setCountries({ display: 1, countries: newCountries });

        setAvailableCountries(existedCountries);
      } else {
        setCountries({ display: 2, countries: [] });
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    fetchNumberScopes();
  }, []);

  useEffect(() => {
    let newScope = [];
    availableCountries.forEach((element) => {
      newScope.push(...element.id);
    });
    setReqBody((prevData) => ({
      ...prevData,
      scope: newScope,
    }));
  }, [availableCountries]);

  const [reqBody, setReqBody] = useState({
    number_id: number_id,
    scope: number_scopes,
  });

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await addOutboundScope(reqBody);
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      successSwall(t("outbound_scope_updated"));
      closeModal();

      changeData("outbound_scope", number_id, reqBody.scope);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div>
      <div className={`modal-container allocate-user-modal fade-in-down`}>
        <div className={` modal-show ${loading && "disabled-event"}`}>
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("add_outbound_calling_scope")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                {name && (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="svg-icon svg-icon-6 me-1">
                      <svg
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#b9c4d1"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: " .st0{fill:;} ",
                            }}
                          />{" "}
                          <g>
                            {" "}
                            <path
                              className="st0"
                              d="M239.732,12.188L0,0l12.176,239.742l42.29,42.29l-9.192-2.301c-9.079,37.239-12.986,70.45-12.986,99.069 c0,33.794,5.399,60.91,14.827,80.798c4.713,9.877,10.572,17.924,17.699,23.906c7.015,5.97,15.746,9.652,24.938,9.652 c8.732,0,17.239-3.333,24.826-8.855c7.475-5.511,14.366-13.322,20.572-23.322c10.338-16.667,16.78-38.384,20.922-63.333 c0.684-4.018,1.256-8.037,1.828-12.178l-20.909-20.92c-0.808,10.236-1.952,20.112-3.568,29.427 c-3.794,23.098-10.001,42.402-17.7,54.816c-5.162,8.158-10.224,13.794-14.703,17.014c-4.602,3.334-8.159,4.254-11.268,4.366 c-3.22-0.113-6.319-1.145-10.224-4.254c-5.746-4.714-12.188-14.59-16.78-29.417c-4.602-14.826-7.475-34.253-7.475-57.7 c0-24.366,2.986-53.21,10.461-85.499l71.257,71.246c1.493-15.399,2.065-31.718,2.065-48.384c0-55.274-7.015-114.12-16.318-153.996 c-10.573,0.224-21.257-3.794-29.304-11.841c-15.635-15.746-15.635-41.144,0-56.891c15.746-15.746,41.144-15.746,56.892,0 c15.735,15.747,15.735,41.145,0,56.891c-1.841,1.728-3.682,3.334-5.746,4.714c3.333,13.558,6.206,28.956,8.731,45.623 c5.286,35.286,8.732,76.083,8.732,115.5c0,24.142-1.269,47.7-4.142,69.305L284.446,512L512,284.446L239.732,12.188z"
                            />{" "}
                            <path
                              className="st0"
                              d="M143.996,152.515c-3.558-14.142-7.352-26.094-11.718-35.523l-20.808,9.776 c3.333,7.126,6.903,18.036,10.236,31.258c0.348,1.38,0.685,2.76,1.033,4.141c7.586-0.123,15.285-2.537,21.841-7.127 C144.456,154.232,144.232,153.311,143.996,152.515z"
                            />{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                    {name}
                  </div>
                )}

                <div className="d-flex align-items-center text-gray-900 header-badge">
                <span className="svg-icon svg-icon-6 me-1">
                    <svg
                      height={24}
                      version="1.1"
                      id="_x32_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <style
                          type="text/css"
                          dangerouslySetInnerHTML={{
                            __html: " .st0{fill:currentColor;} ",
                          }}
                        />{" "}
                        <g>
                          {" "}
                          <path
                            className="st0"
                            d="M255.998,0.002C114.606,0.012,0.01,114.604,0,256c0.01,141.406,114.65,255.328,255.926,255.998h0.334 l0.297-0.009c27.124,0.038,49.507-8.527,64.961-22.594c15.468-14.01,23.727-33.254,23.708-52.736 c0.02-9.148-1.914-18.306-5.521-27.024c6.086-3.464,10.143-6.612,11.301-7.444c4.152-2.957,16-18.766,7.693-31.79 c-8.344-13.014-38.042-42.678-46.152-47.702c-8.086-5.015-21.598-0.124-28.105,9.426c-6.526,9.55-11.674,6.689-11.674,6.689 s-18.516-14.957-44.124-66.621c-25.607-51.694-26.263-75.454-26.263-75.454s0.833-5.847,12.388-5.263 c11.53,0.621,23.598-7.168,24.516-16.66c0.928-9.464-4.698-51.091-10-65.598c-5.316-14.516-25.062-14.65-29.928-13.138 c-4.89,1.502-55.033,13.712-59.014,66.21c-3.966,52.506,9.565,100.18,28.943,139.309c19.387,39.119,49.128,78.765,93.3,107.406 c17.89,11.598,35.058,13.1,49.493,10.67c2.483,5.54,3.718,11.291,3.746,16.985c-0.028,11.292-4.621,22.354-14.066,30.966 c-9.469,8.564-24.071,14.928-45.2,14.967l-0.516,0.009C130.797,481.96,29.387,381.09,29.397,256 c0.01-62.621,25.339-119.186,66.367-160.237c41.053-41.023,97.612-66.354,160.234-66.364c62.621,0.01,119.181,25.34,160.232,66.364 c41.033,41.052,66.354,97.606,66.373,160.237c-0.01,38.67-9.666,74.966-26.698,106.784c-9.531,17.837-21.397,34.23-35.177,48.812 c-5.569,5.905-5.301,15.206,0.594,20.776c5.894,5.578,15.205,5.32,20.784-0.584c15.54-16.46,28.937-34.976,39.712-55.139 C501.071,340.717,512,299.589,512,256C511.98,114.604,397.389,0.012,255.998,0.002z"
                          />{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  {number}
                </div>

                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                    <img
                      alt="Pic"
                      src={`assets/media/flags/${country}.svg`}
                      className="w-15px h-15px"
                    />
                    <span>
                      {country &&
                        country !== "unknown" &&
                        t(`countries.${country.toUpperCase()}`)}
                    </span>
                  </div>
                  <span data-number-country="" />
                </div>

              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body p-6">
            {countries.display === 1 ? (
              <div className="row row-cols-lg-2 g-10">
                <div className="col">
                  <div className="card card-bordered">
                    <div className="card-header min-h-50px">
                      <div className="card-title">
                        <h4 className="card-label fs-5">
                          {t("available_countries")}
                        </h4>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="card">
                        <div
                          className="card-header collapsible cursor-pointer rotate min-h-50px"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_collapsible_eu"
                        >
                          <h3 className="card-title fs-7">{t("europe")}</h3>
                          <div className="card-toolbar rotate-180">
                            <span className="svg-icon svg-icon-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x={11}
                                  y={18}
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 18)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div id="kt_collapsible_eu" className="collapse show">
                          <div className="scope-countries-container p-5">
                            {countries.countries.map(
                              (item, index) =>
                                item.continent == "eu" && (
                                  <div
                                    className="d-flex flex-stack drag_countries"
                                    onDragStart={() => handleDragStart(index)}
                                  >
                                    <img
                                      src={`assets/media/flags/${item.iso_code.toLocaleLowerCase()}.svg`}
                                      className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                      style={{ borderRadius: 4 }}
                                      alt=""
                                    />
                                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                      <div className="me-5">
                                        <a
                                          href="#"
                                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                                        >
                                          {t(`countries.${item.iso_code}`)}
                                        </a>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="m-0">
                                          <span className="badge fs-base">
                                            <a
                                              href="#"
                                              className="btn btn-icon btn-hover-light-primary draggable-handle"
                                            >
                                              <span className="svg-icon svg-icon-2x">
                                                <svg
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header collapsible cursor-pointer rotate min-h-50px"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_collapsible_oc"
                        >
                          <h3 className="card-title fs-7">{t("ocean")}</h3>
                          <div className="card-toolbar rotate-180">
                            <span className="svg-icon svg-icon-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x={11}
                                  y={18}
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 18)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <div id="kt_collapsible_oc" className="collapse show">
                          <div className="scope-countries-container p-5">
                            {countries.countries.map(
                              (item, index) =>
                                item.continent == "oc" && (
                                  <div
                                    className="d-flex flex-stack drag_countries"
                                    onDragStart={() => handleDragStart(index)}
                                  >
                                    <img
                                      src={`assets/media/flags/${item.iso_code.toLocaleLowerCase()}.svg`}
                                      className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                      style={{ borderRadius: 4 }}
                                      alt=""
                                    />
                                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                      <div className="me-5">
                                        <a
                                          href="#"
                                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                                        >
                                          {t(`countries.${item.iso_code}`)}
                                        </a>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="m-0">
                                          <span className="badge fs-base">
                                            <a
                                              href="#"
                                              className="btn btn-icon btn-hover-light-primary draggable-handle"
                                            >
                                              <span className="svg-icon svg-icon-2x">
                                                <svg
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header collapsible cursor-pointer rotate min-h-50px"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_collapsible_am"
                        >
                          <h3 className="card-title fs-7">{t("america")}</h3>
                          <div className="card-toolbar rotate-180">
                            <span className="svg-icon svg-icon-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x={11}
                                  y={18}
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 11 18)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div id="kt_collapsible_am" className="collapse show">
                          <div className="scope-countries-container p-5">
                            {countries.countries.map(
                              (item, index) =>
                                item.continent == "am" && (
                                  <div
                                    className="d-flex flex-stack drag_countries"
                                    onDragStart={() => handleDragStart(index)}
                                  >
                                    <img
                                      src={`assets/media/flags/${item.iso_code.toLocaleLowerCase()}.svg`}
                                      className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                      style={{ borderRadius: 4 }}
                                      alt=""
                                    />
                                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                      <div className="me-5">
                                        <a
                                          href="#"
                                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                                        >
                                          {t(`countries.${item.iso_code}`)}
                                        </a>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="m-0">
                                          <span className="badge fs-base">
                                            <a
                                              href="#"
                                              className="btn btn-icon btn-hover-light-primary draggable-handle"
                                            >
                                              <span className="svg-icon svg-icon-2x">
                                                <svg
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-bordered h-100">
                    <div className="card-header min-h-50px">
                      <div className="card-title">
                        <h4 className="card-label fs-5">
                          {t("selected_coutries")}
                        </h4>
                      </div>
                    </div>
                    <div
                      className="modal-selected-scope-countries-container p-5 h-40rem"
                      onDragOver={(event) => handleDragOver(event)}
                      onDrop={() => handleDrop()}
                    >
                      {availableCountries.map((item, index) => (
                        <div
                          className="d-flex flex-stack drag_countries"
                          onDragStart={() => handleDragStart(index)}
                        >
                          <img
                            src={`assets/media/flags/${item.iso_code.toLocaleLowerCase()}.svg`}
                            className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                            style={{ borderRadius: 4 }}
                            alt=""
                          />
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                {t(`countries.${item.iso_code}`)}
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="">
                                <div
                                  className="delete_country"
                                  id={`[${item.id}]:${item.iso_code}:${item.continent}`}
                                  onClick={unselectCountry}
                                >
                                  <svg
                                    className="delete_country_svg"
                                    fill="#ff0000"
                                    height="64px"
                                    width="64px"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 460.77 460.77"
                                    xmlSpace="preserve"
                                    stroke="#ff0000"
                                    strokeWidth="0.00460775"
                                  >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />{" "}
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : countries.display === 2 ? (
              <div className="h-100 w-100 d-flex align-items-center flex-column justify-content-center gap-6 p-8">
                <img
                  height={150}
                  src="/assets/media/illustrations/unitedpalms-1/earth.png"
                  alt="no-data"
                  style={{ opacity: "0.6" }}
                />
                <span className="fs-7 fw-semibold text-gray-600">
                  {t("no-scope-countries-available")}
                </span>
              </div>
            ) : (
              <SpinnerDiv />
            )}
          </div>

          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />
            <button
              type="submit"
              className={`btn btn-primary-active btn-sm ${
                countries.display !== 1 && "disabled-event"
              }`}
              onClick={() => confirmationSwal(handleUpdate)}
            >
              <span> {t("submit")}</span>
              <span className="indicator-progress">
                {t("please_wait")}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default AddOutboundCallingScope;
