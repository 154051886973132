import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

function ExportDateTimePicker({ dateStartChange, dateEndChange, isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  return (
    <div className="dateTimePickerFlex container-flat">
      <Flatpickr
        options={{
          enableTime: true,
          dateFormat: "y/m/d H:i",
          locale: "default",
          onClose: handleClose, 
          onOpen: handleOpen, 
        }}
        placeholder={t("start")}
        onChange={dateStartChange}
      />

      <Flatpickr
        options={{
          enableTime: true,
          dateFormat: "y/m/d H:i",
          locale: "default",
          onClose: handleClose, 
          onOpen: handleOpen, 
        }}
        placeholder={t("end")}
        onChange={dateEndChange}
      />
    </div>
  );
}

export default ExportDateTimePicker;
