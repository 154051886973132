import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultDateFormat } from "../../../constants/Utils";
import sendMessage from "../Services/sendMessage";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import calculateMessagePrice from "../Services/calculateMessagePrice";
import { messagePriceSwal } from "../../../components/Alerts/messagePriceSwal";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Conversation = ({
  conversation,
  changeBody,
  body,
  updateConversation,
  loading,
  showMore,
  total,
  skip,
  senderNumber,
  country,
  last_message_date,
}) => {
  const { t } = useTranslation();

  const [sendLoading, setSendLoading] = useState(false);

  const chatBodyRef = useRef(null);

  const [sentMessages, setSentMessages] = useState(0);

  useEffect(() => {
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [total, sentMessages]);

  const handleScroll = useCallback(() => {
    const { scrollTop } = chatBodyRef.current;

    if (scrollTop === 0 && (skip + 1) * 10 < total) {
      showMore();
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight / 2;
    }
  }, [showMore]);

  const handleSendMessage = async () => {
    try {
      setSendLoading(true);
      const response = await sendMessage(body);
      setSendLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      changeBody("message", "");

      setSentMessages((prevD) => prevD + 1);

      updateConversation({
        id: result.data.message_draft.id,
        source: 2,
        date: new Date(),
        content: body.message,
        sender_phone_number: senderNumber,
      });
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const handleCalculateMessagePrice = async (message_id) => {
    try {
      const response = await calculateMessagePrice(message_id);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      messagePriceSwal(result.data.totalPrice + result.data.currency, () => {
        changeBody("message", "");

        setSentMessages((prevD) => prevD + 1);
        updateConversation({
          source: 2,
          date: new Date(),
          content: body.message,
        });
      });
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div className={`flex-lg-row-fluid ms-lg-7 ms-xl-10 position-relative`}>
      {loading && <SpinnerDiv />}
      <div
        className={`card ${loading ? "opacity-low" : ""}`}
        id="kt_chat_messenger"
      >
        <div className="card-header bg-ligh mb-4" id="kt_chat_messenger_header">
          <div className="card-title">
            <div className="d-flex gap-10">
              <div className="d-flex flex-column justify-content-center gap-0 fw-bold text-header-light">
                <span>{conversation.contact}</span>

                {country && (
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <img
                      className="flag-style w-20px"
                      src={`/assets/media/flags/${country.toUpperCase()}.svg`}
                      alt={country}
                    />
                    <span className="fs-8 fw-semibold text-gray-600 m-0">
                      {t(`countries.${country}`)}
                    </span>
                    <div className="d-flex gap-1 align-items-start justify-content-center">
                      <div className="d-flex align-items-center gap-2 badge badge-light">
                        <span className="fs-9 fw-semibold text-gray-600 m-0">
                          {t("first_message_date")}:
                        </span>
                        <span className="fs-9 fw-semibold text-gray-600 m-0">
                          {defaultDateFormat("11-01-2024")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-2 badge badge-light">
                        <span className="fs-9 fw-semibold text-gray-600 m-0">
                          {t("last_message_date")}:
                        </span>
                        <span className="fs-9 fw-semibold text-gray-600 m-0">
                          {last_message_date&&defaultDateFormat(last_message_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-body overflow-scroll h-406px p-6"
          id="kt_chat_messenger_body"
          ref={chatBodyRef}
          onScroll={handleScroll}
        >
          {conversation.messages.map((item, index) => (
            <div
              className={`d-flex ${
                index !== conversation.messages.length - 1 ? "mb-10" : ""
              } ${
                item.source === 2
                  ? "justify-content-end"
                  : "justify-content-start"
              }`}
            >
              <div
                className={`d-flex flex-column ${
                  item.source === 2 ? "align-items-end" : "align-items-start"
                }`}
              >
                <div
                  className={`d-flex align-items-center mb-2 ${
                    item.source === 1 && "display-none"
                  }`}
                >
                  <div
                    className={`d-flex gap-3 align-items-center ${
                      item.source === 2 ? "flex-row-reverse" : ""
                    }`}
                  >
                    <span className={`fs-7 fw-bold text-gray-700`}>
                      {item.source === 2 ? senderNumber : item.name}
                    </span>
                  </div>
                </div>
                <div
                  className={`p-3 rounded text-gray-900 mw-lg-400px text-start ${
                    item.source === 2 ? "bg-light-primary" : "bg-light-info"
                  }`}
                  data-kt-element="message-text"
                >
                  {item.content}
                </div>
                <div
                  className={`p-1 rounded text-gray-900 fw-semibold mt-1 cursor-pointer`}
                  data-kt-element="message-text"
                >
                  <span className="text-muted fs-7">
                    {defaultDateFormat(item.date)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="card-footer">
          {localStorage.getItem("have_sms") == "true" ? (
            <div className="d-flex align-items-center gap-2">
              <textarea
                placeholder={t("type_a_message")}
                type="text"
                className="form-control form-control-sm form-control-solid autoResizeTextarea input-invisible"
                style={{ height: 32 }}
                value={body.message}
                onChange={(e) => changeBody("message", e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    !e.shiftKey &&
                    body.message.length > 0
                  ) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />

              {!sendLoading ? (
                <svg
                  onClick={handleSendMessage}
                  viewBox="0 0 15 15"
                  className="cursor-pointer"
                  height={20}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="matrix(1, 0, 0, 1, 0, 0)rotate(45)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M14.9541 0.709802C14.93 0.761862 14.8965 0.810638 14.8536 0.853553L5.40076 10.3064L8.07126 14.7573C8.16786 14.9183 8.34653 15.0116 8.53386 14.9989C8.72119 14.9862 8.88561 14.8696 8.95958 14.697L14.9541 0.709802Z"
                      fill="#00b2ff"
                    ></path>{" "}
                    <path
                      d="M4.69366 9.59931L0.242756 6.92876C0.0817496 6.83216 -0.0115621 6.65349 0.00115182 6.46616C0.0138657 6.27883 0.130462 6.11441 0.303045 6.04044L14.293 0.0447451C14.2399 0.0688812 14.1902 0.102782 14.1465 0.146447L4.69366 9.59931Z"
                      fill="#00b2ff"
                    ></path>
                  </g>
                </svg>
              ) : (
                ""
              )}
            </div>
          ) : (
            <span className="px-3 fw-bold c-grey">
              {t("user_cant_send_sms")}.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Conversation;
