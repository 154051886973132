const fetchAlerts = async ({body}) => {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/core/articles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body,
    });
  };
  
  export default fetchAlerts;
  