import React from "react";

function DatatableHeader({
  fetchDataDirectionFilter,
  column,
  checkIfSelected,
  header,
  isSortable = true,
}) {
  return (
    <div className="header-dt">
      <div className="title-dt">
        <span style={isSortable ? {} : { paddingBottom: "2rem" }}>
          {header}
        </span>
        {isSortable && (
          <div className="sort-by-filter">
            <span>
              <svg
                onClick={() => {
                  fetchDataDirectionFilter(column, "asc");
                }}
                className="svg-filters-up-down"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={checkIfSelected(column, "asc")}
                transform="rotate(180)"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </span>
            <svg
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              fill={checkIfSelected(column, "desc")}
              className="svg-filters-up-down filter-down"
              onClick={() => {
                fetchDataDirectionFilter(column, "desc");
              }}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <path d="m192 384 320 384 320-384z" />
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default DatatableHeader;
