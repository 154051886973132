export const fetchCalls = async (body) => {

return await fetch(
`${process.env.REACT_APP_SERVER_URL}/public/calls/paginated`,
{
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Basic " + btoa("api_key:ca1f295dc66a946809ea6a36a76c9050"),
  },
  body
})
};
