import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import ErrorSwal from "../../../../components/Alerts/errorAlert";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";

import SpinnerSecondary from "../../../../components/SpinnerSecondary";
import { successSwall } from "../../../../components/Alerts/successAlert";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const ChangePasswordModal = ({
  changeData,
  user_id,
  closeModal,
  user,
  first_device,
  second_device,
  credentials,
}) => {
  const [data, setData] = useState({
    password: "",
    password_confirmation: "",
    device: null,
  });

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const [error, setError] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const [solidPw, setSolidPw] = useState(true);

  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const devices = [
    { name: "mobile", id: 1 },
    { name: "desktop", id: 2 },
    { name: "tablet", id: 3 },
  ];

  const handleInputChange = (e) => {
    if (error || !solidPw) {
      setError(false);
      setSolidPw(true);
    }

    const { name, value } = e.target;
    setData((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setError(false);
    setData({ password: "", password_confirmation: "" });
  };

  // UPDATE

  const handleUpdatePassword = async () => {
    try {
      setSpinnerLoad(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interface/change/extension/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            user_id: user_id,
            password: `${data.password}`,
            second_device: second_device ? true : false,
          }),
        }
      );
      setSpinnerLoad(false);
      if (!response.ok) {
        const fetch = await response.json();
        if (fetch.code === "IS_SAME_PASSWORD") {
          throw new Error("same_pw");
        } else {
          throw new Error(response.status);
        }
      }
      successSwall(t("password_updated"));

      if (second_device) {
        changeData("credentials", user_id, [
          credentials[0],
          { username: credentials[1].username, password: data.password },
        ]);
      } else if (first_device) {
        changeData("credentials", user_id, [
          { username: credentials[0].username, password: data.password },
          credentials[1],
        ]);
      } else {
        changeData("credentials", user_id, [
          { username: credentials[0].username, password: data.password },
        ]);
      }
      closeModal();
    } catch (error) {
      if (error.message === "same_pw") {
        ErrorSwal(t("errors.same_pw"));
      } else if (error.message == 400) {
        ErrorSwal(t("errors.pw"));
      } else if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const { t } = useTranslation();

  const handleValidationState = (item, value) => {
    setPasswordValidation((prevData) => ({ ...prevData, [item]: value }));
  };

  const pwValidation = (e) => {
    const password = e.target.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    password.length > 12
      ? handleValidationState("length", true)
      : handleValidationState("length", false);
    hasUpperCase
      ? handleValidationState("uppercase", true)
      : handleValidationState("uppercase", false);
    hasLowerCase
      ? handleValidationState("lowercase", true)
      : handleValidationState("lowercase", false);
    hasNumber
      ? handleValidationState("number", true)
      : handleValidationState("number", false);
    hasSymbol
      ? handleValidationState("symbol", true)
      : handleValidationState("symbol", false);
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className={`modal-container pw-modal fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("update_password")}</h3>

              <div className="d-flex gap-3">
                <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="svg-icon svg-icon-4 me-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {user}
                  </div>
                </div>
                {(first_device || second_device) && (
                  <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                    <div className="d-flex align-items-center text-gray-900 header-badge">
                      <span className="svg-icon svg-icon-4 me-1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                            fill="currentColor"
                          />
                          <path
                            d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {first_device
                        ? t("first_device")
                        : second_device
                        ? t("second_device")
                        : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div>
            <div className="p-6">
              <form className="pw-modal-form" method="post">
                {second_device && (
                  <div className="mb-6">
                    <label htmlFor="" className="form-label mb-3">
                      {t("device")}
                    </label>
                    <div
                      className={`currency-div position-relative`}
                      onClick={() =>
                        setMenuOpen((prevData) => ({
                          ...prevData,
                          paid: !prevData.paid,
                        }))
                      }
                      onMouseLeave={() =>
                        setMenuOpen((prevData) => ({
                          ...prevData,
                          paid: false,
                        }))
                      }
                    >
                      <span>
                        {t(
                          data.device &&
                            devices.find((item) => item.id === data.device).name
                        )}
                      </span>

                      <svg
                        className="svg-menu-currency"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                            fill="#727272"
                          />
                        </g>
                      </svg>

                      {menuOpen.paid && (
                        <div>
                          {devices.map((item) => (
                            <span
                              onClick={() =>
                                setData((prevData) => ({
                                  ...prevData,
                                  device: item.id,
                                }))
                              }
                            >
                              {t(item.name)}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="mb-6">
                  <label htmlFor="" className="form-label mb-3 required">
                    {t("modal.reset.label.new_pw")}
                  </label>

                  <input
                    type="password"
                    className={`form-control form-control-sm form-control-solid ${
                      (error || !solidPw) && "form-control-error"
                    }`}
                    id=""
                    required=""
                    placeholder=""
                    name="password"
                    value={data.password}
                    onChange={(e) => {
                      handleInputChange(e);
                      pwValidation(e);
                    }}
                    onBlur={() => {
                      const password = data.password;
                      const hasUpperCase = /[A-Z]/.test(password);
                      const hasLowerCase = /[a-z]/.test(password);
                      const hasNumber = /[0-9]/.test(password);
                      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

                      if (
                        password.length < 12 ||
                        !hasUpperCase ||
                        !hasLowerCase ||
                        !hasNumber ||
                        !hasSymbol
                      ) {
                        setSolidPw(false);
                      }
                    }}
                  />

                  {data.password.length > 0 && (
                    <div
                      className={`d-flex flex-column mt-3 ${
                        passwordValidation.length &&
                        passwordValidation.number &&
                        passwordValidation.uppercase &&
                        passwordValidation.lowercase &&
                        passwordValidation.symbol &&
                        "display-none"
                      }`}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.length ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.length
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("min_12_char")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.uppercase
                                  ? "#a1a5b7"
                                  : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.uppercase
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("uppercase")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.lowercase
                                  ? "#a1a5b7"
                                  : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.lowercase
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("lowercase")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.number ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.number
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("valid_number")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.symbol ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.symbol
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("symbol")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    (!passwordValidation.length ||
                      !passwordValidation.number ||
                      !passwordValidation.uppercase ||
                      !passwordValidation.lowercase ||
                      !passwordValidation.symbol) &&
                    "disabled-event"
                  }`}
                >
                  <label htmlFor="" className="form-label mb-3 required">
                    {t("modal.reset.label.confirm_pw")}
                  </label>
                  <input
                    type="password"
                    className={`form-control form-control-sm form-control-solid ${
                      error && "form-control-error"
                    }`}
                    id=""
                    required=""
                    placeholder=""
                    name="password_confirmation"
                    value={data.password_confirmation}
                    onChange={handleInputChange}
                    onBlur={() => {
                      if (data.password !== data.password_confirmation) {
                        setError(true);
                      }
                    }}
                  />
                </div>
              </form>

              {error && (
                <div className="d-flex mt-6">
                 <div className="d-flex align-items-center gap-2">
                 <svg height={15} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z" fill="#f1416c"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z" fill="#f1416c"></path> </g></svg>

                        <span
                          className={`color-red`}
                        >
                          {t("errors.pw_not_match")}
                        </span>
                      </div>

                </div>
              )}

             
            </div>

            <div className="d-flex justify-content-end p-6 footer-border-top">
              <SecondaryButton title={t("cancel")} onClick={closeModal} />
              <button
                type="save"
                className={`btn btn-primary-active btn-sm ${
                  (!solidPw || error || data.password.length === 0) &&
                  "disabled-event"
                }`}
                onClick={() => {
                  confirmationSwal(handleUpdatePassword);
                }}
              >
                <span> {t("update")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default ChangePasswordModal;
