
const getLogs = async (body, params) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/Internal/logs?` + params,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body,
    }
  );
};

const LogService = {
  getLogs,
};

export default LogService;
