const sendMessage = async (body) => {
    return await fetch(`${process.env.REACT_APP_CONVERSATIONS_URL}/api/v1/messages/app/draft`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "TOKEN-ID": process.env.REACT_APP_MESSAGES_TOKEN,
      },
      body: JSON.stringify({
        content:body.message,
        receiver: body.receiver,
        customer_id: localStorage.getItem("customer_id"),
        sender_phone_number: body.sender
      }),
    });
  };
  
  export default sendMessage;
  