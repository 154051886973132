import React from "react";
import { Chart } from "react-google-charts";



const DiffBarChart = ({ data, title }) => {


  const options = {
    bar: { groupWidth: "10%" },
    chartArea: { width: "80%" },
    isStacked: true,
    legend: { position: "top" },
    hAxis: {
      title: title,
      minValue: 0,
    },
    vAxis: {},
  };

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default DiffBarChart;
