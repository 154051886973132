import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import UpdatePassword from "../views/UpdatePassword";
import RecoverPassword from "../views/RecoverPassword";
import Verification from "../views/Verification";
import Register from "../views/Register";
import AccountVerification from "../views/AccountVerification";

const LoginRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="/2fa_auth" element={<Verification />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/verification" element={<AccountVerification />} />
    </Routes>
  );
};

export default LoginRoute;
