import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCountries from "../Services/getCountries";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const StickySummary = ({ fixedSummary, body, editBody, refresh }) => {
  const [menuOpen, setMenuOpen] = useState({ countries: false, prefix: false });

  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("");

  const handleInputChange = (e) => {
    setFilterValue(e.target.value.toLowerCase());
  };

  const handleMenu = (item, value) => {
    setMenuOpen((prevData) => ({ ...prevData, [item]: value }));
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = await getCountries();
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      if (result.total > 0) {
        const newData = [];
        result.data.map((item) => newData.push(item));
        setCountries(newData);
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchCountries();
      }
    }
  };

  const refetchCountries = async () => {
    try {
      setLoading(true);
      const response = await getCountries();
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      if (result.total > 0) {
        const newData = [];
        result.data.map((item) => newData.push(item));
        setCountries(newData);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const [countries, setCountries] = useState([]);

  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(filterValue)
  );

  return (
    <div
      className="flex-lg-auto min-w-lg-300px  me-lg-7 me-xl-10"
      id="sticky_pricing"
    >
      <div className={`card ${fixedSummary && "fixed-summary"}`} style={{}}>
        {loading && <SpinnerDiv />}
        <div className={`card-body p-10 ${loading && "disabled-event"}`}>
          <div className="mb-6">
            <label className="form-label fw-bold fs-6 text-gray-700 mb-3">
              {t("country")}
            </label>
            <div
              className="w-235px countries-container"
              onMouseLeave={() => {
                handleMenu("country", false);
                setFilterValue("");
              }}
            >
              <div className="fv-row height-100">
                <div className="row height-100">
                  <div className="d-flex gap-2 height-100 p-0">
                    <div
                      className="country-menu-div position-relative w-198px"
                      onClick={() =>
                        !menuOpen.country && handleMenu("country", true)
                      }
                    >
                      <span>
                        {body.country && (
                          <div>
                            <img
                              className={`height-1-2 width-1-6 obj-fit-cover me-4 flag-style`}
                              src={`/assets/media/flags/${body.country.toLowerCase()}.svg`}
                              alt={"FR"}
                            />
                        <span> {t(`countries.${body.country.toUpperCase()}`)}</span>
                          </div>
                        )}
                      </span>
                      <svg
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                            fill="#5e6278"
                          />
                        </g>
                      </svg>

                      <div
                        className={`position-relative    ${
                          !menuOpen.country && "display-none"
                        }`}
                      >
                        <input
                          type="text"
                          placeholder=""
                          value={filterValue}
                          onChange={handleInputChange}
                          className="search-country-input"
                          autoComplete="new-password"
                        />

                        {filteredCountries.map((item, key) => (
                          <div
                            key={key}
                            onClick={() => {
                              editBody("country", item);
                              setMenuOpen(false);
                              setFilterValue("");
                            }}
                          >
                            <img
                              className="height-1-2 width-1-6 obj-fit-cover me-4 flag-style"
                              src={`/assets/media/flags/${item.toLowerCase()}.svg`}
                              alt={item}
                            />
                            <span> {t(`countries.${item.toUpperCase()}`)}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col">
                <span className={`btn btn-light btn-sm btn-active-light-primary w-100 ${!body.country&&"disabled-event"}`} onClick={refresh}>
                <svg viewBox="0 0 24 24" fill="none" height={15} xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#2e507f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </span>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-dashed mb-6" />
          <div className="mb-6">
            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
              <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                {t("phone_numbers")}
              </span>
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                checked={body.phone_numbers === true ? true : false}
                onChange={() =>
                  body.phone_numbers !== "not-supported" &&
                  editBody("phone_numbers", !body.phone_numbers)
                }
              />
            </label>
            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
              <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                {t("calls")}
              </span>
              <input
                className={`form-check-input cursor-pointer ${
                  body.calls === "not-supported" && "disabled-event"
                }`}
                type="checkbox"
                checked={body.calls === true ? true : false}
                onClick={() =>
                  body.calls !== "not-supported" &&
                  editBody("calls", !body.calls)
                }
              />
            </label>
            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
              <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                {t("sms")}
              </span>
              <input
                className={`form-check-input cursor-pointer ${
                  body.sms === "not-supported" && "disabled-event"
                }`}
                type="checkbox"
                checked={body.sms === true ? true : false}
                onClick={() =>
                  body.sms !== "not-supported" && editBody("sms", !body.sms)
                }
              />
            </label>
          </div>
          {/* <div className="separator separator-dashed mb-6" />
          <div className="mb-6">
            <label className="form-label fw-bold fs-6 text-gray-700 mb-3">
              {t("prefix")}
            </label>
            <div className="border rounded">
              <select
                className="form-select form-select-sm form-select-transparent"
                data-control="select2"
                data-close-on-select="false"
                data-allow-clear="true"
                multiple="multiple"
              >
                <option />
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
              </select>
            </div>
          </div> */}
          <div className="separator separator-dashed mb-6" />
          <div className="mb-0">
            <div className="row mb-0">
           
              <div className="col">
                <span className="btn btn-light btn-sm btn-active-light-primary w-100 disabled-event">
                  {t("send")}
                </span>
              </div>
              <div className="col">
                <span className="btn btn-light btn-sm btn-active-light-primary w-100 disabled-event">
                  {t("download")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickySummary;
