import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import countries from "../../../Data/Countries.json"

const CallsGeographyChart = ({data, load}) => {

  const options = {
    colorAxis: { colors: ["#ffffff", "#507ab4"] },
  };

  const mapCountryCodeToName = (code) => {
    const country = countries.find((country) => country.name.toLocaleLowerCase() === code.toLowerCase());
    
    return country ? country.code : code;
  };

  return (
    <div>
      {!load ? (
        <SpinnerDiv/>
      ) : (
        <div className="chart-flex">
          <div className="dash-geo-charts">
            <Chart
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection();
                    if (selection.length === 0) return;
                  },
                },
              ]}
              chartType="GeoChart"
              data={data}
              options={options}
              className="map-chart"
            />
          </div>

          <div className="geo-chart-coutries">
      {data&&data.slice(1).map((entry, index) => {
        const [country, calls] = entry;
        
        const countryCode = country.toLowerCase();
        return (
          <div key={index} className="geo-chart-country-line">
            <span className="geo-chart-flag-info">
              <img
                src={`/assets/media/flags/${mapCountryCodeToName(countryCode).toLowerCase()}.svg`}
                className="geo-chart-flag"
                alt={country}
              />
              {country}
            </span>
            <span>{calls}</span>
          </div>
        );
      })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CallsGeographyChart;
