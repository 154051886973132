import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import getTickets from "../Services/getTickets";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import DataTable from "react-data-table-component";
import { defaultDateFormat } from "../../../constants/Utils";
import SideBar from "../Components/SideBar";
import Header from "../Components/Header";
import DtSpinner from "../../../components/DtSpinner";
import DeleteTicketModal from "../Components/Modals/DeleteTicketModal";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Tickets = () => {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);


  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await getTickets();
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();
      const newData = [];

      result.data.forEach((item) => {
        newData.push({
          id: item.id,
          name: item.reference,
          class: item.class,
          subject: item.subject,
          status: item.status,
          content: item.content,
          priority: item.priority,
          created_at: item.created_at,
          conversation: item._connections.comments
        });
      });
      setData(newData);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchData();
      }
    }
  };

  const refetchData = async () => {
    try {
      setLoading(true);
      const response = await getTickets();
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();
      const newData = [];

      result.data.forEach((item) => {
        newData.push({
          id: item.id,
          name: item.reference,
          class: item.class,
          subject: item.subject,
          status: item.status,
          content: item.content,
          priority: item.priority,
          created_at: item.created_at,
          conversation: item._connections.comments
        });
      });
      setData(newData);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
   
      localStorage.setItem("active", 12);
      document.dispatchEvent(new CustomEvent("activePage"))
  
    fetchData();
  }, []);

  const [index, setIndex] = useState(null);

  const handleUpdateLine = (ticket_id) => {
    let newData = [];

    data.forEach((element) =>
      element.id === ticket_id
        ? newData.push({
            ...data.find((item) => item.id === ticket_id),
            status: 4,
          })
        : newData.push(element)
    );

    setData(newData);
  };

  const columns = [
    {
      name: t("titles.reference"),
      cell: (row) =>
        loading ? <DtSpinner /> : <span className="fw-bold">{row.name}</span>,
    },
    {
      name: t("titles.class"),
      cell: (row) =>
        loading ? (
          <DtSpinner />
        ) : (
          <span className="call-direction">
            {row.class === 1
              ? t("tech_issues")
              : row.class === 2
              ? t("billing_text")
              : row.class === 3
              ? t("prod_inquiries")
              : row.class === 4
              ? t("feedback")
              : ""}
          </span>
        ),
    },
    {
      name: t("titles.subject"),
      cell: (row) => (loading ? <DtSpinner /> : row.subject),
    },
    {
      name: t("titles.created_at"),
      cell: (row) =>
        loading ? (
          <DtSpinner />
        ) : row.created_at ? (
          defaultDateFormat(row.created_at)
        ) : (
          ""
        ),
    },
    {
      name: t("titles.priority"),
      cell: (row) =>
        loading ? (
          <DtSpinner />
        ) : (
          <div>
            {row.priority === 1 ? (
              <span className="badge badge-light-danger">{t("hight")}</span>
            ) : row.priority === 2 ? (
              <span className="badge badge-light-warning">{t("medium")}</span>
            ) : row.priority === 3 ? (
              <span className="badge badge-light-success">{t("low")}</span>
            ) : (
              ""
            )}
          </div>
        ),
    },

    {
      name: t("titles.status"),
      cell: (row) =>
        loading ? (
          <DtSpinner />
        ) : (
          <div>
            {row.status === 1 ? (
              <span className="badge badge-light-succeded">{t("open")}</span>
            ) : row.status === 2 ? (
              <span className="badge badge-light-warning">{t("pending")}</span>
            ) : row.status === 3 ? (
              <span className="badge badge-light-succeded">{t("open")}</span>
            ) : row.status === 4 ? (
              <span className="badge badge-light-danger">{t("closed")}</span>
            ) : row.status === 5 ? (
              <span className="badge badge-light-danger">{t("closed")}</span>
            ) : (
              row.status
            )}{" "}
          </div>
        ),
    },
   
    {
      name: t("titles.options"),
      cell: (row) =>
        !loading ? (
          row.id && (
            <div className="ticket-actions">
              <span
                title={t("open_conversation")}
                className="grey-button"
                onClick={() => {
                  document.body.classList.add("modal-open");
                  setSideBar(true);
                  setIndex(data.findIndex((element)=>element.id===row.id));
                }}
              >
                <svg
                  className="docs-svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#5c5e6e"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#5c5e6e"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </span>

              <span
                title={t("close_ticket")}
                className={`delete-button ${
                  row.status === 4 && "disabled-event"
                }`}
                onClick={() => {
                  setModal(true);
                  document.body.classList.add("modal-open");
                  setIndex(data.findIndex((element)=>element.id===row.id));
                }}
              >
<svg
  version={1.0}
  className="docs-svg"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 64 64"
  enableBackground="new 0 0 64 64"
  xmlSpace="preserve"
  fill=""
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fill="#f1416c"
      d="M52,24h-4v-8c0-8.836-7.164-16-16-16S16,7.164,16,16v8h-4c-2.211,0-4,1.789-4,4v32c0,2.211,1.789,4,4,4h40 c2.211,0,4-1.789,4-4V28C56,25.789,54.211,24,52,24z M32,48c-2.211,0-4-1.789-4-4s1.789-4,4-4s4,1.789,4,4S34.211,48,32,48z M40,24 H24v-8c0-4.418,3.582-8,8-8s8,3.582,8,8V24z"
    />{" "}
  </g>
</svg>


              </span>
            </div>
          )
        ) : (
          <DtSpinner />
        ),
    },
  ];

  const [data, setData] = useState([
    {
      id: null,
      name: null,
      class: null,
      subject: null,
      status: null,
      content: null,
      priority: null,
      created_at: null,
      updated_at: null,
      conversation: [],
      docs: [],
    },
  ]);

  const [sideBar, setSideBar] = useState(false);

  const [modal, setModal] = useState(false);

  const handleAddTicket = (newLine) => {
    setData((prevData) => [newLine, ...prevData]);
  };

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card mb-12">
                <Header active={2} addTicket={handleAddTicket} />
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column flex-xl-row p-7">
                    <div className="mb-0 w-100 position-relative">
                      <div className="position-absolute">{t("no_data")}</div>

                      <DataTable
                        data={data}
                        columns={columns}
                        pagination={data.length > 5 && true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 50]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="kt_modal_new_ticket"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered mw-750px">
                  <div className="modal-content rounded">
                    <div className="modal-header pb-0 border-0 justify-content-end">
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                      <form
                        id="kt_modal_new_ticket_form"
                        className="form"
                        action="#"
                      ></form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideBar
        sideBar={sideBar}
        setSideBar={(item) => setSideBar(item)}
        conversation={index !== null && data[index].conversation}
        ticket_id={index !== null && data[index].id}
        ticket_status={index !== null && data[index].status}
        details={index!== null && {class: data[index].class, subject: data[index].subject, created_at: data[index].created_at, priority: data[index].priority}}
      />

      {modal && (
        <DeleteTicketModal
          ticket_name={index !== null && data[index].name}
          closeModal={() => {
            document.body.classList.remove("modal-open");
            setModal(false);
          }}
          ticket_id={index !== null && data[index].id}
          updateLine={handleUpdateLine}
        />
      )}
    </div>
  );
};

export default Tickets;
