import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchInvoices } from "../../Services/invoices";
import "../../Style/Modals.css";
import DataTable from "react-data-table-component";
import { defaultDateFormat } from "../../../../constants/Utils";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";
import InvoicesDataTable from "../InvoicesDataTable";

const InvoicesModal = ({ closeModal, number_id, number, country, name }) => {
  const { t } = useTranslation();


  return (
    <div>
      <div className={`modal-container invoices-modal fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header modal-header-bg p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("number_invoices")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                {name && (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="me-1">
                      <svg
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#b9c4d1"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: " .st0{fill:;} ",
                            }}
                          />{" "}
                          <g>
                            {" "}
                            <path
                              className="st0"
                              d="M239.732,12.188L0,0l12.176,239.742l42.29,42.29l-9.192-2.301c-9.079,37.239-12.986,70.45-12.986,99.069 c0,33.794,5.399,60.91,14.827,80.798c4.713,9.877,10.572,17.924,17.699,23.906c7.015,5.97,15.746,9.652,24.938,9.652 c8.732,0,17.239-3.333,24.826-8.855c7.475-5.511,14.366-13.322,20.572-23.322c10.338-16.667,16.78-38.384,20.922-63.333 c0.684-4.018,1.256-8.037,1.828-12.178l-20.909-20.92c-0.808,10.236-1.952,20.112-3.568,29.427 c-3.794,23.098-10.001,42.402-17.7,54.816c-5.162,8.158-10.224,13.794-14.703,17.014c-4.602,3.334-8.159,4.254-11.268,4.366 c-3.22-0.113-6.319-1.145-10.224-4.254c-5.746-4.714-12.188-14.59-16.78-29.417c-4.602-14.826-7.475-34.253-7.475-57.7 c0-24.366,2.986-53.21,10.461-85.499l71.257,71.246c1.493-15.399,2.065-31.718,2.065-48.384c0-55.274-7.015-114.12-16.318-153.996 c-10.573,0.224-21.257-3.794-29.304-11.841c-15.635-15.746-15.635-41.144,0-56.891c15.746-15.746,41.144-15.746,56.892,0 c15.735,15.747,15.735,41.145,0,56.891c-1.841,1.728-3.682,3.334-5.746,4.714c3.333,13.558,6.206,28.956,8.731,45.623 c5.286,35.286,8.732,76.083,8.732,115.5c0,24.142-1.269,47.7-4.142,69.305L284.446,512L512,284.446L239.732,12.188z"
                            />{" "}
                            <path
                              className="st0"
                              d="M143.996,152.515c-3.558-14.142-7.352-26.094-11.718-35.523l-20.808,9.776 c3.333,7.126,6.903,18.036,10.236,31.258c0.348,1.38,0.685,2.76,1.033,4.141c7.586-0.123,15.285-2.537,21.841-7.127 C144.456,154.232,144.232,153.311,143.996,152.515z"
                            />{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                    {name}
                  </div>
                )}
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {number}
                </div>
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                    <img
                      alt="Pic"
                      src={`assets/media/flags/${country}.svg`}
                      className="w-15px h-15px"
                    />
                    <span>
                      {country &&
                        country !== "unknown" &&
                        t(`countries.${country.toUpperCase()}`)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="p-6 position-relative invoices-dt-h">
           
           

            <InvoicesDataTable
            resource_id= {number_id}
            />
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default InvoicesModal;
