import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import "./style/Modals.css";
import SecondaryButton from "../Buttons/SecondaryButton";
import fetchTwoFactAuth from "../../modules/Settings/Services/twoFactService";
import errorSwal from "../Alerts/errorAlert";
import errorExpiredSession from "../Alerts/errorExpiredSession";
import Swal from "sweetalert2";
import SpinnerSecondary from "../SpinnerSecondary";
import { confirmationSwal } from "../Alerts/confirmationAlert";
import { successSwall } from "../Alerts/successAlert";
import InternalErrorSwal from "../Alerts/internalErrorAlert";

const EnableTwoFactAuthModal = ({ updateModalShow }) => {
  const [reqBody, setReqBody] = useState({
    receiver: "",
    method: 2,
  });

  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const handleCancel = () => {
    setReqBody((prevData) => ({ ...prevData, receiver: "" }));
  };

  const [error, setError] = useState(false);


  

  const handleSubmit = async () => {
    try {
      setSpinnerLoad(true);
      const response = await fetchTwoFactAuth(JSON.stringify(reqBody));
      setSpinnerLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      updateModalShow();
      successSwall(t("2fa_activated"));
      localStorage.setItem("active_2fa", true);
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };
  const { t } = useTranslation();

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className={`modal-container pw-modal fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <h2>{t("two_fact_title")}</h2>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => {
                updateModalShow();
              }}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div>
            <div className="p-6">
              <form
                className="pw-modal-form flex-form"
                method="post"
                onKeyDown={(e) => {
                  if (e.code?.includes("Enter")) {
                    handleSubmit();
                  }
                }}
              >
                <div className="double-auth-options">
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input cursor-pointer"
                      checked
                      type="checkbox"
                    />
                    <label
                      className="form-check-label ps-2"
                      htmlFor="kt_settings_notification_email"
                    >
                      
                      {t("email")}

                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      disabled
                      type="checkbox"
                    />
                    <label
                      className="form-check-label ps-2"
                      htmlFor="kt_settings_notification_email"
                    >
                      {t("sms")}
                    </label>
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="form-label mb-3">
                    {t("insert_email")}
                  </label>
                  <input
                    type="text"
                    className={` form-control form-control-sm form-control-solid ${ 
                      error &&"form-control-error"
                    }`}
                    required=""
                    placeholder={t("E_mail_addr")}
                    name="password_confirmation"
                    value={reqBody.receiver}
                    onChange={(e) => {
                      setReqBody((prevData) => ({
                        ...prevData,
                        receiver: e.target.value,
                      }));

                      if (error) {
                        setError(false);
                      }
                    }}
                  />
                  {error && (
                    <div className="d-flex mb-2 mt-2">
                      {" "}
                      <div className="fw-bold danger-drag-and-drop">

                      <svg
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          opacity="0.5"
                          d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                          fill="#e73e3e"
                        ></path>{" "}
                      </g>
                    </svg>

                        <span>{t("invalid_email")}.</span>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className="d-flex justify-content-end p-6 footer-border-top">
              <SecondaryButton title={t("cancel")} onClick={handleCancel} />
              <button
                type="submit"
                className="btn btn-sm px-6 align-self-center text-nowrap btn-primary-active"
                onClick={() => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (emailRegex.test(reqBody.receiver)) {
                   confirmationSwal(handleSubmit);
                  } else {
                    setError(true);
                  }
                }}
              >
                <span className=""> {t("activate")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   updateModalShow();
        // }}
      ></div>
    </div>
  );
};

export default EnableTwoFactAuthModal;
