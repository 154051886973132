export const getNumbers = async(status)=>{
    return await fetch(`${process.env.REACT_APP_BASE_URL}/interface/numbers?with_terms=true&&orderBy=status&&sort=asc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({status:status})
    });
  }