import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LogService from "./Logs.service";
import { LogActions, elements } from "../../../../constants/Logs-Actions";
import DatatableHeader from "../../../../components/reusables/DataTableHeader";
import DataTableTextFilter from "../../../../components/reusables/DataTableTextFilter";
import DataTableDateFilter from "../../../../components/reusables/DataTableDateFilter";
import DataTableSelectFilter from "../../../../components/reusables/DataTableSelectFilter";
import DataTableComponent from "../../../../components/reusables/DataTable";
import useSweetAlert from "../../../../hooks/useSweetAlert";
import { format } from "date-fns";
import {
  convertUTCtoUserTime,
  defaultDateFormat,
  getLocale,
  safeDateFormat,
} from "../../../../constants/Utils";
import DataTableCustomSelectFilter from "../../../../components/reusables/DataTableCustomSelectFilter";

const LogsDatatable = () => {
  const { t } = useTranslation();
  const dateFormat = JSON.parse(localStorage.getItem("data")).date_format;
  const timezone = JSON.parse(localStorage.getItem("data")).timezone;
  const timeFormat = JSON.parse(localStorage.getItem("data")).time_format;
  const languagePref = localStorage.getItem("i18nextLng");

  const locale = getLocale(languagePref);

  const { showSecondTrySwall, showErrorSwal, showErrorExpiredSession } =
    useSweetAlert();

  useEffect(() => {
    Promise.all([import("../style/datatable.css")]).then(() => {
      setLoad(true);
    });
  }, []);

  const getLogsData = async () => {
    try {
      setLoad(false);

      const response = await LogService.getLogs(
        JSON.stringify({
          id: logsResponseData.id ?? null,
          action: logsResponseData.action ?? Object.keys(LogActions),
          element: logsResponseData.element ?? Object.keys(elements),
          date: logsResponseData.log_date ?? null,
          date_start: logsResponseData.date_start ?? null,
          date_end: logsResponseData.date_end ?? null,
        }),
        new URLSearchParams({
          with_terms: true,
          sort: logsResponseData.direction,
          orderBy: logsResponseData.item,
          limit: logsResponseData.limit,
          offset: logsResponseData.skip,
        })
      );
      setLoad(true);
      if (!response.ok) {
        if (response.status === 401) {
          showErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
        } else if (response.status === 403) {
          showErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          showErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          showErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          showErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          showErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          showErrorSwal(t("errors.406"));
        }
        setLogsData([
          {
            id: null,
            action: null,
            log_date: null,
          },
        ]);
        return;
      }
      setLoad(true);
      const fetchedData = await response.json();

      setLogsResponseData((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total > 0) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          action: data.action,
          log_date: convertUTCtoUserTime(data.log_date, timezone),
          element: data.element,
        }));
        setLogsData(mappedData);
      } else {
        setLogsData([
          {
            id: null,
            action: null,
            log_date: null,
          },
        ]);
      }
    } catch (error) {
      showSecondTrySwall();
      getLogsDataSecondTry();
    } finally {
      setLoad(true);
    }
  };
  const getLogsDataSecondTry = async () => {
    try {
      setLoad(false);

      const response = await LogService.getLogs(
        JSON.stringify({
          id: logsResponseData.id ?? null,
          action: logsResponseData.action ?? Object.keys(LogActions),
          element: logsResponseData.element ?? Object.keys(elements),
          date: logsResponseData.log_date ?? null,
          date_start: logsResponseData.date_start ?? null,
          date_end: logsResponseData.date_end ?? null,
        }),
        new URLSearchParams({
          with_terms: true,
          sort: logsResponseData.direction,
          orderBy: logsResponseData.item,
          limit: logsResponseData.limit,
          offset: logsResponseData.skip,
        })
      );

      if (!response.ok) {
        showErrorSwal(t("errors.500"));
        setLogsData([
          {
            id: null,
            action: null,
            log_date: null,
          },
        ]);
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      setLoad(true);
      const fetchedData = await response.json();

      setLogsResponseData((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total > 0) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          action: data.action,
          log_date: convertUTCtoUserTime(data.log_date, timezone),
          element: data.element,
        }));
        setLogsData(mappedData);
      } else {
        setLogsData([
          {
            id: null,
            action: null,
            log_date: null,
          },
        ]);
      }
    } catch (error) {
      showErrorSwal(t("errors.500"));
      setLogsData([
        {
          id: null,
          action: null,
          log_date: null,
        },
      ]);
    } finally {
      setLoad(true);
    }
  };

  const [load, setLoad] = useState(false);

  const [logsResponseData, setLogsResponseData] = useState({
    skip: 0,
    limit: 5,
    total: null,
    item: "log_date",
    direction: "desc",
    id: "",
    action: Object.keys(LogActions),
    element: Object.keys(elements),
    log_date: "",
  });

  const fetchDataDirectionFilter = (item, direction) => {
    setLogsResponseData((prevData) => ({
      ...prevData,
      item: item,
      direction: direction,
    }));
  };

  const handleInputChange = (item, value) => {
    value = item == "action" && value == "" ? Object.keys(LogActions) : value;
    setLogsResponseData((prevData) => ({
      ...prevData,
      [item]: value,
      skip: 0,
    }));
  };

  const handleElementChange = (item, value) => {
    setLogsResponseData((prevData) => ({
      ...prevData,
      element: value,
      skip: 0,
    }));
  };

  const checkIfSelected = (item, direction) => {
    if (
      logsResponseData.item == item &&
      logsResponseData.direction == direction
    )
      return "#919191";
    return "#d9d9d9";
  };

  useEffect(() => {
    getLogsData();
  }, [
    logsResponseData.skip,
    logsResponseData.limit,
    logsResponseData.item,
    logsResponseData.direction,
    logsResponseData.id,
    logsResponseData.action,
    logsResponseData.element,
    logsResponseData.log_date,
    logsResponseData.date_start,
    logsResponseData.date_end,
  ]);

  const logsColumns = [
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="id"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
            header={t("logs.columns.id")}
          />
          <DataTableTextFilter
            column="id"
            handleInputChange={handleInputChange}
            placeholder={t("logs.placeholders.id")}
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.id}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="action"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
            header={t("logs.columns.action")}
          />
          <DataTableCustomSelectFilter
            column="action"
            handleInputChange={handleInputChange}
            placeholder={t("logs.placeholders.action")}
            options={
              {
                CREATE: t("CREATE"),
                UPDATE: t("UPDATE"),
                DELETE: t("DELETE"),
                RECOVER: t("RECOVER"),
                UPLOAD: t("UPLOAD"),
              }
          }
            withAllOption
            classValue="search-input-dt search-input-dt-direction-actions max-w-min-content"
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{t(row.action)}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="element"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
            header={t("logs.columns.element")}
          />
          <DataTableCustomSelectFilter
            column="action"
            handleInputChange={handleElementChange}
            placeholder={t("logs.placeholders.action")}
            options={
              {
              5: t(`elements.${5}`),
              8:t(`elements.${8}`),
              9:t(`elements.${9}`),
              10:t(`elements.${10}`),
              39:t(`elements.${39}`),
              40:t(`elements.${40}`),
              41:t(`elements.${41}`),
              43:t(`elements.${43}`),
              44:t(`elements.${44}`),
              45:t(`elements.${45}`),
              46:t(`elements.${46}`),
              47:t(`elements.${47}`),
              48:t(`elements.${48}`),
              49:t(`elements.${49}`),
              50:t(`elements.${50}`),
              51:t(`elements.${51}`),
              52:t(`elements.${52}`),
              53:t(`elements.${53}`),
              54:t(`elements.${54}`),
              62:t(`elements.${62}`),
              63:t(`elements.${63}`),
              64:t(`elements.${64}`),
              66:t(`elements.${66}`),
              71:t(`elements.${71}`),
              72:t(`elements.${72}`),
              73:t(`elements.${73}`),
              81:t(`elements.${81}`),
              }

            }
            withAllOption
            classValue="search-input-dt search-input-dt-direction-actions max-w-min-content"
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.element&&t(`elements.${row.element}`)}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="log_date"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
            header={t("logs.columns.date_log")}
          />
          <DataTableDateFilter
            isRange={true}
            placeholder={t("logs.placeholders.date_log")}
            onChange={(e) => {
              if (e.length >= 2) {
                handleInputChange(
                  "date_start",
                  e[0]?.toLocaleDateString("en-CA")
                );
                handleInputChange(
                  "date_end",
                  e[1]?.toLocaleDateString("en-CA")
                );
              }
            }}
            onClearClick={() =>
              setLogsResponseData((prevState) => ({
                ...prevState,
                date_start: null,
                date_end: null,
              }))
            }
            date={[logsResponseData.date_start, logsResponseData.date_end]}
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {row.log_date &&
              defaultDateFormat(row.log_date)}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
  ];
  const [logsData, setLogsData] = useState([
    {
      id: null,
      action: null,
      log_date: null,
      element:null
    },
  ]);

  return (
    <DataTableComponent
      columns={logsColumns}
      data={logsData}
      paramsData={logsResponseData}
      setParamsData={setLogsResponseData}
      load={load}
      header={t("logs.datatable.header")}
    />
  );
};

export default LogsDatatable;
