
import Swal from "sweetalert2";


const ErrorSwal = ( message ) => {

    Swal.fire({
      icon: "error", 
      title: "Oops...",
      text: message, 
    });



};

export default ErrorSwal;
