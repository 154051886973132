import Swal from "sweetalert2";
import { t } from "i18next";

export const confirmationSwal = (action) => {
  Swal.fire({
    title: t("are_you_sure"),
    text: t("no_revert"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("confirm"),
    cancelButtonText: t("cancel"),

  }).then((result) => {
    if (result.isConfirmed) {
      action();
      
    }
  });
};
