import React, { useEffect, useState } from "react";
import fetchData from "../Service/generalRep.service";
import Spinner from "../../../components/Spinner";
import { useTranslation } from "react-i18next";
import errorSwal from "../../../components/Alerts/errorAlert";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import formatDuration from "../../../Functions/formatData";
import DatePicker from "../../../components/reusables/DatePicker";
import { formatDate, initialDateFormat } from "../../../constants/Utils";
import PieChart from "../Components/PieChart";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const ReportGeneral = () => {
  const [load, setLoad] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);

  const { t } = useTranslation();

  const [data, setData] = useState({
    indicators: {
      growth: {
        rate: 0,
        symbol: "",
      },
      calls: 0,
      callers: 0,
      cost: 0,
      total_sms: 0,
    },
    details: {
      inbound: {
        total: 0,
        callers: 0,
        duration: 0,
        average: 0,
        top_callers: {},
        by_status: {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
          13: 0,
          14: 0,
        },
      },
      outbound: {
        total: 0,
        destinations: 0,
        duration: 0,
        average: 0,
        by_status: {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
          13: 0,
          14: 0,
        },
      },
    },
    display: 2,
  });

  useEffect(() => {
    Promise.all([import("../Styles/Reportings.css")]).then(() => setLoad(true));
  }, []);

  const fetchRepData = async () => {
    try {
      setDataLoad(false);
      setData((prevData) => ({ ...prevData, display: 2 }));
      const response = await fetchData(JSON.stringify(reqBody));

      setDataLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setData({
        ...fetchedData.data,
        display:
          fetchedData.data.indicators.calls == 0
            ? 0
            : fetchedData.data.indicators.calls > 0
            ? 1
            : 2,
      });
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else {
        fetchSecondTry();
      }
    }
  };

  const fetchSecondTry = async () => {
    try {
      setDataLoad(false);
      const response = await fetchData(JSON.stringify(reqBody));
      setLoad(true);
      setDataLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setData({
        ...fetchedData.data,
        display:
          fetchedData.data.indicators.calls == 0
            ? 0
            : fetchedData.data.indicators.calls > 0
            ? 1
            : 2,
      });
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const [reqBody, setReqBody] = useState({
    period: "today",
    dates: {
      start: "",
      end: "",
    },
  });

  useEffect(() => {
    fetchRepData();
  }, [reqBody.period, reqBody.dates.start, reqBody.dates.end]);

  const [activeBtn, setActiveBtn] = useState(1);

  function getDateOfYesterday() {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  var yesterdayDate = getDateOfYesterday();

  const handleDate = (active, date) => {
    setActiveBtn(active);
    if (date == "yesterday") {
      setReqBody((prevData) => ({
        ...prevData,
        period: "custom",
        dates: {
          start: initialDateFormat(yesterdayDate),
          end: initialDateFormat(yesterdayDate),
        },
      }));
    } else {
      setReqBody((prevData) => ({ ...prevData, period: date }));
    }
  };

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-container container-fluid d-flex flex-row flex-column-fluid ">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  className="app-toolbar align-items-center justify-content-between py-2 py-lg-6"
                >
                  <div
                    className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n10"
                    id="kt_toolbar"
                  >
                    <div className="d-flex flex-column align-items-start me-3 gap-2">
                      <h1 className="d-flex text-dark fw-bold m-0 fs-3">
                        {t("general_stats")}
                      </h1>
                      {/* <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7">
                        <li className="breadcrumb-item text-gray-600">
                          <a
                            href="#"
                            className="text-gray-600 text-hover-primary"
                          >
                            {t("home")}
                          </a>
                        </li>
                        <li className="breadcrumb-item text-gray-600">
                          {t("reportings")}
                        </li>
                        <li className="breadcrumb-item text-gray-500">
                          {t("general_stats")}
                        </li>
                      </ul> */}
                    </div>
                    <div className="d-flex align-items-center py-2">
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 1
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(1, "today")}
                      >
                        {t("today")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 2
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(2, "yesterday")}
                      >
                        {t("yesterday")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 3
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(3, "current_month")}
                      >
                        {t("current_month")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 4
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(4, "previous_month")}
                      >
                        {t("previous_month")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex position-relative ${
                          activeBtn === 5
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-0`}
                      >
                        {t("custom")}{" "}
                        {activeBtn === 5 ? (
                          <span>
                            {" "}
                            &#160; | &#160; {reqBody.dates.start} -{" "}
                            {reqBody.dates.end}
                          </span>
                        ) : (
                          ""
                        )}
                        <DatePicker
                          onChange={(e) => {
                            if (e.length > 1) {
                              setActiveBtn(5);
                              setReqBody((prevData) => ({
                                ...prevData,
                                period: "custom",
                                dates: {
                                  start: formatDate(e[0]),
                                  end: formatDate(e[1]),
                                },
                              }));
                              setDataLoad(false);
                            }
                          }}
                          date={[]}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="mt-8 p-0 collapse"
                    id="kt_advanced_search_form"
                  >
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title align-items-start flex-column mb-0">
                          <span className="card-label fw-bold text-gray-600">
                            {t("filter_options")}
                          </span>
                        </h4>
                        <div className="separator separator-dashed mt-6 mb-6" />
                        <div className="row g-8 mb-8">
                          <div className="col-xxl-7">
                            <label className="fs-6 form-label fw-bold text-dark">
                              {t("tags")}
                            </label>
                            <tags
                              className="tagify form-control form-control-solid"
                              tabIndex={-1}
                            >
                              <tag
                                title="products"
                                contentEditable="false"
                                spellCheck="false"
                                tabIndex={-1}
                                className="tagify__tag tagify--noAnim"
                                value="products"
                              >
                                <x
                                  title=""
                                  className="tagify__tag__removeBtn"
                                  role="button"
                                  aria-label="remove tag"
                                />
                                <div>
                                  <span className="tagify__tag-text">
                                    {t("products")}
                                  </span>
                                </div>
                              </tag>
                              <tag
                                title="users"
                                contentEditable="false"
                                spellCheck="false"
                                tabIndex={-1}
                                className="tagify__tag tagify--noAnim"
                                value="users"
                              >
                                <x
                                  title=""
                                  className="tagify__tag__removeBtn"
                                  role="button"
                                  aria-label="remove tag"
                                />
                                <div>
                                  <span className="tagify__tag-text">
                                    {t("users")}
                                  </span>
                                </div>
                              </tag>
                              <tag
                                title="events"
                                contentEditable="false"
                                spellCheck="false"
                                tabIndex={-1}
                                className="tagify__tag tagify--noAnim"
                                value="events"
                              >
                                <x
                                  title=""
                                  className="tagify__tag__removeBtn"
                                  role="button"
                                  aria-label="remove tag"
                                />
                                <div>
                                  <span className="tagify__tag-text">
                                    {t("events")}
                                  </span>
                                </div>
                              </tag>
                              <span
                                contentEditable=""
                                tabIndex={0}
                                data-placeholder="​"
                                aria-placeholder=""
                                className="tagify__input"
                                role="textbox"
                                aria-autocomplete="both"
                                aria-multiline="false"
                              />
                              ​
                            </tags>
                            <input
                              type="text"
                              className="form-control form-control form-control-solid"
                              name="tags"
                              defaultValue="products, users, events"
                              tabIndex={-1}
                            />
                          </div>
                          <div className="col-xxl-5">
                            <div className="row g-8">
                              <div className="col-lg-6">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Team Type
                                </label>
                                <select
                                  className="form-select form-select-solid select2-hidden-accessible"
                                  data-control="select2"
                                  data-placeholder="In Progress"
                                  data-hide-search="true"
                                  data-select2-id="select2-data-10-fr7q"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  data-kt-initialized={1}
                                >
                                  <option value="" />
                                  <option value={1}>Not started</option>
                                  <option
                                    value={2}
                                    selected="selected"
                                    data-select2-id="select2-data-12-8q4u"
                                  >
                                    In Progress
                                  </option>
                                  <option value={3}>Done</option>
                                </select>
                                <span
                                  className="select2 select2-container select2-container--bootstrap5"
                                  dir="ltr"
                                  data-select2-id="select2-data-11-e4u7"
                                  style={{ width: "100%" }}
                                >
                                  <span className="selection">
                                    <span
                                      className="select2-selection select2-selection--single form-select form-select-solid"
                                      role="combobox"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      tabIndex={0}
                                      aria-disabled="false"
                                      aria-labelledby="select2-ehlz-container"
                                      aria-controls="select2-ehlz-container"
                                    >
                                      <span
                                        className="select2-selection__rendered"
                                        id="select2-ehlz-container"
                                        role="textbox"
                                        aria-readonly="true"
                                        title="In Progress"
                                      >
                                        In Progress
                                      </span>
                                      <span
                                        className="select2-selection__arrow"
                                        role="presentation"
                                      >
                                        <b role="presentation" />
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    className="dropdown-wrapper"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="col-lg-6">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Select Group
                                </label>
                                <div className="nav-group nav-group-fluid">
                                  <label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="type"
                                      defaultValue="has"
                                      defaultChecked="checked"
                                    />
                                    <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                                      All
                                    </span>
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="type"
                                      defaultValue="users"
                                    />
                                    <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                                      Users
                                    </span>
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="type"
                                      defaultValue="orders"
                                    />
                                    <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                                      Orders
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-8">
                          <div className="col-xxl-7">
                            <div className="row g-8">
                              <div className="col-lg-4">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Min. Amount
                                </label>
                                <div
                                  className="position-relative"
                                  data-kt-dialer="true"
                                  data-kt-dialer-min={1000}
                                  data-kt-dialer-max={50000}
                                  data-kt-dialer-step={1000}
                                  data-kt-dialer-prefix="$"
                                  data-kt-dialer-decimals={2}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                                    data-kt-dialer-control="decrease"
                                  >
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={10}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="6.01041"
                                          y="10.9247"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid border-0 ps-12"
                                    data-kt-dialer-control="input"
                                    placeholder="Amount"
                                    name="manageBudget"
                                    readOnly="readonly"
                                    defaultValue="$50"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                                    data-kt-dialer-control="increase"
                                  >
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={10}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="10.8891"
                                          y="17.8033"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          transform="rotate(-90 10.8891 17.8033)"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="6.01041"
                                          y="10.9247"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Max. Amount
                                </label>
                                <div
                                  className="position-relative"
                                  data-kt-dialer="true"
                                  data-kt-dialer-min={1000}
                                  data-kt-dialer-max={50000}
                                  data-kt-dialer-step={1000}
                                  data-kt-dialer-prefix="$"
                                  data-kt-dialer-decimals={2}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                                    data-kt-dialer-control="decrease"
                                  >
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={10}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="6.01041"
                                          y="10.9247"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid border-0 ps-12"
                                    data-kt-dialer-control="input"
                                    placeholder="Amount"
                                    name="manageBudget"
                                    readOnly="readonly"
                                    defaultValue="$100"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                                    data-kt-dialer-control="increase"
                                  >
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={10}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="10.8891"
                                          y="17.8033"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          transform="rotate(-90 10.8891 17.8033)"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="6.01041"
                                          y="10.9247"
                                          width={12}
                                          height={2}
                                          rx={1}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Team Size
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control form-control-solid"
                                  name="city"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-5">
                            <div className="row g-8">
                              <div className="col-lg-6">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Category
                                </label>
                                <select
                                  className="form-select form-select-solid select2-hidden-accessible"
                                  data-control="select2"
                                  data-placeholder="In Progress"
                                  data-hide-search="true"
                                  data-select2-id="select2-data-13-tc58"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  data-kt-initialized={1}
                                >
                                  <option value="" />
                                  <option value={1}>Not started</option>
                                  <option
                                    value={2}
                                    selected="selected"
                                    data-select2-id="select2-data-15-1s2c"
                                  >
                                    Select
                                  </option>
                                  <option value={3}>Done</option>
                                </select>
                                <span
                                  className="select2 select2-container select2-container--bootstrap5"
                                  dir="ltr"
                                  data-select2-id="select2-data-14-tkwf"
                                  style={{ width: "100%" }}
                                >
                                  <span className="selection">
                                    <span
                                      className="select2-selection select2-selection--single form-select form-select-solid"
                                      role="combobox"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      tabIndex={0}
                                      aria-disabled="false"
                                      aria-labelledby="select2-8jg2-container"
                                      aria-controls="select2-8jg2-container"
                                    >
                                      <span
                                        className="select2-selection__rendered"
                                        id="select2-8jg2-container"
                                        role="textbox"
                                        aria-readonly="true"
                                        title="Select"
                                      >
                                        Select
                                      </span>
                                      <span
                                        className="select2-selection__arrow"
                                        role="presentation"
                                      >
                                        <b role="presentation" />
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    className="dropdown-wrapper"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="col-lg-6">
                                <label className="fs-6 form-label fw-bold text-dark">
                                  Status
                                </label>
                                <div className="form-check form-switch form-check-custom form-check-solid mt-1">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexSwitchChecked"
                                    defaultChecked="checked"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchChecked"
                                  >
                                    Active
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    className={`row gy-5 g-xl-10 ${
                      !dataLoad ? "opacity-low" : ""
                    }`}
                  >
                    <div className="col-sm-6 col-xl-2 mb-xl-10 w-20-per">
                      <div className="card h-lg-70">
                        <div className="card-body d-flex justify-content-between align-items-center flex-row p-5 ">
                          <div className="d-flex flex-column mb-0">
                            <span className="svg-icon svg-icon-2 svg-icon-gray-600">
                              <img
                                src="/assets/media/icons/total-calls.png"
                                className="rep-icons"
                              />
                            </span>
                            <div className="m-0">
                              <span className="fw-semibold fs-6 text-gray-400">
                                {t("calls")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex mb-0 align-items-center justify-content-center">
                            <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">
                              {data.indicators.calls}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10 w-20-per">
                      <div className="card h-lg-70">
                        <div className="card-body d-flex justify-content-between align-items-center flex-row p-5">
                          <div className="d-flex flex-column mb-0">
                            <span className="svg-icon svg-icon-2 svg-icon-gray-600">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z"
                                  fill="currentColor"
                                />
                                <path
                                  opacity="0.3"
                                  d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z"
                                  fill="currentColor"
                                />
                                <path
                                  opacity="0.3"
                                  d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z"
                                  fill="currentColor"
                                />
                                <path
                                  opacity="0.3"
                                  d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <div className="m-0">
                              <span className="fw-semibold fs-6 text-gray-400">
                                SMS
                              </span>
                            </div>
                          </div>
                          <div className="d-flex mb-0 align-items-center justify-content-center">
                            <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">
                              {data.indicators.total_sms}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10 w-20-per">
                      <div className="card h-lg-70">
                        <div className="card-body d-flex justify-content-between align-items-center flex-row p-5 opacity-low">
                          <div className="d-flex flex-column mb-0">
                            <span className="svg-icon svg-icon-2 svg-icon-gray-600">
                              <img
                                src="/assets/media/icons/cost.png"
                                className="rep-icons"
                              />
                            </span>
                            <div className="m-0">
                              <span className="fw-semibold fs-6 text-gray-400">
                                {t("total_cost")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex mb-0 align-items-center justify-content-center">
                            <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">
                              0$
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10 w-20-per">
                      <div className="card h-lg-70">
                        <div className="card-body d-flex justify-content-between align-items-center flex-row p-5">
                          <div className="d-flex flex-column mb-0">
                            <span className="svg-icon svg-icon-2 svg-icon-gray-600">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.3"
                                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.70001 6C8.10001 5.7 7.39999 5.40001 6.79999 5.10001C7.79999 4.00001 8.90001 3 10.1 2.2C10.7 2.1 11.4 2 12 2C12.7 2 13.3 2.1 13.9 2.2C12 3.2 10.2 4.5 8.70001 6ZM12 8.39999C13.9 6.59999 16.2 5.30001 18.7 4.60001C18.1 4.00001 17.4 3.6 16.7 3.2C14.4 4.1 12.2 5.40001 10.5 7.10001C11 7.50001 11.5 7.89999 12 8.39999ZM7 20C7 20.2 7 20.4 7 20.6C6.2 20.1 5.49999 19.6 4.89999 19C4.59999 18 4.00001 17.2 3.20001 16.6C2.80001 15.8 2.49999 15 2.29999 14.1C4.99999 14.7 7 17.1 7 20ZM10.6 9.89999C8.70001 8.09999 6.39999 6.9 3.79999 6.3C3.39999 6.9 2.99999 7.5 2.79999 8.2C5.39999 8.6 7.7 9.80001 9.5 11.6C9.8 10.9 10.2 10.4 10.6 9.89999ZM2.20001 10.1C2.10001 10.7 2 11.4 2 12C2 12 2 12 2 12.1C4.3 12.4 6.40001 13.7 7.60001 15.6C7.80001 14.8 8.09999 14.1 8.39999 13.4C6.89999 11.6 4.70001 10.4 2.20001 10.1ZM11 20C11 14 15.4 9.00001 21.2 8.10001C20.9 7.40001 20.6 6.8 20.2 6.2C13.8 7.5 9 13.1 9 19.9C9 20.4 9.00001 21 9.10001 21.5C9.80001 21.7 10.5 21.8 11.2 21.9C11.1 21.3 11 20.7 11 20ZM19.1 19C19.4 18 20 17.2 20.8 16.6C21.2 15.8 21.5 15 21.7 14.1C19 14.7 16.9 17.1 16.9 20C16.9 20.2 16.9 20.4 16.9 20.6C17.8 20.2 18.5 19.6 19.1 19ZM15 20C15 15.9 18.1 12.6 22 12.1C22 12.1 22 12.1 22 12C22 11.3 21.9 10.7 21.8 10.1C16.8 10.7 13 14.9 13 20C13 20.7 13.1 21.3 13.2 21.9C13.9 21.8 14.5 21.7 15.2 21.5C15.1 21 15 20.5 15 20Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <div className="m-0">
                              <span className="fw-semibold fs-6 text-gray-400">
                                {t("unique_callers")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex mb-0 align-items-center justify-content-center">
                            <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">
                              {data.indicators.callers}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-5 mb-xl-10 w-20-per">
                      <div className="card h-lg-70">
                        <div className="card-body d-flex justify-content-between align-items-center flex-row p-5">
                          <div className="d-flex flex-column mb-0">
                            <span className="svg-icon svg-icon-2 svg-icon-gray-600">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.3"
                                  d="M2.10001 10C3.00001 5.6 6.69998 2.3 11.2 2L8.79999 4.39999L11.1 7C9.60001 7.3 8.30001 8.19999 7.60001 9.59999L4.5 12.4L2.10001 10ZM19.3 11.5L16.4 14C15.7 15.5 14.4 16.6 12.7 16.9L15 19.5L12.6 21.9C17.1 21.6 20.8 18.2 21.7 13.9L19.3 11.5Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M13.8 2.09998C18.2 2.99998 21.5 6.69998 21.8 11.2L19.4 8.79997L16.8 11C16.5 9.39998 15.5 8.09998 14 7.39998L11.4 4.39998L13.8 2.09998ZM12.3 19.4L9.69998 16.4C8.29998 15.7 7.3 14.4 7 12.8L4.39999 15.1L2 12.7C2.3 17.2 5.7 20.9 10 21.8L12.3 19.4Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <div className="m-0">
                              <span className="fw-semibold fs-6 text-gray-400">
                                {t("growth")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex mb-0 align-items-center justify-content-center">
                            {data.indicators.growth.symbol === "-" ? (
                              <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={11}
                                    y={18}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-90 11 18)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            ) : data.indicators.growth.rate > 0 ? (
                              <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={13}
                                    y={6}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(90 13 6)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            ) : (
                              ""
                            )}

                            <span
                              className={`fw-semibold fs-2x  lh-1 ls-n2 ${
                                data.indicators.growth.symbol === "-"
                                  ? "color-red"
                                  : data.indicators.growth.rate > 0
                                  ? "color-green"
                                  : "text-gray-800"
                              }`}
                            >
                              {data.indicators.growth.rate}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-6">
                      <div className="card card-xl-stretch mb-xl-8">
                        {!dataLoad && <SpinnerDiv />}
                        <div
                          className={`card-body p-0 ${
                            !dataLoad ? "opacity-low" : ""
                          }`}
                        >
                          <div
                            className="px-9 pt-7 card-rounded h-275px w-100"
                            style={{
                              backgroundColor: "#F1416C",
                              backgroundImage:
                                'url("assets/media/svg/shapes/wave-bg-red.svg")',
                            }}
                          >
                            <div className="d-flex flex-stack">
                              <h3 className="m-0 text-white fw-bold fs-3">
                                {t("inbound_summary")}
                              </h3>
                            </div>
                            <div className="d-flex flex-center text-white pt-8">
                              <div
                                style={{
                                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                                  backgroundColor: "#476fa600",
                                }}
                                className="d-flex flex-center rounded-circle h-80px w-80px me-2"
                              >
                                <svg
                                  className="svg_style"
                                  fill="#ffffff"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path d="M15.793 6.793 13 4v7h7l-2.793-2.793 4.5-4.5-1.414-1.414z" />
                                    <path d="M16.422 13.443a1.001 1.001 0 0 0-1.391.043l-2.392 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.86 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1.001 1.001 0 0 0-.291.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.324 21 17.705 21c.203 0 .326-.006.359-.008a.99.99 0 0 0 .648-.291l1.861-2.171a1.001 1.001 0 0 0-.086-1.391l-4.065-3.696z" />
                                  </g>
                                </svg>
                              </div>
                              <div>
                                <span className="fw-semibold fs-7 d-block">
                                  {t("your_total")}
                                </span>
                                <span className="fw-bold fs-2x pt-1">
                                  {data.details.inbound.total}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1"
                            style={{ marginTop: "-100px" }}
                          >
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/answered-call-color.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("answered_calls")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {data.details.inbound.total}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={13}
                                        y={6}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/missed-call.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("missed_calls")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {data.details.inbound.by_status[3]}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={13}
                                        y={6}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      />

                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/chrono.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("total_duration")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {formatDuration(
                                      data.details.inbound.duration
                                    )}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={11}
                                        y={18}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 11 18)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/chrono.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("av_calls_duration")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {formatDuration(
                                      data.details.inbound.average
                                    )}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={13}
                                        y={6}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="card card-xl-stretch mb-xl-8">
                        {!dataLoad && <SpinnerDiv />}
                        <div
                          className={`card-body p-0 ${
                            !dataLoad ? "opacity-low" : ""
                          }`}
                        >
                          <div
                            className="px-9 pt-7 card-rounded h-275px w-100"
                            style={{
                              backgroundColor: "#7239EA",
                              backgroundImage:
                                "url(assets/media/svg/shapes/wave-bg-purple.svg)",
                            }}
                          >
                            <div className="d-flex flex-stack">
                              <h3 className="m-0 text-white fw-bold fs-3">
                                {t("outbound_summary")}
                              </h3>
                            </div>
                            <div className="d-flex flex-center text-white pt-8">
                              <div
                                style={{
                                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                                  backgroundColor: "#dd325c00",
                                }}
                                className="d-flex flex-center rounded-circle h-80px w-80px me-2"
                              >
                                <svg
                                  className="svg_style"
                                  fill="#ffffff"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path d="m16.793 5.793-4.5 4.5 1.414 1.414 4.5-4.5L21 10V3h-7z" />
                                    <path d="M16.422 13.446a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.516a1 1 0 0 0-1.391-.087L3.299 5.29a.996.996 0 0 0-.291.648c-.015.25-.301 6.172 4.291 10.766 4.006 4.006 9.024 4.299 10.406 4.299.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a1 1 0 0 0-.086-1.391l-4.064-3.696z" />
                                  </g>
                                </svg>
                              </div>
                              <div>
                                <span className="fw-semibold fs-7 d-block">
                                  {t("your_total")}
                                </span>
                                <span className="fw-bold fs-2x pt-1">
                                  {data.details.outbound.total}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1"
                            style={{ marginTop: "-100px" }}
                          >
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/answered-call-color.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("answered_calls")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {data.details.outbound.by_status[1]}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={13}
                                        y={6}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/missed-call.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("missed_calls")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {data.details.outbound.by_status[3]}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={11}
                                        y={18}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 11 18)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/chrono.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("total_duration")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {formatDuration(
                                      data.details.outbound.duration
                                    )}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={11}
                                        y={18}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-90 11 18)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px w-40px me-5">
                                <span className="symbol-label bg-lighten">
                                  <span className="svg-icon svg-icon-1">
                                    <img
                                      src="/assets/media/icons/chrono.png"
                                      className="rep-icons"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                  <span className="fs-5 text-gray-800 fw-bold">
                                    {t("av_calls_duration")}
                                  </span>
                                  <div className="text-gray-400 fw-semibold fs-7"></div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="fw-bold fs-5 text-gray-800 pe-1">
                                    {formatDuration(
                                      data.details.outbound.average
                                    )}
                                  </div>
                                  {/* <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x={13}
                                        y={6}
                                        width={13}
                                        height={2}
                                        rx={1}
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-md-6 position-relative">
                      {!dataLoad && <SpinnerDiv />}
                      <div
                        className={`card h-xl-100 ${
                          !dataLoad ? "opacity-low" : ""
                        }`}
                      >
                        <div
                          className={`card-header position-relative py-0 border-bottom-2 `}
                        >
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">
                              {t("calls_status")}
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pb-3 flex-stats-center">
                          <div className="me-md-5 w-custom-30">
                            <div className="d-flex border border-gray-300 border-dashed rounded p-2 mb-6">
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <img
                                        src="/assets/media/icons/answered-call-color.png"
                                        className="rep-icons"
                                      />
                                    </span>
                                  </span>
                                </div>
                                <div className="me-2">
                                  <span className="text-gray-800 fs-6 fw-bold">
                                    {t("answered")}
                                  </span>
                                  <span className="text-gray-400 fw-bold d-block fs-7"></span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center by-status">
                                <span className="text-dark fw-bolder fs-2x">
                                  {data.details.inbound.by_status[1]}
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  {data.details.inbound.total}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex border border-gray-300 border-dashed rounded p-2 mb-6">
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <img
                                        src="/assets/media/icons/missed-call-color.png"
                                        className="rep-icons"
                                      />
                                    </span>
                                  </span>
                                </div>
                                <div className="me-2">
                                  <span className="text-gray-800 fs-6 fw-bold">
                                    {t("no_answer")}
                                  </span>
                                  <span className="text-gray-400 fw-bold d-block fs-7"></span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center by-status">
                                <span className="text-dark fw-bolder fs-2x">
                                  0
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1 ">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  {data.indicators.calls}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pie-chart-reportings">
                            <PieChart
                              data={[
                                ["call", "status"],
                                [
                                  t("answered"),
                                  data.details.inbound.by_status[1],
                                ],
                                [t("busy"), data.details.inbound.by_status[2]],
                                [
                                  t("not_answered"),
                                  data.details.inbound.by_status[3],
                                ],
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 position-relative">
                      {!dataLoad && <SpinnerDiv />}
                      <div
                        className={`card card-flush h-lg-70 max-height ${
                          !dataLoad ? "opacity-low" : ""
                        }`}
                      >
                        <div className="card-header pt-7 mb-5 ">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">
                              {t("top_callers")}
                            </span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                          </h3>
                        </div>
                        <div className="card-body pt-0">
                          {data.display === 1 ? (
                            Object.entries(
                              data.details.inbound.top_callers
                            ).map(([key, value]) => {
                              return (
                                <div>
                                  {" "}
                                  <div className="d-flex flex-stack">
                                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                      <div className="top-caller">
                                        <div className="flag-container">
                                          <img
                                            src={`assets/media/flags/${value.country.toLowerCase()}.svg`}
                                            className="flag-style w-18px"
                                            style={{ borderRadius: 4 }}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <span className="text-gray-800 fw-bold fs-6 flex-inline-container">
                                            <span>{key}</span>
                                          </span>
                                          <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                            {t("average")}:{" "}
                                            {formatDuration(value.average)}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="text-gray-800 fw-bold fs-6 me-3 d-block">
                                          {formatDuration(value.duration)}
                                        </span>
                                        {/* <div className="m-0">
                                          <span className="badge badge-light-success fs-base">
                                            <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                              <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x={13}
                                                  y={6}
                                                  width={13}
                                                  height={2}
                                                  rx={1}
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </span>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="separator separator-dashed my-3" />
                                </div>
                              );
                            })
                          ) : data.display === 0 ? (
                            <div className="width-100 display-flex flex-column gap-3">
                              <img
                                className="height-12"
                                src="/assets\media\illustrations\unitedpalms-1/no-data.svg"
                                alt="no-data"
                              />
                              <span className="no-data-title">
                                {t("no_data")}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportGeneral;
