import React from 'react'

const UnallocatePhoneNumbersModal = () => {
  return (
    <div
  className="modal fade"
  id="kt_modal_unallocate_phone"
  tabIndex={-1}
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog mw-850px">
    <form className="modal-content" id="">
      <div className="modal-header modal-header-bg py-4">
        <h3 className="text-white">
          Unallocate phone numbers
          <div className="d-flex flex-wrap fw-semibold fs-6 pt-1">
            <div
              href="javascript:"
              className="d-flex align-items-center text-gray-900 me-5"
            >
              <span className="svg-icon svg-icon-4 me-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={8}
                    y={3}
                    width={8}
                    height={8}
                    rx={4}
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div
              href="javascript:"
              className="d-flex align-items-center text-gray-900"
              data-number-status=""
            >
              <span className="svg-icon svg-icon-4 me-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={10}
                    fill="currentColor"
                  />
                  <path
                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
        </h3>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          <span className="svg-icon svg-icon-1">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="currentColor"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="modal-body scroll-y">
        <div className="w-100 mb-7">
          <div className="fv-row mb-0">
            <label className="fs-6 fw-semibold form-label required">
              Numbers
            </label>
            <div className="border rounded">
              <select
                id="kt_docs_select2_rich_content"
                className="form-select form-select-transparent"
                name="..."
                data-placeholder="Choose"
                multiple="multiple"
              >
                <option />
                <option
                  value={0}
                  data-kt-rich-content-subcontent="United states"
                  data-kt-rich-content-icon="assets/media/flags/united-states.svg"
                >
                  0152369857
                </option>
                <option
                  value={1}
                  data-kt-rich-content-subcontent="France"
                  data-kt-rich-content-icon="assets/media/flags/france.svg"
                >
                  3369855158
                </option>
                <option
                  value={2}
                  data-kt-rich-content-subcontent="Belgium"
                  data-kt-rich-content-icon="assets/media/flags/belgium.svg"
                >
                  32699854100
                </option>
                <option
                  value={3}
                  data-kt-rich-content-subcontent="France"
                  data-kt-rich-content-icon="assets/media/flags/france.svg"
                >
                  33690524125
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer py-4">
        <div className="w-100 d-flex flex-end">
          <span className="svg-icon svg-icon-3 ms-1 me-0">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x={11}
                y={18}
                width={12}
                height={2}
                rx={1}
                transform="rotate(-90 11 18)"
                fill="currentColor"
              />
              <rect
                x={6}
                y={11}
                width={12}
                height={2}
                rx={1}
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

  )
}

export default UnallocatePhoneNumbersModal