export const addPayment = async (body) => {
    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/interface/payments`;
  
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body
    });
    return response;
  };
  