import Swal from "sweetalert2";

const useSweetAlert = () => {
  const showSecondTrySwall = () => {
    let timerInterval;

    Swal.fire({
      title: "Trying to reconnect!",
      html: "Next try in <b>0%</b>.",
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          const percentage = (
            ((1000 - Swal.getTimerLeft()) / 1000) *
            100
          ).toFixed(0);
          timer.textContent = `${percentage}%`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  const showErrorSwal = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  const showErrorExpiredSession = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
      confirmButtonText: "OK",
    }).then(() => {
      window.location.reload();
      localStorage.clear();
    });
  };

  return {
    showErrorSwal,
    showSecondTrySwall,
    showErrorExpiredSession,
  };
};

export default useSweetAlert;
