import React, { useEffect, useState } from "react";
import StickySummary from "../Components/StickySummary";
import { useTranslation } from "react-i18next";
import getPricing from "../Services/getPricing";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import "../Styles/Pricing.css";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Pricing = () => {
  const { t } = useTranslation();

  const [scrollPage, setScrollPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPage(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollTop = () => {
      if (window.scrollY == 0) {
        setScrollPage(false);
      }
    };
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  const [body, setBody] = useState({
    country: null,
    phone_numbers: false,
    calls: false,
    sms: false,
    prefix: null,
  });

  const handleBodyChange = (item, value) => {
    setBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    body.country && fetchData(body.country);
  }, [body.country, refresh]);

  const fetchData = async (country) => {
    try {
      setLoading(true);
      const response = await getPricing(country.toUpperCase());
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      if (result.exist) {
        setData((prevData) => ({ ...prevData, display: 1 }));

        const types = [
          "phone.local",
          "phone.mobile",
          "phone.tollf",
          "phone.national",
        ];

        types.forEach((item) => {
          
          const tariffs = result.data.tariffs[item];

          const getAmount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.amount || null;
          const getDiscount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.discount?.amount || 0;

          const getMinPrice = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map(
                    (item) => item.amount
                  )
                )
              : null;
          const getMinDiscount = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map((item) =>
                    item.discount?.amount ? parseFloat(item.discount.amount) : 0
                  )
                )
              : 0;

          if (result.data.products.includes(item)) {
            const mapNumber = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                setup_cost: {
                  price: getAmount(1),
                  discount: getDiscount(1),
                },
                price: {
                  price: getAmount(2),
                  discount: getDiscount(2),
                },
              };
            };

            const mapCalls = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_calls: {
                  price: getMinPrice("call.out"),
                  discount: getMinDiscount("call.out"),
                },
                inbound_calls: {
                  price: getMinPrice("call.in"),
                  discount: getMinDiscount("call.in"),
                },
              };
            };

            const mapSMS = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_sms: {
                  price: getMinPrice("sms.out"),
                  discount: getMinDiscount("sms.out"),
                },
                inbound_sms: {
                  price: getMinPrice("sms.in"),
                  discount: getMinDiscount("sms.in"),
                },
              };
            };

            setData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  mapNumber(number, item)
                ),
                calls: prevData.data.calls.map(mapCalls),
                sms: prevData.data.sms.map(mapSMS),
              },
            }));
          } else {
            setData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        setup_cost: { price: null, discount: 0 },
                        price: { price: null, discount: 0 },
                      }
                    : number
                ),
                calls: prevData.data.calls.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_calls: { price: null, discount: 0 },
                        inbound_calls: { price: null, discount: 0 },
                      }
                    : number
                ),
                sms: prevData.data.sms.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_sms: { price: null, discount: 0 },
                        inbound_sms: { price: null, discount: 0 },
                      }
                    : number
                ),
              },
            }));
          }
        });

        if (result.data.products.length > 0) {
          setBody((prevData) => ({ ...prevData, phone_numbers: true }));
        }

        if (
          result.data.capabilities.includes("call.in") ||
          result.data.capabilities.includes("call.out")
        ) {
          setBody((prevData) => ({ ...prevData, calls: true }));
        } else {
          setBody((prevData) => ({ ...prevData, calls: "not-supported" }));
        }

        if (
          result.data.capabilities.includes("sms.in") ||
          result.data.capabilities.includes("sms.out")
        ) {
          setBody((prevData) => ({ ...prevData, sms: true }));
        } else {
          setBody((prevData) => ({ ...prevData, sms: "not-supported" }));
        }

        data.data.addons.forEach((element) => {
          if (result.data.addons.includes(element.type.replace(/_/g, "."))) {
            setData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                addons: prevData.data.addons.map((item) =>
                  item.type === element.type
                    ? {
                        ...item,
                        price:
                          result.data.tariffs[element.type.replace(/_/g, ".")]
                            .prices[0].amount,
                      }
                    : item
                ),
              },
            }));
          } else {
            setData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                addons: prevData.data.addons.map((item) =>
                  item.type === element.type ? { ...item, price: null } : item
                ),
              },
            }));
          }
        });
      } else {
        setData((prevData) => ({ ...prevData, display: 2 }));
        setBody((prevData) => ({
          ...prevData,
          phone_numbers: false,
          calls: false,
          sms: false,
          prefix: null,
        }));
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const [data, setData] = useState({
    display: 0,
    data: {
      numbers: [
        {
          type: "phone.local",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },

        {
          type: "phone.tollf",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
      ],
      calls: [
        {
          type: "phone.local",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.tollf",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
      ],
      sms: [
        {
          type: "phone.local",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.tollf",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
      ],
      addons: [
        { type: "call_forwarding", price: null, discount: 0 },
        { type: "call_recording", price: null, discount: 0 },
        { type: "recording_storage", price: null, discount: 0 },
        { type: "answering_machine_detection", price: null, discount: 0 },
        { type: "voice_mail", price: null, discount: 0 },
        { type: "ivr", price: null, discount: 0 },
        { type: "sms_autoresponder", price: null, discount: 0 },
        { type: "advanced_insights", price: null, discount: 0 },
      ],
    },
  });

  useEffect(() => {
    localStorage.setItem("active", 11);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="d-flex flex-column flex-lg-row">
                <StickySummary
                  fixedSummary={scrollPage}
                  body={body}
                  editBody={handleBodyChange}
                  refresh={() => setRefresh(refresh + 1)}
                />

                <div className="flex-lg-row-fluid mb-10 mb-lg-0">
                  <div>
                    {data.display === 1 ? (
                      <div>
                        {body.phone_numbers === true && (
                          <div className={`card card-flush mb-5 mb-xl-10`}>
                            {loading && <SpinnerDiv />}
                            <div className="card-header bg-light min-h-50px">
                              <div className="card-title">
                                <h3 className="fw-bold text-header-light">
                                  {t("phone_numbers")}
                                </h3>
                              </div>
                            </div>
                            <div
                              className={`card-body  ${
                                loading && "opacity-low"
                              }`}
                            >
                              <div className="mb-0">
                                <div className="table-responsive">
                                  <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                    <thead>
                                      <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-150px">
                                          {t("number_type")}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {t("setup_cost")}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {t("price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-800">
                                      {data.data.numbers.map((item) => (
                                        <tr>
                                          <td>
                                            <label>
                                              {" "}
                                              {t(
                                                item.type === "phone.local"
                                                  ? "local"
                                                  : item.type ===
                                                    "phone.national"
                                                  ? "national"
                                                  : item.type === "phone.mobile"
                                                  ? "mobile"
                                                  : item.type === "phone.tollf"
                                                  ? "tollfree"
                                                  : ""
                                              )}
                                              <div className="fw-normal text-gray-600">
                                                {item.type === "phone.local"
                                                  ? t("local_desc")
                                                  : item.type ===
                                                    "phone.national"
                                                  ? t("national_desc")
                                                  : item.type === "phone.mobile"
                                                  ? t("mobile_desc")
                                                  : ""}
                                              </div>
                                            </label>
                                          </td>

                                          <td>
                                            {item.setup_cost.price == 0 ||
                                            (item.setup_cost.price == null &&
                                              item.price.price) ? (
                                              <span className="free-price">
                                                {t("free")}
                                              </span>
                                            ) : item.setup_cost.price ===
                                              null ? (
                                              <span className="not-supported">
                                                {t("not_supported")}
                                              </span>
                                            ) : (
                                              <div>
                                                {item.setup_cost.discount >
                                                0 ? (
                                                  <div>
                                                    <span className="old-price">
                                                      {item.setup_cost.price}$
                                                    </span>
                                                    <span>
                                                      {" "}
                                                      {parseFloat(
                                                        item.setup_cost.price -
                                                          item.setup_cost
                                                            .discount
                                                      ).toFixed(2)}
                                                      $
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <span>
                                                    {" "}
                                                    {item.setup_cost.price}$
                                                  </span>
                                                )}
                                              </div>
                                            )}
                                          </td>

                                          <td>
                                            {item.price.price > 0 ? (
                                              <span>
                                                {item.price.price}${" "}
                                                <span className="pre-text ms-1">
                                                  {" "}
                                                  /{t("month")}
                                                </span>
                                              </span>
                                            ) : item.price.price == 0 ||
                                              (item.setup_cost.price &&
                                                item.price.price == null) ? (
                                              <span className="free-price">
                                                {t("free")}
                                              </span>
                                            ) : (
                                              <span className="not-supported">
                                                {" "}
                                                {t("not_supported")}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {body.calls === true && (
                          <div className={`card card-flush mb-5 mb-xl-10`}>
                            {loading && <SpinnerDiv />}

                            <div className="card-header bg-light min-h-50px">
                              <div className="card-title">
                                <h3 className="fw-bold text-header-light">
                                  {t("calls")}
                                </h3>
                              </div>
                            </div>

                            <div
                              className={`card-body ${
                                loading && "opacity-low"
                              }`}
                            >
                              <div>
                                <div className="table-responsive">
                                  <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                    <thead>
                                      <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-150px">
                                          {t("number_type")}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {t("outbound_calls")}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {t("inbound_calls")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-800">
                                      <tr>
                                        <td>
                                          <label>{t("local")}</label>
                                        </td>
                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.local"
                                          ).outbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type === "phone.local"
                                              ).outbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : (
                                                <div>
                                                  {
                                                    data.data.calls.find(
                                                      (element) =>
                                                        element.type ===
                                                        "phone.local"
                                                    ).outbound_calls.price
                                                  }
                                                  $
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.local"
                                          ).inbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type === "phone.local"
                                              ).inbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.local"
                                                ).inbound_calls.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.local"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.local"
                                                      ).inbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.local"
                                                      ).inbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.local"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>{t("national")}</label>
                                        </td>
                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.national"
                                          ).outbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.national"
                                              ).outbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.national"
                                                ).outbound_calls.discount >
                                                0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      )
                                                      .outbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      ).outbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      ).outbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      )
                                                      .outbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>

                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.national"
                                          ).inbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.national"
                                              ).inbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.national"
                                                ).inbound_calls.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      ).inbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      ).inbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.national"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>{t("mobile")}</label>
                                        </td>

                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.mobile"
                                          ).inbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.mobile"
                                              ).inbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.mobile"
                                                ).inbound_calls.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>

                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.mobile"
                                          ).inbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.mobile"
                                              ).inbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.mobile"
                                                ).inbound_calls.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>{t("tollfree")}</label>

                                          <div className="fw-normal text-gray-400">
                                            {t("basic_yearly_bundel")}
                                          </div>
                                        </td>

                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.tollf"
                                          ).outbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type === "phone.tollf"
                                              ).outbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.tollf"
                                                ).outbound_calls.discount >
                                                0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      )
                                                      .outbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      ).outbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      ).outbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      )
                                                      .outbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>

                                        <td>
                                          {data.data.calls.find(
                                            (element) =>
                                              element.type === "phone.tollf"
                                          ).inbound_calls.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.calls.find(
                                                (element) =>
                                                  element.type === "phone.tollf"
                                              ).inbound_calls.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.calls.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.tollf"
                                                ).inbound_calls.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      ).inbound_calls.price -
                                                      data.data.calls.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      ).inbound_calls.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.calls
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.tollf"
                                                      )
                                                      .inbound_calls.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer bg-light-footer">
                              <span>{t("pricing_calls_desc")}.</span>
                            </div>
                          </div>
                        )}

                        {body.sms === true && (
                          <div className={`card card-flush mb-5 mb-xl-10`}>
                            {loading && <SpinnerDiv />}
                            <div className="card-header bg-light min-h-50px">
                              <div className="card-title">
                                <h3 className="fw-bold text-header-light">
                                  SMS
                                </h3>
                              </div>
                            </div>
                            <div
                              className={`card-body  ${
                                loading && "opacity-low"
                              }`}
                            >
                              <div className="mb-0">
                                <div className="table-responsive">
                                  <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                    <thead>
                                      <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-150px">
                                          {t("number_type")}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {" "}
                                          {t("outbound_sms")}{" "}
                                        </th>
                                        <th className="min-w-125px w-30">
                                          {t("inbound_sms")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-800">
                                      <tr>
                                        <td>
                                          <label>{t("mobile")}</label>
                                        </td>

                                        <td>
                                          {data.data.sms.find(
                                            (element) =>
                                              element.type === "phone.mobile"
                                          ).outbound_sms.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.sms.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.mobile"
                                              ).outbound_sms.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.sms.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.mobile"
                                                ).outbound_sms.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.sms
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .outbound_sms.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.sms.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).outbound_sms.price -
                                                      data.data.sms.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).outbound_sms.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.sms
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .outbound_sms.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>

                                        <td>
                                          {data.data.sms.find(
                                            (element) =>
                                              element.type === "phone.mobile"
                                          ).inbound_sms.price === null ? (
                                            <span className="not-supported">
                                              {" "}
                                              {t("not_supported")}
                                            </span>
                                          ) : (
                                            <div>
                                              <div className="pre-text">
                                                {t("starts_at")}
                                              </div>
                                              {data.data.sms.find(
                                                (element) =>
                                                  element.type ===
                                                  "phone.mobile"
                                              ).inbound_sms.price == 0 ? (
                                                <span className="free-price">
                                                  {t("free")}
                                                </span>
                                              ) : data.data.sms.find(
                                                  (element) =>
                                                    element.type ===
                                                    "phone.mobile"
                                                ).inbound_sms.discount > 0 ? (
                                                <div>
                                                  <span className="old-price">
                                                    {data.data.sms
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_sms.price.toFixed(
                                                        2
                                                      )}
                                                    $
                                                  </span>
                                                  <span className="ms-2">
                                                    {(
                                                      data.data.sms.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_sms.price -
                                                      data.data.sms.find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      ).inbound_sms.discount
                                                    ).toFixed(2)}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data.data.sms
                                                      .find(
                                                        (element) =>
                                                          element.type ===
                                                          "phone.mobile"
                                                      )
                                                      .inbound_sms.price.toFixed(
                                                        2
                                                      )}
                                                  </span>
                                                  <span className="pre-text ms-1">
                                                    <span className="pre-text ms-1">
                                                      /{t("min")}
                                                    </span>
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer bg-light-footer">
                              <span>{t("pricing_sms_desc")}.</span>
                            </div>
                          </div>
                        )}

                        <div className={`card card-flush mb-5 mb-xl-10`}>
                          {loading && <SpinnerDiv />}
                          <div className="card-header bg-light min-h-50px">
                            <div className="card-title">
                              <h3 className="fw-bold text-header-light">
                                {t("add_ons_services")}
                              </h3>
                            </div>
                          </div>
                          <div
                            className={`card-body  ${loading && "opacity-low"}`}
                          >
                            <div className="mb-0">
                              <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                  <thead>
                                    <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                      <th className="min-w-150px">
                                        {t("service")}
                                      </th>
                                      <th className="min-w-125px">
                                        {t("price")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="fw-semibold text-gray-800">
                                    {data.data.addons
                                      .filter((item) =>
                                        body.sms !== "not-supported" &&
                                        body.calls !== "not-supported"
                                          ? item
                                          : body.sms !== "not-supported" &&
                                            item.type === "sms_autoresponder"
                                          ? item
                                          : body.calls !== "not-supported" &&
                                            item.type !== "sms_autoresponder"
                                          ? item
                                          : ""
                                      )
                                      .map((item) => (
                                        <tr>
                                          <td>
                                            <label>{t(item.type)}</label>
                                            <div className="fw-normal text-gray-600">
                                              {t(`${item.type}_desc`)}
                                            </div>
                                          </td>
                                          <td>
                                            {item.price == 0 ? (
                                              <span className="free-price">
                                                {t("included_free")}
                                              </span>
                                            ) : item.price === null ? (
                                              <span className="not-supported">
                                                {" "}
                                                {t("not_supported")}
                                              </span>
                                            ) : (
                                              `${item.price}$`
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        {body.calls === true && (
                          <div
                            className={`card card-flush mb-5 mb-xl-10 disabled-event`}
                          >
                            {loading && <SpinnerDiv />}
                            <div className="card-header bg-light min-h-50px">
                              <div className="card-title">
                                <h3 className="fw-bold text-header-light">
                                  {t("geo_area_details")}
                                </h3>
                              </div>
                            </div>
                            <div
                              className={`card-body  ${
                                loading && "opacity-low"
                              }`}
                            >
                              <div className="mb-0">
                                <div className="table-responsive">
                                  <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                    <thead>
                                      <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-150px">
                                          {t("route")}
                                        </th>
                                        <th className="min-w-150px">
                                          Origination Prefix (From)
                                        </th>
                                        <th className="min-w-150px">
                                          Destination Prefix (To)
                                        </th>
                                        <th className="min-w-125px">Price</th>
                                      </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-800">
                                      <tr>
                                        <td>
                                          <label>France</label>
                                          <div className="fw-normal text-gray-600">
                                            FR | +33
                                          </div>
                                        </td>
                                        <td />
                                        <td>
                                          +33, +331, +331000, +331002, +331003
                                          <button className="btn btn-sm btn-light py-1 px-2 fs-8">
                                            View more
                                          </button>
                                        </td>
                                        <td>
                                          $0.053
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>France - Mobile</label>
                                          <div className="fw-normal text-gray-600">
                                            FR | +33
                                          </div>
                                        </td>
                                        <td />
                                        <td>+335091, +336, +337, +3397067</td>
                                        <td>
                                          $0.303
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>
                                            France - Mobile - Major Carriers
                                            (Orange, SFR, Bouygues){" "}
                                          </label>
                                          <div className="fw-normal text-gray-600">
                                            FR | +33
                                          </div>
                                        </td>
                                        <td />
                                        <td>
                                          +336000, +336001, +336002, +336003{" "}
                                          <button className="btn btn-sm btn-light py-1 px-2 fs-8">
                                            View more
                                          </button>
                                        </td>
                                        <td>
                                          $0.353
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>
                                            France - Mobile - Major Carriers
                                            (Orange, SFR, Bouygues) from EEA
                                          </label>
                                          <div className="fw-normal text-gray-600">
                                            FR | +33
                                          </div>
                                        </td>
                                        <td>
                                          +30, +31, +32, +33, +34, +351, +352,
                                          +353,{" "}
                                        </td>
                                        <td>
                                          +33, +331, +331000, +331002, +331003{" "}
                                          <button className="btn btn-sm btn-light py-1 px-2 fs-8">
                                            View more
                                          </button>
                                        </td>
                                        <td>
                                          $0.0495
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>
                                            France - Mobile from EEA{" "}
                                          </label>
                                          <div className="fw-normal text-gray-600">
                                            FR | +33
                                          </div>
                                        </td>
                                        <td>
                                          +30, +31, +32, +33, +34, +351, +352,
                                          +353{" "}
                                          <button className="btn btn-sm btn-light py-1 px-2 fs-8">
                                            View more
                                          </button>
                                        </td>
                                        <td>
                                          +33, +331, +331000, +331002, +331003
                                        </td>
                                        <td>
                                          $0.0426
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>
                                            France - Premium Services
                                          </label>
                                        </td>
                                        <td />
                                        <td>
                                          +33, +331, +331000, +331002, +331003{" "}
                                          <button className="btn btn-sm btn-light py-1 px-2 fs-8">
                                            View more
                                          </button>
                                        </td>
                                        <td>
                                          $5.5
                                          <span className="pre-text ms-1">
                                            <span className="pre-text ms-1">
                                              /{t("min")}
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : data.display === 2 ? (
                      <div className="d-flex flex-xl-center flex-column gap-8 h-500px">
                        <img
                          height={200}
                          src="/assets/media/illustrations/unitedpalms-1/country-not-supported.svg"
                          alt="img"
                        />
                        <span className="no-data-title">
                          {t("coutry_is_not_supported")}
                        </span>
                      </div>
                    ) : (
                      <div
                        className={`d-flex flex-xl-center flex-column h-500px gap-5 `}
                      >
                        <img
                          height={200}
                          src="/assets/media/illustrations/unitedpalms-1/pricing.svg"
                          alt="img"
                          className={loading && "disabled-event"}
                        />
                        <span className="no-data-title position-relative">
                          {loading && <SpinnerDiv />}

                          <span className={loading && "disabled-event"}>
                            {t("select_country_from_left")}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
