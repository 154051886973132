import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import "./style/Modals.css";
import SecondaryButton from "../Buttons/SecondaryButton";
import ErrorExpiredSession from "../Alerts/errorExpiredSession";
import ErrorSwal from "../Alerts/errorAlert";
import SpinnerSecondary from "../SpinnerSecondary";
import { confirmationSwal } from "../Alerts/confirmationAlert";
import { successSwall } from "../Alerts/successAlert";
import InternalErrorSwal from "../Alerts/internalErrorAlert";

const UpdatePasswordModal = ({ updateModalShow }) => {
  const [data, setData] = useState({ password: "", password_confirmation: "" });
  const [error, setError] = useState(false);

  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const handleInputChange = (e) => {
    if (error) {
      setError(false);
    }
    const { name, value } = e.target;
    setData((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setError(false);
    setData({ password: "", password_confirmation: "" });
  };

  // UPDATE

  const handleUpdatePassword = async () => {
    try {
      setSpinnerLoad(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/users/reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            new_password: `${data.password}`,
          }),
        }
      );
      setSpinnerLoad(false);
      if (!response.ok) {
        const fetch = await response.json();
        if (fetch.code === "IS_SAME_PASSWORD") {
          throw new Error("same_pw");
        } else {
          throw new Error(response.status);
        }
      }
      const fetchedData = await response.json();
      localStorage.setItem("token", fetchedData.data[0].access_token);
      successSwall(t("password_updated"));
      updateModalShow(false);
    } catch (error) {
      if (error.message === "same_pw") {
        ErrorSwal(t("errors.same_pw"));
      } else if (error.message == 400) {
        ErrorSwal(t("errors.pw"));
      } else if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const { t } = useTranslation();

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const [solidPw, setSolidPw] = useState(true);

  const handleValidationState = (item, value) => {
    setPasswordValidation((prevData) => ({ ...prevData, [item]: value }));
  };

  const pwValidation = (e) => {
    const password = e.target.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    password.length > 12
      ? handleValidationState("length", true)
      : handleValidationState("length", false);
    hasUpperCase
      ? handleValidationState("uppercase", true)
      : handleValidationState("uppercase", false);
    hasLowerCase
      ? handleValidationState("lowercase", true)
      : handleValidationState("lowercase", false);
    hasNumber
      ? handleValidationState("number", true)
      : handleValidationState("number", false);
    hasSymbol
      ? handleValidationState("symbol", true)
      : handleValidationState("symbol", false);
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className={`modal-container pw-modal fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <h2>{t("update_password")}</h2>
            <div
              className="justify-content-end btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => {
                updateModalShow(false);
              }}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div>
            <div className="p-6">
            <div className="mb-6">
                  <label htmlFor="" className="form-label mb-3 required">
                    {t("modal.reset.label.new_pw")}
                  </label>

                  <input
                    type="password"
                    className={`form-control form-control-sm form-control-solid ${
                      (error || !solidPw) && "form-control-error"
                    }`}
                    id=""
                    required=""
                    placeholder=""
                    name="password"
                    value={data.password}
                    onChange={(e) => {
                      handleInputChange(e);
                      pwValidation(e);
                    }}
                    onBlur={() => {
                      const password = data.password;
                      const hasUpperCase = /[A-Z]/.test(password);
                      const hasLowerCase = /[a-z]/.test(password);
                      const hasNumber = /[0-9]/.test(password);
                      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

                      if (
                        password.length < 12 ||
                        !hasUpperCase ||
                        !hasLowerCase ||
                        !hasNumber ||
                        !hasSymbol
                      ) {
                        setSolidPw(false);
                      }
                    }}
                  />

                  {data.password.length > 0 && (
                    <div
                      className={`d-flex flex-column mt-3 ${
                        passwordValidation.length &&
                        passwordValidation.number &&
                        passwordValidation.uppercase &&
                        passwordValidation.lowercase &&
                        passwordValidation.symbol &&
                        "display-none"
                      }`}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.length ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.length
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("min_12_char")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.uppercase
                                  ? "#a1a5b7"
                                  : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.uppercase
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("uppercase")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.lowercase
                                  ? "#a1a5b7"
                                  : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.lowercase
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("lowercase")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.number ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.number
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("valid_number")}
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <svg
                          height={14}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill="none"
                              stroke={
                                !passwordValidation.symbol ? "#a1a5b7" : "green"
                              }
                              stroke-width="2"
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                            ></path>{" "}
                          </g>
                        </svg>

                        <span
                          className={`fs-8 fw-semibold ${
                            passwordValidation.symbol
                              ? "color-green"
                              : "text-gray-600"
                          }`}
                        >
                          {t("symbol")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    (!passwordValidation.length ||
                      !passwordValidation.number ||
                      !passwordValidation.uppercase ||
                      !passwordValidation.lowercase ||
                      !passwordValidation.symbol) &&
                    "disabled-event"
                  }`}
                >
                  <label htmlFor="" className="form-label mb-3 required">
                    {t("modal.reset.label.confirm_pw")}
                  </label>
                  <input
                    type="password"
                    className={`form-control form-control-sm form-control-solid ${
                      error && "form-control-error"
                    }`}
                    id=""
                    required=""
                    placeholder=""
                    name="password_confirmation"
                    value={data.password_confirmation}
                    onChange={handleInputChange}
                    onBlur={() => {
                      if (data.password !== data.password_confirmation) {
                        setError(true);
                      }
                    }}
                  />
                </div>

              {error && (
                <div className="d-flex mt-6">
                  {" "}
                  <div className="fw-bold danger-drag-and-drop">
                    <svg
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          opacity="0.5"
                          d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                          fill="#e73e3e"
                        ></path>{" "}
                      </g>
                    </svg>
                    <span className="fs-7 fw-semibold text-gray-800">
                      {t("errors.pw_not_match")}.
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-end p-6 footer-border-top">
              <SecondaryButton
                title={t("cancel")}
                onClick={() => updateModalShow(false)}
              />

              <span
                className="btn btn-disable btn-sm fw-semibold me-3"
                onClick={handleCancel}
              >
                {t("clear")}
              </span>

              <button
                type="submit"
                className={`btn btn-primary-active btn-sm ${
                  data.password.length < 8 && "disabled-event"
                }`}
                onClick={() => {
                  if (
                    data.password != data.password_confirmation ||
                    data.password.length < 3
                  ) {
                    setError(true);
                  } else {
                    confirmationSwal(handleUpdatePassword);
                  }
                }}
              >
                <span> {t("update")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   updateModalShow(false);
        // }}
      ></div>
    </div>
  );
};

export default UpdatePasswordModal;
