import React from 'react'
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import { useTranslation } from 'react-i18next';

const SmsContentModal = ({closeModal, country, number, message}) => {

    const {t}=useTranslation()

  return (
    <div>
    <div className={`modal-container allocate-user-modal fade-in-down`}>
      
      <div className={`modal-show`}>
        <div className="modal-header p-6">
          <div className="d-flex flex-column gap-4">
            <h3>{t("message_content")}</h3>
            <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
              <div className="d-flex align-items-center text-gray-900 header-badge">
                <span className="svg-icon svg-icon-4 me-1">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {number}
              </div>
              <div className="d-flex align-items-center text-gray-900 header-badge">
                <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                  <img
                    alt="Pic"
                    src={`/assets/media/flags/${country.toLowerCase()}.svg`}
                    className="w-15px h-15px"
                  />
                  <span>

                    {country &&
                      country !== "unknown" &&
                      t(`countries.${country.toUpperCase()}`)}
                  </span>

                </div>
              </div>
             
            </div>
          </div>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
            onClick={() => closeModal()}
          >
            <span className="svg-icon svg-icon-1">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x={6}
                  y="17.3137"
                  width={16}
                  height={2}
                  rx={1}
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y={6}
                  width={16}
                  height={2}
                  rx={1}
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className="modal-body p-6 d-flex flex-column">
          <div class="pb-3"><label class="form-label"><span>{t("message")}</span></label></div>
          <div className='d-flex'>
            
          <span className='p-3 rounded text-gray-900 text-start bg-light-primary'>{message}</span>
          </div>
           </div>

        <div className="d-flex justify-content-end p-6 footer-border-top">
          <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />
       
        </div>
      </div>
    </div>

    <div
      className="modal-hide-div"
      // onClick={() => {
      //   closeModal();
      // }}
    ></div>
  </div>
  )
}

export default SmsContentModal