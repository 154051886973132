export const getNumberScopes = async (number_id) => {
  const token = localStorage.getItem("token");

  const url = `${process.env.REACT_APP_BASE_URL}/interface/calling_scope?number_id=${number_id}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
