import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const EmptyNumbers = () => {
  const { t } = useTranslation();

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px text-center py-20 my-10">
                    <img src="/assets/img/buy.svg" width={400} />
                    <h2 className="fs-2x fw-bold mb-10">
                      {t("empty_numbers_title")}
                    </h2>
                    <p className="text-gray-400 fs-4 fw-semibold mb-10">
                      {t("empty_numbers_desc1")}
                      <br />
                      {t("empty_numbers_desc2")}
                    </p>

                    <Link to="/numbers_buy" className="btn btn-primary-active ">
                      {t("purchase")}
                    </Link>
                  </div>
                  <div className="text-center px-4">
                    <img className="mw-100 mh-300px" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyNumbers;
