const getPreferences = async () => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/resources/preferences?limit=100`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

const login = async (body) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/resources/users/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
      },
      body,
    }
  );
};

const LoginService = {
  getPreferences,
  login,
};
export default LoginService;
