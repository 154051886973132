import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header";
import sendEmail from "../Services/sendEmail";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import { confirmationSwal } from "../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../components/Alerts/successAlert";
import SpinnerSecondary from "../../../components/SpinnerSecondary";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Contact = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [body, setBody] = useState({
    email: localStorage.getItem("email"),
    name: localStorage.getItem("firstname"),
    receiver_addr: process.env.REACT_APP_RECEIVER_EMAIL,
    subject: "",
    message: "",
  });

  const changeBody = (item, value) => {
    setBody((prevData) => ({ ...prevData, [item]: value }));
  };

  useEffect(() => {
    localStorage.setItem("active", 12);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  const handleSendEmail = async () => {
    try {
      setLoading(true);
      const response = await sendEmail(body);
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      successSwall(t("email_sent"));
      changeBody("subject", "");
      changeBody("message", "");
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      {loading&&<SpinnerSecondary/>}
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card mb-12">
                <Header active={5} />
              </div>
              <div className="card">
                <div className="card-body p-lg-17">
                  <div className="row mb-3">
                    <div className="col-md-6 pe-lg-10">
                      <form
                        action=""
                        className="form mb-15"
                        method="post"
                        id="kt_contact_form"
                      >
                        <h1 className="fw-bold text-dark mb-9">
                          {t("send_us_email")}
                        </h1>
                        <div className="row mb-5">
                          <div className="col-md-6 fv-row">
                            <label className="fs-5 fw-semibold mb-2 required">
                              {t("name")}
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder=""
                              name="name"
                              value={body.name}
                              onChange={(e) =>
                                changeBody("name", e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-6 fv-row">
                            <label className="fs-5 fw-semibold mb-2 required">
                              {t("email")}
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="email"
                              value={body.email}
                              onChange={(e) =>
                                changeBody("email", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row">
                          <label className="fs-5 fw-semibold mb-2 required">
                            {t("subject")}
                          </label>
                          <input
                            className="form-control form-control-solid"
                            name="subject"
                            value={body.subject}
                            onChange={(e) =>
                              changeBody("subject", e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex flex-column mb-10 fv-row">
                          <label className="fs-6 fw-semibold mb-2 required">
                            {t("message")}
                          </label>
                          <textarea
                            className="form-control form-control-solid"
                            rows={6}
                            name="message"
                            value={body.message}
                            onChange={(e) =>
                              changeBody("message", e.target.value)
                            }
                          />
                        </div>

                        <span
                          onClick={() => confirmationSwal(handleSendEmail)}
                          className={`btn btn-primary-active ${
                            (!body.subject || !body.message) && "disabled-event"
                          }`}
                          id="kt_contact_submit_button"
                        >
                          <span className={`indicator-label `}>
                            {t("send_feedback")}
                          </span>
                        </span>
                      </form>
                    </div>
                    <div className="col-md-6 ps-lg-10">
                      <div
                        id="kt_contact_map"
                        className="w-100 rounded mb-2 mb-lg-0 mt-2"
                        style={{ height: 486 }}
                      />
                    </div>
                  </div>
                  <div className="row g-5">
                    <div className="col-sm-6 pe-lg-10">
                      <div className="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
                        <span className="svg-icon svg-icon-3tx svg-icon-primary">
                          {" "}
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                                fill="CurrentColor"
                              ></path>{" "}
                            </g>
                          </svg>
                        </span>{" "}
                        <h1 className="text-dark fw-bold my-5">
                          {t("lets_speak")}
                        </h1>
                        <div className="text-gray-700 fw-semibold fs-2">
                          1 (833) 597-7538
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 ps-lg-10">
                      <div className="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
                        <span className="svg-icon svg-icon-3tx svg-icon-primary">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                              fill="currentColor"
                            />
                            <path
                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <h1 className="text-dark fw-bold my-5">
                          Our Head Office
                        </h1>
                        <div className="text-gray-700 fs-3 fw-semibold">
                          Churchill-laan 16 II, 1052 CD, Amsterdam
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="kt_modal_new_ticket"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered mw-750px">
                  <div className="modal-content rounded">
                    <div className="modal-header pb-0 border-0 justify-content-end">
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                      <form
                        id="kt_modal_new_ticket_form"
                        className="form"
                        action="#"
                      >
                        <div className="mb-13 text-center">
                          <h1 className="mb-3">Create Ticket</h1>
                          <div className="text-gray-400 fw-semibold fs-5">
                            If you need more info, please check
                            <a href="" className="fw-bold link-primary">
                              Support Guidelines
                            </a>
                            .
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-8 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Subject</span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Specify a subject for your issue"
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter your ticket subject"
                            name="subject"
                          />
                        </div>
                        <div className="row g-9 mb-8">
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Product
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Select a product"
                              name="product"
                            >
                              <option value="">Select a product...</option>
                              <option value={1}>HTML Theme</option>
                              <option value={1}>Angular App</option>
                              <option value={1}>Vue App</option>
                              <option value={1}>React Theme</option>
                              <option value={1}>Figma UI Kit</option>
                              <option value={3}>Laravel App</option>
                              <option value={4}>Blazor App</option>
                              <option value={5}>Django App</option>
                            </select>
                          </div>
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Assign
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Select a Team Member"
                              name="user"
                            >
                              <option value="">Select a user...</option>
                              <option value={1}>Karina Clark</option>
                              <option value={2}>Robert Doe</option>
                              <option value={3}>Niel Owen</option>
                              <option value={4}>Olivia Wild</option>
                              <option value={5}>Sean Bean</option>
                            </select>
                          </div>
                        </div>
                        <div className="row g-9 mb-8">
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Status
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-placeholder="Open"
                              data-hide-search="true"
                            >
                              <option value="" />
                              <option value={1} selected="selected">
                                Open
                              </option>
                              <option value={2}>Pending</option>
                              <option value={3}>Resolved</option>
                              <option value={3}>Closed</option>
                            </select>
                          </div>
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Due Date
                            </label>
                            <div className="position-relative d-flex align-items-center">
                              <div className="symbol symbol-20px me-4 position-absolute ms-4">
                                <span className="symbol-label bg-secondary">
                                  <span className="svg-icon">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x={2}
                                        y={2}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={13}
                                        y={2}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={13}
                                        y={13}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={2}
                                        y={13}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <input
                                className="form-control form-control-solid ps-12"
                                placeholder="Select a date"
                                name="due_date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-8 fv-row">
                          <label className="fs-6 fw-semibold mb-2">
                            Description
                          </label>
                          <textarea
                            className="form-control form-control-solid"
                            rows={4}
                            name="description"
                            placeholder="Type your ticket description"
                            defaultValue={""}
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-semibold mb-2">
                            Attachments
                          </label>
                          <div
                            className="dropzone"
                            id="kt_modal_create_ticket_attachments"
                          >
                            <div className="dz-message needsclick align-items-center">
                              <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                  Drop files here or click to upload.
                                </h3>
                                <span className="fw-semibold fs-7 text-gray-400">
                                  Upload up to 10 files
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-15 fv-row">
                          <div className="d-flex flex-stack">
                            <div className="fw-semibold me-5">
                              <label className="fs-6">Notifications</label>
                              <div className="fs-7 text-gray-400">
                                Allow Notifications by Phone or Email
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <label className="form-check form-check-custom form-check-solid me-10">
                                <input
                                  className="form-check-input h-20px w-20px"
                                  type="checkbox"
                                  name="notifications[]"
                                  defaultValue="email"
                                  defaultChecked="checked"
                                />
                                <span className="form-check-label fw-semibold">
                                  Email
                                </span>
                              </label>
                              <label className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input h-20px w-20px"
                                  type="checkbox"
                                  name="notifications[]"
                                  defaultValue="phone"
                                />
                                <span className="form-check-label fw-semibold">
                                  Phone
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="reset"
                            id="kt_modal_new_ticket_cancel"
                            className="btn btn-light me-3"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="kt_modal_new_ticket_submit"
                            className="btn btn-primary-active"
                          >
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
