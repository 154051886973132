import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { Link } from "react-router-dom";
import { getUsers } from "../../Services/getUsers";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import { addOutboundCredits } from "../../Services/addOutboundCredits";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";
import DatePickerProfile from "../../../../components/reusables/DatePickerProfile";
import { initialDateFormat, convertCurrency } from "../../../../constants/Utils";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";
import fetchCurrencyExchange from "../../../../services/currencyExchange";



const AddOutboundCallingCreditModal = ({
  closeModal,
  number,
  name,
  number_id,
  country,
  changeData,
  number_credits,
  user_id
}) => {
  const { t } = useTranslation();

  const credit_currencies = ["USD"];

  const [reqBody, setReqBody] = useState({
    resource_id: number_id,
    method: null,
    amount: 0,
    currency: credit_currencies[0],
    paid_amount: null,
    paid_currency: null,
    date: null,
    user: user_id,
    reference: null,
    "files['PAYMENTS_DOCUMENTS']": null,
  });

  const paymentMethods = [
    { id: 2, name: t("cards.tn-post") },
    { id: 3, name: t("cards.credit-card") },
    { id: 5, name: "PayPal" },
    { id: 7, name: t("cards.check") },
    { id: 8, name: t("cards.bank-transfer") },
  ];

  // const fetchUsersData = async () => {
  //   try {
  //     setLoad(true);
  //     const response = await getUsers({ type: 1, status: 1 });
  //     setLoad(false);
  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //     const results = await response.json();
  //     if (results.total > 0) {
  //       let updatedUsers = { display: 1, data: [] };

  //       results.data.forEach((element) => {
  //         updatedUsers.data.push({
  //           id: element.id,
  //           extension: element.extension,

  //           name: element.name,
  //         });
  //       });

  //       setUsers(updatedUsers);
  //     } else {
  //       setUsers({ display: 2, data: [] });
  //     }
  //   } catch (error) {
  //     if (error.message == 401) {
  //       ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
  //     } else if (error.message == 403) {
  //       ErrorSwal(t("errors.403"));
  //     } else if (error.message == 404) {
  //       ErrorSwal(t("errors.404"));
  //     } else if (error.message == 405) {
  //       ErrorSwal(t("errors.405"));
  //     } else if (error.message == 406) {
  //       ErrorSwal(t("errors.406"));
  //     } else if (error.message == 422) {
  //       ErrorSwal(t("errors.422"));
  //     } else if (error.message == 423) {
  //       ErrorSwal(t("errors.423"));
  //     } else {
  //       reFetchUsersData();
  //     }
  //   }
  // };

  const paid_currencies = ["USD", "EUR", "TND"];

  const currencyExchange = () => {
    let rate;

    if (reqBody.method !== 2 && reqBody.method !== 8) {
      if (reqBody.paid_currency === "EUR") {
        rate = exchanges.EUR;
      } else if (reqBody.paid_currency === "TND") {
        rate = rate = exchanges.USD/exchanges.TND;
      } else if (reqBody.paid_currency === "USD") {
        rate = exchanges.USD;
      }
      const converted = parseFloat(reqBody.amount) * rate;
      changeReqBody(
        "paid_amount",
        !reqBody.paid_currency ? 0 : parseFloat(converted.toFixed(3))
      );
    } 
    
    else {
      rate = rate = exchanges.USD/exchanges.TND;
      const converted = parseFloat(reqBody.paid_amount) * rate;
      changeReqBody(
        "amount",
        !reqBody.paid_amount ? 0 : parseFloat(converted.toFixed(3))
      );
    }
  };

  useEffect(() => {
    if (user_country === "TN") {
      reqBody.method &&
        reqBody.method !== 2 && reqBody.method !== 8 &&
        reqBody.paid_currency &&
        currencyExchange();
    } else {
      currencyExchange();
    }
  }, [reqBody.paid_currency, reqBody.amount]);

  useEffect(() => {
    user_country === "TN" && (reqBody.method === 2 || reqBody.method === 8) && currencyExchange();
  }, [reqBody.paid_amount]);

  const [load, setLoad] = useState(false);

  // const reFetchUsersData = async () => {
  //   try {
  //     setLoad(true);
  //     const response = await getUsers();
  //     setLoad(false);

  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //     const results = await response.json();
  //     if (results.total > 0) {
  //       let updatedUsers = { display: 1, data: [] };

  //       results.data.forEach((element) => {
  //         updatedUsers.data.push({
  //           id: element.id,
  //           extension: element.extension,
  //         });
  //       });

  //       setUsers(updatedUsers);
  //     } else {
  //       setUsers({ display: 2, data: [] });
  //     }
  //   } catch (error) {
  //     InternalErrorSwal();
  //   }
  // };

  // useEffect(() => {
  //   fetchUsersData();
  // }, []);

  const credits = [10, 25, 50, 100, 250, 500];

  const [menuOpen, setMenuOpen] = useState({
    credit: false,
    users: false,
    credit_amount: false,
  });

  // const [users, setUsers] = useState({ data: [], display: 0 });

  const changeReqBody = (item, value) => {
    setReqBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const submitForm = async () => {
    try {
      setLoad(true);
      const formData = new FormData();
      formData.append("resource_id", reqBody.resource_id);
      formData.append("method", reqBody.method);
      formData.append("amount", reqBody.amount);
      formData.append("currency", reqBody.currency);
      formData.append("paid_amount", reqBody.paid_amount);
      formData.append("paid_currency", reqBody.paid_currency);
      formData.append("user_id", reqBody.user);
      reqBody.reference && formData.append("references", reqBody.reference);
      reqBody["files['PAYMENTS_DOCUMENTS']"] &&
        formData.append(
          "files['PAYMENTS_DOCUMENTS']",
          reqBody["files['PAYMENTS_DOCUMENTS']"]
        );

      const response = await addOutboundCredits(formData);

      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      changeData(
        "outbound_credits",
        number_id,
        parseFloat(number_credits) + parseFloat(reqBody.amount)
      );

      successSwall(t("payment_added"));
      closeModal();
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const user_country = localStorage.getItem("country");

  const [exchanges, setExchanges] = useState({
    USD: null,
    TND: null,
    EUR: null,
  });
  

  const getExchange = async () => {
    try {
      setLoad(true);
      const response = await fetchCurrencyExchange("USD");
      setLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      setExchanges({
        USD: result.rates.USD,
        TND: result.rates.TND,
        EUR: result.rates.EUR,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getExchange()
  }, [])

  return (
    <div>
      <div className={`modal-container allocate-user-modal fade-in-down`}>
        {load && <SpinnerDiv />}
        <div className={`modal-show ${load && "disabled-event"}`}>
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("add_outbound_calling_credit")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                
                {name && (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="svg-icon svg-icon-6 me-1">
                      <svg
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#b9c4d1"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: " .st0{fill:currentColor;} ",
                            }}
                          />{" "}
                          <g>
                            {" "}
                            <path
                              className="st0"
                              d="M239.732,12.188L0,0l12.176,239.742l42.29,42.29l-9.192-2.301c-9.079,37.239-12.986,70.45-12.986,99.069 c0,33.794,5.399,60.91,14.827,80.798c4.713,9.877,10.572,17.924,17.699,23.906c7.015,5.97,15.746,9.652,24.938,9.652 c8.732,0,17.239-3.333,24.826-8.855c7.475-5.511,14.366-13.322,20.572-23.322c10.338-16.667,16.78-38.384,20.922-63.333 c0.684-4.018,1.256-8.037,1.828-12.178l-20.909-20.92c-0.808,10.236-1.952,20.112-3.568,29.427 c-3.794,23.098-10.001,42.402-17.7,54.816c-5.162,8.158-10.224,13.794-14.703,17.014c-4.602,3.334-8.159,4.254-11.268,4.366 c-3.22-0.113-6.319-1.145-10.224-4.254c-5.746-4.714-12.188-14.59-16.78-29.417c-4.602-14.826-7.475-34.253-7.475-57.7 c0-24.366,2.986-53.21,10.461-85.499l71.257,71.246c1.493-15.399,2.065-31.718,2.065-48.384c0-55.274-7.015-114.12-16.318-153.996 c-10.573,0.224-21.257-3.794-29.304-11.841c-15.635-15.746-15.635-41.144,0-56.891c15.746-15.746,41.144-15.746,56.892,0 c15.735,15.747,15.735,41.145,0,56.891c-1.841,1.728-3.682,3.334-5.746,4.714c3.333,13.558,6.206,28.956,8.731,45.623 c5.286,35.286,8.732,76.083,8.732,115.5c0,24.142-1.269,47.7-4.142,69.305L284.446,512L512,284.446L239.732,12.188z"
                            />{" "}
                            <path
                              className="st0"
                              d="M143.996,152.515c-3.558-14.142-7.352-26.094-11.718-35.523l-20.808,9.776 c3.333,7.126,6.903,18.036,10.236,31.258c0.348,1.38,0.685,2.76,1.033,4.141c7.586-0.123,15.285-2.537,21.841-7.127 C144.456,154.232,144.232,153.311,143.996,152.515z"
                            />{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                    {name}
                  </div>
                )}

                <div className="d-flex align-items-center text-gray-900 header-badge">
                <span className="svg-icon svg-icon-6 me-1">
                    <svg
                      height={24}
                      version="1.1"
                      id="_x32_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <style
                          type="text/css"
                          dangerouslySetInnerHTML={{
                            __html: " .st0{fill:currentColor;} ",
                          }}
                        />{" "}
                        <g>
                          {" "}
                          <path
                            className="st0"
                            d="M255.998,0.002C114.606,0.012,0.01,114.604,0,256c0.01,141.406,114.65,255.328,255.926,255.998h0.334 l0.297-0.009c27.124,0.038,49.507-8.527,64.961-22.594c15.468-14.01,23.727-33.254,23.708-52.736 c0.02-9.148-1.914-18.306-5.521-27.024c6.086-3.464,10.143-6.612,11.301-7.444c4.152-2.957,16-18.766,7.693-31.79 c-8.344-13.014-38.042-42.678-46.152-47.702c-8.086-5.015-21.598-0.124-28.105,9.426c-6.526,9.55-11.674,6.689-11.674,6.689 s-18.516-14.957-44.124-66.621c-25.607-51.694-26.263-75.454-26.263-75.454s0.833-5.847,12.388-5.263 c11.53,0.621,23.598-7.168,24.516-16.66c0.928-9.464-4.698-51.091-10-65.598c-5.316-14.516-25.062-14.65-29.928-13.138 c-4.89,1.502-55.033,13.712-59.014,66.21c-3.966,52.506,9.565,100.18,28.943,139.309c19.387,39.119,49.128,78.765,93.3,107.406 c17.89,11.598,35.058,13.1,49.493,10.67c2.483,5.54,3.718,11.291,3.746,16.985c-0.028,11.292-4.621,22.354-14.066,30.966 c-9.469,8.564-24.071,14.928-45.2,14.967l-0.516,0.009C130.797,481.96,29.387,381.09,29.397,256 c0.01-62.621,25.339-119.186,66.367-160.237c41.053-41.023,97.612-66.354,160.234-66.364c62.621,0.01,119.181,25.34,160.232,66.364 c41.033,41.052,66.354,97.606,66.373,160.237c-0.01,38.67-9.666,74.966-26.698,106.784c-9.531,17.837-21.397,34.23-35.177,48.812 c-5.569,5.905-5.301,15.206,0.594,20.776c5.894,5.578,15.205,5.32,20.784-0.584c15.54-16.46,28.937-34.976,39.712-55.139 C501.071,340.717,512,299.589,512,256C511.98,114.604,397.389,0.012,255.998,0.002z"
                          />{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  {number}
                </div>

                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                    <img
                      alt="Pic"
                      src={`assets/media/flags/${country}.svg`}
                      className="w-15px h-15px"
                    />
                    <span>
                      {country &&
                        country !== "unknown" &&
                        t(`countries.${country.toUpperCase()}`)}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          opacity="0.5"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                          fill="currentColor"
                        ></path>{" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V6.31673C14.3804 6.60867 15.75 7.83361 15.75 9.5C15.75 9.91421 15.4142 10.25 15 10.25C14.5858 10.25 14.25 9.91421 14.25 9.5C14.25 8.82154 13.6859 8.10339 12.75 7.84748V11.3167C14.3804 11.6087 15.75 12.8336 15.75 14.5C15.75 16.1664 14.3804 17.3913 12.75 17.6833V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.6833C9.61957 17.3913 8.25 16.1664 8.25 14.5C8.25 14.0858 8.58579 13.75 9 13.75C9.41421 13.75 9.75 14.0858 9.75 14.5C9.75 15.1785 10.3141 15.8966 11.25 16.1525V12.6833C9.61957 12.3913 8.25 11.1664 8.25 9.5C8.25 7.83361 9.61957 6.60867 11.25 6.31673V6C11.25 5.58579 11.5858 5.25 12 5.25ZM11.25 7.84748C10.3141 8.10339 9.75 8.82154 9.75 9.5C9.75 10.1785 10.3141 10.8966 11.25 11.1525V7.84748ZM14.25 14.5C14.25 13.8215 13.6859 13.1034 12.75 12.8475V16.1525C13.6859 15.8966 14.25 15.1785 14.25 14.5Z"
                          fill="#1C274C"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  {number_credits} {convertCurrency("USD")}
                </div>
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          {user_country === "TN" ? (
            <div className="modal-body p-6">
              <div className="w-100 mb-10">
                <div className="pb-5 pb-lg-3">
                  <label className="form-label mb-2">
                    <span className="required">{t("chose_payment_method")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    {paymentMethods.map((item) => (
                      <div
                        className={`col-lg-3-5 ${
                          (item.id === 5 || item.id === 7) && "disabled-event"
                        }`}
                        key={item.id}
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="allocation_type"
                          defaultValue="users"
                          defaultChecked={reqBody.method === item.id}
                          id={`allocate-number-form-allocation_type-${item.id}`}
                        />
                        <label
                          className="btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center"
                          htmlFor={`allocate-number-form-allocation_type-${item.id}`}
                          onClick={() => {
                            changeReqBody("method", item.id);
                            changeReqBody("paid_amount", "");
                            if (item.id === 2) {
                              changeReqBody("paid_currency", "TND");
                              changeReqBody("paid_amount", 0);
                              // changeReqBody("user", "");
                              changeReqBody("reference", 0);
                              changeReqBody("date", "");
                            } else {
                              changeReqBody("paid_currency", "");
                              changeReqBody("amount", 0);
                              // changeReqBody("user", "");
                              changeReqBody("reference", 0);
                              changeReqBody("date", "");
                              changeReqBody(
                                "files['PAYMENTS_DOCUMENTS']",
                                null
                              );
                            }
                          }}
                        >
                          <span className="d-flex flex-center w-100">
                            <span className="text-dark fw-bold d-block fs-7 mb-0">
                              {item.name}
                            </span>
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {(reqBody.method === 2 || reqBody.method === 8) ? (
                <div>
                  <div
                    className={`line-divs-container mb-10 ${
                      !reqBody.method && "disabled-event"
                    }`}
                  >
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("paid_currency")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`currency-div position-relative`}
                              onClick={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  paid: !prevData.paid,
                                }))
                              }
                              onMouseLeave={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  paid: false,
                                }))
                              }
                            >
                              <span>TND</span>

                              <svg
                                className="svg-menu-currency"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#727272"
                                  />
                                </g>
                              </svg>

                              {menuOpen.paid && (
                                <div>
                                  <span>TND</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("paid_amount")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <input
                              type="text"
                              value={reqBody.paid_amount}
                              className={`form-control form-control-sm form-control-solid ${
                                (reqBody.method !== 2 && reqBody.method !== 8) && "pointer-event-none"
                              }`}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                              }}
                              onChange={(e) =>
                                changeReqBody("paid_amount", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`line-divs-container mb-10 ${
                      (!reqBody.method || !reqBody.paid_amount) &&
                      "disabled-event"
                    }`}
                  >
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("credit_amount")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`currency-div position-relative`}
                              onClick={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit: !prevData.credit,
                                }))
                              }
                              onMouseLeave={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit: false,
                                }))
                              }
                            >
                              <span>{reqBody.currency}</span>
                              <svg
                                className="svg-menu-currency"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#727272"
                                  />
                                </g>
                              </svg>

                              {menuOpen.credit && (
                                <div>
                                  {credit_currencies.map((item, key) => (
                                    <span
                                      key={key}
                                      onClick={() => {
                                        changeReqBody("currency", item);
                                      }}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("credit_amount")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`form-control form-control-sm form-control-solid false pointer-event-none`}
                            >
                              <span>
                                {reqBody.amount !== "NaN" ? reqBody.amount : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={`line-divs-container mb-10 ${
                      !reqBody.method && "disabled-event"
                    }`}
                  >
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("credit_currency")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`currency-div position-relative`}
                              onClick={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit: !prevData.credit,
                                }))
                              }
                              onMouseLeave={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit: false,
                                }))
                              }
                            >
                              <span>{credit_currencies[0]}</span>
                              <svg
                                className="svg-menu-currency"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#727272"
                                  />
                                </g>
                              </svg>

                              {menuOpen.credit && (
                                <div>
                                  {credit_currencies.map((item, key) => (
                                    <span
                                      key={key}
                                      onClick={() => {
                                        changeReqBody("currency", item);
                                      }}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("credit_amount")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`currency-div position-relative`}
                              onClick={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit_amount: !prevData.credit_amount,
                                }))
                              }
                              onMouseLeave={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  credit_amount: false,
                                }))
                              }
                            >
                              <span>
                                {reqBody.method &&
                                  (reqBody.method !== 2 && reqBody.method !== 8) &&
                                  reqBody.amount}
                              </span>
                              <svg
                                className="svg-menu-currency"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#727272"
                                  />
                                </g>
                              </svg>

                              {menuOpen.credit_amount && (
                                <div>
                                  {credits.map((item, key) => (
                                    <span
                                      key={key}
                                      onClick={() => {
                                        changeReqBody("amount", item);
                                      }}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`line-divs-container mb-10 ${
                      (!reqBody.method || !reqBody.amount) && "disabled-event"
                    }`}
                  >
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("paid_currency")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <div
                              className={`currency-div position-relative`}
                              onClick={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  paid: !prevData.paid,
                                }))
                              }
                              onMouseLeave={() =>
                                setMenuOpen((prevData) => ({
                                  ...prevData,
                                  paid: false,
                                }))
                              }
                            >
                              <span>
                                {reqBody.method === 2
                                  ? "TND"
                                  : reqBody.paid_currency}
                              </span>

                              <svg
                                className="svg-menu-currency"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#727272"
                                  />
                                </g>
                              </svg>

                              {menuOpen.paid && (
                                <div>
                                  {reqBody.method === 2
                                    ? paid_currencies
                                        .filter((el) => el === "TND")
                                        .map((item, key) => (
                                          <span key={key}>{item}</span>
                                        ))
                                    : paid_currencies
                                        .filter((el) => el !== "TND")
                                        .map((item, key) => (
                                          <span
                                            key={key}
                                            onClick={() => {
                                              changeReqBody(
                                                "paid_currency",
                                                item
                                              );
                                            }}
                                          >
                                            {item}
                                          </span>
                                        ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">
                          <span className="required">{t("paid_amount")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex gap-2">
                            <input
                              type="text"
                              value={reqBody.paid_amount}
                              className={`form-control form-control-sm form-control-solid ${
                                (reqBody.method !== 2 && reqBody.method !== 8) && "pointer-event-none"
                              }`}
                              onChange={(e) =>
                                changeReqBody("paid_amount", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div
                className={`w-100 mb-7 ${
                  !reqBody.paid_amount && "disabled-event"
                }`}
              >
                <label className="form-label pb-5">{t("user")}</label>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      {users.display === 2 ? (
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 w-100">
                          <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={10}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={14}
                                width={7}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 14)"
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={17}
                                width={2}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 17)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                {t("there_is_no_users_yet")}.
                              </h4>
                              <div className="fs-6 text-gray-700">
                                {t("users_list_is_empty")},{" "}
                                {t("create_user_from_here")}{" "}
                                <Link to="/users_create" className="fw-bold ">
                                  {t("link")}.
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : users.display === 1 ? (
                        <div className={`fv-row h-44px w-100`}>
                          <div
                            className="currency-div position-relative false"
                            onClick={() =>
                              !menuOpen.users &&
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                users: true,
                              }))
                            }
                            onMouseLeave={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                users: false,
                              }))
                            }
                          >
                            <span>
                              {reqBody.user &&
                              users.data.find(
                                (item) => item.id === reqBody.user
                              ).name
                                ? reqBody.user &&
                                  users.data.find(
                                    (item) => item.id === reqBody.user
                                  ).name
                                : reqBody.user &&
                                  users.data.find(
                                    (item) => item.id === reqBody.user
                                  ).extension}
                            </span>{" "}
                            <svg
                              className="svg-menu-currency"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                  fill="#727272"
                                />
                              </g>
                            </svg>
                            <div
                              className={`menu-flag-drop-down left-0 top-35 ${
                                !menuOpen.users && "display-none"
                              }`}
                            >
                              {users.data.map((item) => (
                                <div
                                  onClick={() => {
                                    changeReqBody("user", item.id);
                                    setMenuOpen((prevData) => ({
                                      ...prevData,
                                      users: false,
                                    }));
                                  }}
                                  className={`${
                                    item.id === reqBody.user &&
                                    "number-selected"
                                  }`}
                                >
                                  <div>
                                    {item.name ? item.name : item.extension}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              <div className={!reqBody.paid_amount && "display-none"}>
                {reqBody.method === 2 ? (
                  <div className={`line-divs-container`}>
                    <div className="w-50">
              <div className="pb-5 pb-lg-3">
                <label className="form-label mb-2">
                  <span className="required">{t("date")}</span>
                </label>
              </div>
              <div>
                <div className="position-relative">
                  <svg
                    className="end-date-svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                        stroke="#5e6278"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>{" "}
                      <rect
                        x="6"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="10.5"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="15"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                    </g>
                  </svg>

                  <input
                    type="text"
                    className={`form-control form-control-sm form-control-solid`}
                    name="passport_number_validity"
                    value={reqBody.date}
                  />
                  <DatePickerProfile
                    onChange={(e) => {
                      changeReqBody("date", initialDateFormat(new Date(e)));
                    }}
                    date={reqBody.date}
                    
                  />
                </div>
              </div>
            </div>
            <div className={`w-50 ${!reqBody.date&&"disabled-event"}`}>
                      <div className="pb-5 pb-lg-3">
                        <label className="form-label mb-2">

                          <span className="required">{t("reference")}</span>
                          
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="d-flex">
                            <input
                              value={reqBody.reference ? reqBody.reference : ""}
                              type="text"
                              className={`form-control form-control-sm form-control-solid`}
                              onChange={(e) =>
                                changeReqBody("reference", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className={`w-50 ${!reqBody.date&&"disabled-event"}`}>
                      <div className={`pb-5 pb-lg-3`}>
                        <label className="form-label mb-2">
                          <span>{t("receipt_doc")}</span>
                        </label>
                      </div>
                      <div className="fv-row">
                        <div className="row">
                          <div className="">
                            <div
                              className={`upload-receipt m-w-50 cursor-pointer position-relative`}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                                    fill="#507ab4"
                                  ></path>{" "}
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 10.4697C12.3897 10.329 12.1989 10.25 12 10.25C11.8011 10.25 11.6103 10.329 11.4697 10.4697L8.96967 12.9697C8.67678 13.2626 8.67678 13.7374 8.96967 14.0303C9.26256 14.3232 9.73744 14.3232 10.0303 14.0303L11.25 12.8107V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.8107L13.9697 14.0303C14.2626 14.3232 14.7374 14.3232 15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697L12.5303 10.4697Z"
                                    fill="currentColor"
                                  ></path>{" "}
                                </g>
                              </svg>
                              <span>{t("upload_file")}</span>

                              <input
                                type="file"
                                name="avatar"
                                accept=".png, .jpg, .jpeg"
                                className=" opacity-0 position-absolute w-100 cursor-pointer"
                                onChange={(e) => {
                                  changeReqBody(
                                    "files['PAYMENTS_DOCUMENTS']",
                                    e.target.files[0]
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="line-divs-container">
                    <div className="w-50">
              <div className="pb-5 pb-lg-3">
                <label className="form-label mb-2">
                  <span className="required">{t("date")}</span>
                </label>
              </div>
              <div>
                <div className="position-relative">
                  <svg
                    className="end-date-svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                        stroke="#5e6278"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>{" "}
                      <rect
                        x="6"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="10.5"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="15"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                    </g>
                  </svg>

                  <input
                    type="text"
                    className={`form-control form-control-sm form-control-solid`}
                    name="passport_number_validity"
                    value={reqBody.date}
                  />
                  <DatePickerProfile
                    onChange={(e) => {
                      changeReqBody("date", initialDateFormat(new Date(e)));
                      
                    }}
                    date={reqBody.date}
                    
                  />
                </div>
              </div>
            </div>
                  <div className={`w-50 ${!reqBody.date&&"disabled-event"}`}>
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("reference")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex">
                          <input
                            value={reqBody.reference ? reqBody.reference : ""}
                            type="text"
                            className={`form-control form-control-sm form-control-solid`}
                            onChange={(e) =>
                              changeReqBody("reference", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="modal-body p-6">
              <div className="line-divs-container mb-10">
                <div className="w-50">
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">{t("credit_currency")}</span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      <div className="d-flex gap-2">
                        <div
                          className={`currency-div position-relative`}
                          onClick={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              credit: !prevData.credit,
                            }))
                          }
                          onMouseLeave={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              credit: false,
                            }))
                          }
                        >
                          <span>{credit_currencies[0]}</span>
                          <svg
                            className="svg-menu-currency"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                fill="#727272"
                              />
                            </g>
                          </svg>

                          {menuOpen.credit && (
                            <div>
                              {credit_currencies.map((item, key) => (
                                <span
                                  key={key}
                                  onClick={() => {
                                    changeReqBody("currency", item);
                                  }}
                                >
                                  {item}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-50">
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">{t("credit_amount")}</span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      <div className="d-flex gap-2">
                        <div
                          className={`currency-div position-relative`}
                          onClick={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              credit_amount: !prevData.credit_amount,
                            }))
                          }
                          onMouseLeave={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              credit_amount: false,
                            }))
                          }
                        >
                          <span>{reqBody.amount}</span>
                          <svg
                            className="svg-menu-currency"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                fill="#727272"
                              />
                            </g>
                          </svg>

                          {menuOpen.credit_amount && (
                            <div>
                              {credits.map((item, key) => (
                                <span
                                  key={key}
                                  onClick={() => {
                                    changeReqBody("amount", item);
                                  }}
                                >
                                  {item}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`line-divs-container mb-10 ${
                  reqBody.amount === 0 && "disabled-event"
                }`}
              >
                <div className="w-50">
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">{t("paid_currency")}</span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      <div className="d-flex gap-2">
                        <div
                          className={`currency-div position-relative`}
                          onClick={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              paid: !prevData.paid,
                            }))
                          }
                          onMouseLeave={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              paid: false,
                            }))
                          }
                        >
                          <span>{reqBody.paid_currency}</span>
                          <svg
                            className="svg-menu-currency"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                fill="#727272"
                              />
                            </g>
                          </svg>

                          {menuOpen.paid && (
                            <div>
                              {paid_currencies
                                .filter((el) => el !== "TND")
                                .map((item, key) => (
                                  <span
                                    key={key}
                                    onClick={() => {
                                      changeReqBody("paid_currency", item);
                                      reqBody.method &&
                                        changeReqBody("method", null);
                                    }}
                                  >
                                    {item}
                                  </span>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-50">
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">{t("paid_amount")}</span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      <div className="d-flex gap-2">
                        <input
                          type="text"
                          value={
                            reqBody.paid_currency ? reqBody.paid_amount : ""
                          }
                          className={`form-control form-control-sm form-control-solid pointer-event-none`}
                          onChange={(e) =>
                            changeReqBody("amount", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className={`w-100 mb-7 ${
                  reqBody.paid_amount === 0 && "disabled-event"
                }`}
              >
                <label className="form-label pb-5">{t("user")}</label>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      {users.display === 2 ? (
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 w-100">
                          <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={10}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={14}
                                width={7}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 14)"
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={17}
                                width={2}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 17)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                {t("there_is_no_users_yet")}.
                              </h4>
                              <div className="fs-6 text-gray-700">
                                {t("users_list_is_empty")},{" "}
                                {t("create_user_from_here")}{" "}
                                <Link to="/users_create" className="fw-bold ">
                                  {t("link")}.
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : users.display === 1 ? (
                        <div
                          className={`fv-row h-44px w-100 ${
                            !reqBody.paid_currency && "disabled-event"
                          }`}
                        >
                          <div
                            className="currency-div position-relative false"
                            onClick={() =>
                              !menuOpen.users &&
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                users: true,
                              }))
                            }
                            onMouseLeave={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                users: false,
                              }))
                            }
                          >
                            <span>
                              {reqBody.user &&
                              users.data.find(
                                (item) => item.id === reqBody.user
                              ).name
                                ? reqBody.user &&
                                  users.data.find(
                                    (item) => item.id === reqBody.user
                                  ).name
                                : reqBody.user &&
                                  users.data.find(
                                    (item) => item.id === reqBody.user
                                  ).extension}
                            </span>{" "}
                            <svg
                              className="svg-menu-currency"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                  fill="#727272"
                                />
                              </g>
                            </svg>
                            <div
                              className={`menu-flag-drop-down left-0 top-35 ${
                                !menuOpen.users && "display-none"
                              }`}
                            >
                              {users.data.map((item) => (
                                <div
                                  onClick={() => {
                                    changeReqBody("user", item.id);
                                    setMenuOpen((prevData) => ({
                                      ...prevData,
                                      users: false,
                                    }));
                                  }}
                                  className={`${
                                    item.id === reqBody.user &&
                                    "number-selected"
                                  }`}
                                >
                                  <div>
                                    {item.name ? item.name : item.extension}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                className={`w-100 mb-10 ${
                  !reqBody.paid_amount && "disabled-event"
                }`}
              >
                <div className="pb-5 pb-lg-3">
                  <label className="form-label mb-2">
                    <span className="required">{t("chose_payment_method")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    {paymentMethods
                      .filter((el) => el.id !== 2)
                      .map((item) => (
                        <div
                          className={`col-lg-3 ${
                            item.id === 5 && "disabled-event"
                          }`}
                          key={item.id}
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="allocation_type"
                            defaultValue="users"
                            defaultChecked={reqBody.method === item.id}
                            id={`allocate-number-form-allocation_type-${item.id}`}
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center"
                            htmlFor={`allocate-number-form-allocation_type-${item.id}`}
                            onClick={() => changeReqBody("method", item.id)}
                          >
                            <span className="d-flex flex-center w-100">
                              <span className="text-dark fw-bold d-block fs-7 mb-0">
                                {item.name}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div
                className={`line-divs-container ${
                  !reqBody.method && "display-none"
                }`}
              >
                
                <div className="w-50">
              <div className="pb-5 pb-lg-3">
                <label className="form-label mb-2">
                  <span className="required">{t("date")}</span>
                </label>
              </div>
              <div>
                <div className="position-relative">
                  <svg
                    className="end-date-svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                        stroke="#5e6278"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>{" "}
                      <rect
                        x="6"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="10.5"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="15"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                    </g>
                  </svg>

                  <input
                    type="text"
                    className={`form-control form-control-sm form-control-solid`}
                    name="passport_number_validity"
                    value={reqBody.date}
                  />
                  <DatePickerProfile
                    onChange={(e) => {
                      changeReqBody("date", initialDateFormat(new Date(e)));
                    }}
                    date={reqBody.date}
                    
                  />
                </div>
              </div>
            </div>   
                  <div className="w-50">
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("reference")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex">
                          <input
                            value={reqBody.reference ? reqBody.reference : ""}
                            type="text"
                            className={`form-control form-control-sm form-control-solid`}
                            onChange={(e) =>
                              changeReqBody("reference", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
          )}

          <div className={`d-flex justify-content-end p-6 footer-border-top`}>
            <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />
            <button
              type="submit"
              className={`btn btn-primary-active btn-sm ${
                !reqBody.reference &&
                !reqBody["files['PAYMENTS_DOCUMENTS']"] &&
                "disabled-event"
              }`}
              onClick={() => confirmationSwal(submitForm)}
            >
              <span> {t("save")}</span>
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default AddOutboundCallingCreditModal;
