// Components
import CallsGeographyChart from "./Components/CallsGeographyChart";
import CostsChart from "./Components/CostsCahrt";
import PerformanceChart from "./Components/PerformanceChart";
import Spinner from "../../components/Spinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchData from "./Services/Dashboard";
import errorSwal from "../../components/Alerts/errorAlert";
import errorExpiredSession from "../../components/Alerts/errorExpiredSession";
import reconnectionAlert from "../../components/Alerts/reconnectionAlert";
import countryData from "../../Data/Countries.json";
import DatePicker from "../../components/reusables/DatePicker";
import { formatDate, initialDateFormat } from "../../constants/Utils";
import SpinnerDiv from "../../Layouts/SpinnerDiv";
import InternalErrorSwal from "../../components/Alerts/internalErrorAlert";

const Dashboard = () => {
  const { t } = useTranslation();
  function getDateOfYesterday() {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  var yesterdayDate = getDateOfYesterday();

  useEffect(() => {
    localStorage.setItem("active", 1);

    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const [load, setLoad] = useState(false);

  const [dataLoad, setDataLoad] = useState(false);

  useEffect(() => {
    Promise.all([import("./Dashboard.css")]).then(() => setLoad(true));
  }, []);

  const [activeBtn, setActiveBtn] = useState(1);

  const [geoChart, setGeoChart] = useState();

  const [calls, setCalls] = useState({
    inbound: { total: 0, average: 0, duration: 0 },
    outbound: { total: 0, average: 0, duration: 0 },
  });

  const mapCountryCodeToName = (code) => {
    const country = countryData.find((country) => country.code === code);
    return country ? country.name : code;
  };

  const [reqBody, setReqBody] = useState({
    period: "today",
    dates: {
      start: "",
      end: "",
    },
  });

  const [performanceData, setPerformanceData] = useState();

  const fetchChartData = async () => {
    try {
      setDataLoad(false);
      const response = await fetchData(JSON.stringify(reqBody));
      if (!response.ok) {
        throw new Error(response.status);
      }
      setDataLoad(true);
      const fetchedData = await response.json();
      setCalls(fetchedData.data.indicators.calls);

      const transformedData = fetchedData.data.charts.geography.map(
        ([code, value]) => [mapCountryCodeToName(code), value]
      );
      const performanceAxis = fetchedData.data.charts.activity.axis.map(
        (date) => date.slice(-2)
      );
      const performanceChartData = fetchedData.data.charts.activity.data;

      const transformedPerformanceData = performanceAxis.map((date, index) => [
        date,
        performanceChartData[index],
      ]);

      setPerformanceData([["date", t("calls")], ...transformedPerformanceData]);
      setGeoChart([["Country", t("calls")], ...transformedData]);
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else {
        reconnectionAlert();
        fetchChartDataSecondTry();
      }
    }
  };

  const fetchChartDataSecondTry = async () => {
    try {
      setDataLoad(false);
      const response = await fetchData();
      if (!response.ok) {
        throw new Error(response.status);
      }
      setDataLoad(true);
      const fetchedData = await response.json();
      setCalls(fetchedData.data.indicators.calls);

      const transformedData = fetchedData.data.charts.geography.map(
        ([code, value]) => [mapCountryCodeToName(code), value]
      );
      const performanceAxis = fetchedData.data.charts.activity.axis.map(
        (date) => date.slice(-2)
      );
      const performanceChartData = fetchedData.data.charts.activity.data;

      const transformedPerformanceData = performanceAxis.map((date, index) => [
        date,
        performanceChartData[index],
      ]);

      setPerformanceData([["date", t("calls")], ...transformedPerformanceData]);
      setGeoChart([["Country", t("calls")], ...transformedData]);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [reqBody.period, reqBody.dates.start, reqBody.dates.end]);

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedHours =
      hours > 0 ? (hours < 10 ? `0${hours}` : `${hours}`) : "00";
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleDate = (active, date) => {
    setActiveBtn(active);
    if (date == "yesterday") {
      setReqBody((prevData) => ({
        ...prevData,
        period: "custom",
        dates: {
          start: initialDateFormat(yesterdayDate),
          end: initialDateFormat(yesterdayDate),
        },
      }));
    } else {
      setReqBody((prevData) => ({ ...prevData, period: date }));
    }
  };

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  className="app-toolbar align-items-center justify-content-between py-2 py-lg-4 mb-4"
                >
                  <div
                    className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n10"
                    id="kt_toolbar"
                  >
                    <div className="d-flex flex-column align-items-start me-3 gap-2 opacity_one">
                      <h1 className="d-flex text-dark fw-bold m-0 fs-3">
                        {t("dashboard")}
                      </h1>
                      {/* <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7">
                        <li className="breadcrumb-item text-gray-600">
                          <a
                            href="#"
                            className="text-gray-600 text-hover-primary"
                          >
                            {t("home")}
                          </a>
                        </li>
                        <li className="breadcrumb-item text-gray-600">
                          {t("dashboard")}
                        </li>
                      </ul> */}
                    </div>
                    <div className="d-flex align-items-center py-2">
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 1
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(1, "today")}
                      >
                        {t("today")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 2
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(2, "yesterday")}
                      >
                        {t("yesterday")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 3
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(3, "current_month")}
                      >
                        {t("current_month")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex ${
                          activeBtn === 4
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold me-3`}
                        onClick={() => handleDate(4, "previous_month")}
                      >
                        {t("previous_month")}
                      </span>
                      <span
                        className={`btn btn-sm btn-flex position-relative ${
                          activeBtn === 5
                            ? "btn-primary-active"
                            : "btn-light-primary period"
                        } fw-bold`}
                      >
                        {t("custom")}{" "}
                        {activeBtn === 5 ? (
                          <span>
                            {" "}
                            &#160; | &#160; {reqBody.dates.start} -{" "}
                            {reqBody.dates.end}
                          </span>
                        ) : (
                          ""
                        )}
                        <DatePicker
                          onChange={(e) => {
                            if (e.length > 1) {
                              setActiveBtn(5);
                              setReqBody((prevData) => ({
                                ...prevData,
                                period: "custom",
                                dates: {
                                  start: formatDate(e[0]),
                                  end: formatDate(e[1]),
                                },
                              }));
                              setDataLoad(false);
                            }
                          }}
                          date={[]}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                    <div className="col-xl-3 position-relative">
                      {!dataLoad && <SpinnerDiv />}
                      <div
                        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100 ${
                          !dataLoad ? "opacity-low" : ""
                        } `}
                        style={{
                          backgroundColor: "#F1416C",
                          backgroundImage:
                            'url("assets/media/svg/shapes/wave-bg-red.svg")',
                        }}
                      >
                        <div className="card-header pt-5 mb-3">
                          <div
                            className="d-flex flex-center rounded-circle h-80px w-80px"
                            style={{
                              border: "1px dashed rgba(255, 255, 255, 0.4)",
                              backgroundColor: "#F1416C",
                            }}
                          >
                            <svg
                              className="svg_style"
                              fill="#ffffff"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path d="M15.793 6.793 13 4v7h7l-2.793-2.793 4.5-4.5-1.414-1.414z" />
                                <path d="M16.422 13.443a1.001 1.001 0 0 0-1.391.043l-2.392 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.86 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1.001 1.001 0 0 0-.291.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.324 21 17.705 21c.203 0 .326-.006.359-.008a.99.99 0 0 0 .648-.291l1.861-2.171a1.001 1.001 0 0 0-.086-1.391l-4.065-3.696z" />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="card-body d-flex align-items-end mb-3">
                          <div className="d-flex align-items-center">
                            <span className="fs-4hx text-white fw-bold me-6">
                              {calls.inbound.total}
                            </span>
                            <div className="fw-bold fs-6 text-white">
                              <span className="d-block"> {t("inbound")}</span>
                              <span className=""> {t("calls")}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card-footer display-flex-space"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                            background: "rgba(0, 0, 0, 0.15)",
                          }}
                        >
                          <div className="fw-bold text-white py-2">
                            <span className="fs-1 d-block">
                              {formatDuration(calls.inbound.duration)}
                            </span>
                            <span className="opacity-50">
                              {" "}
                              {t("total_duration")}
                            </span>
                          </div>
                          <div className="fw-bold text-white py-2">
                            <span className="fs-1 d-block">
                              {formatDuration(calls.inbound.average)}
                            </span>
                            <span className="opacity-50">
                              {" "}
                              {t("average_duration")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 position-relative">
                      {!dataLoad && <SpinnerDiv />}

                      <div
                        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100 ${
                          !dataLoad ? "opacity-low" : ""
                        } `}
                        style={{
                          backgroundColor: "#7239EA",
                          backgroundImage:
                            "url(assets/media/svg/shapes/wave-bg-purple.svg)",
                        }}
                      >
                        <div className="card-header pt-5 mb-3">
                          <div
                            className="d-flex flex-center rounded-circle h-80px w-80px"
                            style={{
                              border: "1px dashed rgba(255, 255, 255, 0.4)",
                              backgroundColor: "#7239EA",
                            }}
                          >
                            <svg
                              className="svg_style"
                              fill="#ffffff"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path d="m16.793 5.793-4.5 4.5 1.414 1.414 4.5-4.5L21 10V3h-7z" />
                                <path d="M16.422 13.446a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.516a1 1 0 0 0-1.391-.087L3.299 5.29a.996.996 0 0 0-.291.648c-.015.25-.301 6.172 4.291 10.766 4.006 4.006 9.024 4.299 10.406 4.299.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a1 1 0 0 0-.086-1.391l-4.064-3.696z" />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="card-body d-flex align-items-end mb-3">
                          <div className="d-flex align-items-center">
                            <span className="fs-4hx text-white fw-bold me-6">
                              {calls.outbound.total}
                            </span>
                            <div className="fw-bold fs-6 text-white">
                              <span className="d-block"> {t("outbound")}</span>
                              <span className=""> {t("calls")}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card-footer display-flex-space"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                            background: "rgba(0, 0, 0, 0.15)",
                          }}
                        >
                          <div className="fw-bold text-white py-2">
                            <span className="fs-1 d-block">
                              {formatDuration(calls.outbound.duration)}
                            </span>
                            <span className="opacity-50">
                              {" "}
                              {t("total_duration")}
                            </span>
                          </div>
                          <div className="fw-bold text-white py-2">
                            <span className="fs-1 d-block">
                              {formatDuration(calls.outbound.average)}
                            </span>
                            <span className="opacity-50">
                              {" "}
                              {t("average_duration")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="card card-flush overflow-hidden h-lg-100">
                        <div className="card-header pt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                              {t("performance")}
                            </span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                          </h3>
                        </div>
                        <PerformanceChart
                          data={performanceData}
                          load={dataLoad}
                        />
                        <div className="soon_center">
                          {/* <img
                            src="/assets/img/chart1.png"
                            alt=""
                            className="dash_css blur-div"
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-5 g-xl-10 mb-xl-10">
                    <CostsChart loading = {dataLoad} />
                    <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0 ">
                      <div className="card card-flush h-lg-100 over-hidden">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                              {t("calls_geography")}
                            </span>
                            <span className="text-gray-400 pt-2 fw-semibold fs-6"></span>
                          </h3>
                        </div>
                        <CallsGeographyChart data={geoChart} load={dataLoad} />
                      </div>
                    </div>
                  </div>

                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-4 mb-xl-10">
                      <div
                        className="card h-md-100"
                        style={{
                          background:
                            "linear-gradient(112.14deg, #FF8A00 0%, #E96922 100%)",
                        }}
                      >
                        <div className="card-body d-flex flex-column align-items-center align-items-center gap-3">
                       
                            <h1 className="fw-semibold text-gray-800 text-center lh-lg">
                              {t("are_you_ready_for_the_new_app")}?
                              <br />
                              <span className="fw-bolder">Myxtel webphone</span>
                            </h1>
                            <img src="/assets/media/illustrations/unitedpalms-1/dashboard.svg" height={200} alt="logo"/>
                            <div className="pt-10 text-center text-gray-800 fw-bold">
                              {t("app_bloc_desc")}.
                            </div>
                       
                          <div className="text-center mb-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
