import React, { useEffect, useState } from "react";
import HeaderSettings from "../Layouts/HeaderSettings";
import { useTranslation } from "react-i18next";
import UpdatePasswordModal from "../../../components/Modals/UpdatePasswordModal";
import fetchAccountSecurityData from "../Services/security.service";
import Spinner from "../../../components/Spinner";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import errorSwal from "../../../components/Alerts/errorAlert";
import PerformanceChart from "../../Dashboard/Components/PerformanceChart";
import DatePicker from "../../../components/reusables/DatePicker";
import { defaultDateFormat, formatDate } from "../../../constants/Utils";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import EnableTwoFactAuthModal from "../../../components/Modals/EnableTwoFactAuthModal";
import fetchAlerts from "../Services/fetchAlerts.service";
import DisableTwoAuthModal from "../../../components/Modals/DisableTwoAuthModal";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Security = ({ userType }) => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const [modalShow, setModalShow] = useState(false);

  const [warningModalShow, setWarningModalShow] = useState(false);

  const [twoFactModal, setTwoFactModal] = useState(false);

  const [reqBody, setReqBody] = useState({
    period: "today",
    dates: {
      start: "",
      end: "",
    },
  });

  const modalHandelShow = () => {
    document.body.classList.remove("modal-open");
    setModalShow(false);
  };



  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const [load, setLoad] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [data, setData] = useState({
    connection_total: 0,
    connection_admin: 0,
    connection_user: 0,
    connection_failed: 0,
  });

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const fetchData = async () => {
    try {
      setDataLoad(false);

      const response = await fetchAccountSecurityData(JSON.stringify(reqBody));
      setLoad(true);
      setDataLoad(true);
      if (!response.ok) {
        if (response.status === 401) {
          errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
        } else if (response.status == 403) {
          errorSwal(t("errors.403"));
        } else if (response.status === 404) {
          errorSwal(t("errors.404"));
        } else if (response.status === 405) {
          errorSwal(t("errors.405"));
        } else if (response.status === 406) {
          errorSwal(t("errors.406"));
        } else if (response.status === 422) {
          errorSwal(t("errors.422"));
        } else if (response.status === 423) {
          errorSwal(t("errors.423"));
        } else {
          // reconnectionSwal();
          secondFetchTry();
        }
      } else {
        const fetchedData = await response.json();
        setData(fetchedData.data);
        const performanceAxis = fetchedData.data.Activity.axis.map((date) =>
          date.slice(-2)
        );
        const performanceChartData = fetchedData.data.Activity.data;

        const transformedPerformanceData = performanceAxis.map(
          (date, index) => [date, performanceChartData[index]]
        );
        setChartData([
          ["date", t("session_logs")],
          ...transformedPerformanceData,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const secondFetchTry = async () => {
    try {
      setDataLoad(false);

      const response = await fetchAccountSecurityData(JSON.stringify(reqBody));
      if (!response.ok) {
        InternalErrorSwal();
      } else {
        const fetchedData = await response.json();
        setData(fetchedData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reqBody.period, reqBody.dates.start, reqBody.dates.end]);

  useEffect(() => {
    fetchAllAlerts();
  }, []);

  const [activeBtn, setActiveBtn] = useState(1);

  const handleDate = (active, date) => {
    setActiveBtn(active);
    setReqBody((prevData) => ({ ...prevData, period: date }));
  };

  const [alerts, setAlerts] = useState([]);

  const fetchAllAlerts = async () => {
    try {
      const response = await fetchAlerts(JSON.stringify(reqBody));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      if (fetchedData.total > 0) {
        setAlerts(fetchedData.data);
      }
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        // reconnectionSwal();
        fetchAllAlertsSecondTry();
      }
    }
  };

  const fetchAllAlertsSecondTry = async () => {
    try {
      const response = await fetchAlerts(JSON.stringify(reqBody));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = response.json();
      if (fetchedData.total > 0) {
        setAlerts(fetchedData.data);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      {twoFactModal && (
        <EnableTwoFactAuthModal
          updateModalShow={() => {
            setTwoFactModal(false);
            document.body.classList.remove("modal-open");
          }}
        />
      )}
      {!load && <Spinner />}
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <HeaderSettings active={2} type={userType} />
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card mb-5 mb-xl-10">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_signin_method"
                >
                  <div className="card-title m-0 title-flex">
                    <h3 className="fw-bold m-0">{t("si_method")}</h3>
                  </div>
                </div>
                <div
                  id="kt_account_settings_signin_method"
                  className="collapse show"
                >
                  <div className="card-body border-top p-9">
                    <div className="d-flex flex-wrap align-items-center mb-10">
                      <div id="kt_signin_password">
                        <div className="fs-6 fw-bold mb-1">
                          {t("change_password")}
                        </div>
                        <div className="fw-semibold text-gray-600">
                          ************
                        </div>
                      </div>
                      <div
                        id="kt_signin_password_edit"
                        className="flex-row-fluid d-none"
                      >
                        <form
                          id="kt_signin_change_password"
                          className="form"
                          noValidate="novalidate"
                        >
                          <div className="row mb-1">
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="currentpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  {t("current_password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="currentpassword"
                                  id="currentpassword"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="newpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  {t("new_password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="newpassword"
                                  id="newpassword"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="confirmpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  {t("confirm_new_password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="confirmpassword"
                                  id="confirmpassword"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-text mb-5"></div>
                          <div className="d-flex">
                            <button
                              id="kt_password_submit"
                              type="button"
                              className="btn btn-primary btn-sm me-2 px-6"
                            >
                              {t("update_password")}
                            </button>
                            <button
                              id="kt_password_cancel"
                              type="button"
                              className="btn btn-color-gray-400 btn-sm btn-active-light-primary px-6"
                            >
                              {t("cancel")}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div id="kt_signin_password_button" className="ms-auto">
                        <SecondaryButton
                          title={t("reset_password")}
                          onClick={() => {
                            document.body.classList.add("modal-open");
                            setModalShow(true);
                          }}
                          marg_none={true}
                        />
                      </div>
                    </div>
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 ">
                      <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                        <div className="mb-3 mb-md-0 fw-semibold">
                          <h4 className="text-gray-900 fw-bold">
                            {t("secure_account")}
                          </h4>
                          <div className="fs-6 text-gray-700 pe-7">
                            {localStorage.getItem("active_2fa") == "false"
                              ? t("two_factor_auth")
                              : t("disable_two_fact_auth")}
                            .
                          </div>
                        </div>
                        {localStorage.getItem("active_2fa") == "true" ? (
                          <span
                            className="btn btn-sm px-6 align-self-center text-nowrap btn-primary-active"
                            onClick={() => {
                              setWarningModalShow(true);
                              document.body.classList.add("modal-open");
                            }}
                          >
                            {t("disable")}
                          </span>
                        ) : (
                          <span
                            className="btn btn-sm px-6 align-self-center text-nowrap btn-primary-active"
                            onClick={() => {
                              document.body.classList.add("modal-open");
                              setTwoFactModal(true);
                            }}
                          >
                            {t("enable")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-xxl-9">
                <div className="col-xxl-8">
                  <div className="card card-xxl-stretch mb-5 mb-xl-10">
                    <div className="card-header card-header-stretch">
                      <div className="card-title">
                        <h3 className="m-0 text-gray-900">
                          {t("security_summary")}
                        </h3>
                      </div>
                      <div className="card-toolbar">
                        {" "}
                        <div
                          className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n10 margin-btm-10"
                          id="kt_toolbar"
                        >
                          <div></div>
                          <div className="d-flex align-items-center py-2">
                            <span
                              className={`btn btn-sm btn-flex ${
                                activeBtn === 1
                                  ? "btn-primary-active"
                                  : "btn-light-primary period"
                              } fw-bold me-3`}
                              onClick={() => handleDate(1, "today")}
                            >
                              {t("today")}
                            </span>
                            {/* <span
                    
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 2
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(2, "yesterday")}
                  >
                    {t("yesterday")}
                  </span> */}
                            <span
                              className={`btn btn-sm btn-flex ${
                                activeBtn === 3
                                  ? "btn-primary-active"
                                  : "btn-light-primary period"
                              } fw-bold me-3`}
                              onClick={() => handleDate(3, "current_month")}
                            >
                              {t("current_month")}
                            </span>
                            {/* <span
                   
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 4
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(4, "previous_month")}
                  >
                    {t("previous_month")}
                  </span> */}
                            <span
                              className={`btn btn-sm btn-flex position-relative ${
                                activeBtn === 5
                                  ? "btn-primary-active"
                                  : "btn-light-primary period"
                              } fw-bold me-0`}
                            >
                              {t("custom")}{" "}
                              {activeBtn === 5 ? (
                                <span>
                                  {" "}
                                  &#160; | &#160; {reqBody.dates.start} -{" "}
                                  {reqBody.dates.end}
                                </span>
                              ) : (
                                ""
                              )}
                              <DatePicker
                                onChange={(e) => {
                                  if (e.length > 1) {
                                    setActiveBtn(5);
                                    setReqBody((prevData) => ({
                                      ...prevData,
                                      period: "custom",
                                      dates: {
                                        start: formatDate(e[0]),
                                        end: formatDate(e[1]),
                                      },
                                    }));
                                    setDataLoad(false);
                                  }
                                }}
                                date={[]}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-body pt-7 pb-0 px-0 ${
                        !dataLoad ? "opacity-low" : ""
                      }`}
                    >
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="kt_security_summary_tab_pane_hours"
                          role="tabpanel"
                        >
                          <div className="row p-0 mb-5 px-9">
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-4 fw-semibold text-primary d-block">
                                  {t("admin_si")}
                                </span>
                                <span
                                  className="fs-2hx fw-bold text-gray-900"
                                  data-kt-countup="true"
                                  data-kt-countup-value="0"
                                >
                                  {data.connection_admin}
                                </span>
                              </div>
                            </div>
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-4 fw-semibold text-success d-block">
                                  {t("user_si")}
                                </span>
                                <span
                                  className="fs-2hx fw-bold text-gray-900"
                                  data-kt-countup="true"
                                  data-kt-countup-value="0"
                                >
                                  {data.connection_user}
                                </span>
                              </div>
                            </div>

                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-4 fw-semibold text-danger d-block">
                                  {t("failed_attempts")}
                                </span>
                                <span
                                  className="fs-2hx fw-bold text-gray-900"
                                  data-kt-countup="true"
                                  data-kt-countup-value="0"
                                >
                                  {data.connection_failed}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="d-flex align-items-center pb-6 px-9">
                              <h3 className="m-0 text-gray-900 flex-grow-1">
                                {t("activity_chart")}
                              </h3>
                            </div>
                            <div className="tab-content px-3">
                              <div
                                className="tab-pane fade active show"
                                id="kt_security_summary_tab_pane_hours_agents"
                                role="tabpanel"
                              >
                                <PerformanceChart
                                  data={chartData}
                                  load={load}
                                />
                              </div>
                              <div
                                className="tab-pane fade"
                                id="kt_security_summary_tab_pane_hours_clients"
                                role="tabpanel"
                              >
                                <div
                                  id="kt_security_summary_chart_hours_clients"
                                  style={{ height: 300 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_security_summary_tab_pane_day"
                          role="tabpanel"
                        >
                          <div className="row p-0 mb-5 px-9">
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-5 fw-semibold text-success d-block">
                                  {t("user_si")}
                                </span>
                                <span
                                  className="fs-1 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={30467}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-5 fw-semibold text-primary d-block">
                                  {t("admin_si")}
                                </span>
                                <span
                                  className="fs-1 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={120}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-5 fw-semibold text-danger d-block">
                                  {t("failed_attempts")}
                                </span>
                                <span
                                  className="fs-1 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={23}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="d-flex align-items-center pb-9 px-9">
                              <h3 className="m-0 text-gray-800 flex-grow-1">
                                {t("activity_chart")}
                              </h3>
                              <ul className="nav nav-pills nav-line-pills border rounded p-1">
                                <li className="nav-item me-2">
                                  <a
                                    className="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-semibold active"
                                    data-bs-toggle="tab"
                                    id="kt_security_summary_tab_day_agents"
                                    href="#kt_security_summary_tab_pane_day_agents"
                                  >
                                    {t("agents")}
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-semibold"
                                    data-bs-toggle="tab"
                                    id="kt_security_summary_tab_day_clients"
                                    href="#kt_security_summary_tab_pane_day_clients"
                                  >
                                    {t("clients")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="tab-content">
                              <div
                                className="tab-pane fade active show"
                                id="kt_security_summary_tab_pane_day_agents"
                                role="tabpanel"
                              >
                                <div
                                  id="kt_security_summary_chart_day_agents"
                                  style={{ height: 300 }}
                                />
                              </div>
                              <div
                                className="tab-pane fade"
                                id="kt_security_summary_tab_pane_day_clients"
                                role="tabpanel"
                              >
                                <div
                                  id="kt_security_summary_chart_day_clients"
                                  style={{ height: 300 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_security_summary_tab_pane_week"
                          role="tabpanel"
                        >
                          <div className="row p-0 mb-5 px-9">
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-lg-4 fs-6 fw-semibold text-success d-block">
                                  {t("user_si")}
                                </span>
                                <span
                                  className="fs-lg-2hx fs-2 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={340}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-lg-4 fs-6 fw-semibold text-primary d-block">
                                  {t("admin_si")}
                                </span>
                                <span
                                  className="fs-lg-2hx fs-2 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={90}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                            <div className="col">
                              <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-lg-4 fs-6 fw-semibold text-danger d-block">
                                  {t("failed_attempts")}
                                </span>
                                <span
                                  className="fs-lg-2hx fs-2 fw-bold text-gray-800"
                                  data-kt-countup="true"
                                  data-kt-countup-value={230}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="d-flex align-items-center pb-9 px-9">
                              <h3 className="m-0 text-gray-800 flex-grow-1">
                                {t("activity_chart")}
                              </h3>
                              <ul className="nav nav-pills nav-line-pills border rounded p-1">
                                <li className="nav-item me-2">
                                  <a
                                    className="nav-link btn btn-active-light py-2 px-5 fs-6 btn-active-color-gray-700 btn-color-gray-400 fw-semibold active"
                                    data-bs-toggle="tab"
                                    id="kt_security_summary_tab_week_agents"
                                    href="#kt_security_summary_tab_pane_week_agents"
                                  >
                                    {t("agents")}
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link btn btn-active-light py-2 px-5 btn-active-color-gray-700 btn-color-gray-400 fs-6 fw-semibold"
                                    data-bs-toggle="tab"
                                    id="kt_security_summary_tab_week_clients"
                                    href="#kt_security_summary_tab_pane_week_clients"
                                  >
                                    {t("clients")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="tab-content">
                              <div
                                className="tab-pane fade active show"
                                id="kt_security_summary_tab_pane_week_agents"
                                role="tabpanel"
                              >
                                <div
                                  id="kt_security_summary_chart_week_agents"
                                  style={{ height: 300 }}
                                />
                              </div>
                              <div
                                className="tab-pane fade"
                                id="kt_security_summary_tab_pane_week_clients"
                                role="tabpanel"
                              >
                                <div
                                  id="kt_security_summary_chart_week_clients"
                                  style={{ height: 300 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8">
                  <div className="card card-xxl-stretch-50 mb-5 mb-xl-10">
                    <div className="card-body pt-5">
                      <div
                        id="kt_security_recent_alerts"
                        className="carousel carousel-custom carousel-stretch slide"
                        data-bs-ride="carousel"
                        data-bs-interval={8000}
                      >
                        <div className="d-flex flex-stack align-items-center flex-wrap">
                          <h4 className="text-gray-400 fw-semibold mb-0 pe-2">
                            {t("recent_alerts")}
                          </h4>
                          <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                            {alerts.map((alert, index) => (
                              <li
                                key={index}
                                className={`ms-1 cursor-pointer ${
                                  index === activeIndex ? "active" : ""
                                }`}
                                onClick={() => handleItemClick(index)}
                              ></li>
                            ))}
                          </ol>
                        </div>
                        {alerts.length > 0 ? (
                          <div className="carousel-inner pt-6">
                            {alerts.map((alert, index) => (
                              <div
                                key={index}
                                className={`carousel-item ${
                                  index === activeIndex ? "active" : ""
                                }`}
                              >
                                <div className="carousel-wrapper">
                                  <div className="d-flex flex-column flex-grow-1">
                                    <span className="fs-5 fw-bold text-dark text-hover-primary">
                                      {alert.name}
                                    </span>
                                    <p className="text-gray-600 fs-6 fw-semibold pt-3 mb-0">
                                      {alert.content}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-stack pt-8">
                                    <span className="badge badge-light-primary fs-7 fw-bold me-2">
                                      {defaultDateFormat(alert.date_start)}
                                    </span>
                                    <a
                                      href="javascript:"
                                      className="btn btn-sm btn-light"
                                    >
                                      {t("learn_more")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="no-alerts-container">
                            <img
                              src="\assets\media\illustrations\unitedpalms-1/empty-articles.svg"
                              className="img-empty-al"
                              alt=""
                            />
                            <span>{t("no_alerts")}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <UpdatePasswordModal
          updateModalShow={modalHandelShow}
        />
      )}

      {warningModalShow && (
        <DisableTwoAuthModal
          deleteModalShow={() => {
            setWarningModalShow(false);
            document.body.classList.remove("modal-open");
          }}
        />
      )}
    </div>
  );
};

export default Security;
