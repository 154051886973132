const getTotalConversations = async () => {
    return await fetch(
      `${process.env.REACT_APP_CONVERSATIONS_URL}/api/v1/app/total/conversations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "TOKEN-ID": process.env.REACT_APP_MESSAGES_TOKEN,
        },
        body: JSON.stringify({
          customer_id: localStorage.getItem("customer_id"),
          direction: 1
        }),
      }
    );
  };
  
  export default getTotalConversations;
  