import React, { useEffect, useState } from "react";
import HeaderSettings from "../Layouts/HeaderSettings";
import Spinner from "../../../components/Spinner";
import { useTranslation } from "react-i18next";
import timezone from "../../../Data/Preferences/TimeZone.json";
import dateformat from "../../../Data/Preferences/DateFormat.json";
import timeformat from "../../../Data/Preferences/TimeFormat.json";
import currencyformat from "../../../Data/Preferences/CurrencyFormat.json";

import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Swal from "sweetalert2";
import { updateDateFormatAndTimeFormat } from "../../../constants/Utils";
import SpinnerSecondary from "../../../components/SpinnerSecondary";
import "../Style/Preferences.css";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";
import { successSwall } from "../../../components/Alerts/successAlert";

const Preferences = ({ userType }) => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [load, setLoad] = useState(false);

  const [preferencesDetails, setPreferencesDetails] = useState({
    id: JSON.parse(localStorage.getItem("data")).id,
    timezone: JSON.parse(localStorage.getItem("data")).timezone,
    date_format: JSON.parse(localStorage.getItem("data")).date_format,
    time_format: JSON.parse(localStorage.getItem("data")).time_format,
    currency_format: JSON.parse(localStorage.getItem("data")).currency_format,
  });

  const [notifications, setNotifications] = useState([]);

  const fetchNotification = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/notifications?status=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        setLoad(true);
        if (response.status === 401) {
          ErrorExpiredSession(
            t("session_expired"),
            t("errors.401"),
            t("login")
          );
        } else if (response.status === 403) {
          ErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          ErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          ErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          ErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          ErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          ErrorSwal(t("errors.406"));
        } else {
          fetchNotifictionSecondTry();
        }
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      const fetchedData = await response.json();

      if (fetchedData) {
        setNotifications(fetchedData.data);

        setLoad(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchNotifictionSecondTry = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/notifications?status=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        InternalErrorSwal();

        throw new Error(`${response.status}: ${response.statusText}`);
      }
      const fetchedData = await response.json();

      if (fetchedData) {
        setNotifications(fetchedData.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  // UPDATE DETAILS

  const handleSubmit = async () => {
    try {
      setSpinnerLoad(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/preferences/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            ...preferencesDetails,
          }),
        }
      );
      setSpinnerLoad(false);

      if (!response.ok) {
        if (response.status === 401) {
          ErrorExpiredSession(
            t("session_expired"),
            t("errors.401"),
            t("login")
          );
        } else if (response.status === 403) {
          ErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          ErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          ErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          ErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          ErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          ErrorSwal(t("errors.406"));
        } else {
          InternalErrorSwal();
        }
      } else {
        const fetchedData = await response.json();
        updateDateFormatAndTimeFormat(
          fetchedData.data[0].date_format,
          fetchedData.data[0].time_format,
          fetchedData.data[0].timezone ? fetchedData.data[0].timezone : "00:00",
          fetchedData.data[0].currency_format ? fetchedData.data[0].currency_format : "isoCode"
        );

        localStorage.setItem("data", JSON.stringify(preferencesDetails));

        successSwall(t("Preferences_updated_successefully"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setPreferencesDetails((prePref) => ({
      ...prePref,
      [name]: value,
    }));
  };

  const handleNotifiction = async (index, item) => {
    try {
      if (!notifications) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/resources/notifications/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              class: index,
              operation: item,
            }),
          }
        );
        if (!response.ok) {
          if (response.status === 401) {
            ErrorExpiredSession(
              t("session_expired"),
              t("errors.401"),
              t("login")
            );
          } else if (response.status === 403) {
            ErrorSwal(t("errors.403"));
          } else if (response.status === 404) {
            ErrorSwal(t("errors.404"));
          } else if (response.status === 405) {
            ErrorSwal(t("errors.405"));
          } else if (response.status === 422) {
            ErrorSwal(t("errors.422"));
          } else if (response.status === 423) {
            ErrorSwal(t("errors.423"));
          } else if (response.status === 406) {
            ErrorSwal(t("errors.406"));
          } else {
            InternalErrorSwal();
          }
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        const fetchedData = await response.json();
        if (fetchedData) {
          setNotifications(fetchedData.data[0]);
        }
      } else {
        if (
          notifications &&
          notifications.length > 0 &&
          notifications.some(
            (notification) =>
              notification.class === index && notification.operation === item
          )
        ) {
          const existingNotification = notifications.find(
            (notification) =>
              notification.class === index && notification.operation === item
          );

          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/resources/notifications/update`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({ id: existingNotification.id, status: 3 }),
            }
          );
          if (!response.ok) {
            if (response.status === 401) {
              ErrorExpiredSession(
                t("session_expired"),
                t("errors.401"),
                t("login")
              );
            } else if (response.status === 403) {
              ErrorSwal(t("errors.403"));
            } else if (response.status === 404) {
              ErrorSwal(t("errors.404"));
            } else if (response.status === 405) {
              ErrorSwal(t("errors.405"));
            } else if (response.status === 422) {
              ErrorSwal(t("errors.422"));
            } else if (response.status === 423) {
              ErrorSwal(t("errors.423"));
            } else if (response.status === 406) {
              ErrorSwal(t("errors.406"));
            } else {
              InternalErrorSwal();
            }
            throw new Error(`${response.status}: ${response.statusText}`);
          }
          const fetchedData = await response.json();

          if (fetchedData) {
            setNotifications((prevNotifications) =>
              prevNotifications.filter(
                (notification) => notification.id !== existingNotification.id
              )
            );
          }
        } else {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/resources/notifications/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                class: index,
                operation: item,
              }),
            }
          );
          if (!response.ok) {
            if (response.status === 401) {
              ErrorExpiredSession(
                t("session_expired"),
                t("errors.401"),
                t("login")
              );
            } else if (response.status === 403) {
              ErrorSwal(t("errors.403"));
            } else if (response.status === 404) {
              ErrorSwal(t("errors.404"));
            } else if (response.status === 405) {
              ErrorSwal(t("errors.405"));
            } else if (response.status === 422) {
              ErrorSwal(t("errors.422"));
            } else if (response.status === 423) {
              ErrorSwal(t("errors.423"));
            } else if (response.status === 406) {
              ErrorSwal(t("errors.406"));
            } else {
              InternalErrorSwal();
            }
            throw new Error(`${response.status}: ${response.statusText}`);
          }
          const fetchedData = await response.json();
          if (fetchedData) {
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              fetchedData.data[0],
            ]);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      {load ? (
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <HeaderSettings active={4} type={userType} />
              <div className="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">{t("display_details")}</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <form
                        id="kt_ecommerce_settings_general_form"
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        action="#"
                      >
                        <div className="card-body">
                          <div className="fv-row mb-7 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span>{t("timezone")}</span>
                            </label>
                            <div className="position-relative">
                              <select
                                className="form-control form-control-solid cursor-pointer"
                                name="timezone"
                                value={preferencesDetails.timezone}
                                onChange={(e) => {
                                  handleSelectChange(e);
                                }}
                              >
                                {timezone.map((timezone) => (
                                  <option
                                    key={timezone.value}
                                    value={timezone.value}
                                  >
                                    {t(`timezones.${timezone.name}`)}
                                  </option>
                                ))}
                              </select>
                              <svg
                                viewBox="0 0 1024 1024"
                                className="down-icon"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#5e6278"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                            <div className="col">
                              <div className="fv-row mb-7 fv-plugins-icon-container">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                  <span>{t("date_format")}</span>
                                </label>

                                <div className="position-relative">
                                  <select
                                    className="form-control form-control-solid cursor-pointer"
                                    name="date_format"
                                    value={preferencesDetails.date_format}
                                    onChange={(e) => {
                                      handleSelectChange(e);
                                    }}
                                  >
                                    {dateformat.map((dateformat) => (
                                      <option
                                        key={dateformat.value}
                                        value={dateformat.value}
                                      >
                                        {t(`date_formats.${dateformat.name}`)}
                                      </option>
                                    ))}
                                  </select>
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    className="down-icon"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                  >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      <path
                                        d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                        fill="#5e6278"
                                      />
                                    </g>
                                  </svg>
                                </div>
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                            <div className="col">
                              <div className="fv-row mb-7 fv-plugins-icon-container">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                  <span>{t("time_format")}</span>
                                </label>
                                <div className="position-relative">
                                  <select
                                    className="form-control form-control-solid cursor-pointer"
                                    name="time_format"
                                    value={preferencesDetails.time_format}
                                    onChange={(e) => {
                                      handleSelectChange(e);
                                    }}
                                  >
                                    {timeformat.map((timeformat) => (
                                      <option
                                        key={timeformat.value}
                                        value={timeformat.value}
                                      >
                                        {t(`time_formats.${timeformat.name}`)}
                                      </option>
                                    ))}
                                  </select>
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    className="down-icon"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                  >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      <path
                                        d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                        fill="#5e6278"
                                      />
                                    </g>
                                  </svg>
                                </div>

                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          <div className="fv-row mb-7 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span>{t("currency_format")}</span>
                            </label>
                            <div className="position-relative">
                              <select
                                className="form-control form-control-solid cursor-pointer"
                                name="currency_format"
                                value={preferencesDetails.currency_format}
                                onChange={(e) => {
                                  handleSelectChange(e);
                                }}
                              >
                                {currencyformat.map((currencyformat) => (
                                  <option
                                    key={currencyformat.value}
                                    value={currencyformat.value}
                                  >
                                    {t(
                                      `currency_formats.${currencyformat.name}`
                                    )}
                                  </option>
                                ))}
                              </select>
                              <svg
                                viewBox="0 0 1024 1024"
                                className="down-icon"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                    fill="#5e6278"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      {/* <button className="btn btn-light btn-sm btn-active-light-primary me-2">
                        
                      </button> */}
                      <SecondaryButton title={t("cancel")} />
                      <button
                        className="btn btn-primary-active btn-sm px-6"
                        onClick={handleSubmit}
                      >
                        {t("save_changes")}
                      </button>
                    </div>
                  </div>

                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_notifications"
                      aria-expanded="true"
                      aria-controls="kt_account_notifications"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">{t("notifications")}</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_notifications"
                      className="collapse show"
                    >
                      <form className="form">
                        <div className="card-body border-top px-9 pt-3 pb-4">
                          <div className="table-responsive">
                            <table className="table table-row-dashed border-gray-300 align-middle gy-6">
                              <tbody className="fs-6 fw-semibold">
                                <tr>
                                  <td className="min-w-250px fs-4 fw-bold">
                                    {t("billing_updates")}
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 1 &&
                                              item.operation ===
                                                "billing_updates" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(
                                            1,
                                            "billing_updates"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("web")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 2 &&
                                              item.operation ===
                                                "billing_updates" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(
                                            2,
                                            "billing_updates"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("desktop")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 3 &&
                                              item.operation ===
                                                "billing_updates" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(
                                            3,
                                            "billing_updates"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("email")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 4 &&
                                              item.operation ===
                                                "billing_updates" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(
                                            4,
                                            "billing_updates"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_phone"
                                      >
                                        {t("sms")}
                                      </label>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="min-w-250px fs-4 fw-bold">
                                    {t("invoices")}
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 1 &&
                                              item.operation === "invoices" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(1, "invoices");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("web")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 2 &&
                                              item.operation === "invoices" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(2, "invoices");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("desktop")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 3 &&
                                              item.operation === "invoices" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(3, "invoices");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("email")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 4 &&
                                              item.operation === "invoices" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(4, "invoices");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_phone"
                                      >
                                        {t("sms")}
                                      </label>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="min-w-250px fs-4 fw-bold">
                                    {t("new_products")}
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 1 &&
                                              item.operation ===
                                                "new_products" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(1, "new_products");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("web")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 2 &&
                                              item.operation ===
                                                "new_products" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(2, "new_products");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("desktop")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 3 &&
                                              item.operation ===
                                                "new_products" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(3, "new_products");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_email"
                                      >
                                        {t("email")}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="w-125px">
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        defaultChecked={
                                          notifications &&
                                          notifications.length > 0 &&
                                          notifications.some(
                                            (item) =>
                                              item.class === 4 &&
                                              item.operation ===
                                                "new_products" &&
                                              item.status === 1
                                          )
                                        }
                                        onClick={() => {
                                          handleNotifiction(4, "new_products");
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2"
                                        htmlFor="kt_settings_notification_phone"
                                      >
                                        {t("sms")}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Preferences;
