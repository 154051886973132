import React from 'react'
import { useTranslation } from 'react-i18next';

const DataTableCustomSelectFilter = ({
    handleInputChange,
    column,
    options,
    withAllOption = false,
    classValue
  }) => {

  const { t } = useTranslation();

  return (
    <select
      className={classValue}
      onChange={(e) => {
        handleInputChange(column, e.target.value);
      }}
    >
      {withAllOption && (
        <option value="">{t("data_table.filters.select.all")}</option>
      )}
      {Object.entries(options).map(([key, value]) => (
        <option value={key} key={key} selected={key === ""}>
          {value}
        </option>
      ))}
    </select>
  )
}

export default DataTableCustomSelectFilter;
