import React from "react";
import { useTranslation } from "react-i18next";


const PageComingSoon = ({small}) => {
  const { t } = useTranslation();

  return (
    <div className={`com-soon pointer-event-none ${small==1?"margin-plus":""}`}>
      <h1>{t("coming_soon")}..</h1>
      <h6>{t("next_version")}.</h6>
    </div>
  );
};

export default PageComingSoon;
