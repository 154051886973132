import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import Conversation from "../Components/Conversation";
import getConversations from "../Services/getConversations";
import Inbox from "../Components/Inbox";
import "../Style/smsConver.css";
import getConversation from "../Services/getConversation";
import AddNewConversationModal from "../Components/Modals/AddNewConversationModal";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const SmsConversations = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  const [conversationIndex, setConversationIndex] = useState(0);

  const changeBody = (item, value) => {
    setBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const [filter, setFilter] = useState({
    search: "",
    date_start: "",
    date_end: "",
  });

  const handleChangeFilter = (item, value) => {
    setFilter((prevData) => ({ ...prevData, [item]: value }));
  };

  const [loading, setLoading] = useState({
    conversations: false,
    conversation: false,
  });

  useEffect(() => {
    localStorage.setItem("active", 13);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const [conversations, setConversations] = useState([]);

  const [conversation, setConversation] = useState({
    contact: "",
    country: "",
    messages: [],
    first_message_date: "",
    last_message_date: "",
  });

  const [body, setBody] = useState({
    receiver: null,
    sender: null,
    message: "",
  });

  const [contact, setContact] = useState(null);

  const fetchInitializedConversation = async (newConversation) => {
    try {
      setLoading((prevData) => ({ ...prevData, conversation: true }));
      const response = await getConversation(
        0,
        conversationParams.limit,
        contact
      );
      setLoading((prevD) => ({ ...prevD, conversation: false }));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.total > 0) {
        setConversationParams((prevD) => ({ ...prevD, total: result.total }));
        const newData = [];
        result.data.map((item) =>
          newData.unshift({
            source: item.direction,
            date: item.dateInit,
            content: item.content,
            message_id: item.id,
          })
        );
        if (newConversation) {
          setConversation((prevData) => ({
            ...prevData,
            contact: result.data[0].contact,
            messages: newData,
            country: result.data[0].country,
          }));
        } else {
          setConversation((prevD) => ({
            ...prevD,
            messages: [...newData, ...prevD.messages],
          }));
        }
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchConversation();
      }
    }
  };

  useEffect(() => {
    contact &&
      setBody((prevData) => ({
        ...prevData,
        receiver: conversations[conversationIndex].contact,
      }));

    conversations.length > 0 &&
      setConversation((prevData) => ({
        ...prevData,
        last_message_date: conversations[conversationIndex].last_message.date,
      }));
  }, [conversationIndex]);

  useEffect(() => {
    setConversationParams((prevD) => ({ ...prevD, skip: 0 }));
    setBody((prevData) => ({ ...prevData, receiver: contact }));
    contact && fetchInitializedConversation(true);
  }, [body.receiver, body.sender]);

  const updateConversation = (newLine) => {
    setConversation((prevData) => ({
      ...prevData,
      messages: [...prevData.messages, newLine],
    }));

    if (conversations[0].last_message_id !== newLine.id) {
      setConversations((prevData) => [
        {
          ...prevData[conversationIndex],
          last_message: {
            date: new Date(),
            content: newLine.content,
            direction: 2,
          },
        },
        ...prevData.filter((item, index) => index !== conversationIndex),
      ]);
      setConversationIndex(0);
    }
  };

  const [conversationsParams, setConversationsParams] = useState({
    limit: 10,
    skip: 0,
    total: 0,
  });

  const [conversationParams, setConversationParams] = useState({
    limit: 10,
    skip: 0,
    total: 0,
  });

  const fetchFilteredConversations = async () => {
    try {
      setLoading((prevData) => ({ ...prevData, conversations: true }));
      const response = await getConversations(0, 10, filter);
      setLoading((prevData) => ({ ...prevData, conversations: false }));

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      !contact && result.total > 0 && setContact(result.data[0].contact);
      !body.sender &&
        result.total > 0 &&
        setBody((prevData) => ({
          ...prevData,
          sender: result.data[0].source_number,
        }));
      setConversationsParams((prevData) => ({
        ...prevData,
        total: result.total,
      }));

      const newData = [];
      result.data.map((item) => newData.push(item));
      setConversations((prevData) => newData);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchConversations();
      }
    }
  };

  // const refetchFilteredConversations = async () => {
  //   try {
  //     setLoading((prevData) => ({ ...prevData, conversations: true }));
  //     const response = await getConversations(0, 10, filter);
  //     setLoading((prevData) => ({ ...prevData, conversations: false }));
  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //   } catch (error) {
  //     InternalErrorSwal();
  //   }
  // };

  // useEffect(() => {
    
  //   if (filter.search.length > 0) {
  //     fetchFilteredConversations();
  //   }
  // }, [filter.search, filter.date_start, filter.date_end]);

  const fetchConversations = async () => {
    try {
      setLoading((prevData) => ({ ...prevData, conversations: true }));
      const response = await getConversations(conversationsParams.skip, 10);
      setLoading((prevData) => ({ ...prevData, conversations: false }));

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      !contact && result.total > 0 && setContact(result.data[0].contact);
      !body.sender &&
        result.total > 0 &&
        setBody((prevData) => ({
          ...prevData,
          sender: result.data[0].source_number,
        }));
      setConversationsParams((prevData) => ({
        ...prevData,
        total: result.total,
      }));

      const newData = [];
      result.data.map((item) => newData.push(item));
      setConversations((prevData) => [...prevData, ...newData]);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchConversations();
      }
    }
  };

  const fetchInitializedConversations = async () => {
    try {
      setLoading((prevData) => ({ ...prevData, conversations: true }));
      const response = await getConversations(0, 10);
      setLoading((prevData) => ({ ...prevData, conversations: false }));

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      !contact && result.total > 0 && setContact(result.data[0].contact);
      !body.sender &&
        result.total > 0 &&
        setBody((prevData) => ({
          ...prevData,
          sender: result.data[0].source_number,
        }));
      setConversationsParams((prevData) => ({
        ...prevData,
        total: result.total,
      }));

      const newData = [];
      result.data.map((item) => newData.push(item));
      setConversations((prevData) => newData);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchConversations();
      }
    }
  };

  const refetchConversations = async () => {
    try {
      setLoading((prevData) => ({ ...prevData, conversations: true }));
      const response = await getConversations(conversationsParams.skip, 10);

      setLoading((prevData) => ({ ...prevData, conversations: false }));

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();
      const newData = [];
      result.data.map((item) => newData.push(item));
      setConversations(newData);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const fetchConversation = async (newConversation) => {
    try {
      setLoading((prevData) => ({ ...prevData, conversation: true }));
      const response = await getConversation(
        conversationParams.skip,
        conversationParams.limit,
        body.receiver
      );
      setLoading((prevD) => ({ ...prevD, conversation: false }));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.total > 0) {
        setBody((prevData) => ({
          ...prevData,
          sender: result.data[0].sourceNumber,
        }));
        setConversationParams((prevD) => ({ ...prevD, total: result.total }));
        const newData = [];
        result.data.map((item) =>
          newData.unshift({
            source: item.direction,
            date: item.dateInit,
            content: item.content,
            message_id: item.id,
          })
        );
        if (newConversation) {
          setConversation({
            contact: result.data[0].contact,
            messages: newData,
          });
        } else {
          setConversation((prevD) => ({
            ...prevD,
            messages: [...newData, ...prevD.messages],
          }));
        }
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchConversation();
      }
    }
  };

  const refetchConversation = async () => {
    try {
      setLoading((prevD) => ({ ...prevD, conversation: true }));
      const response = await getConversation(
        conversationParams.skip,
        conversationParams.limit,
        body.receiver
      );
      setLoading((prevD) => ({ ...prevD, conversation: false }));
      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();
      setConversationParams((prevD) => ({ ...prevD, total: result.total }));
      const newData = [];
      result.data.map((item) =>
        newData.push({
          source: item.direction,
          date: item.dateInit,
          content: item.content,
          message_id: item.id,
        })
      );
      setConversation((prevD) => [...newData, ...prevD]);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const handleShowMoreConversations = () => {
    setConversationsParams((prevD) => ({ ...prevD, skip: prevD.skip + 1 }));
  };

  const handleShowMoreConversationMessages = () => {
    if (conversationParams.total > conversationParams.skip * 10) {
      setConversationParams((prevData) => ({
        ...prevData,
        skip: prevData.skip + 1,
      }));
    }
  };

  useEffect(() => {
    conversationParams.skip > 0 && fetchConversation();
  }, [conversationParams.skip]);

  useEffect(() => {
    filter.search ? fetchFilteredConversations() : fetchInitializedConversations();
  }, [filter.search, filter.date_start, filter.date_end]);

  useEffect(() => {
    if (conversationsParams.total > conversationsParams.skip * 10) {
      fetchConversations();
    }
  }, [conversationsParams.skip]);

  return (
    <div className="app-content flex-column-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-column flex-column-fluid">
        <div className="app-navbar card p-6">
          <div
            className="app-navbar-item d-flex justify-content-between"
            id="kt_menu_shopping_cart_toggle"
          >
            <a
              className="d-flex align-items-center"
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <button className="btn btn-icon btn-body position-relative me-5 border border-gray-300">
                <i className="fs-1">
                  <svg
                    fill="#000000"
                    height="15px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    enableBackground="new 0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fill="currentColor"
                        d="M256,0C114.6,0,0,85.9,0,192c0,75,57.5,139.8,141.1,171.4L85.3,512l160.5-128.4c3.4,0.1,6.7,0.4,10.2,0.4 c141.4,0,256-85.9,256-192C512,85.9,397.4,0,256,0z M128,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S151.5,234.7,128,234.7z M256,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S279.5,234.7,256,234.7z M384,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S407.5,234.7,384,234.7z"
                      />{" "}
                    </g>
                  </svg>
                </i>{" "}
              </button>
              <span className="d-flex flex-column">
                <span className="fs-6 fw-bold">
                  <span className="d-none d-lg-inline me-1">SMS Inbox</span>
                </span>
              </span>
            </a>
            <div
              className="menu menu-sub menu-sub-dropdown w-300px w-lg-400px"
              data-kt-menu="true"
              data-kt-menu-close="#kt_menu_shopping_cart_close"
              data-kt-menu-toggle="#kt_menu_shopping_cart_toggle"
              style={{}}
            >
              <a
                href="#"
                className="d-flex align-items-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              ></a>
              <div className="card card-flush">
                <a
                  href="#"
                  className="d-flex align-items-center"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <div className="card-header w-100 py-5 mb-4">
                    <h3 className="card-title text-gray-900 fw-bold">
                      Shopping Cart
                    </h3>
                  </div>
                </a>
                <div className="card-body py-1 pe-4">
                  <a
                    href="#"
                    className="d-flex align-items-center"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  ></a>
                  <div className="hover-scroll-y py-0 h-350px pe-4">
                    <a
                      href="#"
                      className="d-flex align-items-center"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-attach="parent"
                      data-kt-menu-placement="bottom-end"
                    ></a>
                    <div className="d-flex align-items-center">
                      <a
                        href="#"
                        className="d-flex align-items-center"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                      ></a>
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <a
                          href="#"
                          className="d-flex align-items-center"
                          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                        ></a>
                        <div className="mb-3">
                          <a
                            href="#"
                            className="d-flex align-items-center"
                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          ></a>
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            Iblender
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            The best kitchen gadget in 2022
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 350
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            5
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-1.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            SmartCleaner
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Smart tool for cooking
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 650
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            4
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            CameraMaxr
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Professional camera for edge
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 150
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            3
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-8.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            $D Printer
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Manfactoring unique objekts
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 1450
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            7
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-26.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            MotionWire
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Perfect animation tool
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 650
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            7
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-21.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            Samsung
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Profile info,Timeline etc
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 720
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            6
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-34.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column me-3 flex-grow-1">
                        <div className="mb-3">
                          <a
                            href="/metronic8/demo28/apps/ecommerce/sales/details.html"
                            className="text-gray-800 text-hover-primary fs-4 fw-bold"
                          >
                            $D Printer
                          </a>
                          <span className="text-gray-500 fw-semibold d-block">
                            Manfactoring unique objekts
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-gray-800 fs-6">
                            $ 430
                          </span>
                          <span className="text-muted mx-2">for</span>
                          <span className="fw-bold text-gray-800 fs-6 me-3">
                            8
                          </span>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
                          >
                            <i className="ki-duotone ki-minus fs-4" />{" "}
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
                          >
                            <i className="ki-duotone ki-plus fs-4" />{" "}
                          </a>
                        </div>
                      </div>
                      <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                        <img
                          src="/metronic8/demo28/assets/media/stock/600x400/img-27.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer py-5">
                  <div className="d-flex flex-stack">
                    <span className="fw-bold text-gray-600">Total</span>
                    <span className="text-gray-700 fw-bolder fs-6">
                      $1,800.00
                    </span>
                  </div>
                  <div className="d-flex flex-stack">
                    <span className="fw-bold text-gray-600">Sub total</span>
                    <span className="text-success fw-bolder fs-5">
                      $1,800.00
                    </span>
                  </div>
                  <div className="d-flex justify-content-end mt-5">
                    <a
                      href="#"
                      className="btn btn-sm btn-primary justify-content-end fw-bold"
                    >
                      Pleace Order
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span
                className="btn btn-sm btn-primary-active"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setModal(true)}
              >
                {t("create_new_conversation")}
              </span>
            </div>
          </div>
        </div>
        <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div
              id="kt_app_toolbar"
              className="d-flex flex-wrap flex-stack pb-7"
            >
              <div
                className="d-flex flex-grow-1 flex-stack flex-wrap gap-2"
                id="kt_toolbar"
              >
                {/* <div className="d-flex flex-column align-items-start me-3 gap-2">
                  <h1 className="d-flex text-gray-900 fw-bold m-0 fs-3">
                    {t("sms_conversations")}
                  </h1>
                </div> */}
              </div>
            </div>
            <div id="kt_app_content" className="arow g-6 g-xl-9 margin-bottom">
              <div className="d-flex flex-column flex-lg-row">
                <Inbox
                  conversations={conversations}
                  loading={loading.conversations}
                  showMore={handleShowMoreConversations}
                  changeConversation={(contact) => setContact(contact)}
                  index={conversationIndex}
                  changeIndex={(item) => setConversationIndex(item)}
                  filters={filter}
                  changeFilter={(item, value) =>
                    handleChangeFilter(item, value)
                  }
                  getConversations={fetchConversations}
                />
                <Conversation
                  conversation={conversation}
                  changeBody={changeBody}
                  body={body}
                  updateConversation={updateConversation}
                  loading={loading.conversation}
                  showMore={handleShowMoreConversationMessages}
                  total={conversationParams.total}
                  skip={conversationParams.skip}
                  senderNumber={body.sender}
                  country={conversation.country}
                  first_message_date={conversation.first_message_date}
                  last_message_date={conversation.last_message_date}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && <AddNewConversationModal closeModal={() => setModal(false)} />}
    </div>
  );
};

export default SmsConversations;
