import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../Buttons/SecondaryButton";
import errorExpiredSession from "../Alerts/errorExpiredSession";
import errorSwal from "../Alerts/errorAlert";
import SpinnerSecondary from "../SpinnerSecondary";
import { confirmationSwal } from "../Alerts/confirmationAlert";
import { successSwall } from "../Alerts/successAlert";
import InternalErrorSwal from "../Alerts/internalErrorAlert";

const DisableTwoAuthModal = ({ deleteModalShow }) => {
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const { t } = useTranslation();

  const handleDeleteDoc = async () => {
    try {
      setSpinnerLoad(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/tools/2fa/accounts/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            status: 3,
            method: 2,
          }),
        }
      );
      setSpinnerLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      successSwall(t("2fa_desactivated"))
      deleteModalShow(false);
      localStorage.setItem("active_2fa", false);
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className={`warning-modal-container fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <h2 className="">{t("disable_confirmation")}</h2>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => {
                deleteModalShow(false);
              }}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="warning-container p-6">
            <div className="danger-drag-and-drop d-flex align-items-center p-6">
              <svg
                className="danger-color"
                width={50}
                height={50}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={14}
                  width={7}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 14)"
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={17}
                  width={2}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 17)"
                  fill="currentColor"
                />
              </svg>

              <div className="d-flex flex-column login-error-div">
                <h5 className="mb-1">{t("delete_confirmation")} ?</h5>
                <span className="danger-color">
                  {" "}
                  <span className="fw-bolder">
                    {t("note")}
                    {": "}
                  </span>
                  {t("two_fact_disabling_confirmation")}.
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end footer-border-top p-6">
            <SecondaryButton
              title={t("cancel")}
              onClick={() => {
                deleteModalShow(false);
              }}
            />
            <button
              type="submit"
              className="btn btn-disable btn-sm"
              onClick={()=>confirmationSwal(handleDeleteDoc) }
            >
              <span> {t("disable")}</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   deleteModalShow(false);
        // }}
      ></div>
    </div>
  );
};

export default DisableTwoAuthModal;
