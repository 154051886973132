import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteUser } from "../../Services/deleteUser";

import ErrorSwal from "../../../../components/Alerts/errorAlert";
import SpinnerSecondary from "../../../../components/SpinnerSecondary";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { convertedDate, initialDateFormat } from "../../../../constants/Utils";
import { successSwall } from "../../../../components/Alerts/successAlert";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import DateTimePicker from "../../../../components/reusables/DateTimePicker";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import DatePickerProfile from "../../../../components/reusables/DatePickerProfile";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const DeleteExtensionModal = ({ closeModal, changeData, user_id, user }) => {
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const { t } = useTranslation();

  const handleDeleteNumber = async () => {
    try {
      setSpinnerLoad(true);
      const response = await deleteUser(JSON.stringify(reqBody));
      setSpinnerLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      successSwall(t("user_deleted_succ"));
      closeModal();
      changeData("status", user_id, 3);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const reasons = ["Reason 1", "Reason 2", "Reason 3", t("other")];

  const [menuOpen, setMenuOpen] = useState(false);

  const [reqBody, setReqBody] = useState({
    resource_id: user_id,
    reason: null,
    description: null,
    end_date: null,
  });

  const changeReqBody = (item, value) => {
    setReqBody((prevData) => ({ ...prevData, [item]: value }));
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className={`warning-del-number-container fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("delete_user")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {user}
                </div>
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="p-6">
            <div className="mb-6">
              <div>
                <label className="form-label mb-3">
                  <span>{t("user_end_date")}</span>
                </label>
              </div>
              <div>
                <div className="position-relative">
                  <svg
                    className="end-date-svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                        stroke="#5e6278"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>{" "}
                      <rect
                        x="6"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="10.5"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                      <rect
                        x="15"
                        y="12"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="#5e6278"
                      ></rect>{" "}
                    </g>
                  </svg>

                  <input
                    type="text"
                    className={`form-control form-control-sm form-control-solid`}
                    name="passport_number_validity"
                    value={reqBody.end_date}
                  />
                  <DatePickerProfile
                    onChange={(e) => {
                      changeReqBody("end_date", initialDateFormat(new Date(e)));
                    }}
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className={!reqBody.end_date && "disabled-event"}>
              <div>
                <label className="form-label mb-3">
                  <span>{t("reason")}</span>
                </label>
              </div>
              <div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <div
                        className={`currency-div position-relative`}
                        onClick={() => setMenuOpen(!menuOpen)}
                        onMouseLeave={() => setMenuOpen(false)}
                      >
                        <span>{reqBody.reason}</span>
                        <svg
                          className="svg-menu-currency"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                              fill="#727272"
                            />
                          </g>
                        </svg>

                        {menuOpen && (
                          <div>
                            {reasons.map((item, key) => (
                              <span
                                key={key}
                                onClick={() => {
                                  changeReqBody("reason", item);
                                }}
                              >
                                {item}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            {reqBody.reason === t("other") && (
              <div className="">
                <div className="pt-6">
                  <div>
                    <label className="form-label mb-3">
                      <span>{t("description")}</span>
                    </label>
                  </div>
                  <div className="row">
                    <div className="d-flex">
                      <textarea
                        value={reqBody.description ? reqBody.description : ""}
                        type="text"
                        className={`form-control form-control-sm form-control-solid`}
                        onChange={(e) =>
                          changeReqBody("description", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton
              title={t("cancel")}
              onClick={() => {
                closeModal();
              }}
            />

            <button
              type="submit"
              className={`btn btn-disable btn-sm ${
                (!reqBody.reason ||
                  (reqBody.reason === t("other") && !reqBody.description)) &&
                "disabled-event"
              }`}
              onClick={() => confirmationSwal(handleDeleteNumber)}
            >
              <span> {t("delete")}</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default DeleteExtensionModal;
