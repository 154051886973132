import React, { useEffect, useState } from "react";
import HeaderSettings from "../Layouts/HeaderSettings";
import SoonInterface from "../../../Layouts/SoonInterface";
import { useTranslation } from "react-i18next";

const Users = ({ userType }) => {
  // const [first, setfirst] = useState(false);
  // const [load, setLoad] = useState(false);
  const { t } = useTranslation();


  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  return (
    <div
      className="app-wrapper flex-column flex-row-fluid "
      id="kt_app_wrapper"
    >
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid ">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <HeaderSettings active={5} type={userType} />

          <div className="card coming-soon">
            <div className="container py-5 px-4 px-lg-5 my-auto">
              <div
                className="row py-5 py-sm-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-12 text-center mx-auto">
                  <h1 className="text-9 text-white bg-danger d-inline-block fw-700 rounded px-3 py-2 mb-4">
                    {t("coming_soon")}!
                  </h1>
                  <h2 className="text-15 fw-600 mb-4 margin-top-bottom">{t("feature_soon")}</h2>
                </div>
                <div className="mx-auto text-center">
                  <p className="text-5 text-muted mb-3">
                    {t("new_start_soon")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
