import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import PageComingSoon from "./PageComingSoon";
import Spinner from "../components/Spinner";

const Menu = ({ setShow, menuClicked, closeMenu }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(
    parseInt(localStorage.getItem("active"))
  );

  

  document.addEventListener("activePage", () => {
    setActiveMenuItem(parseInt(localStorage.getItem("active")));
  });

  const [load, setLoad] = useState(false);

  useState(() => {
    Promise.all([import("./CSS/Menu.css")]).then(() => setLoad(true));
  }, []);

  const [scrollPage, setScrollPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPage(true);
      setShow();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollTop = () => {
      if (window.scrollY === 0) {
        setScrollPage(false);
      }
    };
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  const { t } = useTranslation();

  const [listing, setListing] = useState(false);
  const [reportings, setReportings] = useState(false);

  const listingRef = useRef();
  const reportingsRef = useRef();

  useEffect(() => {
    const handleListingHoverOutside = (e) => {
      if (listingRef.current && !listingRef.current.contains(e.target)) {
        setListing(false);
      }
    };

    const handleReportingsHoverOutside = (e) => {
      if (reportingsRef.current && !reportingsRef.current.contains(e.target)) {
        setReportings(false);
      }
    };

    window.addEventListener("mouseleave", handleListingHoverOutside);
    window.addEventListener("mouseleave", handleReportingsHoverOutside);

    return () => {
      window.removeEventListener("mouseleave", handleListingHoverOutside);
      window.removeEventListener("mouseleave", handleReportingsHoverOutside);
    };
  }, [listing, reportings, listingRef, reportingsRef]);

  const handleListingMouseEnter = () => {
    setListing(true);
  };

  const handleListingMouseLeave = () => {
    setListing(false);
  };

  const handleReportingsMouseEnter = () => {
    setReportings(true);
  };

  const handleReportingsMouseLeave = () => {
    setReportings(false);
  };

  const handleActiveItem = (item) => {
    setActiveMenuItem(item);
    localStorage.setItem("active", item);
  };

  const [toolsShow, setToolsShow] = useState(false);

  return (
    <div
    onClick={closeMenu}
      className={`app-header-secondary app-header-mobile-drawer ${menuClicked&&"d-flex hight-priority"} ${
        scrollPage && "menu-fixed"
      }`}
    >
      {!load && <Spinner />}
      <div className="app-container container-fluid app-container-fit-mobile d-flex align-items-stretch">
        <div
          className="header-menu d-flex align-items-stretch w-100"
          id="menu_id"
        >
          <div
            className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-400 menu-bullet-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch"
            id="#kt_header_menu"
            data-kt-menu="true"
          >
            <Link
              to="/dashboard"
              className="menu-item"
              onClick={() => handleActiveItem(1)}
            >
              <a className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      activeMenuItem === 1 && "active-menu-item"
                    }`}
                  >
                    {t("dashboard")}
                  </span>
                  <span className="menu-desc">{t("dashboard_desc")}</span>
                </span>
                <span className="menu-arrow d-lg-none"></span>
              </a>
            </Link>
            <Link
              to="/numbers_list"
              className="menu-item"
              onClick={() => handleActiveItem(2)}
            >
              <a className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      activeMenuItem === 2 && "active-menu-item"
                    }`}
                  >
                    {t("numbers")}
                  </span>
                  <span className="menu-desc">{t("numbers_desc")}</span>
                </span>
              </a>
            </Link>
            <Link
              to="/users_list"
              className="menu-item"
              onClick={() => handleActiveItem(3)}
            >
              <a className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      activeMenuItem === 3 && "active-menu-item"
                    }`}
                  >
                    {t("users")}
                  </span>
                  <span className="menu-desc">{t("users_desc")}</span>
                </span>
              </a>
            </Link>
            <div
              className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}
              onMouseEnter={handleListingMouseEnter}
              onMouseLeave={handleListingMouseLeave}
            >
              <span className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      (activeMenuItem === 4 || activeMenuItem === 5) &&
                      "active-menu-item"
                    }`}
                  >
                    {t("listing")}
                  </span>
                  <span className="menu-desc">{t("listing_desc")}</span>
                </span>
                <span className="menu-arrow d-lg-none"></span>
              </span>
              <div
                ref={listingRef}
                className={`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px ${
                  listing && "menu-listing"
                }`}
              >
                <Link
                  to="/listing/calls"
                  className="menu-item"
                  onClick={() => {
                    handleActiveItem(4);
                    setListing(false);
                  }}
                >
                  <a
                    className={`menu-link py-3 ${
                      activeMenuItem === 4 && "active show"
                    }`}
                  >
                    <span className="menu-icon">
                      <span className="svg-icon svg-icon-3">
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
 
      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
      fill="currentColor"

    />{" "}
  </g>
</svg>


                        {/* <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                            fill="currentColor"
                          />
                          <path
                            d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                            fill="currentColor"
                          />
                        </svg> */}
                      </span>
                    </span>
                    <span className="menu-title">{t("calls")}</span>
                  </a>
                </Link>
                <Link
                  to="/listing/sms"
                  className="menu-item active"
                  onClick={() => {
                    handleActiveItem(5);
                    setListing(false);
                  }}
                >
                  <a
                    className={`menu-link py-3 ${
                      activeMenuItem === 5 && "active show"
                    }`}
                  >
                    <span className="menu-icon">
                      <span className="svg-icon svg-icon-3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" />
  </g>
</svg>

                        {/* <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                            fill="currentColor"
                          />
                        </svg> */}
                      </span>
                    </span>
                    <span className="menu-title">SMS</span>
                  </a>
                </Link>
              </div>
            </div>

            <div
              className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 position-relative"
              onMouseEnter={handleReportingsMouseEnter}
              onMouseLeave={handleReportingsMouseLeave}
            >
              <a className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      (activeMenuItem === 6 ||
                        activeMenuItem === 7 ||
                        activeMenuItem === 8 ||
                        activeMenuItem === 9) &&
                      "active-menu-item"
                    }`}
                  >
                    {t("reportings")}
                  </span>
                  <span className="menu-desc">{t("reportings_desc")}</span>
                </span>
              </a>
              <div
                ref={reportingsRef}
                className={`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px ${
                  reportings && "position-menu "
                }`}
              >
                <div
                  className="menu-state-bg menu-extended overflow-hidden"
                  data-kt-menu-dismiss="true"
                >
                  <div className="row">
                    <div className="col-lg-8 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6 width-full">
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <Link
                            onClick={() => {
                              handleActiveItem(6);
                              setReportings(false);
                            }}
                            to="/report_general"
                            className="menu-item report-menu p-0 m-0"
                          >
                            <a
                              className={`menu-link report-menu ${
                                activeMenuItem === 6 && "active"
                              }`}
                            >
                              <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-primary svg-icon-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M10.9607 12.9128H18.8607C19.4607 12.9128 19.9607 13.4128 19.8607 14.0128C19.2607 19.0128 14.4607 22.7128 9.26068 21.7128C5.66068 21.0128 2.86071 18.2128 2.16071 14.6128C1.16071 9.31284 4.96069 4.61281 9.86069 4.01281C10.4607 3.91281 10.9607 4.41281 10.9607 5.01281V12.9128Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.9607 10.9128V3.01281C12.9607 2.41281 13.4607 1.91281 14.0607 2.01281C16.0607 2.21281 17.8607 3.11284 19.2607 4.61284C20.6607 6.01284 21.5607 7.91285 21.8607 9.81285C21.9607 10.4129 21.4607 10.9128 20.8607 10.9128H12.9607Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fs-6 fw-bold text-gray-800">
                                  {t("features_marketplace")}
                                </span>

                                <span className="fs-7 fw-semibold text-muted">
                                  {t("r_and_s")}
                                </span>
                              </span>
                            </a>
                          </Link>
                        </div>

                        <Link
                          onClick={() => {
                            handleActiveItem(7);
                            setReportings(false);
                          }}
                          to="/report_numbers"
                          className="col-lg-6 mb-3"
                        >
                          <div className="menu-item report-menu p-0 m-0">
                            <a
                              className={`menu-link report-menu ${
                                activeMenuItem === 7 && "active"
                              }`}
                            >
                              <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M14 4.5C14 5.9 12.9 7 11.5 7C10.1 7 9 5.9 9 4.5C9 3.1 10.1 2 11.5 2C12.9 2 14 3.1 14 4.5ZM18.5 2C17.1 2 16 3.1 16 4.5C16 5.9 17.1 7 18.5 7C19.9 7 21 5.9 21 4.5C21 3.1 19.9 2 18.5 2ZM4.5 9C3.1 9 2 10.1 2 11.5C2 12.9 3.1 14 4.5 14C5.9 14 7 12.9 7 11.5C7 10.1 5.9 9 4.5 9ZM11.5 9C10.1 9 9 10.1 9 11.5C9 12.9 10.1 14 11.5 14C12.9 14 14 12.9 14 11.5C14 10.1 12.9 9 11.5 9ZM18.5 9C17.1 9 16 10.1 16 11.5C16 12.9 17.1 14 18.5 14C19.9 14 21 12.9 21 11.5C21 10.1 19.9 9 18.5 9ZM4.5 16C3.1 16 2 17.1 2 18.5C2 19.9 3.1 21 4.5 21C5.9 21 7 19.9 7 18.5C7 17.1 5.9 16 4.5 16ZM11.5 16C10.1 16 9 17.1 9 18.5C9 19.9 10.1 21 11.5 21C12.9 21 14 19.9 14 18.5C14 17.1 12.9 16 11.5 16ZM18.5 16C17.1 16 16 17.1 16 18.5C16 19.9 17.1 21 18.5 21C19.9 21 21 19.9 21 18.5C21 17.1 19.9 16 18.5 16Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fs-6 fw-bold text-gray-800">
                                  {t("stats_by_number")}
                                </span>

                                <span className="fs-7 fw-semibold text-muted">
                                  {t("sales_report")}
                                </span>
                              </span>
                            </a>
                          </div>
                        </Link>

                        <Link
                          onClick={() => {
                            handleActiveItem(8);
                            setReportings(false);
                          }}
                          to="/report_geo"
                          className="col-lg-6 mb-3"
                        >
                        
                          <div className="menu-item report-menu p-0 m-0">
                            <a
                              className={`menu-link report-menu  ${
                                activeMenuItem == 8 && "active"
                              }`}
                            >
                              <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-info svg-icon-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fs-6 fw-bold text-gray-800">
                                  {t("graph_stats")}
                                </span>

                                <span className="fs-7 fw-semibold text-muted">
                                  {t("tgc")}
                                </span>
                              </span>
                            </a>
                          </div>
                       
                        </Link>

                        <Link
                          onClick={() => {
                            handleActiveItem(9);
                            setReportings(false);
                          }}
                          to="/report_users"
                          className="col-lg-6 mb-3"
                        >
                          <div className="menu-item report-menu p-0 m-0">
                            <a
                              className={`menu-link report-menu ${
                                activeMenuItem == 9 && "active"
                              }`}
                            >
                              <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-success svg-icon-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="14"
                                      y="4"
                                      width="4"
                                      height="4"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="6"
                                      y="5"
                                      width="6"
                                      height="6"
                                      rx="3"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fs-6 fw-bold text-gray-800">
                                  {t("users_stats")}
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  {t("activity_progress")}
                                </span>
                              </span>
                            </a>
                          </div>
                        </Link>
                      </div>
                      {/* <div className="separator separator-dashed mx-5 my-5"></div>
                      <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
                        <div className="d-flex flex-column me-5">
                          <div className="fs-6 fw-bold text-gray-800">
                            {t("custom_dashboard")}
                          </div>
                          <div className="fs-7 fw-semibold text-muted">
                            {t("custom_dash_details")}
                          </div>
                        </div>
                        <a className="btn btn-sm btn-primary-active fw-bold">
                          {" "}
                          {t("submit")}
                        </a>
                      </div> */}
                    </div>
                    {/* <div className="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end">
                      <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                        {t("more_dash")}
                      </h4>
                      <div className="menu-item p-0 m-0">
                        <a className="menu-link py-2">
                          <span className="menu-title">
                            {t("finance_performance")}
                          </span>
                        </a>
                      </div>
                      <div className="menu-item p-0 m-0">
                        <a className="menu-link py-2">
                          <span className="menu-title">{t("del")}</span>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 position-relative tool-show"
              onMouseEnter={() => setToolsShow(true)}
              onMouseLeave={() => setToolsShow(false)}
            >
              <span className="menu-link py-3 ">
                <span className="menu-title">
                  <span className="menu-text ">{t("tools")}</span>
                  <span className="menu-desc">{t("tools_desc")}</span>
                </span>
                <span className="menu-arrow d-lg-none "></span>
              </span>

              <div
                className={`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 tool-div   ${
                  toolsShow && "tool-div-active"
                }`}
              >
                <PageComingSoon />

                <div className="tools-container opacity-tools-invisible pointer-event-none">
                  <div className="tool-col">
                    <h4>Advanced</h4>
                    <span className="menu-link-disabled">
                      <span className="menu-title">Cloud PBX</span>
                      <span className="menu-title">Lookup</span>
                    </span>
                  </div>

                  <div className="tool-col">
                    <h4>Voice features</h4>

                    <span className="menu-link-disabled">
                      <span className="menu-title">Voicemail</span>
                      <span className="menu-title">Callback</span>
                      <span className="menu-title">Call forwarding</span>
                      <span className="menu-title">Background music</span>
                      <span className="menu-title">Background music</span>
                      <span className="menu-title">Background music</span>
                    </span>
                  </div>

                  <div className="tool-col">
                    <h4>Management</h4>

                    <span className="menu-link-disabled">
                      <span className="menu-title">IVR</span>
                      <span className="menu-title">Call recordings</span>
                      <span className="menu-title">Blacklist</span>
                    </span>
                  </div>
                  <div className="tool-col">
                    <h4>Integrations</h4>

                    <span className="menu-link-disabled">
                      <span className="">Softphone</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Link
              to="/settings_account"
              className="menu-item"
              onClick={() => handleActiveItem(10)}
            >
              <a className="menu-link py-3">
                <span className="menu-title">
                  <span
                    className={`menu-text ${
                      activeMenuItem === 10 && "active-menu-item"
                    }`}
                  >
                    {t("settings")}
                  </span>

                  <span className="menu-desc">{t("settings_desc")}</span>
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
