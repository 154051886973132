export const addOutboundCredits = async (formData) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/interface/outbound/calling/credit`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  return response;
};
