const getConversations = async (skip, limit, data) => {
  return await fetch(
    `${process.env.REACT_APP_CONVERSATIONS_URL}/api/v1/app/conversations?limit=${limit}&offset=${skip}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "TOKEN-ID": process.env.REACT_APP_MESSAGES_TOKEN,
      },
      body: JSON.stringify({
        customer_id: localStorage.getItem("customer_id"),
        search: data.search,
        date_start: data.date_start,
        date_end: data.date_end,
      }),
    }
  );
};

export default getConversations;
