import React from "react";

const SecondaryButton = ({ title, onClick, marg_none }) => {
  return (
    <a className={`btn btn-light secondary btn-sm ${marg_none?"":"me-3"}`} onClick={onClick}>
      {title}
    </a>
  );
};

export default SecondaryButton;
