const getPricing = async (country) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/interface/pricing?country=${country}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export default getPricing;
