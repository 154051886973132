import { t } from "i18next";
import Swal from "sweetalert2";

const InternalErrorSwal = () => {

  const handleConfirm = () => {
    window.location.reload();
  };

  Swal.fire({
    icon: "warning",
    title: t("error"),
    text: t("errors.500"),
    confirmButtonText: t("refresh"),
    showCancelButton: true,
    customClass: {
      container: 'session-expired-swal-container' 
    }
  }).then((result) => {
    if (result.isConfirmed) {
      handleConfirm();
    }
  });
};

export default InternalErrorSwal;
