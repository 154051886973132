import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SpinnerSecondary from "../components/SpinnerSecondary";
import Spinner from "../components/Spinner";


const RecoverPassword = () => {
  const { t } = useTranslation();

const [spinnerLoad, setSpinnerLoad]=useState(false)
const [load, setLoad]=useState(false)

  useEffect(() => {
    Promise.all([
      import("./Style/Login.css"),
      import("./Style/bootstrap.min.css"),
      import("./Style/all.min.css"),
    ]).then(() => {
      setLoad(true);
    });;
  }, []);
  const [email, setEmail] = useState("");
  const [inputErrors, setInputErrors] = useState(false);
  const [submitRecover, setSubmitRecover] = useState(false);

  const [reqError, setReqError]=useState(null)

  const handleChangeInput = (e) => {
    if(inputErrors){
      setInputErrors(false)
    }
    if(reqError){
      setReqError(null)
    }
    setEmail(e.target.value);
  };

  let hasErrors = false;

  const [sendEmail, setSendEmail] = useState(false);

  const handleRecoverPw = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setInputErrors(false);
    } else {
      setInputErrors(true);
      setReqError("errors.invalid_email")
      hasErrors = true;
    }
    if (hasErrors == false) {
      try {
        setSpinnerLoad(true)
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/resources/users/reset`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        );
        setSpinnerLoad(false)

        if (!response.ok) {
          throw new Error(response.status);
        }
        const fetchedData = await response.json();

        setSendEmail(true);

        if (fetchedData) {
          setSubmitRecover(true);
        }
      } catch (error) {
        if(error.message == 400){
          setReqError("errors.email_dosnt_exist")
        }
        else{
          setReqError("errors.500")
        }
      }
    }
  };

  
  const [menuLang, setMenuLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { i18n } = useTranslation();

  const handleLangChange = (item) => {
    setSelectedLang(item);
    setMenuLang(false);
    localStorage.setItem("i18nextLng", item);
    i18n.changeLanguage(item);
  };

  const menuRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuLang(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div>
      {!load?<Spinner/>:<div className="oxyy-login-register">
      {spinnerLoad&&<SpinnerSecondary/>}
      <div className="container-fluid px-0">
        <div className="row g-0 min-vh-100">
          <div className="col-md-7 d-flex flex-column order-2 order-md-1">
          <div className="lang-switcher" ref={menuRef}>
                  <span
                    className="login-selected-lang p-1 px-3"
                    onClick={() => setMenuLang(!menuLang)}
                  >
                    {selectedLang.includes("fr") 
                      ? t("french")
                      : selectedLang.includes("en")                      
                       ? t("english")
                      : ""}
                    <img
                      className="login-flags"
                      src={`assets/media/flags/${
                        selectedLang.includes("fr")
                          ? "fr"
                          : selectedLang.includes("en")
                          ? "gb"
                          : ""
                      }.svg`}
                      alt=""
                    />{" "}
                  </span>
                  <div
                    className={`login-lang-container ${
                      menuLang && "container-show"
                    }`}
                  >
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("fr")}
                    >
                      <span>{t("french")}</span>
                      <span>
                      <img
                        className="login-flags"
                        src="assets/media/flags/fr.svg"
                        alt=""
                      />
                      </span>
                    </span>
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("en")}
                    >
                     <span> {t("english")}</span>
                      <span><img
                        className="login-flags"
                        src="assets/media/flags/gb.svg"
                        alt=""
                      />
                      </span>
                    </span>
                  </div>
                </div>
            <div className="container my-auto py-5">
              <div className="row mx-0">
                <div className="col-11 col-lg-9 col-xl-8 mx-auto text-center">
                  <div className="logo mb-5 text-center">
                    {" "}
                   
                      <img
                        src="/assets/media/myxtel.png"
                        alt="GoCall"
                        width={180}
                      />
                   
                  </div>
                  <h4 className="mb-4">{t("auth.reset.title")}</h4>
                  <p className="text-muted text-center mb-4">
                    {t("auth.reset.description")}
                  </p>
                  
                  {reqError&&
                  <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  fill="currentColor"
                                />
                                <rect
                                  x="7"
                                  y="15.3137"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-45 7 15.3137)"
                                  fill="currentColor"
                                />
                                <rect
                                  x="8.41422"
                                  y="7"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 8.41422 7)"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </span>
                  <div className="d-flex flex-column login-error-div">
                    <h5 className="mb-1 text-left">{t("error")}</h5>
                    <span className="text-left">{t(reqError)}.</span>
                  </div>
                </div>
                }
                  
                  <form method="post">
                    <div className="mb-3">
                      {!submitRecover ? (
                        <input
                          type="email"
                          className={`form-control ${
                            inputErrors && "invalid-value"
                          }`}
                          id="emailAddress"
                          required=""
                          placeholder={t("enter_email_pn")}
                          onChange={handleChangeInput}
                        />
                      ) : (
                        <span className="email-sent">
                          {t("auth.reset.email_sent")}
                        </span>
                      )}
                    </div>
                    <div className="d-grid my-4">
                      {!sendEmail && (
                        <a
                          className="btn btn-primary shadow-none"
                          type="submit"
                          onClick={(e) => {
                            handleRecoverPw(e);
                          }}
                        >
                          {t("buttons.continue")}
                        </a>
                      )}
                    </div>
                  </form>
                  <p className="text-muted text-2 mb-0">
                    {t("auth.return_to")}
                    <Link to={`${process.env.REACT_APP_LOCAL}/login`}>{t("login")}</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="container py-2">
              <p className="text-2 text-muted text-center mb-0">
                Copyright © 2024 <a href="#">ITwise</a>
                {t("auth.footer.line_0")}
              </p>
            </div>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="hero-mask opacity-8 bg-secondary" />
              <div
                className="hero-bg hero-bg-scroll"
                style={{ backgroundImage: 'url("/assets/img/bg2.jpg")' }}
              ></div>
              <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                <div className="row g-0 my-auto py-5">
                  <div className="col-10 col-lg-9 mx-auto">
                    <p className="text-4 text-white">
                      {" "}
                      {t("auth.reset.side.title")}
                    </p>
                    <h1 className="text-10 text-white mb-4">
                      {t("auth.reset.side.description")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
      
      </div>
    
  );
};

export default RecoverPassword;
