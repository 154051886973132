import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import countriesData from "../../../Data/Countries.json";
import "../Style/Listing.css";

import {
  defaultDateFormat,
  convertedDate,
  defaultOnlyDateFormat,
  formatDate,
  initialDateTimeFormat,
} from "../../../constants/Utils";

import DatePicker from "../../../components/reusables/DatePicker";
import DateTimePicker from "../../../components/Form/DateTimePicker";
import TimerPicker from "../../../components/Form/TimerPicker";

// import "jspdf-autotable";

import { fetchData, getLogs } from "../Services/export.service";

import ExportDateTimePicker from "../../../components/Form/ExportDateTimePicker";
import SpinnerSecondary from "../../../components/SpinnerSecondary";
import { successSwall } from "../../../components/Alerts/successAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import { fetchCalls } from "../Services/calls";
import SmsDataTable from "./SmsDataTable";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const ListingDatatable = ({ selectedList }) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    id: "",
    caller: "",
    did: "",
    duration: "",
    extension: "",
    cost: "",
    startTime: "",
    endTime: "",
  });

  const [refresh, setRefresh] = useState(0);

  const [reqBody, setReqBody] = useState({
    date_start: "",
    date_end: "",
    format: 1,
  });


  const [filtersValues, setFiltersValues] = useState({
    countries: [""],
    status: [""],
  });

  const resetFilters = () => {
    setFiltersCount(0);
    setFilters({
      id: false,
      feature: false,
      date: false,
      status: false,
      did: false,
      country: false,
      caller: false,
      duration: false,
      extension: false,
      cost: false,
    });
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
  };

  const handleReconnection = async () => {
    let filters = {
      in: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
      out: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `%${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
    };
    if (callsReqBody.feature) {
      if (callsReqBody.feature === "Inbound") {
        delete filters.out;
      } else {
        delete filters.in;
      }
    }
    try {
      const response = await fetchCalls(
        JSON.stringify({
          filters,
          pagination: {
            skip: callsReqBody.skip,
            limit: callsReqBody.limit,
            order_by: [
              {
                column: callsReqBody.item,
                direction: callsReqBody.direction,
              },
            ],
          },
          with_last_outbound_call: false,
          with_indicators: false,
          with_filters: true,
        })
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        countries: fetchedData.filters.countries,
        status: fetchedData.filters.statuses,
      }));

      setCallsReqBody((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          direction: "Inbound",
          date: data.call_date,
          status: data.status,
          did: data.did,
          country: data.country,
          caller: data.caller,
          duration: data.duration,
          user: data.extension,
          cost: data.enduser_cost,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const getCallsData = async () => {
    let filters = {
      in: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,
        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },

      out: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `%${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
    };
    if (callsReqBody.feature) {
      if (callsReqBody.feature === "Inbound") {
        delete filters.out;
      } else {
        delete filters.in;
      }
    }
    try {
      const response = await fetchCalls(
        JSON.stringify({
          filters,
          pagination: {
            skip: callsReqBody.skip,
            limit: callsReqBody.limit,
            order_by: [
              {
                column: callsReqBody.item,
                direction: callsReqBody.direction,
              },
            ],
          },
          with_last_outbound_call: false,
          with_indicators: false,
          with_filters: true,
        })
      );

      setLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        countries: fetchedData.filters.countries,
        status: fetchedData.filters.statuses,
      }));

      setCallsReqBody((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          direction: "Inbound",
          date: data.call_date,
          status: data.status,
          did: data.did,
          country: data.country,
          caller: data.caller,
          duration: data.duration,
          user: data.extension,
          cost: data.enduser_cost,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        handleReconnection();
      }
    }
  };

  useEffect(() => {
    if (refresh > 0) {
      setLoad(false);
      getCallsData();
    }
  }, [refresh]);

  const [filters, setFilters] = useState({
    id: false,
    feature: false,
    date: false,
    status: false,
    did: false,
    caller: false,
    country: false,
    caller: false,
    duration: false,
    extension: false,
    cost: false,
  });

  const [load, setLoad] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const endOfDay = new Date();

  endOfDay.setHours(23, 59, 59, 999);

  const [initialReqBody, setInitialReqBody] = useState({
    skip: 0,
    limit: 5,
    total: null,
    date_start: formatDate(currentDate),
    call_date: "",
    date_end: formatDate(currentDate),
    item: "call_date",
    direction: "desc",
    id: "",
    status: "",
    did: "",
    country: "",
    caller: "",
    duration: "",
    extension: "",
    cost: "",
    feature: "",
  });

  const [callsReqBody, setCallsReqBody] = useState({
    skip: 0,
    limit: 5,
    total: null,
    date_start: formatDate(currentDate),
    call_date: "",
    date_end: formatDate(currentDate),
    item: "call_date",
    direction: "desc",
    id: "",
    status: "",
    did: "",
    country: "",
    caller: "",
    duration: "",
    extension: "",
    cost: "",
    feature: "",
  });

  const handleTimeChange = (startDate, endDate) => {
    if (startDate) {
      if (!callsReqBody.date_start.includes(" ")) {
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_start: `${callsReqBody.date_start} ${startDate}`,
        }));
      } else {
        const [date, time] = callsReqBody.date_start.split(" ");
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_start: `${date} ${startDate}`,
        }));
      }
    }

    if (endDate) {
      if (!callsReqBody.date_end.includes(" ")) {
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_end: `${callsReqBody.date_end} ${endDate}`,
        }));
      } else {
        const [date, time] = callsReqBody.date_end.split(" ");
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_end: `${date} ${endDate}`,
        }));
      }
    }

    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const handleDateTimeFilterChange = (startDate, endDate) => {
    if (startDate) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        date_start: initialDateTimeFormat(new Date(startDate)),
      }));
    }
    if (endDate) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        date_end: initialDateTimeFormat(new Date(endDate)),
      }));
    }
    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const fetchDataDirectionFilter = (item, direction, click) => {
    if (sortByClicked != click) {
      setLoad(false);
      setSortByClicked(click);
      setCallsReqBody((prevData) => ({
        ...prevData,
        item: item,
        direction: direction,
      }));
    }
  };

  const handleInputChange = (item, value) => {
    if (callsReqBody[item] != value) {
      setLoad(false);
      setCallsReqBody((prevData) => ({ ...prevData, [item]: inputs[item] }));

      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleSelectChange = (item, value) => {
    if (callsReqBody[item] != value) {
      setLoad(false);
      setCallsReqBody((prevData) => ({ ...prevData, [item]: value }));
      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleDeleteFilter = (item, value) => {
    setLoad(false);
    setCallsReqBody((prevData) => ({ ...prevData, [item]: value }));
    setInputs((prevData) => ({ ...prevData, [item]: value }));
    setFilters((prevData) => ({ ...prevData, [item]: false }));
    setFiltersCount(filtersCount - 1);
  };

  const handleDeleteTimeFilter = () => {
    setLoad(false);
    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: initialReqBody.date_start,
      date_end: initialReqBody.date_end,
    }));

    setInputs((prevData) => ({ ...prevData, startTime: "", endTime: "" }));
    setFilters((prevData) => ({ ...prevData, date: false }));
    setFiltersCount(filtersCount - 1);
  };

  const [period, setPeriod] = useState({
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  });

  useEffect(() => {
    setLoad(false);
    selectedList === 4 && getCallsData();
  }, [
    callsReqBody.skip,
    callsReqBody.limit,
    callsReqBody.date_start,
    callsReqBody.call_date,
    callsReqBody.date_end,
    callsReqBody.id,
    callsReqBody.status,
    callsReqBody.did,
    callsReqBody.country,
    callsReqBody.caller,
    callsReqBody.duration,
    callsReqBody.extension,
    callsReqBody.cost,
    callsReqBody.item,
    callsReqBody.direction,
    callsReqBody.feature,
  ]);

  const [sortByClicked, setSortByClicked] = useState(6);

  const handleInputonChange = (value, item) => {
    setInputs((prevData) => ({ ...prevData, [item]: value }));
  };

  const callsColumns = [
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.call_id")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("id", "asc", 1);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 1 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 2 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("id", "desc", 2);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("call_id")}
            className="search-input-dt search-input-dt-id"
            value={inputs.id}
            onChange={(e) => handleInputonChange(e.target.value, "id")}
            onBlur={(e) => {
              handleInputChange("id", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.id}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.direction")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  // onClick={() => {
                  //   fetchDataDirectionFilter("direction", "asc", 3);
                  // }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 3 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 4 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                // onClick={() => {
                //   fetchDataDirectionFilter("direction", "desc", 4);
                // }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            className="search-input-dt search-input-dt-direction"
            value={callsReqBody.feature}
            onChange={(e) => {
              handleSelectChange("feature", e.target.value);
            }}
          >
            <option selected value="">
              {t("all")}
            </option>
            <option value="Inbound">{t("inbound")}</option>
            <option value="Outbound">{t("outbound")}</option>
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.direction && (
            <div className="call-direction">
              <img
                src={`/assets/media/icons/callin.svg`}
                alt=""
                className="call-direction-icon"
              />{" "}
              <span>{t(`${row.direction.toLowerCase()}`)}</span>
            </div>
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.date")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("call_date", "asc", 5);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 5 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 6 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("call_date", "desc", 6);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>

          {period.date_start != period.date_end ? (
            <DateTimePicker
              handleTimePicker={handleDateTimeFilterChange}
              start_time={inputs.startTime}
              end_time={inputs.endTime}
              changeStartTime={(value) =>
                setInputs((prevData) => ({ ...prevData, startTime: value }))
              }
              changeEndTime={(value) =>
                setInputs((prevData) => ({ ...prevData, endTime: value }))
              }
              minDate={period.date_start}
              maxDate={period.date_end}
            />
          ) : (
            <TimerPicker
              handleTimePicker={handleTimeChange}
              start_time={inputs.startTime}
              end_time={inputs.endTime}
              changeStartTime={(value) =>
                setInputs((prevData) => ({ ...prevData, startTime: value }))
              }
              changeEndTime={(value) =>
                setInputs((prevData) => ({ ...prevData, endTime: value }))
              }
            />
          )}
        </div>
      ),
      cell: (row) =>
        load ? (
          <span> {row.date && defaultDateFormat(row.date)}</span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.status")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("status", "asc", 7);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 7 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 8 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("status", "desc", 8);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            className="search-input-dt "
            value={callsReqBody.status}
            onChange={(e) => {
              handleSelectChange("status", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>

            <option value="1">{t("answered")}</option>
            <option value="2">{t("busy")}</option>
            <option value="3">{t("not_answered")}</option>
            {/* <option value="4">{t("canceled")}</option>
            <option value="10">{t("not_confirmed")}</option>
            <option value="11">{t("accepted")}</option>
            <option value="13">{t("rejected")}</option>
            <option value="14">{t("non_billable")}</option> */}
            <option value="7">{t("other")}</option>
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          <span
            className={` badge ${
              row.status === 1
                ? "badge-light-success"
                : row.status === 3
                ? "badge-light-danger"
                : row.status === 0 || row.status === 2 || row.status === 4
                ? "badge-light-warning"
                : "badge-light-neutral"
            }`}
          >
            {row.status == 0
              ? t("failed")
              : row.status == 1
              ? t("answered")
              : row.status == 2
              ? t("busy")
              : row.status == 3
              ? t("not_answered")
              : row.status == 4
              ? t("canceled")
              : row.status == 7
              ? t("not_confirmed")
              : row.status == 10
              ? t("accepted")
              : row.status == 11
              ? t("rejected")
              : row.status == 13
              ? t("non_billable")
              : row.status == 14
              ? t("other")
              : ""}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.destination")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("did", "asc", 9);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 9 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 10 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("did", "desc", 10);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className="search-input-dt"
            placeholder={t("destination")}
            value={inputs.did}
            onChange={(e) => handleInputonChange(e.target.value, "did")}
            onBlur={(e) => {
              handleInputChange("did", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.did}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.country")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("country", "asc", 11);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 11 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 12 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("country", "desc", 12);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>

          <select
            name=""
            value={callsReqBody.country}
            className="search-input-dt"
            onChange={(e) => {
              handleSelectChange("country", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>

            {filtersValues.countries.map((countryCode) => {
              const country = countriesData.find((c) => c.code === countryCode);
              return (
                <option key={countryCode} value={countryCode}>
                  {country ? country.name : countryCode}
                </option>
              );
            })}
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.country ? (
            <span>
              <div className="d-flex align-items-center">
                <div className="me-2 position-relative">
                  <img
                    className="height-1 width-1-6 obj-fit-cover flag-style"
                    alt={row.country}
                    src={`/assets/media/flags/${row.country.toLowerCase()}.svg`}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="fw-semibold fs-6 text-gray-400">
                    {t(`countries.${row.country}`)}
                  </div>
                </div>
              </div>
            </span>
          ) : (
            ""
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.caller_id")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("caller", "asc", 13);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 13 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 14 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("caller", "desc", 14);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className="search-input-dt"
            placeholder={t("caller_id")}
            value={inputs.caller}
            onChange={(e) => handleInputonChange(e.target.value, "caller")}
            onBlur={(e) => {
              handleInputChange("caller", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.caller}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.duration")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("duration", "asc", 15);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 15 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 16 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("duration", "desc", 16);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("duration")}
            className="search-input-dt"
            value={inputs.duration}
            onChange={(e) => handleInputonChange(e.target.value, "duration")}
            onBlur={(e) => {
              handleInputChange("duration", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {row.duration ? formatDuration(row.duration) : row.duration}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.user")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("extension", "asc", 17);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 17 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 18 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("extension", "desc", 18);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className="search-input-dt"
            placeholder={t("user")}
            value={inputs.extension}
            onChange={(e) => handleInputonChange(e.target.value, "extension")}
            onBlur={(e) => {
              handleInputChange("extension", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.user}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.cost")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("enduser_cost", "asc", 19);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 19 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 20 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("enduser_cost", "desc", 20);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("cost")}
            className="search-input-dt"
            value={inputs.cost}
            onChange={(e) => handleInputonChange(e.target.value, "cost")}
            onBlur={(e) => {
              handleInputChange("cost", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.cost}</span> : <div className="skeleton-dt"></div>,
    },
  ];

  const [callsData, setCallsData] = useState([
    {
      id: null,
      direction: null,
      date: null,
      status: null,
      did: null,
      country: null,
      caller: null,
      duration: null,
      user: null,
      cost: null,
    },
  ]);

  // Period filters
  // -----

  const setDateToToday = () => {
    resetFilters();
    setActiveBtn(1);
    setPeriod({
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
    });
    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));
  };

  const setDateToYesterday = () => {
    setActiveBtn(2);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    setPeriod({
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));
    resetFilters();
  };

  const setDateToCurrentMonth = () => {
    setActiveBtn(3);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setPeriod({
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    resetFilters();
  };

  const setDateToPreviousMonth = () => {
    setActiveBtn(4);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    );
    setPeriod({
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    resetFilters();
  };

  // -----

  const handleNextPage = () => {
    if (callsReqBody.skip + callsReqBody.limit < callsReqBody.total) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        skip: prevData.skip + prevData.limit,
      }));
    }
  };

  const handlePrevioustPage = () => {
    if (!callsReqBody.skip == 0) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        skip: prevData.skip - prevData.limit,
      }));
    }
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const setLimitFunction = (newLimit) => {
    return new Promise((resolve, reject) => {
      try {
        setCallsReqBody((prevData) => ({
          ...prevData,
          limit: newLimit,
          skip: 0,
        }));
        resolve("Limit set successfully");
      } catch (error) {
        reject(`Error setting limit: ${error.message}`);
      }
    });
  };

  const handleRowsPerPageChange = async (newLimit, currentPage) => {
    try {
      await setLimitFunction(newLimit);
    } catch (error) {
      console.error(error);
    }
  };

  const [activeBtn, setActiveBtn] = useState(1);

  const [filtersCount, setFiltersCount] = useState(0);

  const mapCountryCodeToName = (code) => {
    const country = countriesData.find((country) => country.code === code);
    return country ? country.name : code;
  };

  const handleClearFilters = () => {
    setCallsReqBody(initialReqBody);
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
    setFiltersCount(0);
    resetFilters();
  };

  const [modalShow, setModalShow] = useState(false);

  const exportDrawerShow = async () => {
    setModalShow(true);
  };

  const [activeExportBtn, setActiveExportBtn] = useState(1);

  const handleExportHistoryClick = () => {
    setActiveExportBtn(2);
    getLogsData();
  };

  const handleExport = async () => {
    setSpinnerLoad(true);
    try {
      const response = await fetchData(JSON.stringify(reqBody));
      setSpinnerLoad(false);
      setModalShow(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      successSwall(t("success"));
    } catch (error) {
      if (error.message == 400) {
        ErrorSwal(t("errors.400"));
      }
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const containerRef = useRef(null);

  const handleMouseLeave = (e) => {
    if (
      containerRef.current &&
      e.relatedTarget instanceof Node &&
      !containerRef.current.contains(e.relatedTarget) &&
      !isOpen
    ) {
      setModalShow(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const [logsData, setLogsData] = useState([]);

  const [logsLoading, setLogsLoading] = useState(false);

  const getLogsData = async () => {
    try {
      setLogsLoading(true);
      const response = await getLogs(JSON.stringify({ action: "EXPORT" }));
      setLogsLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();

      const mappedData = fetchedData.data.map((item) => ({
        date: item.log_date,
        status: item.status,
      }));

      setLogsData(mappedData);
    } catch (error) {
      if (error.message === "401") {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message === "403") {
        ErrorSwal(t("errors.403"));
      } else if (error.message === "404") {
        ErrorSwal(t("errors.404"));
      } else if (error.message === "405") {
        ErrorSwal(t("errors.405"));
      } else if (error.message === "422") {
        ErrorSwal(t("errors.422"));
      } else if (error.message === "423") {
        ErrorSwal(t("errors.423"));
      } else if (error.message === "406") {
        ErrorSwal(t("errors.406"));
      } else {
        getLogsDataSecondTry();
      }
    }
  };

  const getLogsDataSecondTry = async () => {
    try {
      setLogsLoading(true);
      const response = await getLogs(JSON.stringify({ action: "EXPORT" }));
      setLogsLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setLogsData(...fetchedData.data);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      <div className="nav-item ms-auto date-listing">
        <div className="d-flex align-items-center py-2">
          <span
            className={`btn btn-sm btn-flex ${
              activeBtn === 1 ? "btn-primary-active" : "period"
            } fw-bold me-3`}
            onClick={() => setDateToToday()}
          >
            {t("today")}
          </span>
          <span
            className={`btn btn-sm btn-flex ${
              activeBtn === 2 ? "btn-primary-active" : "period"
            } fw-bold me-3`}
            onClick={() => setDateToYesterday()}
          >
            {t("yesterday")}
          </span>
          <span
            className={`btn btn-sm btn-flex ${
              activeBtn === 3 ? "btn-primary-active" : "period"
            } fw-bold me-3`}
            onClick={() => setDateToCurrentMonth()}
          >
            {t("current_month")}
          </span>
          <span
            className={`btn btn-sm btn-flex ${
              activeBtn === 4 ? "btn-primary-active" : "period"
            } fw-bold me-3`}
            onClick={() => setDateToPreviousMonth()}
          >
            {t("previous_month")}
          </span>
          <span
            className={`btn btn-sm btn-flex position-relative ${
              activeBtn === 5 ? "btn-primary-active" : "period"
            } fw-bold me-3`}
          >
            {t("custom")}{" "}
            {activeBtn === 5 ? (
              <span>
                {" "}
                &#160; | &#160; {callsReqBody.date_start} -{" "}
                {callsReqBody.date_end}
              </span>
            ) : (
              ""
            )}
            <DatePicker
              onChange={(e) => {
                if (e.length > 1) {
                  setActiveBtn(5);
                  setInitialReqBody((prevData) => ({
                    ...prevData,
                    date_start: formatDate(e[0]),
                    date_end: formatDate(e[1]),
                  }));

                  setPeriod({
                    date_start: formatDate(e[0]),
                    date_end: formatDate(e[1]),
                  });

                  setCallsReqBody((prevData) => ({
                    ...prevData,
                    date_start: formatDate(e[0]),
                    date_end: formatDate(e[1]),
                  }));

                  setLoad(false);
                }
              }}
              date={[]}
            />
          </span>
        </div>
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6"
          data-kt-menu="true"
        >
          <div className="menu-item px-5">
            <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
              {t("payments")}
            </div>
          </div>
          <div className="menu-item px-5">
            <a href="#" className="menu-link px-5">
              {t("create_invoice")}
            </a>
          </div>
          <div className="menu-item px-5">
            <a href="#" className="menu-link flex-stack px-5">
              {t("create_payment")}

              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Specify a target name for future usage and reference"
              />
            </a>
          </div>
          <div
            className="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="left-start"
          >
            <a href="#" className="menu-link px-5">
              <span className="menu-title">Subscription</span>
              <span className="menu-arrow" />
            </a>
            <div className="menu-sub menu-sub-dropdown w-175px py-4">
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-5">
                  {t("apps")}
                </a>
              </div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-5">
                  {t("billings")}
                </a>
              </div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-5">
                  {t("statements")}
                </a>
              </div>
              <div className="separator my-2" />
              <div className="menu-item px-3">
                <div className="menu-content px-3">
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input w-30px h-20px"
                      type="checkbox"
                      defaultValue=""
                      name="notifications"
                      defaultChecked="checked"
                      id="kt_user_menu_notifications"
                    />
                    <span
                      className="form-check-label text-muted fs-6"
                      htmlFor="kt_user_menu_notifications"
                    >
                      {t("notifications")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="separator my-3" />
          <div className="menu-item px-5">
            <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
              {t("account")}
            </div>
          </div>
          <div className="menu-item px-5">
            <a href="#" className="menu-link px-5">
              {t("reports")}
            </a>
          </div>
          <div className="menu-item px-5 my-1">
            <a href="#" className="menu-link px-5">
              {t("account_settings")}
            </a>
          </div>
          <div className="menu-item px-5">
            <a href="#" className="menu-link text-danger px-5">
              {t("delete_customer")}
            </a>
          </div>
        </div>
      </div>

      <div
        className={`tab-pane ${
          selectedList === 4 ? "active show" : "display-none"
        }`}
        id="kt_calls"
        role="tabpanel"
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-header ">
            <div className="card-title ">
              <h2> {t("calls_list")}</h2>
            </div>
            <div className="card-toolbar m-0 d-flex">
              <div
                className="export-btn me-3"
                onClick={() => setRefresh(refresh + 1)}
              >
                <svg
                  height={14}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M3 12C3 16.9706 7.02944 21 12 21C14.3051 21 16.4077 20.1334 18 18.7083L21 16M21 12C21 7.02944 16.9706 3 12 3C9.69494 3 7.59227 3.86656 6 5.29168L3 8M21 21V16M21 16H16M3 3V8M3 8H8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                <span> {t("refresh")} </span>
              </div>
              <div className="export-btn" onClick={() => setModalShow(true)}>
              <svg height={15} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.163 2.819C9 3.139 9 3.559 9 4.4V11H7.803c-.883 0-1.325 0-1.534.176a.75.75 0 0 0-.266.62c.017.274.322.593.931 1.232l4.198 4.401c.302.318.453.476.63.535a.749.749 0 0 0 .476 0c.177-.059.328-.217.63-.535l4.198-4.4c.61-.64.914-.96.93-1.233a.75.75 0 0 0-.265-.62C17.522 11 17.081 11 16.197 11H15V4.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 0 0-.655-.656C13.861 2 13.441 2 12.6 2h-1.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zM5 21a1 1 0 0 0 1 1h12a1 1 0 1 0 0-2H6a1 1 0 0 0-1 1z" fill="currentColor"></path></g></svg>
                <span> {t("export")} </span>
              </div>

              <div
                ref={containerRef}
                className={`bg-body drawer drawer-end flex-drawer-export  drawer-gap${
                  modalShow ? " drawer-on" : ""
                }`}
              >
                <div className="d-flex align-items-center justify-content-between me-5">
                  <ul className="export-tab nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                    <li className="nav-item">
                      <span
                        className={`nav-link text-active-primary pb-4 ${
                          activeExportBtn === 1 && "active show"
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => setActiveExportBtn(1)}
                      >
                        {t("export")}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className={`nav-link text-active-primary pb-4 ${
                          activeExportBtn === 2 && "active show"
                        }`}
                        data-kt-countup-tabs="true"
                        data-bs-toggle="tab"
                        onClick={() => handleExportHistoryClick()}
                      >
                        {t("export_logs")}
                      </span>
                    </li>
                    <li className="drawer-header-bar" />
                  </ul>
                  <svg
                    onClick={() => setModalShow(false)}
                    viewBox="0 0 24 24"
                    className="cursor-pointer"
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        opacity="0.5"
                        d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22Z"
                        fill="#cbcbcb"
                      />{" "}
                      <path
                        d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0607 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                        fill="#3f4969"
                      />{" "}
                    </g>
                  </svg>
                </div>
                {activeExportBtn === 1 ? (
                  <div className="export-drawer-form">
                    <div className="export-form">
                      <form className="pw-drawer-form flex-form-export">
                        <h3>{t("export_calls_history")}</h3>
                        <div className="display-flex-inline">
                          <div className="form-label-export">
                            {t("chose_type")}
                          </div>
                          <div className="doc-items">
                            <div className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input cursor-pointer"
                                checked={reqBody.format === 1}
                                onClick={() =>
                                  setReqBody((prevData) => ({
                                    ...prevData,
                                    format: 1,
                                  }))
                                }
                                type="checkbox"
                              />
                              <label
                                className="form-check-label ps-2"
                                htmlFor="kt_settings_notification_email"
                              >
                                CSV
                              </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input cursor-pointer"
                                checked={reqBody.format === 2}
                                onClick={() =>
                                  setReqBody((prevData) => ({
                                    ...prevData,
                                    format: 2,
                                  }))
                                }
                                type="checkbox"
                              />
                              <label
                                className="form-check-label ps-2"
                                htmlFor="kt_settings_notification_email"
                              >
                                XLS
                              </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input cursor-pointer"
                                checked={reqBody.format === 3}
                                onClick={() =>
                                  setReqBody((prevData) => ({
                                    ...prevData,
                                    format: 3,
                                  }))
                                }
                                type="checkbox"
                              />
                              <label
                                className="form-check-label ps-2"
                                htmlFor="kt_settings_notification_email"
                              >
                                XLSX
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 display-flex-inline">
                          <label htmlFor="" className="form-label-export">
                            {t("calls_date")}
                          </label>
                          <ExportDateTimePicker
                            dateStartChange={(e) => {
                              setReqBody((prevData) => ({
                                ...prevData,
                                date_start: initialDateTimeFormat(new Date(e)),
                              }));
                            }}
                            dateEndChange={(e) => {
                              setReqBody((prevData) => ({
                                ...prevData,
                                date_end: initialDateTimeFormat(new Date(e)),
                              }));
                            }}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                        </div>
                        <div className="mb-4 display-flex-inline flex-end">
                          <span
                            onClick={() => handleExport()}
                            className="btn btn-sm btn-primary-active"
                          >
                            {t("export")}
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="upload-div-container">
                    {logsLoading && <SpinnerDiv />}
                    <div
                      className={`timeline h-700px ${
                        logsLoading && "disabled-event"
                      }`}
                    >
                      {logsData &&
                        logsData.map((item) => (
                          <div
                            className={`tl-content ${
                              item.status === 1
                                ? "tl-content-active"
                                : item.status === 2
                                ? "tl-content-warning"
                                : item.status === 3
                                ? "tl-content-danger"
                                : item.status === 4
                                ? "tl-content-danger"
                                : ""
                            }`}
                          >
                            <div className="tl-header">
                              <span className="tl-marker" />
                              <p className="tl-title">
                                {item.status === 1
                                  ? "The export file has been sent successfully"
                                  : item.status === 2
                                  ? "The export file sending process is in progress"
                                  : item.status === 3
                                  ? "the export file sending process is failed"
                                  : item.status === 4
                                  ? "The export file request is rejected"
                                  : ""}
                              </p>
                              <p>
                                <time className="tl-time" dateTime="2023-06-20">
                                  {defaultDateFormat(item.date)}
                                </time>
                              </p>
                            </div>
                            <div className="tl-body">
                              <p>--- Badges here ---</p>
                            </div>
                          </div>
                        ))}

                      {/* <div className="tl-content tl-content-danger">
                          <div className="tl-header">
                            <span className="tl-marker" />
                            <p className="tl-title">In egestas tempus ipsum</p>
                            <p>
                              <time className="tl-time" dateTime="2023-06-18">
                                June 18, 2023
                              </time>
                            </p>
                          </div>
                          <div className="tl-body">
                            <p>
                              Here
                            </p>
                          </div>
                        </div>

                        <div className="tl-content tl-content-warning">
                          <div className="tl-header">
                            <span className="tl-marker" />
                            <p className="tl-title">Nulla mollis lorem nec</p>
                            <p>
                              <time className="tl-time" dateTime="2023-06-14">
                                June 14, 2023
                              </time>
                            </p>
                          </div>
                          <div className="tl-body">
                            <p>
                            Here
                            </p>
                          </div>
                        </div>

                        <div className="tl-content tl-content-warning">
                          <div className="tl-header">
                            <span className="tl-marker" />
                            <p className="tl-title">Nulla mollis lorem nec</p>
                            <p>
                              <time className="tl-time" dateTime="2023-06-14">
                                June 14, 2023
                              </time>
                            </p>
                          </div>
                          <div className="tl-body">
                            <p>
                            Here
                            </p>
                          </div>
                        </div>
                        <div className="tl-content tl-content-warning">
                          <div className="tl-header">
                            <span className="tl-marker" />
                            <p className="tl-title">Nulla mollis lorem nec</p>
                            <p>
                              <time className="tl-time" dateTime="2023-06-14">
                                June 14, 2023
                              </time>
                            </p>
                          </div>
                          <div className="tl-body">
                            <p>
                            Here
                            </p>
                          </div>
                        </div> */}
                    </div>
                  </div>

                  // <div className="scroll-y my-5 px-8">
                  //   <h2>{t("export_logs")}</h2>
                  //   {logsData.map((item, index) => (
                  //     <div className="d-flex flex-stack py-4" key={index}>
                  //       <div className="d-flex align-items-center me-2">
                  //         <span className={`badge ${item.status===0?"badge-light-warning":item.status===1?"badge-light-success":item.status===2?"badge-light-warning":item.status===3?"badge-light-danger":item.status===4?"badge-light-danger":""}`}>
                  //           {item.status===0?t("requested"):item.status===1?t("sent"):item.status===2?t("processing"):item.status===3?t("failed"):item.status===4?t("rejected"):""}
                  //         </span>

                  //       </div>
                  //       <span className="badge badge-light fs-8">{defaultDateFormat(item.date)}</span>
                  //     </div>
                  //   ))}
                  // </div>
                )}
              </div>

              {modalShow && (
                <div
                  className="drawer-back"
                  onClick={() => setModalShow(false)}
                ></div>
              )}
            </div>
          </div>
          <div>
            {filtersCount > 0 && (
              <div className="all-filters p-6 pb-0">
                <div className="filters-tag">
                  <img
                    className="filters-icon"
                    src="/assets/media/icons/filters-icon.png"
                  />
                  <span className="filters-title">{t("titles.filters")}:</span>
                </div>
                <div className="filters-container">
                  {filters.id && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          className="filter-img"
                          src="/assets/media/icons/call-id-icon.png"
                        />
                        <span>
                          {t("datatable.call_id")}: {callsReqBody.id}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("id", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.feature && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/oposite-arrows.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.direction")}:{" "}
                          {callsReqBody.feature === "Inbound"
                            ? t("inbound")
                            : callsReqBody.feature === "Outbound"
                            ? t("outbound")
                            : callsReqBody.feature}
                        </span>
                        <span
                          className={`delete-filter-btn 
                        `}
                          onClick={() => handleDeleteFilter("feature", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.date && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/date-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.date")}:{" "}
                          {callsReqBody.date_start.includes(" ")
                            ? convertedDate(callsReqBody.date_start)
                            : `${defaultOnlyDateFormat(
                                callsReqBody.date_start
                              )} 00:00`}{" "}
                          {t("to")}{" "}
                          {callsReqBody.date_end.includes(" ")
                            ? convertedDate(callsReqBody.date_end)
                            : `${defaultOnlyDateFormat(
                                callsReqBody.date_end
                              )} 23:59`}
                        </span>{" "}
                        <span
                          className={`delete-filter-btn 
                         
                        `}
                          onClick={() => handleDeleteTimeFilter()}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.status && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/status-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.status")}:{" "}
                          {callsReqBody.status == 0
                            ? t("failed")
                            : callsReqBody.status == 1
                            ? t("answered")
                            : callsReqBody.status == 2
                            ? t("busy")
                            : callsReqBody.status == 3
                            ? t("not_answered")
                            : callsReqBody.status == 4
                            ? t("canceled")
                            : callsReqBody.status == 10
                            ? t("not_confirmed")
                            : callsReqBody.status == 11
                            ? t("accepted")
                            : callsReqBody.status == 13
                            ? t("rejected")
                            : callsReqBody.status == 14
                            ? t("non_billable")
                            : callsReqBody.status == 7
                            ? t("other")
                            : callsReqBody.status}
                        </span>{" "}
                        <span
                          className={`delete-filter-btn 
                        `}
                          onClick={() => handleDeleteFilter("status", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.did && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/call-in-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.destination")}: {callsReqBody.did}
                        </span>
                        <span
                          className={`delete-filter-btn  `}
                          onClick={() => handleDeleteFilter("did", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.country && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/country-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.country")}:{" "}
                          {mapCountryCodeToName(callsReqBody.country)}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("country", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.caller && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/caller-id-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.caller_id")}: {callsReqBody.caller}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("caller", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.duration && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/chrono-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.duration")}: {callsReqBody.duration}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("duration", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.extension && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/user-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.user")}: {callsReqBody.extension}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("extension", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.cost && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/cost-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.cost")}: {callsReqBody.cost}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("cost", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filtersCount > 1 && (
                    <div
                      className="clear-all"
                      onClick={() => handleClearFilters()}
                    >
                      {t("clear_all")}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="card-body p-6 position-relative">
              <div className="dt-header-color"></div>
              <div className="header-bar"></div>
              {callsReqBody.total === 0 && load && (
                <div className="empty-calls">{t("no_calls_found")}</div>
              )}

              <DataTable
                className="table align-middle table-row-dashed fs-6 fw-bold gy-5 datatable-style position-relative"
                columns={callsColumns}
                data={callsData}
                paginationPerPage={5}
                fixedHeader
                {...(callsReqBody.total > 5 && {
                  pagination: true,
                  paginationPerPage: callsReqBody.limit,
                  paginationRowsPerPageOptions: [5, 10, 50],
                  onChangeRowsPerPage: handleRowsPerPageChange,
                  paginationTotalPages: callsReqBody.total,
                })}
              ></DataTable>

              {callsReqBody.total > 5 ? (
                <div className="listing-dt-filters">
                  <span className="rows-per-page">
                    {callsReqBody.total == 0
                      ? 0
                      : callsReqBody.skip + 1
                      ? callsReqBody.skip + 1
                      : 1}
                    -
                    {callsReqBody.skip + callsReqBody.limit > callsReqBody.total
                      ? callsReqBody.total
                      : callsReqBody.skip + callsReqBody.limit}{" "}
                    {t("of")} {callsReqBody.total}
                  </span>
                  <div className="dt-filter-buttons">
                    {/* <div class="datatable-button">
                    <svg
                      className="svg-filters"
                      fill="#000000"
                      viewBox="0 0 512 512"
                      id="Layer_1"
                      version="1.1"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M297.2,478l20.7-21.6L108.7,256L317.9,55.6L297.2,34L65.5,256L297.2,478z M194.1,256L425.8,34l20.7,21.6L237.3,256 l209.2,200.4L425.8,478L194.1,256z" />
                      </g>
                    </svg>
                  </div> */}
                    <div
                      className="datatable-button"
                      onClick={handlePrevioustPage}
                    >
                      <svg
                        className="svg-filters"
                        fill={!callsReqBody.skip == 0 ? "#000000" : "#aaaaaa"}
                        height="200px"
                        width="200px"
                        version="1.1"
                        id="XMLID_54_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 24 24"
                        xmlSpace="preserve"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="previous">
                            {" "}
                            <g>
                              {" "}
                              <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                    <div class="datatable-button" onClick={handleNextPage}>
                      <svg
                        className="svg-filters"
                        fill={
                          callsReqBody.skip + callsReqBody.limit <
                          callsReqBody.total
                            ? "#000000"
                            : "#aaaaaa"
                        }
                        height="200px"
                        width="200px"
                        version="1.1"
                        id="XMLID_287_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 24 24"
                        xmlSpace="preserve"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="next">
                            {" "}
                            <g>
                              {" "}
                              <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                    {/* <div class="datatable-button">
                    <svg
                      fill="#000000"
                      className="svg-filters"
                      viewBox="0 0 512 512"
                      data-name="Layer 1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M214.78,478l-20.67-21.57L403.27,256,194.11,55.57,214.78,34,446.46,256ZM317.89,256,86.22,34,65.54,55.57,274.7,256,65.54,456.43,86.22,478Z" />
                      </g>
                    </svg>
                  </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`tab-pane ${
          selectedList === 5 ? "active show" : "display-none"
        }`}
        role="tabpanel"
      >
        <div className="card mb-6 mb-xl-9">
          <SmsDataTable
            period={period}
            date_start={callsReqBody.date_start}
            date_end={callsReqBody.date_end}
            active={selectedList === 5 ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingDatatable;
