export const LogActions = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  VERIFY: "VERIFY",
  RECOVER: "RECOVER",
  RESET: "RESET",
  GENERATE: "GENERATE",
  UPLOAD: "UPLOAD",
  EXPORT: "EXPORT"
};

export const SessionLogActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const elements = {
  5: "Contact data",
  8: "Two factor account",
  9: "Two factor code",
  10: "Two factor request",
  39: "Customer",
  40: "Customer document",
  41: "Customer payment method",
  43: "Order item",
  44: "Order resource",
  45: "Order",
  46: "Payment",
  47: "Account notification",
  48: "Account preference",
  49: "Resource credit",
  50: "Resources parametre",
  51: "Resource",
  52: "Account user",
  53: "Claim cost",
  54: "Claim",
  62: "Ticket",
  63: "Ticket comment",
  64: "Tickets attachment",
  66: "Note",
  71: "Statement",
  72: "Statement item",
  73: "Statement issue",
  81: "Customer account",
  82: "Extention"
};
