import React from "react";
import Flatpickr from "react-flatpickr";

const DatePickerSingle = (
    {  onChange,
        date,
    }
) => {

  return (
    <div className="date-picker-btn">
    <Flatpickr
      options={{
        closeOnSelect: true,
        mode:"single" ,
   
      }}
      onChange={onChange}
      value={date}
    
    />
  </div>
  )
}

export default DatePickerSingle