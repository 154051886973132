const twoFactAuth = async (code) => {
  return await fetch(`${process.env.REACT_APP_BASE_URL}/tools/2fa/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      code: parseInt(code)
    }),
  });
};




export default twoFactAuth;
