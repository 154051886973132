import React, { useEffect } from "react";
import ListNumbers from "./Views/ListNumbers";
import EmptyNumbers from "./Views/EmptyNumbers"
import AllocateUsersModal from "../../components/Modals/AllocateUsersModal";
import AddOutboundCallingScopeModal from "../../components/Modals/AddOutboundCallingScopeModal";
import AddPaymentModal from "../../components/Modals/AddPaymentModal";
import AddOutboundCallingCreaditModal from "../../components/Modals/AddOutboundCallingCreaditModal";
import CallsAndSmsModal from "../../components/Modals/CallsAndSmsModal";
import SearchUsersModal from "../../components/Modals/SearchUsersModal";

const MainNumbers = () => {

  useEffect(() => {
    localStorage.setItem("active", 2);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  return (
    <div>
      <ListNumbers />
      {/* <EmptyNumbers/> */}

      {/* Modals */}
      {/* <AllocateUsersModal />
      <AllocateUsersModal />
      <AddOutboundCallingScopeModal />
      <AddPaymentModal />
      <AddOutboundCallingCreaditModal />
      <CallsAndSmsModal />
      <SearchUsersModal/> */}
    </div>
  );
};

export default MainNumbers;
