import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../Style/support.css";
import CreateTicketModal from "./Modals/CreateTicketModal";
import getOverview from "../Services/getOverview";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Header = ({ active, addTicket }) => {
  const [search, setSearch] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const { t } = useTranslation();

  const [fetchCount, setFetchCount] = useState(0);

  const fetchOverview = async () => {
    try {
      const response = await getOverview(search);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      if (result.data.tickets.length > 0) {
        const newData = [];
        result.data.tickets.map((item) =>
          newData.push({
            reclamation: item.subject,
            class: item.class,
            answer: item.content,
          })
        );

      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchOverview();
      }
    }
  };

  const refetchOverview = async () => {
    try {
      const response = await getOverview(search);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
    } catch (error) {
      InternalErrorSwal();
    }
  };

  // useEffect(() => {
  //   fetchOverview();
  // }, [fetchCount]);

  return (
    <div className="card-body flex-column p-5">
      {/* <div className="d-flex align-items-center h-lg-300px p-5 p-lg-15">
        <div className="d-flex flex-column align-items-start justift-content-center flex-equal me-5">
          <h1 className="fw-bold fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-10">
            {t("how_can_we_help")}?
          </h1>
          <div className="position-relative w-100">
            <span className="svg-icon svg-icon-2 svg-icon-primary position-absolute top-50 translate-middle ms-8">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height={2}
                  rx={1}
                  transform="rotate(45 17.0365 15.1223)"
                  fill="currentColor"
                />
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <input
              type="text"
              className="form-control fs-4 py-4 ps-14 text-gray-700 placeholder-gray-400 mw-500px"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={() => setFetchCount(fetchCount + 1)}
              defaultValue=""
              placeholder={t("ask_a_question")}
            />
          </div>
        </div>
        <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
          <img
            src="assets/media/illustrations/sketchy-1/20.png"
            alt=""
            className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
          />
        </div>
      </div> */}
      <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5">
        <ul className="nav flex-wrap border-transparent fw-bold">
          <li className="nav-item my-1">
            <Link
              className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                active === 1 ? "active" : ""
              }`}
              to="/support"
            >
              {t("overview")}
            </Link>
          </li>
          <li className="nav-item my-1">
            <Link
              className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                active === 2 ? "active" : ""
              }`}
              to="/support_tickets"
            >
              {t("tickets")}
            </Link>
          </li>
          <li className="nav-item my-1">
            <Link
              className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                active === 3 ? "active" : ""
              }`}
              to="/support_tutorials"
            >
              {t("tutorials")}
            </Link>
          </li>
          <li className="nav-item my-1">
            <Link
              className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                active === 4 ? "active" : ""
              }`}
              to="/support_faq"
            >
              {t("faq")}
            </Link>
          </li>
          <li className="nav-item my-1">
            <Link
              className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                active === 5 ? "active" : ""
              }`}
              to="/support_contact"
            >
              {t("contact_us")}
            </Link>
          </li>
        </ul>
        <span
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_new_ticket"
          className="btn btn-primary-active fw-bold fs-8 fs-lg-base"
          onClick={() => setModalShow(true)}
        >
          {t("create_ticket")}
        </span>
      </div>
      {modalShow && (
        <CreateTicketModal
          closeModal={() => setModalShow(false)}
          addTicket={addTicket}
        />
      )}
    </div>
  );
};

export default Header;
