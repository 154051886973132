const createTicket = async (body) => {


    const formData = new FormData();
    formData.append("service_id", body.service_id);
    formData.append("class", body.class);
    formData.append("subject", body.subject);
    formData.append("priority", body.priority);
    formData.append("reference", body.reference);
    formData.append("content", body.content);
    formData.append("status", 2);
    formData.append("files['TICKETS_ATTACHMENT']", body.attachement);


    return await fetch(`${process.env.REACT_APP_BASE_URL}/support/tickets/create`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    });
  };
  
  export default createTicket;
  