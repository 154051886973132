import React from 'react'
import SpinnerDiv from '../../../Layouts/SpinnerDiv'
import Chart from 'react-google-charts'

const NumbersChart = ({data, load}) => {


let maximum = 3;

if (data) {
  for (let i = 1; i < data.length; i++) {
    let call = data[i];
    if (call[1] > maximum) {
      maximum = call[1];
    }
    if (call[2] > maximum) {
        maximum = call[2];
      }
  }
}

const options = {
    vAxis: {viewWindow: { min: 0, max: maximum<9?maximum:maximum+4 } },curveType: "function",
  }
  return (
     <div>
    {!load ? (
      <SpinnerDiv/>
    ) : (
      <Chart
        chartType="LineChart"
        width="100%"
        height="300px"
        data={data}
        options={options}
      />
    )}
  </div>
  )
}

export default NumbersChart