import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

function DateTimePicker({
  handleTimePicker,
  start_time,
  end_time,
  changeStartTime,
  changeEndTime,
  minDate,
  maxDate,
}) {
  const { t } = useTranslation();
  const [minEndDate, setMinEndDate] = useState(null);

  const handleStartDateChange = (selectedDates) => {
    const newMinEndDate = new Date(selectedDates[0]);
    newMinEndDate.setHours(newMinEndDate.getHours() + 1);
    setMinEndDate(newMinEndDate);

    changeStartTime(selectedDates);
  };

  const handleEndDateChange = (selectedDates) => {
    changeEndTime(selectedDates);
  };

  const timeFormat = JSON.parse(localStorage.getItem("data")).time_format;
  const timeFormatType = timeFormat.charAt(timeFormat.length - 1) === "a";


  return (
    <div className="dateTimePickerFlex">
      <Flatpickr
        onChange={handleStartDateChange}
        value={start_time}
        options={{
          enableTime: true,
          dateFormat: "d/m H:i",
          locale: "default",
          minDate: new Date(minDate),
          maxDate: new Date(maxDate),
          time_24hr: timeFormatType ? false : true,
        }}
        placeholder={t("start")}
      />

      <Flatpickr
        onChange={handleEndDateChange}
        value={end_time}
        options={{
          enableTime: true,
          dateFormat: "d/m H:i",
          locale: "default",
          minDate: minEndDate,
          maxDate: new Date(maxDate),
          time_24hr: timeFormatType ? false : true,
        }}
        placeholder={t("end")}
      />
      {(start_time || end_time) && (
        <span
          className="submit-time-picker"
          onClick={() => handleTimePicker(start_time, end_time)}
        >
          <img
            src="/assets/media/icons/submit-search.png"
            className="search-png"
          />
        </span>
      )}
    </div>
  );
}

export default DateTimePicker;
