import React from 'react'
import { Chart } from "react-google-charts";


const PieChart = ({data}) => {

    
     const options = {
        sliceVisibilityThreshold: 0.2, 
      };

      


  return (
    <Chart
    chartType="PieChart"
    width="100%"
    data={data}
    options={options}
  />
  )
}

export default PieChart