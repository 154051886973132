import Swal from "sweetalert2";

const ErrorExpiredSession = (title, message, login) => {
  const handleConfirm = () => {
    window.location.reload();
    var lang = localStorage.getItem("i18nextLng");
    localStorage.clear();
    localStorage.setItem("i18nextLng", lang);
  };

  Swal.fire({
    icon: "warning",
    title: title,
    text: message,
    confirmButtonText: login,
    customClass: {
      container: 'session-expired-swal-container' 
    }
  }).then(handleConfirm);
};

export default ErrorExpiredSession;
