const getSmsService = async (body, skip, limit, sort, orderBy) => {
  return await fetch(
    `${process.env.REACT_APP_CONVERSATIONS_URL}/api/v1/messages/app?offset=${skip}&limit=${limit}&with_connections=true&sort=${sort}&orderBy=${orderBy==="senderPhoneNumber"?"source.code":orderBy==="did"?"contact":orderBy}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "TOKEN-ID": process.env.REACT_APP_MESSAGES_TOKEN,
      },
      body: JSON.stringify({
        customer_id: localStorage.getItem("customer_id"),
        country: body.country,
        contact: body.did,
        date_start: body.date_start,
        date_end: body.date_end,
        sender_phone_number: body.sender,
        direction: body.direction,
        id: body.id,
        status: body.status,
      }),
    }
  );
};

export default getSmsService;
