import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/fr.js";

const DateTimePicker = ({ onChange, date, minDate, maxDate }) => {
  const language = localStorage.getItem("i18nextLng").split("-")[0];
  return (
    <div className="date-picker-profile">
      <Flatpickr
        options={{
          closeOnSelect: true,
          mode: "single",
          locale: language,
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          minDate: minDate && minDate,
          maxDate: maxDate && maxDate,
        }}
        onChange={onChange}
        value={date}
      />
    </div>
  );
};

export default DateTimePicker;
