import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import currencies from "../Data/Preferences/CurrenciesFile.json"

const locales = { enUS, fr };

export const getLocale = (language) => {
  let locale;

  if (language.toLowerCase().includes("fr")) {
    locale = locales.fr;
  } else if (language.toLowerCase().includes("en")) {
    locale = locales.enUS;
  } else {
    locale = locales.enUS;
  }
  return locale;
};


let dateFormat = JSON.parse(localStorage.getItem("data"))
  ? JSON.parse(localStorage.getItem("data")).date_format
  : "";
let languagePref = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "";

let locale = getLocale(languagePref);

export const updateLang = (lang) => {
  languagePref = lang;
  locale = getLocale(languagePref);
};

let timeFormat = localStorage.getItem("data")
  ? JSON.parse(localStorage.getItem("data")).time_format
  : "";

export const updateDateFormatAndTimeFormat = (
  date_format,
  time_format,
  time_zone,
  currency_format
) => {
  dateFormat = date_format;
  timeFormat = time_format;
  timeZone = time_zone;
  currencyFormat = currency_format
};

let currencyFormat = JSON.parse(localStorage.getItem("data"))
? JSON.parse(localStorage.getItem("data")).currency_format
: ""



export const convertCurrency = (currency)=>{ 

  try {
    if(!currencyFormat){
      throw new Error("")
    }
    return currencies[currency]?currencies[currency][currencyFormat]:currencies["USD"].currencyFormat;
  } catch (error) {
    return currencies[currency].isoCode
  }
  
}




let timeZone = JSON.parse(localStorage.getItem("data"))
  ? JSON.parse(localStorage.getItem("data")).timezone
  : "";

export const defaultDateFormat = (date) => {

  try {
    const convertedDate = convertUTCtoUserTime(date, timeZone);

    let finalDate = "";
    if (dateFormat != "null" && timeFormat != "null") {
      finalDate = format(
        new Date(convertedDate),
        dateFormat + " " + timeFormat,
        {
          locale,
        }
      );
    } else if (dateFormat != "null") {

      finalDate = format(new Date(convertedDate), dateFormat + " " + "HH:mm", {
        locale,
      });
      
    } else if (timeFormat != "null") {
      finalDate = format(
        new Date(convertedDate),
        "yyyy-MM-dd" + " " + timeFormat,
        {
          locale,
        }
      );
    } else {
      finalDate = format(
        new Date(convertedDate),
        "yyyy-MM-dd" + " " + "HH:mm",
        {
          locale,
        }
      );
    }

    return finalDate;
  } catch (error) {
    return format(new Date(date), "yyyy-MM-dd" + " " + "HH:mm", {
      locale,
    });
  }
};

export const convertedDate = (date) => {
  try {
    const finalDate = format(
      new Date(convertedDate),
      dateFormat + " " + timeFormat,
      {
        locale,
      }
    );
    return finalDate;
  } catch (error) {
    return format(new Date(date), "yyyy-MM-dd" + " " + "HH:mm", {
      locale,
    });
  }
};

export const defaultOnlyDateFormat = (date) => {
  try {
    return format(new Date(date), dateFormat, {
      locale,
    });
  } catch (error) {
    return format(new Date(date), "yyyy-MM-dd", {
      locale,
    });
  }
};

export const initialDateFormat = (date) => {
  return format(date, "yyyy-MM-dd", locale);
};

export const initialDateTimeFormat = (date) => {
  return format(date, "yyyy-MM-dd HH:mm");
};

export const safeDateFormat = (date, formatString, locale) => {
  try {
    return format(date, formatString, locale);
  } catch (error) {
    return format(date, "yyyy-MM-dd", locale);
  }
};

export const formatDate = (date) => {
  if (!date) return;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const convertUTCtoUserTime = (utcDateTime, userTimezoneOffset) => {
  const utcDate = new Date(utcDateTime);

  const [hoursStr, minutesStr] = userTimezoneOffset?.split(":");
  const userOffsetHours = parseInt(hoursStr, 10) || 0;
  const userOffsetMinutes = parseInt(minutesStr, 10) || 0;

  const totalUserOffsetMinutes = userOffsetHours * 60 + userOffsetMinutes;
  const userTime = new Date(
    utcDate.getTime() + totalUserOffsetMinutes * 60 * 1000
  );

  return userTime.toISOString();
};

export const formatedDate = (date, plus = 0) => {
  if (!date) return;

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = (date.getDate() + plus).toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const convertToUserTime = (utcDateTime, plus) => {
  try {
    const utcDate = new Date(utcDateTime);
    const [hoursStr, minutesStr] = timeZone?.split(":");
    const userOffsetHours = parseInt(hoursStr, 10) || 0;
    const userOffsetMinutes = parseInt(minutesStr, 10) || 0;

    const totalUserOffsetMinutes = userOffsetHours * 60 + userOffsetMinutes;
    const userTime = new Date(
      utcDate.getTime() + totalUserOffsetMinutes * 60 * 1000
    );

    const convertedDate = formatedDate(userTime, plus);

    return convertedDate;
  } catch (error) {
    return utcDateTime;
  }
};

export const formatter = (currency) =>
   new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (str) => {
  switch (str?.toLowerCase()) {
    case "eur":
      return "€";

    case "usd":
      return "$";

    default:
      return str;
  }
};

export const checkValidPDF = (str) => {
  return str.split(".").at(-1) == "pdf";
};
