import React from "react";

function DataTableTextFilter({ handleInputChange, column, placeholder }) {
  return (
    <input
      placeholder={placeholder}
      className="search-input-dt "
      onKeyDown={(e) => {
        if (e.code.includes("Enter")) {
          handleInputChange(column, e.target.value);
        }
      }}
      onBlur={(e) => {
        handleInputChange(column, e.target.value ?? null);
      }}
    ></input>
  );
}

export default DataTableTextFilter;
