import React from 'react'

const AddOutboundCallingCreaditModal = () => {
  return (
    <div
  className="modal fade"
  id="kt_modal_add_outbound_credit"
  tabIndex={-1}
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog mw-850px">
    <form className="modal-content" id="">
      <div className="modal-header py-4">
        <h3 className="text-white">
          Add Outbound Calling Credit
          <div className="d-flex flex-wrap fw-semibold fs-6 pt-1">
            <div
              href="javascript:"
              className="d-flex align-items-center text-gray-900 me-5"
            >
              <span className="svg-icon svg-icon-4 me-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div
              href="javascript:"
              className="d-flex align-items-center text-gray-900 me-5"
            >
              <div className="symbol symbol-20px symbol-circle me-1">
                <img
                  alt="Pic"
                  src="assets/media/flags/gb.svg"
                  data-number-country-image=""
                  className="w-15px"
                />
              </div>
              <span data-number-country="" />
            </div>
            <div
              href="javascript:"
              className="d-flex align-items-center text-gray-900"
              data-number-status=""
            >
              <span className="svg-icon svg-icon-4 me-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={10}
                    fill="currentColor"
                  />
                  <path
                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
        </h3>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          <span className="svg-icon svg-icon-1">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="currentColor"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="modal-body scroll-y">
        <div className="w-100 mb-7">
          <label className="form-label required">Calling credit</label>
          <select
            name="business_activity"
            className="form-select form-select-sm form-select-solid"
            data-control="select2"
            data-placeholder="Select..."
            data-allow-clear="true"
            data-hide-search="true"
          >
            <option />
            <option value={1}>10 EUR</option>
            <option value={1}>25 EUR</option>
            <option value={2}>50 EUR</option>
            <option value={3}>100 EUR</option>
            <option value={4}>250 EUR</option>
            <option value={5}>500 EUR</option>
          </select>
        </div>
        <div className="w-100 mb-7">
          <div className="d-flex flex-column mb-5 fv-row rounded-3 p-7 border border-dashed border-gray-300">
            <div className="fs-5 fw-bold form-label mb-3">
              Usage treshold
              <i
                tabIndex={0}
                className="cursor-pointer fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="popover"
                data-bs-trigger="hover focus"
                data-bs-html="true"
                data-bs-delay-hide={1000}
                data-bs-content="Thresholds help manage risk by limiting the unpaid usage balance a customer can accrue. Thresholds only measure and bill for metered usage (including discounts but excluding tax). <a href='#'>Learn more</a>."
                data-kt-initialized={1}
              />
            </div>
            <label className="form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked="checked"
                defaultValue={1}
              />
              <span className="form-check-label text-gray-600">
                Bill immediately if usage treshold reaches 80%.
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="modal-footer py-4">
        <div className="w-100 d-flex flex-end">
          <span className="svg-icon svg-icon-3 ms-1 me-0">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x={11}
                y={18}
                width={12}
                height={2}
                rx={1}
                transform="rotate(-90 11 18)"
                fill="currentColor"
              />
              <rect
                x={6}
                y={11}
                width={12}
                height={2}
                rx={1}
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

  )
}

export default AddOutboundCallingCreaditModal