import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import TextArea from "antd/es/input/TextArea";
import ErrorSwal from "../../.././../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../.././../components/Alerts/errorExpiredSession";
import getServices from "../../Services/getServices";
import createTicket from "../../Services/createTicket";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const CreateTicketModal = ({ closeModal, addTicket }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const fetchServices = async () => {
    try {
      setLoading(true);
      const response = await getServices();
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      let array = [];
      result.data.forEach((element) =>
        array.push({
          id: element.id,
          name: element.name,
          reference: element.reference,
        })
      );
      setServices(array);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchServices();
      }
    }
  };

  const refetchServices = async () => {
    try {
      setLoading(true);
      const response = await getServices();
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      let array = [];
      result.data.forEach((element) =>
        array.push({
          id: element.id,
          name: element.name,
          reference: element.reference,
        })
      );
      setServices(array);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const handleCreateTicket = async () => {
    try {
      setLoading(true);
      const response = await createTicket(body);
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();


      const newLine = {
        id: result.data[0].id,
        name: result.data[0].reference,
        class: result.data[0].class,
        subject: result.data[0].subject,
        status: result.data[0].status,
        content: result.data[0].content,
        priority: result.data[0].priority,
        created_at: result.data[0].created_at,
        updated_at: result.data[0].updated_at,
        conversation: result.data[0].comment
      };


      addTicket && addTicket(newLine);

      successSwall(t("ticket_created"));
      closeModal();
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const [services, setServices] = useState([]);

  const [menuOpen, setMenuOpen] = useState({
    types: false,
    services: false,
    priority: false,
  });

  useEffect(() => {
    fetchServices();
  }, []);

  const [body, setBody] = useState({
    service_id: null,
    class: null,
    subject: "",
    priority: 3,
    reference: "",
    content: "",
    attachement: null,
  });

  const changeBody = (item, value) => {
    setBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const ticket_types = [
    { id: 1, name: t("tech_issues") },
    { id: 2, name: t("billing_text") },
    { id: 3, name: t("prod_inquiries") },
    { id: 4, name: t("feedback") },
  ];

  const priorities = [
    { id: 1, name: t("hight") },
    { id: 2, name: t("medium") },
    { id: 3, name: t("low") },
  ];

  return (
    <div>
      <div className={`modal-container allocate-user-modal fade-in-down`}>
        {loading && <SpinnerDiv />}
        <div className={`modal-show ${loading && "disabled-event"}`}>
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("create_ticket")}</h3>
            </div>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={closeModal}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body p-6">
            <div className={`line-divs-container mb-10`}>
              <div className="w-50">
                <div className="pb-3">
                  <label className="form-label mb-2 required">
                    <span>{t("type")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <div
                        className={`menu-container position-relative`}
                        onClick={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            types: !prevData.types,
                          }))
                        }
                        onMouseLeave={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            types: false,
                          }))
                        }
                      >
                        <span>
                          {!body.class
                            ? t("chose_a_type")
                            : ticket_types.find(
                                (item) => item.id === body.class
                              ).name}
                        </span>

                        <svg
                          className="svg-menu-currency"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                              fill="#727272"
                            />
                          </g>
                        </svg>

                        {menuOpen.types && (
                          <div className="mh-250px scroll-y">
                            {ticket_types.map((item, key) => (
                              <span
                                key={key}
                                onClick={() => {
                                  changeBody("class", item.id);
                                }}
                              >
                                {item.name}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`w-50 ${!body.class && "disabled-event"}`}>
                <div className="pb-3">
                  <label className="form-label mb-2 required">
                    <span>{t("service")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <div
                        className={`menu-container position-relative`}
                        onClick={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            services: !prevData.services,
                          }))
                        }
                        onMouseLeave={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            services: false,
                          }))
                        }
                      >
                        <span>
                          {!body.service_id
                            ? t("chose_a_service")
                            : services.find(
                                (item) => item.id === body.service_id
                              ).name}
                        </span>

                        <svg
                          className="svg-menu-currency"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                              fill="#727272"
                            />
                          </g>
                        </svg>

                        {menuOpen.services && (
                          <div className="mh-250px scroll-y">
                            {services.map((item, key) => (
                              <span
                                key={key}
                                onClick={() => {
                                  changeBody("service_id", item.id);
                                  changeBody("reference", item.reference);
                                }}
                              >
                                {item.name}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`line-divs-container mb-10 ${
                !body.service_id && "disabled-event"
              }`}
            >
              <div className="w-34">
                <div className="pb-3">
                  <label className="form-label mb-2 required">
                    <span>{t("subject")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <input
                        type="text"
                        className={`form-control form-control-sm form-control-solid`}
                        onChange={(e) => changeBody("subject", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={`w-34 ${!body.service_id && "disabled-event"}`}>
                <div className="pb-3">
                  <label className="form-label mb-2 required">
                    <span>{t("priority")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <div
                        className={`menu-container position-relative`}
                        onClick={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            priority: !prevData.priority,
                          }))
                        }
                        onMouseLeave={() =>
                          setMenuOpen((prevData) => ({
                            ...prevData,
                            priority: false,
                          }))
                        }
                      >
                        <span>
                          {!body.priority
                            ? t("priority")
                            : priorities.find(
                                (item) => item.id === body.priority
                              ).name}
                        </span>

                        <svg
                          className="svg-menu-currency"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                              fill="#727272"
                            />
                          </g>
                        </svg>

                        {menuOpen.priority && (
                          <div className="mh-250px scroll-y">
                            {priorities.map((item, key) => (
                              <span
                                key={key}
                                onClick={() => {
                                  changeBody("priority", item.id);
                                }}
                              >
                                {item.name}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`w-34 ${!body.subject && "disabled-event"}`}>
                <div className="pb-3">
                  <label className="form-label mb-2">
                    <span>{t("attachement")}</span>
                  </label>
                </div>

                <div className="fv-row">
                  <div
                    className={`upload-receipt m-w-50 cursor-pointer position-relative ${
                      !body.subject && "disabled-event"
                    }`}
                  >
                    
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                          fill="currentColor"
                        ></path>{" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 10.4697C12.3897 10.329 12.1989 10.25 12 10.25C11.8011 10.25 11.6103 10.329 11.4697 10.4697L8.96967 12.9697C8.67678 13.2626 8.67678 13.7374 8.96967 14.0303C9.26256 14.3232 9.73744 14.3232 10.0303 14.0303L11.25 12.8107V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.8107L13.9697 14.0303C14.2626 14.3232 14.7374 14.3232 15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697L12.5303 10.4697Z"
                          fill="currentColor"
                        ></path>{" "}
                      </g>
                    </svg>
                    <span>{t("upload_file")}</span>

                    <input
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      className=" opacity-0 position-absolute w-100 cursor-pointer"
                      onChange={(e) => {
                        changeBody("attachement", e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`line-divs-container ${
                !body.subject && "disabled-event"
              }`}
            >
              <div className="w-100">
                <div className="pb-3">
                  <label className="form-label mb-2 required">
                    <span>{t("message")}</span>
                  </label>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="d-flex gap-2">
                      <TextArea
                        type="text"
                        className={`form-control form-control-sm form-control-solid`}
                        onChange={(e) => changeBody("content", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`d-flex justify-content-end p-6 footer-border-top`}>
            <SecondaryButton title={t("cancel")} onClick={closeModal} />
            <button
              type="submit"
              className={`btn btn-primary-active btn-sm ${
                !body.content && "disabled-event"
              }`}
              onClick={() => confirmationSwal(handleCreateTicket)}
            >
              <span> {t("submit")}</span>
            </button>
          </div>
        </div>
      </div>

      <div className="modal-hide-div" onClick={closeModal}></div>
    </div>
  );
};

export default CreateTicketModal;
