// Libraries
// import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";

// Dashboard

import Header from "./Header";
import Footer from "./Footer";
import AllRoutes from "../routes/AllRoutes";

const Main = () => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    (async () => {
      await Promise.all([import("./CSS/Header.css")
    ]);
      setLoad(true);
    })();
  }, []);

  return (
    <div>
      {load && (
        <div>
          {" "}
          <Header />
          <AllRoutes />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Main;
