import React from 'react';

const SpinnerSecondary = () => {


  return (
    
    <div className="preloader low-opacity" style={{ display: 'block'  }}>
      <div className="lds-ellipsis" style={{ display: 'block'}}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default SpinnerSecondary;
