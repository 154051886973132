import React from "react";
import { TimePicker } from "antd";
import { useTranslation } from "react-i18next";

const TimerPicker = ({ handleTimePicker, start_time, end_time, changeStartTime, changeEndTime }) => {
  const { t } = useTranslation();



  const handleStartTimeChange = (time) => {
    changeStartTime(time);
    if (end_time && time.isAfter(end_time)) {
      changeEndTime(null);
    }
  };

  const handleEndTimeChange = (time) => {
    changeEndTime(time);
  };

  return (
    <div className="timePickerFlex">
      <TimePicker
        onChange={handleStartTimeChange}
        value={start_time}
        format="HH:mm"
        placeholder={t("start")}
      />
      <TimePicker
        onChange={handleEndTimeChange}
        value={end_time}
        format="HH:mm"
        placeholder={t("end")}
        disabledHours={() => {
          if (start_time) {
            return Array.from({ length: start_time.hour() }, (_, i) => i);
          }
        }}
        disabledMinutes={(selectedHour) => {
          if (start_time && selectedHour === start_time.hour()) {
            return Array.from({ length: start_time.minute() }, (_, i) => i);
          }
        }}
      />
      {(start_time || end_time) && (
        <span
          className="submit-time-picker"
          onClick={() =>
            handleTimePicker(
              start_time && start_time.format("HH:mm"),
              end_time && end_time.format("HH:mm")
            )
          }
        >
          <img
            src="/assets/media/icons/submit-search.png"
            className="search-png"
          />
        </span>
      )}
    </div>
  );
};

export default TimerPicker;
