import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import countriesData from "../../../Data/Countries.json";
import "../Style/Listing.css";

import {
  defaultDateFormat,
  convertedDate,
  defaultOnlyDateFormat,
  formatDate,
  initialDateTimeFormat,
} from "../../../constants/Utils";
import getSmsService from "../Services/getSmsService";
import DateTimePicker from "../../../components/Form/DateTimePicker";
import TimerPicker from "../../../components/Form/TimerPicker";

// import { fetchData, getLogs } from "../Services/export.service";

import SpinnerSecondary from "../../../components/SpinnerSecondary";
// import { successSwall } from "../../../components/Alerts/successAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import SmsContentModal from "./SmsContentModal";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const SmsDataTable = ({ date_start, date_end, active }) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    id: "",
    senderPhoneNumber: "",
    did: "",
    duration: "",
    extension: "",
    cost: "",
    startTime: "",
    endTime: "",
  });

  const [refresh, setRefresh] = useState(0);

  // const [reqBody, setReqBody] = useState({
  //   date_start: "",
  //   date_end: "",
  //   format: 1,
  // });

  const [filtersValues, setFiltersValues] = useState({
    countries: [""],
    status: [""],
  });

  const resetFilters = () => {
    setFiltersCount(0);
    setFilters({
      id: false,
      feature: false,
      date: false,
      status: false,
      did: false,
      country: false,
      caller: false,
      duration: false,
      extension: false,
      cost: false,
    });
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
  };

  const [selectOptions, setSelectOptions] = useState({
    status: [],
    countries: [],
  });

  const handleReconnection = async () => {
    try {
      setLoad(false);
      const response = await getSmsService(
        body,
        body.skip,
        body.limit,
        body.sort,
        body.orderBy
      );

      setLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        countries: fetchedData.filters.countries,
        status: fetchedData.filters.statuses,
      }));

      setBody((prevData) => ({
        ...prevData,
        total: fetchedData.data.total,
      }));

      if (fetchedData.data.total) {
        const mappedData = fetchedData.data.messages.map((data) => ({
          id: data.id,
          direction: "Inbound",
          date: data.dateInit,
          status: data.status,
          did: data.did,
          country: data.country,
          caller: data.caller,
          duration: data.duration,
          user: data.extension,
          cost: data.enduser_cost,
          message: data.content,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const [body, setBody] = useState({
    date_start: date_start,
    date_end: date_end,
    status: null,
    direction: null,
    id: null,
    country: null,
    skip: 0,
    limit: 5,
    total: null,
    sort: "DESC",
    orderBy: "dateInit",
    did: "",
    senderPhoneNumber: "",
    start: 1,
    end: 5
  });

  const getSMSData = async () => {
    try {
      setLoad(false);
      const response = await getSmsService(
        body,
        body.skip,
        body.limit,
        body.sort,
        body.orderBy
      );

      setLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        // countries: fetchedData.filters.countries,
        // status: fetchedData.filters.statuses,
      }));

      setBody((prevData) => ({
        ...prevData,
        total: fetchedData.data.total,
      }));

      setSelectOptions({
        status: fetchedData.data.status,
        countries: fetchedData.data.countries,
      });

      if (fetchedData.data.total) {
        const mappedData = fetchedData.data.messages.map((data) => ({
          id: data.id,
          direction: data.direction,
          date: data.dateInit,
          status: data.status,
          did: data.contact,
          country: data.country,
          caller: data.sourceNumber,
          user: data.extension,
          cost: data.enduser_cost,
          message: data.content,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        handleReconnection();
      }
    }
  };







  useEffect(() => {
    if (refresh > 0) {
      getSMSData();
    }
  }, [refresh]);

  const [filters, setFilters] = useState({
    id: false,
    feature: false,
    date: false,
    status: false,
    did: false,
    senderPhoneNumber: false,
    country: false,
    caller: false,
    duration: false,
    extension: false,
    cost: false,
  });

  const [load, setLoad] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const endOfDay = new Date();

  endOfDay.setHours(23, 59, 59, 999);

  const [initialReqBody, setInitialReqBody] = useState({
    date_start: date_start,
    date_end: date_end,
    status: null,
    direction: null,
    id: null,
    country: null,
    skip: 0,
    limit: 5,
    total: null,
    sort: "DESC",
    orderBy: "dateInit",
    did: "",
    senderPhoneNumber: "",
  });

  const handleTimeChange = (startDate, endDate) => {
    if (startDate) {
      if (!body.date_start.includes(" ")) {
        setBody((prevData) => ({
          ...prevData,
          date_start: `${body.date_start} ${startDate}`,
        }));
      } else {
        const [date, time] = body.date_start.split(" ");
        setBody((prevData) => ({
          ...prevData,
          date_start: `${date} ${startDate}`,
        }));
      }
    }

    if (endDate) {
      if (!body.date_end.includes(" ")) {
        setBody((prevData) => ({
          ...prevData,
          date_end: `${body.date_end} ${endDate}`,
        }));
      } else {
        const [date, time] = body.date_end.split(" ");
        setBody((prevData) => ({
          ...prevData,
          date_end: `${date} ${endDate}`,
        }));
      }
    }

    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const handleDateTimeFilterChange = (startDate, endDate) => {
    if (startDate) {
      setBody((prevData) => ({
        ...prevData,
        date_start: initialDateTimeFormat(new Date(startDate)),
      }));
    }
    if (endDate) {
      setBody((prevData) => ({
        ...prevData,
        date_end: initialDateTimeFormat(new Date(endDate)),
      }));
    }
    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const fetchDataDirectionFilter = (item, direction, click) => {
    if (sortByClicked != click) {
      setSortByClicked(click);
      setBody((prevData) => ({
        ...prevData,
        orderBy: item,
        sort: direction,
      }));
    }
  };

  const handleInputChange = (item, value) => {
    if (body[item] != value) {
      setBody((prevData) => ({ ...prevData, [item]: inputs[item] }));

      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleSelectChange = (item, value) => {
    if (body[item] != value) {
      setLoad(false);
      setBody((prevData) => ({ ...prevData, [item]: value }));
      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleDeleteFilter = (item, value) => {
    setLoad(false);
    setBody((prevData) => ({ ...prevData, [item]: value }));
    setInputs((prevData) => ({ ...prevData, [item]: value }));
    setFilters((prevData) => ({ ...prevData, [item]: false }));
    setFiltersCount(filtersCount - 1);
  };

  const handleDeleteTimeFilter = () => {
    setLoad(false);
    setBody((prevData) => ({
      ...prevData,
      date_start: initialReqBody.date_start,
      date_end: initialReqBody.date_end,
    }));

    setInputs((prevData) => ({ ...prevData, startTime: "", endTime: "" }));
    setFilters((prevData) => ({ ...prevData, date: false }));
    setFiltersCount(filtersCount - 1);
  };

  useEffect(() => {
    setBody((prevData) => ({
      ...prevData,
      date_start: date_start,
      date_end: date_end,
    }));
  }, [date_end, date_start]);

  useEffect(() => {
    active && getSMSData();
  }, [
    body.skip,
    body.limit,
    body.date_end,
    body.date_start,
    body.status,
    body.direction,
    body.id,
    body.country,
    body.did,
    body.senderPhoneNumber,
    body.sort,
    body.orderBy,
  ]);

  const [sortByClicked, setSortByClicked] = useState(6);

  const handleInputonChange = (value, item) => {
    setInputs((prevData) => ({ ...prevData, [item]: value }));
  };

  const [index, setIndex] = useState(null);

  const callsColumns = [
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.sms_id")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("id", "ASC", 1);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 1 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 2 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("id", "DESC", 2);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("sms_id")}
            className="search-input-dt search-input-dt-id"
            value={inputs.id}
            onChange={(e) => handleInputonChange(e.target.value, "id")}
            onBlur={(e) => {
              handleInputChange("id", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.id}</span> : <div className="skeleton-dt"></div>,
    },

    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.direction")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("direction", "ASC", 3);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 3 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 4 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("direction", "DESC", 4);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            className="search-input-dt search-input-dt-direction"
            value={body.direction}
            onChange={(e) => {
              handleSelectChange("direction", e.target.value);
            }}
          >
            <option selected value="">
              {t("all")}
            </option>
            <option value="1">{t("inbound")}</option>
            <option value="2">{t("outbound")}</option>
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.direction && (
            <div className="call-direction">
              {row.direction === 2 ? (
                <svg
                  height={15}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <rect width="24" height="24" fill="white"></rect>{" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14V11.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L12.5858 10H10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8H14.6717C15.4054 8 16 8.59489 16 9.32837V14Z"
                      fill="#606060"
                    ></path>{" "}
                  </g>
                </svg>
              ) : row.direction === 1 ? (
                <svg
                  height={15}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="matrix(-1, 0, 0, -1, 0, 0)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <rect width="24" height="24" fill="white"></rect>{" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14V11.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L12.5858 10H10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8H14.6717C15.4054 8 16 8.59489 16 9.32837V14Z"
                      fill="#606060"
                    ></path>{" "}
                  </g>
                </svg>
              ) : (
                ""
              )}

              <span>
                {row.direction === 1
                  ? t("inbound")
                  : row.direction === 2
                  ? t("outbound")
                  : ""}
              </span>
            </div>
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },

    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.date")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("dateInit", "ASC", 5);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 5 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 6 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("dateInit", "DESC", 6);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>

          {date_start != date_end ? (
            <DateTimePicker
              handleTimePicker={handleDateTimeFilterChange}
              start_time={inputs.startTime}
              end_time={inputs.endTime}
              changeStartTime={(value) =>
                setInputs((prevData) => ({ ...prevData, startTime: value }))
              }
              changeEndTime={(value) =>
                setInputs((prevData) => ({ ...prevData, endTime: value }))
              }
              minDate={date_start}
              maxDate={date_end}
            />
          ) : (
            <TimerPicker
              handleTimePicker={handleTimeChange}
              start_time={inputs.startTime}
              end_time={inputs.endTime}
              changeStartTime={(value) =>
                setInputs((prevData) => ({ ...prevData, startTime: value }))
              }
              changeEndTime={(value) =>
                setInputs((prevData) => ({ ...prevData, endTime: value }))
              }
            />
          )}
        </div>
      ),
      cell: (row) =>
        load ? (
          <span> {row.date && defaultDateFormat(row.date)}</span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },

    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.status")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("status", "ASC", 7);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 7 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 8 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("status", "DESC", 8);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            className="search-input-dt "
            value={body.status}
            onChange={(e) => {
              handleSelectChange("status", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>
            {selectOptions.status.map((item) => (
              <option value={item}>
                {item === 1
                  ? t("draft")
                  : item === 2
                  ? t("queued")
                  : item === 3
                  ? t("confirmed")
                  : item === 4
                  ? t("unconfirmed")
                  : item === 5
                  ? t("sent")
                  : item === 6
                  ? t("delivered")
                  : item === 7
                  ? t("failed")
                  : item === 8
                  ? t("archived")
                  : item === 9
                  ? t("received")
                  : item === 10
                  ? t("spam")
                  : item === 11
                  ? t("read")
                  : item === 11
                  ? t("bounced")
                  : item === 12
                  ? t("deleted")
                  : ""}
              </option>
            ))}
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          <span
            className={` badge ${
              row.status === 3 ||
              row.status === 5 ||
              row.status === 6 ||
              row.status === 8 ||
              row.status === 9 ||
              row.status === 11
                ? "badge-light-success"
                : row.status === 2 ||
                  row.status === 4 ||
                  row.status === 7 ||
                  row.status === 10 ||
                  row.status === 12 ||
                  row.status === 13
                ? "badge-light-danger"
                : row.status === 1 || row.status === 2 || row.status === 4
                ? "badge-light-warning"
                : "badge-light-neutral"
            }`}
          >
            {row.status === 1
              ? t("draft")
              : row.status === 2
              ? t("queued")
              : row.status === 3
              ? t("confirmed")
              : row.status === 4
              ? t("unconfirmed")
              : row.status === 5
              ? t("sent")
              : row.status === 6
              ? t("delivered")
              : row.status === 7
              ? t("failed")
              : row.status === 8
              ? t("archived")
              : row.status === 9
              ? t("received")
              : row.status === 10
              ? t("spam")
              : row.status === 11
              ? t("read")
              : row.status === 12
              ? t("bounced")
              : row.status === 13
              ? t("deleted")
              : ""}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.destination")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("did", "ASC", 9);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 9 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 10 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("did", "DESC", 10);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className="search-input-dt"
            placeholder={t("destination")}
            value={inputs.did}
            onChange={(e) => handleInputonChange(e.target.value, "did")}
            onBlur={(e) => {
              handleInputChange("did", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.did}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.country")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("country", "ASC", 11);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 11 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 12 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("country", "DESC", 12);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>

          <select
            name=""
            value={body.country}
            className="search-input-dt"
            onChange={(e) => {
              handleSelectChange("country", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>

            {selectOptions.countries.map((countryCode) => {
              const country = countriesData.find((c) => c.code === countryCode);
              return (
                <option key={countryCode} value={countryCode}>
                  {country ? country.name : countryCode}
                </option>
              );
            })}
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.country ? (
            <span>
              <div className="d-flex align-items-center">
                <div className="me-2 position-relative">
                  <img
                    className="height-1 width-1-6 obj-fit-cover flag-style"
                    alt={row.country}
                    src={`/assets/media/flags/${row.country.toLowerCase()}.svg`}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="fw-semibold fs-6 text-gray-400">
                    {t(`countries.${row.country}`)}
                  </div>
                </div>
              </div>
            </span>
          ) : (
            ""
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.sender")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("senderPhoneNumber", "ASC", 13);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 13 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 14 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("senderPhoneNumber", "DESC", 14);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className="search-input-dt"
            placeholder={t("caller_id")}
            value={inputs.senderPhoneNumber}
            onChange={(e) =>
              handleInputonChange(e.target.value, "senderPhoneNumber")
            }
            onBlur={(e) => {
              handleInputChange("senderPhoneNumber", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.caller}</span> : <div className="skeleton-dt"></div>,
    },
    // {
    //   name: (
    //     <div className="header-dt">
    //       <div className="title-dt">
    //         <span>{t("datatable.cost")}</span>
    //         <div className="sort-by-filter">
    //           <span>
    //             <svg
    //               onClick={() => {
    //                 fetchDataDirectionFilter("enduser_cost", "ASC", 19);
    //               }}
    //               className="svg-filters-up-down"
    //               viewBox="0 0 1024 1024"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill={`${sortByClicked === 19 ? "#919191" : "#d9d9d9"}`}
    //               transform="rotate(180)"
    //             >
    //               <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    //               <g
    //                 id="SVGRepo_tracerCarrier"
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //               />
    //               <g id="SVGRepo_iconCarrier">
    //                 <path d="m192 384 320 384 320-384z" />
    //               </g>
    //             </svg>
    //           </span>
    //           <svg
    //             viewBox="0 0 1024 1024"
    //             xmlns="http://www.w3.org/2000/svg"
    //             fill={`${sortByClicked === 20 ? "#919191" : "#d9d9d9"}`}
    //             className="svg-filters-up-down filter-down"
    //             onClick={() => {
    //               fetchDataDirectionFilter("enduser_cost", "DESC", 20);
    //             }}
    //           >
    //             <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    //             <g
    //               id="SVGRepo_tracerCarrier"
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //             <g id="SVGRepo_iconCarrier">
    //               <path d="m192 384 320 384 320-384z" />
    //             </g>
    //           </svg>
    //         </div>
    //       </div>
    //       <input
    //         placeholder={t("cost")}
    //         className="search-input-dt"
    //         value={inputs.cost}
    //         onChange={(e) => handleInputonChange(e.target.value, "cost")}
    //         onBlur={(e) => {
    //           handleInputChange("cost", e.target.value);
    //         }}
    //       ></input>
    //     </div>
    //   ),
    //   cell: (row) =>
    //     load ? <span>{row.cost}</span> : <div className="skeleton-dt"></div>,
    // },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.message")}</span>
          </div>
          <input
            className="search-input-dt opacity-0 disabled-event"
            placeholder={t("user")}
            value={inputs.message}
            onChange={(e) => handleInputonChange(e.target.value, "message")}
            onBlur={(e) => {
              handleInputChange("message", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row, index) =>
        load ? (
          row.id && (
            <span
              title={t("open_conversation")}
              className="grey-button"
              onClick={() => {
                setIndex(index, setModalShow(true));
              }}
            >
              <svg
                className="docs-svg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                    stroke="#5c5e6e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                    stroke="#5c5e6e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </span>
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
  ];

  const [callsData, setCallsData] = useState([
    {
      id: null,
      direction: null,
      date: null,
      status: null,
      did: null,
      country: null,
      caller: null,
      duration: null,
      user: null,
      cost: null,
      message: "",
    },
  ]);

  const handleNextPage = () => {
    if ((body.skip + 1) * 5 < body.total) {
      setBody((prevData) => ({
        ...prevData,
        skip: prevData.skip + 1,
        start: prevData.start + prevData.limit,
        end: prevData.end + prevData.limit

      }));
    }
  };

  const handlePrevioustPage = () => {
    if (!body.skip == 0) {
      setBody((prevData) => ({
        ...prevData,
        skip: prevData.skip - 1,
        start: prevData.start - prevData.limit,
        end: prevData.end - prevData.limit
      }));
    }
  };

  const setLimitFunction = (newLimit) => {
    return new Promise((resolve, reject) => {
      try {
        setBody((prevData) => ({
          ...prevData,
          limit: newLimit,
          skip: 0,
        }));
        resolve("Limit set successfully");
      } catch (error) {
        reject(`Error setting limit: ${error.message}`);
      }
    });
  };

  const handleRowsPerPageChange = async (newLimit, currentPage) => {
    try {
      await setLimitFunction(newLimit);
    } catch (error) {
      console.error(error);
    }
  };

  const [filtersCount, setFiltersCount] = useState(0);

  const mapCountryCodeToName = (code) => {
    const country = countriesData.find((country) => country.code === code);
    return country ? country.name : code;
  };

  const handleClearFilters = () => {
    setBody(initialReqBody);
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
    setFiltersCount(0);
    resetFilters();
  };

  const [modalShow, setModalShow] = useState(false);

  // const exportDrawerShow = async () => {
  //   setModalShow(true);
  // };

  // const [activeExportBtn, setActiveExportBtn] = useState(1);

  // const handleExportHistoryClick = () => {
  //   setActiveExportBtn(2);
  //   getLogsData();
  // };

  // const handleExport = async () => {
  //   setSpinnerLoad(true);
  //   try {
  //     const response = await fetchData(JSON.stringify(reqBody));
  //     setSpinnerLoad(false);
  //     setModalShow(false);
  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //     successSwall(t("success"));
  //   } catch (error) {
  //     if (error.message == 400) {
  //       ErrorSwal(t("errors.400"));
  //     }
  //     if (error.message == 401) {
  //       ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
  //     } else if (error.message == 403) {
  //       ErrorSwal(t("errors.403"));
  //     } else if (error.message == 404) {
  //       ErrorSwal(t("errors.404"));
  //     } else if (error.message == 405) {
  //       ErrorSwal(t("errors.405"));
  //     } else if (error.message == 406) {
  //       ErrorSwal(t("errors.406"));
  //     } else if (error.message == 422) {
  //       ErrorSwal(t("errors.422"));
  //     } else if (error.message == 423) {
  //       ErrorSwal(t("errors.423"));
  //     } else {
  //       InternalErrorSwal();
  //     }
  //   }
  // };

  const containerRef = useRef(null);

  const handleMouseLeave = (e) => {
    if (
      containerRef.current &&
      e.relatedTarget instanceof Node &&
      !containerRef.current.contains(e.relatedTarget) &&
      !isOpen
    ) {
      setModalShow(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  // const [logsData, setLogsData] = useState([]);

  // const [logsLoading, setLogsLoading] = useState(false);

  // const getLogsData = async () => {
  //   try {
  //     setLogsLoading(true);
  //     const response = await getLogs(JSON.stringify({ action: "EXPORT" }));
  //     setLogsLoading(false);
  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //     const fetchedData = await response.json();

  //     const mappedData = fetchedData.data.map((item) => ({
  //       date: item.log_date,
  //       status: item.status,
  //     }));

  //     setLogsData(mappedData);
  //   } catch (error) {
  //     if (error.message === "401") {
  //       ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
  //     } else if (error.message === "403") {
  //       ErrorSwal(t("errors.403"));
  //     } else if (error.message === "404") {
  //       ErrorSwal(t("errors.404"));
  //     } else if (error.message === "405") {
  //       ErrorSwal(t("errors.405"));
  //     } else if (error.message === "422") {
  //       ErrorSwal(t("errors.422"));
  //     } else if (error.message === "423") {
  //       ErrorSwal(t("errors.423"));
  //     } else if (error.message === "406") {
  //       ErrorSwal(t("errors.406"));
  //     } else {
  //       getLogsDataSecondTry();
  //     }
  //   }
  // };

  // const getLogsDataSecondTry = async () => {
  //   try {
  //     setLogsLoading(true);
  //     const response = await getLogs(JSON.stringify({ action: "EXPORT" }));
  //     setLogsLoading(false);
  //     if (!response.ok) {
  //       throw new Error(response.status);
  //     }
  //     const fetchedData = await response.json();
  //     setLogsData(...fetchedData.data);
  //   } catch (error) {
  //     InternalErrorSwal();
  //   }
  // };

  return (
    <div>
      <div className="card-header">
        <div className="card-title">
          <h2>{t("sms_list")}</h2>
        </div>
        <div className="card-toolbar m-0 d-flex">
          <div
            className="export-btn me-3"
            onClick={() => setRefresh(refresh + 1)}
          >
            <svg
              height={14}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M3 12C3 16.9706 7.02944 21 12 21C14.3051 21 16.4077 20.1334 18 18.7083L21 16M21 12C21 7.02944 16.9706 3 12 3C9.69494 3 7.59227 3.86656 6 5.29168L3 8M21 21V16M21 16H16M3 3V8M3 8H8"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
            <span> {t("refresh")} </span>
          </div>
          <div className="export-btn disabled-event">
            <img
              className="img-download"
              src="/assets/media/icons/download.png"
              alt="download"
            />
            <span> {t("export")} </span>
          </div>
        </div>
      </div>
      {spinnerLoad && <SpinnerSecondary />}

      {filtersCount > 0 && (
        <div className="all-filters p-6 pb-0">
          <div className="filters-tag">
            <img
              className="filters-icon"
              src="/assets/media/icons/filters-icon.png"
            />
            <span className="filters-title">{t("titles.filters")}:</span>
          </div>
          <div className="filters-container">
            {filters.id && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    className="filter-img"
                    src="/assets/media/icons/call-id-icon.png"
                  />
                  <span>
                    {t("datatable.call_id")}: {body.id}
                  </span>
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("id", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.direction && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/oposite-arrows.png"
                    className="filter-img"
                  />
                  <span>
                    {t("titles.direction")}:{" "}
                    {body.direction == 1
                      ? t("inbound")
                      : body.direction == 2
                      ? t("outbound")
                      : body.direction}
                  </span>
                  <span
                    className={`delete-filter-btn 
                        `}
                    onClick={() => handleDeleteFilter("feature", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.date && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/date-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {t("titles.date")}:{" "}
                    {body.date_start.includes(" ")
                      ? convertedDate(body.date_start)
                      : `${defaultOnlyDateFormat(body.date_start)} 00:00`}{" "}
                    {t("to")}{" "}
                    {body.date_end.includes(" ")
                      ? convertedDate(body.date_end)
                      : `${defaultOnlyDateFormat(body.date_end)} 23:59`}
                  </span>{" "}
                  <span
                    className={`delete-filter-btn 
                         
                        `}
                    onClick={() => handleDeleteTimeFilter()}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.status && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/status-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {t("titles.status")}:{" "}
                    {body.status == 1
                      ? t("draft")
                      : body.status == 2
                      ? t("queued")
                      : body.status == 3
                      ? t("confirmed")
                      : body.status == 4
                      ? t("unconfirmed")
                      : body.status == 5
                      ? t("sent")
                      : body.status == 6
                      ? t("delivered")
                      : body.status == 7
                      ? t("failed")
                      : body.status == 8
                      ? t("archived")
                      : body.status == 9
                      ? t("received")
                      : body.status == 10
                      ? t("spam")
                      : body.status == 11
                      ? t("read")
                      : body.status == 12
                      ? t("bounced")
                      : body.status == 13
                      ? t("deleted")
                      : ""}
                  </span>{" "}
                  <span
                    className={`delete-filter-btn 
                        `}
                    onClick={() => handleDeleteFilter("status", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.did && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/call-in-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {" "}
                    {t("datatable.destination")}: {body.did}
                  </span>
                  <span
                    className={`delete-filter-btn  `}
                    onClick={() => handleDeleteFilter("did", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.country && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/country-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {" "}
                    {t("datatable.country")}:{" "}
                    {mapCountryCodeToName(body.country)}
                  </span>{" "}
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("country", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.senderPhoneNumber && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/caller-id-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {t("datatable.caller_id")}: {body.senderPhoneNumber}
                  </span>
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("caller", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.duration && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/chrono-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {t("datatable.duration")}: {body.duration}
                  </span>
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("duration", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.extension && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/user-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {t("datatable.user")}: {body.extension}
                  </span>{" "}
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("extension", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filters.cost && (
              <div className="dt-filters-list">
                <div className="td-filter">
                  <img
                    src="/assets/media/icons/cost-icon.png"
                    className="filter-img"
                  />
                  <span>
                    {" "}
                    {t("datatable.cost")}: {body.cost}
                  </span>{" "}
                  <span
                    className="delete-filter-btn"
                    onClick={() => handleDeleteFilter("cost", "")}
                  >
                    <img
                      className="x-close"
                      src="/assets/media/icons/x-close.png"
                    />
                  </span>
                </div>
              </div>
            )}
            {filtersCount > 1 && (
              <div className="clear-all" onClick={() => handleClearFilters()}>
                {t("clear_all")}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="card-body p-6 position-relative">

        <div className="dt-header-color"></div>

        <div className="header-bar"></div>
        
        {body.total === 0 && (
          <div className="empty-calls">{t("no_calls_found")}</div>
        )}


        <DataTable
          className="table align-middle table-row-dashed fs-6 fw-bold gy-5 datatable-style position-relative"
          columns={callsColumns}
          data={callsData}
          paginationPerPage={5}
          fixedHeader
          {...(body.total > 5 && {
            pagination: true,
            paginationPerPage: body.limit,
            paginationRowsPerPageOptions: [5],
            onChangeRowsPerPage: handleRowsPerPageChange,
            paginationTotalPages: body.total,
          })}
        ></DataTable>


        {body.total > 5 ? (
          <div className="listing-dt-filters">
            <span className="rows-per-page">
              {body.start}-
              {body.end + body.limit > body.total
                ? body.total
                : body.end}{" "}
              {t("of")} {body.total}
            </span>
            <div className="dt-filter-buttons">
              {/* <div class="datatable-button">
                    <svg
                      className="svg-filters"
                      fill="#000000"
                      viewBox="0 0 512 512"
                      id="Layer_1"
                      version="1.1"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M297.2,478l20.7-21.6L108.7,256L317.9,55.6L297.2,34L65.5,256L297.2,478z M194.1,256L425.8,34l20.7,21.6L237.3,256 l209.2,200.4L425.8,478L194.1,256z" />
                      </g>
                    </svg>
                  </div> */}
              <div className="datatable-button" onClick={handlePrevioustPage}>
                <svg
                  className="svg-filters"
                  fill={!body.skip == 0 ? "#000000" : "#aaaaaa"}
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="XMLID_54_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g id="previous">
                      {" "}
                      <g>
                        {" "}
                        <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
              <div class="datatable-button" onClick={handleNextPage}>
                <svg
                  className="svg-filters"
                  fill={
                    body.skip + body.limit < body.total ? "#000000" : "#aaaaaa"
                  }
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="XMLID_287_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g id="next">
                      {" "}
                      <g>
                        {" "}
                        <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
              {/* <div class="datatable-button">
                    <svg
                      fill="#000000"
                      className="svg-filters"
                      viewBox="0 0 512 512"
                      data-name="Layer 1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M214.78,478l-20.67-21.57L403.27,256,194.11,55.57,214.78,34,446.46,256ZM317.89,256,86.22,34,65.54,55.57,274.7,256,65.54,456.43,86.22,478Z" />
                      </g>
                    </svg>
                  </div> */}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {modalShow && (
        <SmsContentModal
          closeModal={() => setModalShow(false)}
          message={callsData[index].message}
          country={callsData[index].country}
          number={callsData[index].did}
        />
      )}
    </div>
  );
};

export default SmsDataTable;
