import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../Components/Card";
import DataTableNumbers from "../Components/DataTable";
import Spinner from "../../../components/Spinner";
import { useTranslation } from "react-i18next";
import "../Style/NumbersDataTable.css";
import EmptyNumbers from "./EmptyNumbers";
import { FloatingLabel, Form } from "react-bootstrap";
import {
  getLocale,
  initialDateFormat,
  safeDateFormat,
} from "../../../constants/Utils";
import DatePickerSingle from "../../../components/reusables/DatePickerSingle";

import "../Style/NumbersList.css";
import { getNumbers } from "../Services/getNumbers";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import AddNew from "../../../components/Cards/AddNew";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

// import ModalSearchUsers from "../../../components/Modals/SearchUsersModal";
// import ModalAllocateUser from "../../../components/Modals/AllocatePhoneNumbersModal";
// import ModalAddOutboundCallingScope from "../../../components/Modals/AddOutboundCallingScopeModal";
// import ModalAddPayment from "../../../components/Modals/AddPaymentModal";
// import ModalAddOutboundCallingCreadit from "../../../components/Modals/AddOutboundCallingCreaditModal";
// import ModalCallsAndSms from "../../../components/Modals/CallsAndSmsModal";
// import ModalAllocatePhoneNumber from "../../../components/Modals/AllocateUsersModal";
// import AllocateUsersModal from "../../../components/Modals/AllocateUsersModal";
// import AddOutboundCallingScopeModal from "../../../components/Modals/AddOutboundCallingScopeModal";
// import AddPaymentModal from "../../../components/Modals/AddPaymentModal";
// import AddOutboundCallingCreaditModal from "../../../components/Modals/AddOutboundCallingCreaditModal";
// import CallsAndSmsModal from "../../../components/Modals/CallsAndSmsModal";

const ListNumbers = () => {
  const { t } = useTranslation();

  const dateFormat = JSON.parse(localStorage.getItem("data")).date_format;
  const languagePref = localStorage.getItem("i18nextLng");
  const locale = getLocale(languagePref);

  const [pagination, setPagination] = useState({
    total: 0,
    from: 1,
    to: 6,
    limit: 6,
    status: null,
    date: null,
    number: null,
    features: null,
  });

  const [numbers, setNumbers] = useState([
    {
      number: "",
      direction: "",
      features: "",
      status: "",
      users: "",
      outbound_credit: "",
      outbound_credit_currency: "",
      outbound_scope: "",
      starting_date: "",
      renewal_date: "",
    },
  ]);

  const handleReconnection = async () => {
    try {
      const response = await getNumbers();

      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setPagination((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total != 0) {
        setNumbersExists(1);
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          number: data.number,
          features: data.features,
          direction: data._terms.features,
          status:  data.status,
          extensions: data.extensions,
          outbound_credits: data.outbound_credit,
          outbound_credits_currency: data.outbound_credit_currency,
          outbound_scope: data.outbound_scope,
          outbound_scope_name: data.outbound_scope_name,
          starting_date: new Date(data.starting_date),
          renewal_date: new Date(data.membership.next_payment_date),
          country: data.country,
          number_name: data.number_name,
        }));

        setNumbers(mappedData);
      } else {
        setNumbersExists(2);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const handleNextPage = () => {
    if (pagination.to < allFilteredNumbers) {
      setPagination((prevData) => ({
        ...prevData,
        from: prevData.from + prevData.limit,
        to: prevData.to + prevData.limit,
      }));
    }
  };

  const handlePrevioustPage = () => {
    if (pagination.from != 0 && pagination.from != 1) {
      setPagination((prevData) => ({
        ...prevData,
        from: prevData.from - prevData.limit,
        to: prevData.to - prevData.limit,
      }));
    }
  };

  const [filteredNumbers, setFilteredNumbers] = useState([]);

  const [allFilteredNumbers, setAllFilteredNumbers] = useState(0);


  useEffect(() => {
    const totalRows = numbers.filter(
      (item) =>
        (pagination.status ? item.status == pagination.status : true) &&
        (pagination.number
          ? item.number.toUpperCase().includes(pagination.number.toUpperCase())
          : true) &&
        (pagination.date
          ? initialDateFormat(item.starting_date) ==
            initialDateFormat(pagination.date)
          : true) &&
        (pagination.features
          ? item.features?.includes(pagination.features)
          : true)
    );

    const newtotalRows = numbers
      .filter(
        (item) =>
          (pagination.status ? item.status == pagination.status : true) &&
          (pagination.number
            ? item.number
                .toUpperCase()
                .includes(pagination.number.toUpperCase())
            : true) &&
          (pagination.date
            ? initialDateFormat(item.starting_date) ==
              initialDateFormat(pagination.date)
            : true) &&
          (pagination.features
            ? item.features?.includes(pagination.features)
            : true)
      )
      .slice(pagination.from - 1, pagination.to);

    setFilteredNumbers(newtotalRows);
    setAllFilteredNumbers(totalRows.length);
  }, [
    pagination.total,
    pagination.limit,
    pagination.from,
    pagination.to,
    pagination.status,
    pagination.number,
    pagination.features,
    numbers,
    pagination.date,
  ]);

  const [numbersExists, setNumbersExists] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getNumbers();

      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setPagination((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total != 0) {
        setNumbersExists(1);
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          number: data.number,
          features: data.features,
          direction: data._terms.features,
          status:  data.status,
          extensions: data.extensions,
          outbound_credits: data.outbound_credit,
          outbound_credits_currency: data.outbound_credit_currency,
          outbound_scope: data.outbound_scope,
          outbound_scope_name: data.outbound_scope_name,
          starting_date: new Date(data.starting_date),
          renewal_date: new Date(data.membership.next_payment_date),
          country: data.country,
          number_name: data.number_name,
        }));

        setNumbers(mappedData);
      } else {
        setNumbersExists(2);
      }
    } catch (error) {
      setNumbersExists(2);
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        handleReconnection();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const handleMenuOpen = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const changeData = (item, id, value) => {
    setNumbers((prevData) => {
      const index = prevData.findIndex((element) => element.id === id);
      if (index !== -1) {
        const updatedItem = { ...prevData[index], [item]: value };
        const newData = [
          ...prevData.slice(0, index),
          updatedItem,
          ...prevData.slice(index + 1),
        ];
        return newData;
      }
      return prevData;
    });
  };

  return (
    <div>
      {(numbersExists === 1 && numbers.length > 0) ? 
      (
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div className="d-flex flex-wrap flex-stack pb-7">
                    <div className="d-flex flex-wrap align-items-center my-1">
                      <h3 className="fw-bold me-5 my-1 flex-divs">
                        {t("list_numbers")}

                        {allFilteredNumbers != pagination.total ? (
                          <div className="showing-line">
                            <span>{t("showing")}</span>
                            <span>
                              ({allFilteredNumbers}/
                              <span className="colored-total">
                                {pagination.total}
                              </span>
                              )
                            </span>
                          </div>
                        ) : (
                          <div className="showing-line">
                            <span>{t("total")}</span>
                            <span>({pagination.total})</span>
                          </div>
                        )}
                      </h3>
                    </div>
                    <div className="d-flex flex-wrap my-1 ">
                      <div className="d-flex my-0 display-flex-line al-items-center">
                        <Link
                          className="btn btn-sm btn-primary-active flex-space-evenly me-3"
                          to="/numbers_buy"
                        >
                          <svg
                            width="20"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
                              fill="currentColor"
                            />
                            <path
                              opacity="0.3"
                              d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
                              fill="currentColor"
                            />
                            <path
                              opacity="0.3"
                              d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span> {t("buy_new")}</span>
                        </Link>
                        <ul className="nav nav-pills flex-center me-3">
                          <li className="nav-item m-0">
                            <span
                              className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${
                                currentPage === 1 && "active show"
                              }`}
                              data-bs-toggle="tab"
                              onClick={() => setCurrentPage(1)}
                            >
                              <span className="svg-icon svg-icon-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x={5}
                                      y={5}
                                      width={5}
                                      height={5}
                                      rx={1}
                                      fill="currentColor"
                                    />
                                    <rect
                                      x={14}
                                      y={5}
                                      width={5}
                                      height={5}
                                      rx={1}
                                      fill="currentColor"
                                      opacity="0.3"
                                    />
                                    <rect
                                      x={5}
                                      y={14}
                                      width={5}
                                      height={5}
                                      rx={1}
                                      fill="currentColor"
                                      opacity="0.3"
                                    />
                                    <rect
                                      x={14}
                                      y={14}
                                      width={5}
                                      height={5}
                                      rx={1}
                                      fill="currentColor"
                                      opacity="0.3"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </li>
                          <li className="nav-item m-0 ">
                            <span
                              className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${
                                currentPage === 2 && "active show"
                              }`}
                              data-bs-toggle="tab"
                              onClick={() => setCurrentPage(2)}
                            >
                              <span className="svg-icon svg-icon-2">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </span>
                          </li>
                        </ul>{" "}
                        <div
                          className={`position-relative flex-date height-42 br-6 ${
                            pagination.date && "filter-selected"
                          }`}
                        >
                          <label className="label-float">
                            {t("titles.date")}
                          </label>

                          <div className="width-div-numbers">
                            <input
                              type="text"
                              className="date-numbers width-div-numbers date-placeholder"
                              value={
                                pagination.date
                                  ? safeDateFormat(
                                      new Date(pagination.date),
                                      dateFormat,
                                      {
                                        locale,
                                      }
                                    )
                                  : t("select_date")
                              }
                            />

                            <DatePickerSingle
                              onChange={(e) => {
                                setPagination((prevData) => ({
                                  ...prevData,
                                  date: initialDateFormat(new Date(e)),
                                }));
                              }}
                              date={pagination.date ? pagination.date : ""}
                            />
                          </div>
                          {pagination.date && (
                            <span
                              className="delete-date-filter"
                              onClick={() =>
                                setPagination((prevData) => ({
                                  ...prevData,
                                  date: "",
                                }))
                              }
                            >
                              x
                            </span>
                          )}
                        </div>
                        <FloatingLabel
                          controlId={`floatingSelect `}
                          label={t("titles.status")}
                          value={pagination.status}
                          onChange={(e) => {
                            setPagination((prevData) => ({
                              ...prevData,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <Form.Select
                            aria-label="Floating label select example "
                            className={`form-dimentiens cursor-pointer ${
                              pagination.status && "filter-selected"
                            }`}
                          >
                            <option value="">{t("all")}</option>
                            <option value={1}>{t("active")}</option>
                            <option value={3}>{t("disabled")}</option>
                            <option value={2}>{t("pending")}</option>
                          </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingSelect"
                          label={t("filters.features")}
                          value={pagination.features}
                          onChange={(e) => {
                            setPagination((prevData) => ({
                              ...prevData,
                              features: e.target.value,
                            }));
                          }}
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            className={`form-dimentiens cursor-pointer ${
                              pagination.features && "filter-selected"
                            }`}
                          >
                            <option selected value="">
                              {t("all")}
                            </option>
                            <option value={1}>{t("voice_in")}</option>
                            <option value={2}>{t("voice_out")}</option>
                            <option value={3}>{t("sms_in")}</option>
                            <option value={4}>{t("sms_out")}</option>
                          </Form.Select>
                        </FloatingLabel>
                        <div
                          className={`d-flex align-items-center position-relative height-42 br-6 ${
                            pagination.number && "filter-selected"
                          }`}
                        >
                          <label className="label-float">{t("number")}</label>
                          {/* <span className="svg-icon svg-icon-3 position-absolute ms-3">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height={2}
                                rx={1}
                                transform="rotate(45 17.0365 15.1223)"
                                fill="currentColor"
                              />
                              <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span> */}
                          <input
                            type="text"
                            className="form-control form-control-sm border-body bg-body w-150px heiht-100 num-input"
                            placeholder={t("search")}
                            onChange={(e) =>
                              setPagination((prevData) => ({
                                ...prevData,
                                number: e.target.value,
                              }))
                            }
                          />
                        </div>
                        {/* <select
                          name="status"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Export"
                          className="form-select form-select-sm border-body bg-body w-100px"
                        >
                          <option value="">{t("export")}</option>
                          <option value={1}>Excel</option>
                          <option value={1}>PDF</option>
                          <option value={2}>{t("print")}</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      id="kt_project_users_card_pane"
                      className={`tab-pane fade ${
                        currentPage === 1 && "active show"
                      }`}
                    >
                      <div className="row g-6 g-xl-9 margin-bottom">
                        {filteredNumbers[0]?.status &&
                          filteredNumbers.map((number, index) => (
                            <Card
                              key={index}
                              data={number}
                              index={index}
                              openMenuIndex={openMenuIndex}
                              onMenuOpen={handleMenuOpen}
                              changeData={changeData}
                            />
                          ))}

                        {(pagination.total < pagination.to ||
                          filteredNumbers.length < 6) && (
                          <AddNew
                            title={"buy_new_number"}
                            path={"/numbers_buy"}
                          />
                        )}
                      </div>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div className="nav-item ms-auto date-listing">
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-5">
                            <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                              {t("payments")}
                            </div>
                          </div>
                          <div className="menu-item px-5">
                            <a href="#" className="menu-link px-5">
                              {t("create_invoice")}
                            </a>
                          </div>
                          <div className="menu-item px-5">
                            <a href="#" className="menu-link flex-stack px-5">
                              {t("create_payment")}

                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify a target name for future usage and reference"
                              />
                            </a>
                          </div>
                          <div
                            className="menu-item px-5"
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="left-start"
                          >
                            <a href="#" className="menu-link px-5">
                              <span className="menu-title">Subscription</span>
                              <span className="menu-arrow" />
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-5">
                                  {t("apps")}
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-5">
                                  {t("billings")}
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-5">
                                  {t("statements")}
                                </a>
                              </div>
                              <div className="separator my-2" />
                              <div className="menu-item px-3">
                                <div className="menu-content px-3">
                                  <label className="form-check form-switch form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input w-30px h-20px"
                                      type="checkbox"
                                      defaultValue=""
                                      name="notifications"
                                      defaultChecked="checked"
                                      id="kt_user_menu_notifications"
                                    />
                                    <span
                                      className="form-check-label text-muted fs-6"
                                      htmlFor="kt_user_menu_notifications"
                                    >
                                      {t("notifications")}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="separator my-3" />
                          <div className="menu-item px-5">
                            <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                              {t("account")}
                            </div>
                          </div>
                          <div className="menu-item px-5">
                            <a href="#" className="menu-link px-5">
                              {t("reports")}
                            </a>
                          </div>
                          <div className="menu-item px-5 my-1">
                            <a href="#" className="menu-link px-5">
                              {t("account_settings")}
                            </a>
                          </div>
                          <div className="menu-item px-5">
                            <a href="#" className="menu-link text-danger px-5">
                              {t("delete_customer")}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          currentPage === 2 && "show active"
                        }`}
                        id="kt_calls"
                        role="tabpanel"
                      >
                        <div className="card mb-6 mb-xl-9">
                          <div className="card-header">
                            <div className="card-title">
                              <h2> {t("numbers_list")}</h2>
                            </div>
                            <div className="card-toolbar m-0"></div>
                          </div>
                          <div>
                            <div className="card-body p-6">
                              {filteredNumbers.length > 0 ? (
                                <DataTableNumbers
                                  numbers={filteredNumbers}
                                  changeData={changeData}
                                />
                              ) : (
                                <div className="full-size display-flex m-top-10">
                                  <div className="display-flex flex-col-num gap-5 pad-30">
                                    <span>{t("no_records")}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Pagination */}
                  </div>
                  <div className="d-flex flex-stack flex-wrap">
                    {filteredNumbers.length !== 0 && pagination.total > 6 && (
                      <div className="numbers-dt-pagination">
                        <div className="rows-per-page">
                          {t("rows_per_page")}{" "}
                          <select
                            className="rows-per-page-select"
                            onChange={(e) =>
                              setPagination((prevData) => ({
                                ...prevData,
                                from: 1,
                                limit: parseInt(e.target.value),
                                to: parseInt(e.target.value),
                              }))
                            }
                          >
                            <option value="6">6</option>
                            <option value="9">9</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                        <span className="rows-per-page">
                          {pagination.from}-
                          {pagination.to < allFilteredNumbers
                            ? pagination.to
                            : allFilteredNumbers}{" "}
                          {t("of")} {allFilteredNumbers}
                        </span>
                        <div className="dt-filter-buttons">
                          <div
                            className="datatable-button"
                            onClick={handlePrevioustPage}
                          >
                            <svg
                              className="svg-filters"
                              fill={`${
                                pagination.from != 0 && pagination.from != 1
                                  ? "#000000"
                                  : "#aaaaaa"
                              }`}
                              height="200px"
                              width="200px"
                              version="1.1"
                              id="XMLID_54_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 24 24"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g id="previous">
                                  {" "}
                                  <g>
                                    {" "}
                                    <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </div>
                          <div
                            className="datatable-button"
                            onClick={handleNextPage}
                          >
                            <svg
                              className="svg-filters"
                              fill={
                                pagination.to < pagination.total
                                  ? "#000000"
                                  : "#aaaaaa"
                              }
                              height="200px"
                              width="200px"
                              version="1.1"
                              id="XMLID_287_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 24 24"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g id="next">
                                  {" "}
                                  <g>
                                    {" "}
                                    <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="modal fade"
                    id="kt_modal_view_users"
                    tabIndex={-1}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog mw-650px">
                      <div className="modal-content">
                        <div className="modal-header pb-0 border-0 justify-content-end">
                          <div
                            className="btn btn-sm btn-icon btn-active-color-primary"
                            data-bs-dismiss="modal"
                          >
                            <span className="svg-icon svg-icon-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x={6}
                                  y="17.3137"
                                  width={16}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-45 6 17.3137)"
                                  fill="currentColor"
                                />
                                <rect
                                  x="7.41422"
                                  y={6}
                                  width={16}
                                  height={2}
                                  rx={1}
                                  transform="rotate(45 7.41422 6)"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : numbersExists === 2 ? (
        <EmptyNumbers />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ListNumbers;
