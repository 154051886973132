import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../components/Spinner";


library.add(faHeart);


const SoonInterface = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
      import("./CSS/SoonInterface.css").then(() => {
        setLoad(true);
      });
  }, []);
  

  const { t } = useTranslation();

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div className="body-soon">
          <div id="main-wrapper-soon">
            <section
              className="min-vh-100 d-flex flex-column"
              style={{ alignItems: "center" }}
            >
              <div className="container py-5 px-4 px-lg-5 my-auto">
                <div
                  className="row py-5 py-sm-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
              
                  <div className="col-12 text-center mx-auto">
                    <h1 className="text-9 text-white bg-danger d-inline-block fw-700 rounded px-3 py-2 mb-4">
                      {t("coming_soon")}!
                    </h1>
                    <h2 className="text-15 fw-600 mb-4">{t("feature_soon")}</h2>
                  </div>
                  <div className="mx-auto text-center">
                    <p className="text-5 text-muted mb-3">
                      {t("new_start_soon")}
                    </p>
                  </div>
                </div>
              </div>
              <footer className="container text-center">
                <p className="copyright" style={{ color: "#bfc9d7" }}>
                  {t("made_with")}{" "}
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{ color: "#ff45453d", fontSize: 12 }}
                  />
                  <span href=""> {t("by")}</span>
                  <a target="_blank"> ITwise</a>
                </p>
              </footer>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default SoonInterface;
