import { t } from "i18next";
import Swal from "sweetalert2";

const reconnectionSwal = (load) => {
  const swalInstance = Swal.fire({
    title: t("reconnection"),
    didOpen: () => {
      Swal.showLoading();
    },
    willClose: true,
  });
  if (!load) {
    swalInstance.then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        Swal.close();
      }
    });
  }
};

export default reconnectionSwal;
