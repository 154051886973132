import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header";
import getTutorials from "../Services/getTutorials";
import { defaultDateFormat } from "../../../constants/Utils";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const Tutorials = () => {
  const { t } = useTranslation();


  const [data, setData] = useState({ data: [], display: 0 });

  const fetchData = async () => {
    try {
      const response = await getTutorials();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.total > 0) {
        const newData = [];
        result.data.forEach((element) =>
          newData.push({
            name: element.name,
            content: element.content,
            date_start: element.date_start,
            date_end: element.date_end,
          })
        );
        setData({ data: newData, display: 1 });
      } else {
        setData({ data: [], display: 2 });
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchData();
      }
    }
  };

  const refetchData = async () => {
    try {
      const response = await getTutorials();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.total > 0) {
        const newData = [];
        result.data.forEach((element) =>
          newData.push({
            name: element.name,
            content: element.content,
            date_start: element.date_start,
            date_end: element.date_end,
          })
        );
        setData({ data: newData, display: 1 });
      } else {
        setData({ data: [], display: 2 });
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    fetchData();
    localStorage.setItem("active", 12);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);


  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          
          <div className="d-flex flex-column flex-column-fluid">

            <div id="kt_app_content" className="app-content flex-column-fluid">

              <div className="card mb-12">
                <Header active={3} />
              </div>

              <div className="card">

                {data.display === 0 && <SpinnerDiv />}

                <div
                  className={`card-body p-10 p-lg-15 m-h-44rem ${
                    data.display === 0 ? "disabled-event" : ""
                  }`}
                >
                  <div className="">
                    <h3 className="text-dark mb-7">{t("last_news")}</h3>
                    <div className="separator separator-dashed mb-9" />

                    {data.display === 1 ? (

                      <div className="row">
                        <div className="col-md-6">
                          <div className="h-100 d-flex flex-column pe-lg-6 mb-lg-0 mb-10">
                            <div className="mb-3">
                              <iframe
                                className="embed-responsive-item card-rounded h-275px w-100 mb-6"
                                src="https://www.youtube.com/embed/TWdDZYNqlg4"
                                allowFullScreen="allowfullscreen"
                              />
                            </div>
                            <div className="mb-5">
                              <div className="d-flex align-items-center">
                                <span className="fs-2 text-dark fw-bold text-dark lh-base">
                                  {data.data[0].name}
                                </span>
                                <span className="badge badge-light-primary fw-bold ms-2">
                                  {t("on")}{" "}
                                  {defaultDateFormat(data.data[0].date_start)}
                                </span>
                              </div>
                              <div className="fw-semibold fs-5 text-gray-600 text-dark mt-4">
                                {data.data[0].content}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 justify-content-between d-flex flex-column">
                          <div className="ps-lg-6 mt-md-0 mt-17">
                            {data.data
                              .filter((item, index) => index !== 0)
                              .map((element) => (
                                <div className="mb-10">
                                  <div className="d-flex align-items-center">
                                    <span className="fw-bold text-dark fs-2 lh-base">
                                      {element.name}
                                    </span>
                                    <span className="badge badge-light-primary fw-bold ms-2">
                                      {t("on")}{" "}
                                      {defaultDateFormat(element.date_start)}
                                    </span>
                                  </div>
                                  <div className="fw-semibold fs-5 mt-4 text-gray-600 text-dark">
                                    {element.content}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ) : data.display === 2 ? (
                      <div className="d-flex justify-content-center">
                        <img
                          width={500}
                          src="/assets/media/svg/illustrations/not-found.svg"
                          alt="not_found"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="mb-17">
                    <div className="d-flex flex-stack mb-5">
                      <h3 className="text-dark">{t("video_tutorials")}</h3>
                      <a href="#" className="fs-6 fw-semibold link-primary">
                        {t("view_all_videos")}
                      </a>
                    </div>
                    <div className="separator separator-dashed mb-9" />
                    <div className="row g-10">
                      <div className="col-md-4">
                        <div className="card-xl-stretch me-md-6">
                          <a
                            className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x400/img-73.jpg")',
                            }}
                            data-fslightbox="lightbox-video-tutorials"
                            href="https://www.youtube.com/embed/btornGtLwIo"
                          >
                            <img
                              src="assets/media/svg/misc/video-play.svg"
                              className="position-absolute top-50 start-50 translate-middle"
                              alt=""
                            />
                          </a>
                          <div className="m-0">
                            <a
                              href="../../demo22/dist/pages/user-profile/overview.html"
                              className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base"
                            >
                              Admin Panel - How To Started the Dashboard
                              Tutorial
                            </a>
                            <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">
                              We’ve been focused on making a the from also not
                              been afraid to and step away been focused create
                              eye
                            </div>
                            <div className="fs-6 fw-bold">
                              <a
                                href="../../demo22/dist/pages/user-profile/overview.html"
                                className="text-gray-700 text-hover-primary"
                              >
                                Jane Miller
                              </a>
                              <span className="text-muted">on Mar 21 2021</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-xl-stretch mx-md-3">
                          <a
                            className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x400/img-74.jpg")',
                            }}
                            data-fslightbox="lightbox-video-tutorials"
                            href="https://www.youtube.com/embed/btornGtLwIo"
                          >
                            <img
                              src="assets/media/svg/misc/video-play.svg"
                              className="position-absolute top-50 start-50 translate-middle"
                              alt=""
                            />
                          </a>
                          <div className="m-0">
                            <a
                              href="../../demo22/dist/pages/user-profile/overview.html"
                              className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base"
                            >
                              Admin Panel - How To Started the Dashboard
                              Tutorial
                            </a>
                            <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">
                              We’ve been focused on making the from v4 to v5 but
                              we have also not been afraid to step away been
                              focused
                            </div>
                            <div className="fs-6 fw-bold">
                              <a
                                href="../../demo22/dist/pages/user-profile/overview.html"
                                className="text-gray-700 text-hover-primary"
                              >
                                Cris Morgan
                              </a>
                              <span className="text-muted">on Apr 14 2021</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-xl-stretch ms-md-6">
                          <a
                            className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x400/img-47.jpg")',
                            }}
                            data-fslightbox="lightbox-video-tutorials"
                            href="https://www.youtube.com/embed/TWdDZYNqlg4"
                          >
                            <img
                              src="assets/media/svg/misc/video-play.svg"
                              className="position-absolute top-50 start-50 translate-middle"
                              alt=""
                            />
                          </a>
                          <div className="m-0">
                            <a
                              href="../../demo22/dist/pages/user-profile/overview.html"
                              className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base"
                            >
                              Admin Panel - How To Started the Dashboard
                              Tutorial
                            </a>
                            <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">
                              We’ve been focused on making the from v4 to v5 but
                              we’ve also not been afraid to step away been
                              focused
                            </div>
                            <div className="fs-6 fw-bold">
                              <a
                                href="../../demo22/dist/pages/user-profile/overview.html"
                                className="text-gray-700 text-hover-primary"
                              >
                                Carles Nilson
                              </a>
                              <span className="text-muted">on May 14 2021</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className="modal fade"
                id="kt_modal_new_ticket"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered mw-750px">
                  <div className="modal-content rounded">
                    <div className="modal-header pb-0 border-0 justify-content-end">
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                      <form
                        id="kt_modal_new_ticket_form"
                        className="form"
                        action="#"
                      >
                        <div className="mb-13 text-center">
                          <h1 className="mb-3">Create Ticket</h1>
                          <div className="text-gray-400 fw-semibold fs-5">
                            If you need more info, please check
                            <a href="" className="fw-bold link-primary">
                              Support Guidelines
                            </a>
                            .
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-8 fv-row">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Subject</span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Specify a subject for your issue"
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter your ticket subject"
                            name="subject"
                          />
                        </div>
                        <div className="row g-9 mb-8">
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Product
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Select a product"
                              name="product"
                            >
                              <option value="">Select a product...</option>
                              <option value={1}>HTML Theme</option>
                              <option value={1}>Angular App</option>
                              <option value={1}>Vue App</option>
                              <option value={1}>React Theme</option>
                              <option value={1}>Figma UI Kit</option>
                              <option value={3}>Laravel App</option>
                              <option value={4}>Blazor App</option>
                              <option value={5}>Django App</option>
                            </select>
                          </div>
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Assign
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Select a Team Member"
                              name="user"
                            >
                              <option value="">Select a user...</option>
                              <option value={1}>Karina Clark</option>
                              <option value={2}>Robert Doe</option>
                              <option value={3}>Niel Owen</option>
                              <option value={4}>Olivia Wild</option>
                              <option value={5}>Sean Bean</option>
                            </select>
                          </div>
                        </div>
                        <div className="row g-9 mb-8">
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Status
                            </label>
                            <select
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-placeholder="Open"
                              data-hide-search="true"
                            >
                              <option value="" />
                              <option value={1} selected="selected">
                                Open
                              </option>
                              <option value={2}>Pending</option>
                              <option value={3}>Resolved</option>
                              <option value={3}>Closed</option>
                            </select>
                          </div>
                          <div className="col-md-6 fv-row">
                            <label className="required fs-6 fw-semibold mb-2">
                              Due Date
                            </label>
                            <div className="position-relative d-flex align-items-center">
                              <div className="symbol symbol-20px me-4 position-absolute ms-4">
                                <span className="symbol-label bg-secondary">
                                  <span className="svg-icon">
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x={2}
                                        y={2}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={13}
                                        y={2}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={13}
                                        y={13}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x={2}
                                        y={13}
                                        width={9}
                                        height={9}
                                        rx={2}
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <input
                                className="form-control form-control-solid ps-12"
                                placeholder="Select a date"
                                name="due_date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-8 fv-row">
                          <label className="fs-6 fw-semibold mb-2">
                            Description
                          </label>
                          <textarea
                            className="form-control form-control-solid"
                            rows={4}
                            name="description"
                            placeholder="Type your ticket description"
                            defaultValue={""}
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-semibold mb-2">
                            Attachments
                          </label>
                          <div
                            className="dropzone"
                            id="kt_modal_create_ticket_attachments"
                          >
                            <div className="dz-message needsclick align-items-center">
                              <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                  Drop files here or click to upload.
                                </h3>
                                <span className="fw-semibold fs-7 text-gray-400">
                                  Upload up to 10 files
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-15 fv-row">
                          <div className="d-flex flex-stack">
                            <div className="fw-semibold me-5">
                              <label className="fs-6">Notifications</label>
                              <div className="fs-7 text-gray-400">
                                Allow Notifications by Phone or Email
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <label className="form-check form-check-custom form-check-solid me-10">
                                <input
                                  className="form-check-input h-20px w-20px"
                                  type="checkbox"
                                  name="notifications[]"
                                  defaultValue="email"
                                  defaultChecked="checked"
                                />
                                <span className="form-check-label fw-semibold">
                                  Email
                                </span>
                              </label>
                              <label className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input h-20px w-20px"
                                  type="checkbox"
                                  name="notifications[]"
                                  defaultValue="phone"
                                />
                                <span className="form-check-label fw-semibold">
                                  Phone
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="reset"
                            id="kt_modal_new_ticket_cancel"
                            className="btn btn-light me-3"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="kt_modal_new_ticket_submit"
                            className="btn btn-primary-active"
                          >
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
