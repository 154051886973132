import React, { useEffect, useState } from "react";
import fetchData from "../Service/statsByNumber.service";
import Spinner from "../../../components/Spinner";
import fetchUsersData from "../Service/statsByUser.service";
import errorSwal from "../../../components/Alerts/errorAlert";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import { useTranslation } from "react-i18next";
import StatsByNumberDt from "../Components/StatsByNumberDt";
import { formatDate, initialDateFormat } from "../../../constants/Utils";
import DatePicker from "../../../components/reusables/DatePicker";
import DtSpinner from "../../../components/DtSpinner";
import DataTable from "react-data-table-component";
import formatDuration from "../../../Functions/formatData";
import reconnectionSwal from "../../../components/Alerts/reconnectionAlert";
import NumbersChart from "../../Dashboard/Components/NumbersChart";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const ReportNumbers = () => {
  useEffect(() => {
    Promise.all([import("../Styles/Reportings.css")]).then(() => setLoad(true));
  }, []);

  const [dataLoad, setDataLoad] = useState(false);

  const [usersData, setUserData] = useState([
    {
      user: null,
      inbound: {
        total: null,
        callers: null,
        duration: null,
        answered_calls: null,
        missed_calls: null,
        average: null,
      },
      outbound: {
        total: null,
        duration: null,
        average: null,
      },
    },
  ]);

  const [totals, setTotals] = useState({ users: 0, numbers: 0 });
  const [activeBtn, setActiveBtn] = useState(1);

  const handleDate = (active, date) => {
    setActiveBtn(active);
    setReqBody((prevData) => ({ ...prevData, period: date }));
  };

  const [reqBody, setReqBody] = useState({
    period: "today",
    dates: {
      start: "",
      end: "",
    },
  });

  const { t } = useTranslation();

  const [data, setData] = useState([
    {
      number: null,
      inbound: {
        total: null,
        callers: null,
        duration: null,
        answered_calls: null,
        missed_calls: null,
        average: null,
      },
      outbound: {
        total: null,
        duration: null,
        average: null,
      },
    },
  ]);

  const [chartData, setChartData] = useState();

  const [load, setLoad] = useState(false);

  const fetchStatsByUser = async () => {
    try {
      setDataLoad(false);

      const response = await fetchUsersData(JSON.stringify(reqBody));
      setDataLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setUserData(fetchedData.data);
      setTotals((prevData) => ({ ...prevData, users: fetchedData.total }));
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        fetchSecondTry();
      }
    }
  };

  const fetchSecondTry = async () => {
    try {
      setDataLoad(false);

      const response = await fetchUsersData(JSON.stringify(reqBody));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setUserData(fetchedData.data);
      setTotals((prevData) => ({ ...prevData, users: fetchedData.total }));
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const fetchStatsByNumber = async () => {
    try {
      const response = await fetchData(JSON.stringify(reqBody));
      setDataLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setData(fetchedData.data);
      setTotals((prevData) => ({ ...prevData, numbers: fetchedData.total }));
      const axis = fetchedData.data[0].inbound.activity.axis;
      const chartData = [
        ["title", ...fetchedData.data.map((item) => item.number)],
      ];

      for (let i = 0; i < axis.length; i++) {
        const title = axis[i];
        const values = fetchedData.data.map(
          (item) => item.inbound.activity.data[i]
        );
        chartData.push([title, ...values]);
      }

      setChartData(chartData);
    } catch (error) {
      if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        secondFetchTry();
      }
    }
  };

  const secondFetchTry = async () => {
    try {
      const response = await fetchData(JSON.stringify(reqBody));
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      } else {
        const fetchedData = await response.json();
        setData(fetchedData.data);
        setTotals((prevData) => ({ ...prevData, numbers: fetchedData.total }));
        const axis = fetchedData.data[0].inbound.activity.axis;
        const chartData = [
          ["title", ...fetchedData.data.map((item) => item.number)],
        ];

        for (let i = 0; i < axis.length; i++) {
          const title = axis[i];
          const values = fetchedData.data.map(
            (item) => item.inbound.activity.data[i]
          );
          chartData.push([title, ...values]);
        }
        setChartData(chartData);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const usersColumns = [
    {
      name: <span className="title-dt"> {t("titles.user")}</span>,

      cell: (row) => (dataLoad ? <span>{row.user}</span> : <DtSpinner />),
    },
    {
      name: <span className="title-dt">{t("titles.callers")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.callers}</span> : <DtSpinner />,
    },

    {
      name: <span className="title-dt">{t("titles.answered_calls")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.answered_calls}</span> : <DtSpinner />,
    },
    {
      name: <span className="title-dt">{t("titles.missed_calls")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.missed_calls}</span> : <DtSpinner />,
    },
    {
      name: <span className="title-dt">{t("titles.duration")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>
            {formatDuration(row.inbound.duration + row.outbound.duration)}
          </span>
        ) : (
          <DtSpinner />
        ),
    },
    {
      name: <span className="title-dt">{t("titles.average")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>
            {formatDuration(row.inbound.average + row.outbound.average)}
          </span>
        ) : (
          <DtSpinner />
        ),
    },
    {
      name: <span className="title-dt">{t("titles.total")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>{row.inbound.total + row.outbound.total}</span>
        ) : (
          <DtSpinner />
        ),
    },
  ];

  useEffect(() => {
    fetchStatsByUser();
    fetchStatsByNumber();
  }, [reqBody.period, reqBody.dates.start, reqBody.dates.end]);

  const columns = [
    {
      name: <span className="title-dt">{t("titles.numbers")}</span>,

      cell: (row) => (dataLoad ? <span>{row.number}</span> : <DtSpinner />),
    },
    {
      name: <span className="title-dt">{t("titles.callers")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.callers}</span> : <DtSpinner />,
    },

    {
      name: <span className="title-dt">{t("titles.answered_calls")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.answered_calls}</span> : <DtSpinner />,
    },
    {
      name: <span className="title-dt">{t("titles.missed_calls")}</span>,

      cell: (row) =>
        dataLoad ? <span>{row.inbound.missed_calls}</span> : <DtSpinner />,
    },
    {
      name: <span className="title-dt">{t("titles.duration")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>
            {formatDuration(row.inbound.duration + row.outbound.duration)}
          </span>
        ) : (
          <DtSpinner />
        ),
    },
    {
      name: <span className="title-dt">{t("titles.average")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>
            {formatDuration(row.inbound.average + row.outbound.average)}
          </span>
        ) : (
          <DtSpinner />
        ),
    },
    {
      name: <span className="title-dt">{t("titles.total")}</span>,

      cell: (row) =>
        dataLoad ? (
          <span>{row.inbound.total + row.outbound.total}</span>
        ) : (
          <DtSpinner />
        ),
    },
  ];

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      {!load && <Spinner />}
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid ">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div
              id="kt_app_toolbar"
              className="app-toolbar align-items-center justify-content-between py-2 py-lg-6"
            >
              <div
                className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n10 mg-min10"
                id="kt_toolbar"
              >
                <div className="d-flex flex-column align-items-start me-3 gap-2">
                  <h1 className="d-flex text-dark fw-bold m-0 fs-3">
                    {t("numbers_stats")}
                    {/* <span className="h-20px border-gray-400 border-start mx-3" /> */}
                    {/* <small className="text-gray-500 fs-7 fw-semibold my-1">
                      You have 5
                      <span className="text-primary fw-bolder">
                        Active Numbers
                      </span>
                    </small> */}
                  </h1>
                  {/* <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7">
                    <li className="breadcrumb-item text-gray-600">
                      <a href="#" className="text-gray-600 text-hover-primary">
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item text-gray-600">
                      Reportings
                    </li>
                    <li className="breadcrumb-item text-gray-500">
                      Numbers stats
                    </li>
                  </ul> */}
                </div>
                {/* <div className="d-flex align-items-center py-2">
                  <div className="me-3">
                    <a
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      className="btn btn-sm btn-flex btn-light-primary fw-bold"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <a
                    className="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_app"
                    id="kt_toolbar_primary_button"
                  >
                    New number
                  </a>
                </div> */}
                <div className="d-flex align-items-center py-2">
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 1
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(1, "today")}
                  >
                    {t("today")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 2
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(2, "yesterday")}
                  >
                    {t("yesterday")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 3
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(3, "current_month")}
                  >
                    {t("current_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 4
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-3`}
                    onClick={() => handleDate(4, "previous_month")}
                  >
                    {t("previous_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex position-relative ${
                      activeBtn === 5
                        ? "btn-primary-active"
                        : "btn-light-primary period"
                    } fw-bold me-0`}
                  >
                    {t("custom")}{" "}
                    {activeBtn === 5 ? (
                      <span>
                        {" "}
                        &#160; | &#160; {reqBody.dates.start} -{" "}
                        {reqBody.dates.end}
                      </span>
                    ) : (
                      ""
                    )}
                    <DatePicker
                      onChange={(e) => {
                        if (e.length > 1) {
                          setActiveBtn(5);
                          setReqBody((prevData) => ({
                            ...prevData,
                            period: "custom",
                            dates: {
                              start: formatDate(e[0]),
                              end: formatDate(e[1]),
                            },
                          }));
                          setDataLoad(false);
                        }
                      }}
                      date={[]}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mt-8 p-0 collapse" id="kt_advanced_search_form">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Overview
                      </span>
                      {/* <span className="text-gray-400 pt-2 fw-semibold fs-6">
                        Updated 37 minutes ago
                      </span> */}
                    </h3>
                    {/* <div className="card-toolbar"></div>
                    <ul className="nav nav-tabs nav-line-tabs mb-0 fs-6 border-0">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-flex btn-active-light-success active"
                          data-bs-toggle="tab"
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-primary me-3">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                                fill="currentColor"
                              />
                              <rect
                                opacity="0.3"
                                x={8}
                                y={3}
                                width={8}
                                height={8}
                                rx={4}
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span className="d-flex flex-column align-items-start">
                            <span className="fs-4 fw-bold">{t("persons")}</span>
                            <span className="fs-7">{t("total")} : 0</span>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="btn btn-flex btn-active-light-success"
                          data-bs-toggle="tab"
                        >
                          <span className="svg-icon svg-icon-2 svg-icon-primary me-3">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span className="d-flex flex-column align-items-start">
                            <span className="fs-4 fw-bold">{t("servers")}</span>
                            <span className="fs-7">{t("total")} : 0</span>
                          </span>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  <div className="card-body p-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_persons"
                        role="tabpanel"
                      >
                        <DataTable
                          fixedHeader
                          data={data}
                          columns={columns}
                          noDataComponent={t("data_table.no_records")}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_servers"
                        role="tabpanel"
                      >
                        {/* <table
                          id=""
                          className="table table-row-bordered gy-5 kt_datatable"
                        >
                          <thead>
                            <tr className="fw-semibold fs-6 text-muted">
                              <th>Server</th>
                              <th>First call</th>
                              <th>Last call</th>
                              <th>Total duration</th>
                              <th>Total calls</th>
                              <th>Missed calls</th>
                              <th>Av. calls duration</th>
                              <th>Total Inbound</th>
                              <th>Total Outbound </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>195.25.334.14</td>
                              <td>2021-05-24 15:00:23</td>
                              <td>2022-08-12 22:28:04</td>
                              <td>09:25:33</td>
                              <td>125</td>
                              <td>62</td>
                              <td>00:19:22</td>
                              <td>120</td>
                              <td>5</td>
                            </tr>
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="row mb-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title flex-column-rep">
                      <span className="card-label fw-bold text-dark">
                        {t("numbers_overview")}
                      </span>
                    </h3>
                    <span className="text-gray-400 pt-2 fw-semibold fs-6 position-flex-rep">
                      {t("total")}: {totals.numbers}
                    </span>
                  </div>
                  <div className="card-body p-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_persons"
                        role="tabpanel"
                      >
                        <DataTable fixedHeader data={data} columns={columns} />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_servers"
                        role="tabpanel"
                      >
                        {/* <table
                          id=""
                          className="table table-row-bordered gy-5 kt_datatable"
                        >
                          <thead>
                            <tr className="fw-semibold fs-6 text-muted">
                              <th>Server</th>
                              <th>First call</th>
                              <th>Last call</th>
                              <th>Total duration</th>
                              <th>Total calls</th>
                              <th>Missed calls</th>
                              <th>Av. calls duration</th>
                              <th>Total Inbound</th>
                              <th>Total Outbound </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>195.25.334.14</td>
                              <td>2021-05-24 15:00:23</td>
                              <td>2022-08-12 22:28:04</td>
                              <td>09:25:33</td>
                              <td>125</td>
                              <td>62</td>
                              <td>00:19:22</td>
                              <td>120</td>
                              <td>5</td>
                            </tr>
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title flex-column-rep">
                      <span className="card-label fw-bold text-dark">
                        {t("users_overview")}
                      </span>
                    </h3>
                    <span className="text-gray-400 pt-2 fw-semibold fs-6 position-flex-rep">
                      {t("total")}: {totals.users}
                    </span>
                  </div>
                  <div className="card-body p-4">
                    <DataTable
                      data={usersData}
                      columns={usersColumns}
                      noDataComponent={t("data_table.no_records")}
                    />
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="card card-flush h-xl-100">
                  <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        {t("numbers_activity")}
                      </span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                    </h3>
                  </div>
                  <div className="card-body d-flex align-items-end px-0 pt-3 pb-5 first-child-width-100">
                    <NumbersChart data={chartData} load={dataLoad} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportNumbers;
