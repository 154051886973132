import React, { useEffect } from "react";
import ListUsers from "./Views/ListUsers";
import AllocatePhoneNumbersModal from "../../components/Modals/AllocatePhoneNumbersModal";
import AddOutboundCallingCreaditModal from "../../components/Modals/AddOutboundCallingCreaditModal";
import UnallocatePhoneNumbersModal from "../../components/Modals/UnallocatePhoneNumbersModal";
import ChangePwModal from "../../components/Modals/ChangePwModal";
import SearchUsersModal from "../../components/Modals/SearchUsersModal";

const MainUsers = () => {
  useEffect(() => {
    localStorage.setItem("active", 3);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  return (
    <div>
      <ListUsers />

      {/* Modals */}
      <AllocatePhoneNumbersModal />
      <AddOutboundCallingCreaditModal />
      <UnallocatePhoneNumbersModal />
      <ChangePwModal />
      <SearchUsersModal />
    </div>
  );
};

export default MainUsers;
