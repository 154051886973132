const getTutorials = async () => {
  return await fetch(`${process.env.REACT_APP_BASE_URL}/core/articles?orderBy=id&sort=desc&limit=5`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ class: 6 }),
  });
};

export default getTutorials;
