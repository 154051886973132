import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import getOverview from "../Services/getOverview";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";

const Overview = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({
    tickets: [],
    faqs: [],
    tutorials: [],
  });

  const [display, setDisplay] = useState({ tickets: 0, faqs: 0, tutorials: 0 });

  const [executeFetch, setExecuteFetch] = useState(0);

  const fetchOverview = async () => {
    try {
      const response = await getOverview();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();

      const newData = { tickets: [], faqs: [], tutorials: [] };

      if (result.data.tickets.length > 0) {
        setDisplay((prevData) => ({ ...prevData, tickets: 1 }));
        result.data.tickets.map((item) =>
          newData.tickets.push({
            reclamation: item.subject,
            class: item.class,
            answer: item.content,
          })
        );
      } else {
        setDisplay((prevData) => ({ ...prevData, tickets: 2 }));
      }

      if (result.data.faqs.length > 0) {
        setDisplay((prevData) => ({ ...prevData, faqs: 1 }));
        result.data.faqs.map((item) =>
          newData.faqs.push({
            reclamation: item.name,
            answer: item.content,
          })
        );
      } else {
        setDisplay((prevData) => ({ ...prevData, faqs: 2 }));
      }

      if (result.data.tutorials.length > 0) {
        setDisplay((prevData) => ({ ...prevData, tutorials: 1 }));
        result.data.tutorials.map((item) =>
          newData.tutorials.push({
            reclamation: item.name,
            answer: item.content,
          })
        );
      } else {
        setDisplay((prevData) => ({ ...prevData, tutorials: 2 }));
      }

      setData(newData);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchOverview();
      }
    }
  };

  const refetchOverview = async () => {
    try {
      const response = await getOverview();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    fetchOverview();
  }, [executeFetch]);

  const [active, setActive] = useState({ ticket: 0, faq: 0, tutorial: 0 });

  useEffect(() => {
    localStorage.setItem("active", 12);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card mb-12">
                <Header active={1} />
              </div>
              <div className="row gy-0 mb-6 mb-xl-12">
                <div className="col-md-6">
                  <div className="card card-md-stretch me-xl-3 mb-md-0 mb-6">
                    <div className="card-body p-10 p-lg-15 h-35rem">
                      <div className="d-flex flex-stack mb-7">
                        <h1 className="fw-bold text-dark">
                          {t("popular_tickets")}
                        </h1>
                        <div className="d-flex align-items-center">
                          <Link
                            to="/support_tickets"
                            className="text-primary fw-bold me-1"
                          >
                            {t("support")}
                          </Link>
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>

                      {display.tickets === 1 ? (
                        data.tickets.map((element, index) => (
                          <div className="m-0">
                            <div
                              className="d-flex align-items-center collapsible py-3 toggle mb-0 cursor-pointer"
                              onClick={() =>
                                setActive((prevData) => ({
                                  ...prevData,
                                  ticket: index,
                                }))
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#kt_support_1_1"
                            >
                              <div className="ms-n1 me-5">
                                <span className="svg-icon toggle-on svg-icon-primary svg-icon-2">
                                  <svg
                                    className={`${
                                      active.ticket === index ? "" : "rotate90"
                                    } t-300`}
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                      fill={
                                        active.ticket === index
                                          ? "currentColor"
                                          : "grey"
                                      }
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <h3 className="text-gray-800 fw-semibold cursor-pointer me-3 mb-0">
                                  {element.reclamation}
                                </h3>
                                <span className="badge badge-light my-1 d-block">
                                  {element.class === 1
                                    ? t("tech_issues")
                                    : element.class === 2
                                    ? t("billing_text")
                                    : element.class === 3
                                    ? t("prod_inquiries")
                                    : element.class === 4
                                    ? t("feedback")
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {active.ticket === index && (
                              <div
                                id="kt_support_1_1"
                                className="collapse show fs-6 ms-10"
                              >
                                <div className="mb-4">
                                  <span className="text-muted fw-semibold fs-5">
                                    {element.answer}.
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : display.tickets === 2 ? (
                        <div className="h-80 d-flex align-items-center flex-column justify-content-center gap-5">
                          <img
                            src="\assets\media\illustrations\unitedpalms-1/tickets.svg"
                            height={150}
                            alt="tickets"
                          />

                          <span className="no-data-title">
                            {" "}
                            {t("no_tickets_yet")}
                          </span>
                        </div>
                      ) : (
                        <SpinnerDiv />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-md-stretch ms-xl-3">
                    <div className="card-body p-10 p-lg-15 h-35rem">
                      <div className="d-flex flex-stack mb-7">
                        <h1 className="fw-bold text-dark">{t("faq")}</h1>
                        <div className="d-flex align-items-center">
                          <Link
                            to="/support_faq"
                            className="text-primary fw-bold me-1"
                          >
                            {t("full_faq")}
                          </Link>
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      {display.faqs === 1 ? (
                        data.faqs.map((element, index) => (
                          <div className="m-0">
                            <div
                              className="d-flex align-items-center collapsible py-3 toggle mb-0 cursor-pointer"
                              onClick={() =>
                                setActive((prevData) => ({
                                  ...prevData,
                                  faq: index,
                                }))
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#kt_support_1_1"
                            >
                              <div className="ms-n1 me-5">
                                <span className="svg-icon toggle-on svg-icon-primary svg-icon-2">
                                  <svg
                                    className={`${
                                      active.faq === index ? "" : "rotate90"
                                    } t-300`}
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                      fill={
                                        active.faq === index
                                          ? "currentColor"
                                          : "grey"
                                      }
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <h3 className="text-gray-800 fw-semibold cursor-pointer me-3 mb-0">
                                  {element.reclamation}
                                </h3>
                                <span className="badge badge-light my-1 d-block">
                                  {element.class === 1
                                    ? t("tech_issues")
                                    : element.class === 2
                                    ? t("billing_text")
                                    : element.class === 3
                                    ? t("prod_inquiries")
                                    : element.class === 4
                                    ? t("feedback")
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {active.faq === index && (
                              <div
                                id="kt_support_1_1"
                                className="collapse show fs-6 ms-10"
                              >
                                <div className="mb-4">
                                  <span className="text-muted fw-semibold fs-5">
                                    {element.answer}.
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : display.faqs === 2 ? (
                        <div className="h-80 d-flex align-items-center justify-content-center flex-column gap-5">
                          <img
                            src="\assets\media\illustrations\unitedpalms-1/faqs.svg"
                            height={150}
                            alt="faqs"
                          />
                          <span className="no-data-title">
                            {" "}
                            {t("no_faqs_yet")}
                          </span>
                        </div>
                      ) : (
                        <SpinnerDiv />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gy-0 mb-6 mb-xl-12">
                <div className="col-md-6">
                  <div className="card card-md-stretch me-xl-3 mb-md-0 mb-6">
                    <div className="card-body p-10 p-lg-15 h-35rem">
                      <div className="d-flex flex-stack mb-7">
                        <h1 className="fw-bold text-dark">{t("tutorials")}</h1>
                        <div className="d-flex align-items-center">
                          <Link
                            to="/support_tutorials"
                            className="text-primary fw-bold me-1"
                          >
                            {t("all_tutorials")}
                          </Link>
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      {display.tutorials === 1 ? (
                        data.tutorials.map((element, index) => (
                          <div className="m-0">
                            <div
                              className="d-flex align-items-center collapsible py-3 toggle mb-0 cursor-pointer"
                              onClick={() =>
                                setActive((prevData) => ({
                                  ...prevData,
                                  tutorial: index,
                                }))
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#kt_support_1_1"
                            >
                              <div className="ms-n1 me-5">
                                <span className="svg-icon toggle-on svg-icon-primary svg-icon-2">
                                  <svg
                                    className={`${
                                      active.tutorial === index
                                        ? ""
                                        : "rotate90"
                                    } t-300`}
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                      fill={
                                        active.tutorial === index
                                          ? "currentColor"
                                          : "grey"
                                      }
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <h3 className="text-gray-800 fw-semibold cursor-pointer me-3 mb-0">
                                  {element.reclamation}
                                </h3>
                                <span className="badge badge-light my-1 d-block">
                                  {element.class === 1
                                    ? t("tech_issues")
                                    : element.class === 2
                                    ? t("billing_text")
                                    : element.class === 3
                                    ? t("prod_inquiries")
                                    : element.class === 4
                                    ? t("feedback")
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {active.tutorial === index && (
                              <div
                                id="kt_support_1_1"
                                className="collapse show fs-6 ms-10"
                              >
                                <div className="mb-4">
                                  <span className="text-muted fw-semibold fs-5">
                                    {element.answer}.
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : display.tutorials === 2 ? (
                        <div className="h-80 d-flex align-items-center justify-content-center flex-column gap-5">
                          <img
                            src="\assets\media\illustrations\unitedpalms-1/tutorials.svg"
                            height={150}
                            alt="tutorials"
                          />

                          <span className="no-data-title">
                            {" "}
                            {t("no_tutorials_yet")}{" "}
                          </span>
                        </div>
                      ) : (
                        <SpinnerDiv />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-md-stretch ms-xl-3">
                    <div className="card-body pp-10 p-lg-15">
                      <div className="d-flex flex-stack mb-7">
                        <h1 className="fw-bold text-dark">{t("videos")}</h1>
                        {/* <div className="d-flex align-items-center">
                          <a
                            href="https://www.youtube.com/c/KeenThemesTuts/videos"
                            target="_blank"
                            className="text-primary fw-bold me-1"
                          >
                            {t("all_videos")}
                          </a>
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={18}
                                y={13}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div> */}
                      </div>
                      {/* <div>
                        <iframe
                          className="embed-responsive-item card-rounded h-275px w-100"
                          src="https://www.youtube.com/embed/TWdDZYNqlg4"
                          allowFullScreen="allowfullscreen"
                        />
                      </div> */}
                      <div className="h-80 d-flex align-items-center justify-content-center flex-column gap-5">
                        <img
                          src="\assets\media\illustrations\unitedpalms-1/videos.svg"
                          height={150}
                          alt="videos"
                        />
                        <span className="no-data-title">{t("no_videos")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mb-2">
                <div className="card-body p-10 p-lg-15">
                  <h1 className="fw-bold text-dark mb-12 ps-0">
                    Products Documentations
                  </h1>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Free Admin Dashboard
                        </a>
                      </div>
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Bootstrap 5 Admin Template
                        </a>
                      </div>
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Google Admin Dashboard
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Free Vector Laravel Starter Kit
                        </a>
                      </div>
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          React Admin Dashboard
                        </a>
                      </div>
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          HTML Admin Dashboard
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Best VueJS Admin Template
                        </a>
                      </div>
                      <div className="d-flex align-items-center mb-6">
                        <span className="svg-icon svg-icon-2 ms-n1 me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <a
                          href="#"
                          className="fw-semibold text-gray-800 text-hover-primary fs-3 m-0"
                        >
                          Forest Front-end Template
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
