import React from 'react'
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";

const CostChart = ({data, load}) => {

    let maximum = 3

    if(data){
    
      for (let call of data){
        if(call[1]>maximum){
    maximum=call[1]
        }
      }
    }
    
    
    
    const options = {
      vAxis: {viewWindow: { min: 0, max: maximum<9?maximum:maximum+4 } },curveType: "function",
    }

    
  return (
    <div>
          {!load ? (
        <SpinnerDiv/>
      ) : (
        <Chart
          chartType="LineChart"
          width="100%"
          height="350px"
          data={data}
          options={options}
        />
      )}
    </div>
  )
}

export default CostChart