const sendMessage = async (body) => {

  const formData = new FormData();

  formData.append("ticket_id", body.ticket_id);
  formData.append("content", body.content);
  formData.append("files['TICKETS_ATTACHMENT']", body.attachement);

  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/interface/ticket/comment/create`,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    }
  );
};

export default sendMessage;
