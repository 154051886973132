import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { convertCurrency, defaultDateFormat, defaultOnlyDateFormat, getLocale } from "../../../constants/Utils";
import AllocateUserModal from "./Modals/AllocateUserModal";
import AddPaymentModal from "./Modals/AddPaymentModal";
import AddOutboundCallingCreditModal from "./Modals/AddOutboundCallingCreditModal";
import AddOutboundCallingScope from "./Modals/AddOutboundCallingScope";
import CallsAndSmsModal from "./Modals/CallsAndSmsModal";
import InvoicesModal from "./Modals/InvoicesModal";
import DeleteNumberModal from "./Modals/DeleteNumberModal";

const DataTableNumbers = ({ numbers, changeData }) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(null);
  const [dataIndex, setDataIndex] = useState(null);

  const callsColumns = [
    {
      name: t("number_dt.number"),
      sortable: true,
      selector: "number",
      cell: (row) => <span className="fw-bold">{row.number}</span>,
    },
    {
      name: t("number_dt.features"),
      sortable: true,
      selector: "features",

      cell: (row) => {
        if (row.direction && Array.isArray(row.direction)) {
          return (
            <div className="features-dt">
              {row.direction.map((direction) => (
                <div className="pointer-event-none badge badge-light">
                  {direction === "Voice IN"
                    ? `${t("voice_in")}`
                    : direction === "Voice OUT"
                    ? `${t("voice_out")}`
                    : direction === "SMS IN"
                    ? `${t("sms_in")}`
                    : `${t("sms_out")}`}
                </div>
              ))}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      name: t("number_dt.users"),
      cell: (row, rowIndex) => (
        <div>
          {row.extensions && row.extensions.length > 0 ? (
            <div className="symbol-group symbol-hover mb-1">
              {row.extensions
                .filter((element, index) => index < 3)
                .map((item) => (
                  <div className="symbol symbol-circle symbol-25px">
                    <img
                      src={
                        item.avatar
                          ? item.avatar
                          : "/assets/media/avatars/blank.png"
                      }
                      alt=""
                    />
                  </div>
                ))}

              {row.extensions.length > 3 && (
                <div className="symbol symbol-circle symbol-25px">
                  <div className="symbol-label bg-dark">
                    <span className="fs-8 text-inverse-dark">
                      {row.extensions.length - 3}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <span
              className={`add-number-button disabled-event`}
              onClick={() => {
                setDataIndex(rowIndex);
                handleModalDisplay("allocate_users", true);
              }}
            >
              <img
                src="/assets/media/icons/plus-icon.png"
                className="add-number-btn"
                alt=""
              />
            </span>
          )}
        </div>
      ),
    },
    {
      name: t("number_dt.outbound_credit"),
      sortable: true,
      selector: (row) => (
        <span className="badge badge-light pointer-event-none">
          {row.outbound_credits} {convertCurrency("USD")}
        </span>
      ),
    },
    {
      name: t("number_dt.outbound_scope"),
      cell: (row, rowIndex) => (
        <div>
          {row.outbound_scope.length > 0 ? (
            <div className="symbol-group symbol-hover mb-1">
              {row.outbound_scope &&
                row.outbound_scope_name
                  .filter((element, index) => index < 3)
                  .map((item) => (
                    <div className="symbol symbol-25px symbol-circle ">
                      <img
                        className="flag-style obj-fit-cover"
                        src={`assets/media/flags/${item.toLowerCase()}.svg`}
                        alt=""
                      />
                    </div>
                  ))}
              {row.outbound_scope.length > 3 && (
                <div className="symbol symbol-circle symbol-25px">
                  <div className="symbol-label bg-dark">
                    <span className="fs-8 text-inverse-dark">
                      {row.outbound_scope.length - 3}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <span
              className={`add-number-button ${
                row.status === 3 ? "disabled-event" : ""
              } `}
              onClick={() => {
                setDataIndex(rowIndex);
                handleModalDisplay("add_outbound_scope", true);
              }}
            >
              <img
                src="/assets/media/icons/plus-icon.png"
                className="add-number-btn"
                alt=""
              />
            </span>
          )}
        </div>
      ),
    },
    {
      name: t("titles.creation_date"),
      sortable: true,
      selector: "starting_date",
      cell: (row) => (
        <span>{row.starting_date && defaultDateFormat(row.starting_date)}</span>
      ),
    },
    {
      name: t("number_dt.next_pay_date"),
      sortable: true,
      selector: "next_pay_date",
      cell: (row) => (
        
        <span>
          {row.renewal_date && defaultOnlyDateFormat(row.renewal_date)}
        </span>
      ),
    },
    {
      name: t("number_dt.status"),
      cell: (row) => (
        <span
          className={`pointer-event-none badge margin-dim ${
            row.status === 1 || row.status === 5
              ? "badge-light-success"
              : row.status === 3
              ? "badge-light-danger"
              : row.status === 4 || row.status === 2
              ? "badge-light-warning"
              : "badge-light-warning"
          }`}
        >
          {row.status === 1 || row.status === 5
            ? t("active")
            : row.status === 3
            ? t("disabled")
            : row.status === 4 || row.status === 2
            ? t("pending")
            : ""}
        </span>
      ),
    },
    {
      name: t("number_dt.actions"),
      cell: (row, rowIndex) => (
        <div className="pe-0 text-end" onMouseLeave={() => setIndex(null)}>
          <span
            className="btn btn-sm btn-light btn-active-light-primary show menu-dropdown"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            onClick={() => {
              setIndex(rowIndex);
              setDataIndex(rowIndex);
            }}
          >
            {t("actions")}
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </span>

          <div
            className={`width-active menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 py-4 menu-numbers ${
              rowIndex === index ? "active show " : ""
            }`}
          >
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 disabled-event ${
                  row.status !== 1 ? " disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_allocate_users"
                onClick={() => handleModalDisplay("allocate_users", true)}
              >
                {t("allocate_users")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status !== 1 ? " disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_payment"
                onClick={() => handleModalDisplay("add_payment", true)}
              >
                {t("add_payment")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 3 ? " disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_outbound_credit"
                onClick={() => handleModalDisplay("add_outbound_credit", true)}
              >
                {" "}
                {t("add_outbound_calling_credit")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 3 ? " disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_outbound_scope"
                onClick={() => handleModalDisplay("add_outbound_scope", true)}
              >
                {t("add_outbound_calling_scope")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 2 || row.status === 4 ? "disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_cdr"
                onClick={() => handleModalDisplay("calls_sms", true)}
              >
                {t("calls")} &amp; {t("sms_list")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 2 || row.status === 4 ? "disabled-event" : ""
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_list_invoices"
                onClick={() => handleModalDisplay("invoices", true)}
              >
                {t("invoices")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 3 ? " disabled-event" : ""
                }`}
                data-kt-customer-table-filter="delete_row"
                onClick={() => handleModalDisplay("delete_number", true)}
              >
                {t("delete")}
              </span>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [modals, setModals] = useState({
    allocate_users: false,
    add_payment: false,
    add_outbound_credit: false,
    add_outbound_scope: false,
    calls_sms: false,
    invoices: false,
    delete_number: false,
  });

  const handleModalDisplay = (item, value) => {
    setModals((prevData) => ({ ...prevData, [item]: value }));
  };

  return (
    <div>
      <div className="header-color"></div>
      <DataTable
        className="table align-middle table-row-dashed fs-6 fw-bold gy-5"
        columns={callsColumns}
        data={numbers}
        fixedHeader
        sortable
      ></DataTable>

      {modals.allocate_users && (
        <AllocateUserModal
          changeData={changeData}
          closeModal={() => handleModalDisplay("allocate_users", false)}
          number={numbers[dataIndex].number}
          extensions={numbers[dataIndex].extensions}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          number_id={numbers[dataIndex].id}
        />
      )}

      {modals.add_payment && (
        <AddPaymentModal
          closeModal={() => handleModalDisplay("add_payment", false)}
          number={numbers[dataIndex].number}
          number_id={numbers[dataIndex].id}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
        />
      )}

      {modals.add_outbound_credit && (
        <AddOutboundCallingCreditModal
          number_id={numbers[dataIndex].id}
          number={numbers[dataIndex].number}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          closeModal={() => handleModalDisplay("add_outbound_credit", false)}
          changeData={changeData}
          number_credits={numbers[dataIndex].outbound_credits}
        />
      )}

      {modals.add_outbound_scope && (
        <AddOutboundCallingScope
          changeData={changeData}
          number_scopes={numbers[dataIndex].outbound_scope}
          number={numbers[dataIndex].number}
          number_id={numbers[dataIndex].id}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          closeModal={() => handleModalDisplay("add_outbound_scope", false)}
        />
      )}

      {modals.calls_sms && (
        <CallsAndSmsModal
          number={numbers[dataIndex].number}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          closeModal={() => handleModalDisplay("calls_sms", false)}
        />
      )}

      {modals.invoices && (
        <InvoicesModal
          number={numbers[dataIndex].number}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          number_id={numbers[dataIndex].id}
          closeModal={() => handleModalDisplay("invoices", false)}
        />
      )}

      {modals.delete_number && (
        <DeleteNumberModal
          number={numbers[dataIndex].number}
          country={
            numbers[dataIndex].country
              ? numbers[dataIndex].country.toLowerCase()
              : "unknown"
          }
          changeData={changeData}
          closeModal={() => handleModalDisplay("delete_number", false)}
          number_id={numbers[dataIndex].id}
        />
      )}
    </div>
  );
};

export default DataTableNumbers;
