import Swal from "sweetalert2";

export const successSwall = (message)=>{
    Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: true,
        timer: 99999,
        customClass: {
          container: 'success-swall' 
        }
      });
}