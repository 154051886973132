import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({ data }) => {

  

let maximum = 0


for(let country of data){

  if(country[1]>maximum){
    maximum = country[1]
  }
  if(country[2]>maximum){
    maximum = country[2]
  }

}

 
  const options = {
    bar: { groupWidth: "25%" },
    hAxis: { viewWindow: { min: 0, max: maximum<9?9:maximum+9 } }
  };

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default BarChart;
