import React, { useEffect, useState } from "react";
import NumberSummary from "../Components/AddNumberForm/NumberSummary";
import NumberDetails from "../Components/AddNumberForm/NumberDetails";
import OutboundCallingScope from "../Components/AddNumberForm/OutboundCallingScope";
import PaymentMethods from "../Components/AddNumberForm/PaymentMethods";
import Spinner from "../../../components/Spinner";
import { getNumberOldServices } from "../Services/getNumbersOldServices";
import { useTranslation } from "react-i18next";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import { createNumber } from "../Services/createNumber";
import { successSwall } from "../../../components/Alerts/successAlert";
import SpinnerSecondary from "../../../components/SpinnerSecondary";
import CountriesAndContinents from "../../../Data/CountriesAndContinents.json";
import getPricing from "../../Pricing/Services/getPricing";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";
import PaymentFormModal from "../Components/Modals/PaymentFormModal";

const AddNumber = () => {
  const [load, setLoad] = useState({ css: true, data: true });

  useEffect(() => {
    Promise.all([import("../Style/BuyNumber.css")]).then(() =>
      setLoad((prevData) => ({ ...prevData, css: false }))
    );
  }, []);

  const [data, setData] = useState({});

  const [modalDisplay, setModalDisplay] = useState(false);

  const { t } = useTranslation();

  const [reqBody, setReqBody] = useState({
    quantity: 1,
    country: null,
    number_type_id: null,
    features: [],
    outbound_credit: 0,
    outbound_scope: null,
    consecutive: false,
    
  });

  const [pricingData, setPricingData] = useState({
    exists: false,
    data: {
      numbers: [
        {
          type: "phone.local",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },

        {
          type: "phone.tollf",
          setup_cost: { price: null, discount: 0 },
          price: { price: null, discount: 0 },
        },
      ],
      calls: [
        {
          type: "phone.local",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
        {
          type: "phone.tollf",
          outbound_calls: { price: null, discount: 0 },
          inbound_calls: { price: null, discount: 0 },
        },
      ],
      sms: [
        {
          type: "phone.local",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.mobile",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.national",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
        {
          type: "phone.tollf",
          outbound_sms: { price: null, discount: 0 },
          inbound_sms: { price: null, discount: 0 },
        },
      ],

      // addons: [
      //   { type: "call_forwarding", price: null, discount: 0 },
      //   { type: "call_recording", price: null, discount: 0 },
      //   { type: "recording_storage", price: null, discount: 0 },
      //   { type: "answering_machine_detection", price: null, discount: 0 },
      //   { type: "voice_mail", price: null, discount: 0 },
      //   { type: "ivr", price: null, discount: 0 },
      //   { type: "sms_autoresponder", price: null, discount: 0 },
      //   { type: "advanced_insights", price: null, discount: 0 },
      // ],
    },
  });

  const [addons, setAddons] = useState([]);

  const fetchPricing = async () => {
    try {
      setPricingLoading(true);
      const response = await getPricing(reqBody.country);
      setPricingLoading(false);

      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.exist) {
        setPricingData((prevData) => ({ ...prevData, exists: true }));

        const types = [
          "phone.local",
          "phone.mobile",
          "phone.tollf",
          "phone.national",
        ];

        types.forEach((item) => {
          const tariffs = result.data.tariffs[item];

          const getAmount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.amount || null;

          const getDiscount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.discount?.amount || 0;

          const getMinPrice = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map(
                    (item) => item.amount
                  )
                )
              : null;
          const getMinDiscount = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map((item) =>
                    item.discount?.amount ? parseFloat(item.discount.amount) : 0
                  )
                )
              : 0;

          if (result.data.products.includes(item)) {
            const mapNumber = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                setup_cost: {
                  price: getAmount(1),
                  discount: getDiscount(1),
                },
                price: {
                  price: getAmount(2),
                  discount: getDiscount(2),
                },
              };
            };

            const mapCalls = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_calls: {
                  price: getMinPrice("call.out"),
                  discount: getMinDiscount("call.out"),
                },
                inbound_calls: {
                  price: getMinPrice("call.in"),
                  discount: getMinDiscount("call.in"),
                },
              };
            };

            const mapSMS = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_sms: {
                  price: getMinPrice("sms.out"),
                  discount: getMinDiscount("sms.out"),
                },
                inbound_sms: {
                  price: getMinPrice("sms.in"),
                  discount: getMinDiscount("sms.in"),
                },
              };
            };

            setPricingData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  mapNumber(number, item)
                ),
                calls: prevData.data.calls.map(mapCalls),
                sms: prevData.data.sms.map(mapSMS),
              },
            }));
          } else {
            setPricingData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        setup_cost: { price: null, discount: 0 },
                        price: { price: null, discount: 0 },
                      }
                    : number
                ),
                calls: prevData.data.calls.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_calls: { price: null, discount: 0 },
                        inbound_calls: { price: null, discount: 0 },
                      }
                    : number
                ),
                sms: prevData.data.sms.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_sms: { price: null, discount: 0 },
                        inbound_sms: { price: null, discount: 0 },
                      }
                    : number
                ),
              },
            }));
          }
        });

        const newAddons = [];
        result.data.addons.forEach((element) =>
          newAddons.push(element.replace(".", "_"))
        );
        setAddons(newAddons);
      } else {
        setData((prevData) => ({ ...prevData, exists: false }));
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchPricing();
      }
    }
  };

  const refetchPricing = async () => {
    try {
      setPricingLoading(true);
      const response = await getPricing(reqBody.country);
      setPricingLoading(false);

      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.exist) {
        setPricingData((prevData) => ({ ...prevData, exists: true }));

        const types = [
          "phone.local",
          "phone.mobile",
          "phone.tollf",
          "phone.national",
        ];

        types.forEach((item) => {
          const tariffs = result.data.tariffs[item];

          const getAmount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.amount || null;

          const getDiscount = (billingModel) =>
            tariffs.prices.find((el) => el.billing_model === billingModel)
              ?.discount?.amount || 0;

          const getMinPrice = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map(
                    (item) => item.amount
                  )
                )
              : null;
          const getMinDiscount = (capability) =>
            tariffs.capabilities[capability] &&
            tariffs.capabilities[capability].length > 0
              ? Math.min(
                  ...(tariffs.capabilities[capability] || []).map((item) =>
                    item.discount?.amount ? parseFloat(item.discount.amount) : 0
                  )
                )
              : 0;

          if (result.data.products.includes(item)) {
            const mapNumber = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                setup_cost: {
                  price: getAmount(1),
                  discount: getDiscount(1),
                },
                price: {
                  price: getAmount(2),
                  discount: getDiscount(2),
                },
              };
            };

            const mapCalls = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_calls: {
                  price: getMinPrice("call.out"),
                  discount: getMinDiscount("call.out"),
                },
                inbound_calls: {
                  price: getMinPrice("call.in"),
                  discount: getMinDiscount("call.in"),
                },
              };
            };

            const mapSMS = (number) => {
              if (number.type !== item) return number;

              return {
                type: item,
                outbound_sms: {
                  price: getMinPrice("sms.out"),
                  discount: getMinDiscount("sms.out"),
                },
                inbound_sms: {
                  price: getMinPrice("sms.in"),
                  discount: getMinDiscount("sms.in"),
                },
              };
            };

            setPricingData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  mapNumber(number, item)
                ),
                calls: prevData.data.calls.map(mapCalls),
                sms: prevData.data.sms.map(mapSMS),
              },
            }));
          } else {
            setPricingData((prevData) => ({
              ...prevData,
              data: {
                ...prevData.data,
                numbers: prevData.data.numbers.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        setup_cost: { price: null, discount: 0 },
                        price: { price: null, discount: 0 },
                      }
                    : number
                ),
                calls: prevData.data.calls.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_calls: { price: null, discount: 0 },
                        inbound_calls: { price: null, discount: 0 },
                      }
                    : number
                ),
                sms: prevData.data.sms.map((number) =>
                  number.type === item
                    ? {
                        type: item,
                        outbound_sms: { price: null, discount: 0 },
                        inbound_sms: { price: null, discount: 0 },
                      }
                    : number
                ),
              },
            }));
          }
        });

        const newAddons = [];
        result.data.addons.forEach((element) =>
          newAddons.push(element.replace(".", "_"))
        );
        setAddons(newAddons);
      } else {
        setData((prevData) => ({ ...prevData, exists: false }));
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    reqBody.country && fetchPricing();
  }, [reqBody.country]);

  const [prices, setPrices] = useState({
    setup: { price: 0, discount: 0 },
    fees: { price: 0, discount: 0 },
  });

  const handleChangeFeatures = (item) => {
    const selectedTypeTagCode = data.services.number_types.find(
      (item) => item.service_id === reqBody.number_type_id
    ).tagcode;

    const selectedFeature = data.services.features.find(
      (feature) => feature.service_id === item
    ).tagcode;

    const modifiedFeature = selectedFeature.slice(0, -2) + "out";

    const scope = Object.keys(
      data.countries[reqBody.country].number_types[selectedTypeTagCode].features
    ).includes(modifiedFeature)
      ? data.countries[reqBody.country].number_types[selectedTypeTagCode]
          .features[modifiedFeature].scope
      : [];

    if (!reqBody.features.find((feature_id) => feature_id === item)) {
      scope.forEach((element) => {
        if (
          !scopeCountries.find((item) => item.iso_code === element.iso_code)
        ) {
          setScopeCountries((prevData) => [
            ...prevData,
            {
              id: [element.id],
              iso_code: element.iso_code,
              continent: CountriesAndContinents.find(
                (item) => item.code === element.iso_code
              ).continent,
            },
          ]);
        } else {
          const index = scopeCountries.findIndex(
            (item) => item.iso_code === element.iso_code
          );
          let updatedScope = scopeCountries[index].id;
          if (!updatedScope.find((item) => item === element.id)) {
            updatedScope.push(element.id);
          }
        }
      });
    } else {
      scope.forEach((element) => {
        const index = scopeCountries.findIndex(
          (item) => item.iso_code === element.iso_code
        );

        const unselectedFeatureScope = scopeCountries[index].id.filter(
          (item) => item !== element.id
        );

        setScopeCountries((prevData) => {
          if (prevData[index]) {
            prevData[index].id = unselectedFeatureScope;

            return prevData;
          } else {
            return [];
          }
        });
      });

      setScopeCountries((prevData) => prevData.filter((item) => item.id > 0));
    }

    const selectedFeatures = reqBody.features;

    if (!selectedFeatures.includes(item)) {
      if (callingScope) {
        if (
          Object.keys(
            data.countries[reqBody.country].number_types[
              data.services.number_types.find(
                (item) => item.service_id === reqBody.number_type_id
              ).tagcode
            ].features
          )
        ) {
        }
        const selectedFeatureOut =
          data.services.features
            .find((element) => element.service_id === item)
            .tagcode.slice(0, -2) + "out";
        selectedFeatures.push(
          data.services.features.find(
            (element) => element.tagcode === selectedFeatureOut
          ).service_id
        );
      }
      selectedFeatures.push(item);
      setReqBody((prevData) => ({ ...prevData, features: selectedFeatures }));
    } else {
      let updatedFeatures = [];
      if (callingScope) {
        const unselectedFeatureOut =
          data.services.features
            .find((element) => element.service_id === item)
            .tagcode.slice(0, -2) + "out";
        const unselectedFeatureServiceId = data.services.features.find(
          (element) => element.tagcode === unselectedFeatureOut
        ).service_id;

        updatedFeatures = selectedFeatures.filter(
          (feature) =>
            feature !== item && feature !== unselectedFeatureServiceId
        );
      } else {
        updatedFeatures = selectedFeatures.filter(
          (feature) => feature !== item
        );
      }

      setReqBody((prevData) => ({ ...prevData, features: updatedFeatures }));
    }
  };

  useEffect(() => {
    if (reqBody.number_type_id) {
      const selectedTypeTagCode = data.services.number_types.find(
        (item) => item.service_id === reqBody.number_type_id
      ).tagcode;

      const numberTypePrice = pricingData.data.numbers.find(
        (item) => item.type.replace(".", "") === selectedTypeTagCode
      );

      setPrices({
        setup: {
          price: numberTypePrice.setup_cost.price,
          discount: numberTypePrice.setup_cost.discount,
        },
        fees: {
          price: numberTypePrice.price.price,
          discount: numberTypePrice.price.discount,
        },
      });
    }
  }, [reqBody.number_type_id]);

  const handleChangeReqBody = (item, value) => {
    setReqBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const getServices = async () => {
    try {
      const response = await getNumberOldServices();
      setLoad((prevData) => ({ ...prevData, data: false }));
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        getServicesSecondTry();
      }
    }
  };

  const getServicesSecondTry = async () => {
    try {
      const response = await getNumberOldServices();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleChangeOutboundCallingScope = () => {
    const features = reqBody.features;

    if (!callingScope) {
      setCallingScope(true);
      features.forEach((element) => {
        const outboundFeature =
          data.services.features
            .find((item) => item.service_id === element)
            .tagcode.slice(0, -2) + "out";

        if (
          Object.keys(
            data.countries[reqBody.country].number_types[
              data.services.number_types.find(
                (item) => item.service_id === reqBody.number_type_id
              ).tagcode
            ].features
          ).find((item) => item === outboundFeature)
        ) {
          const newFeature = data.services.features.find(
            (item) => item.tagcode === outboundFeature
          ).service_id;
          features.push(newFeature);

          // ----- HERE -----
          if (
            Object.keys(
              data.countries[reqBody.country].number_types[
                data.services.number_types.find(
                  (item) => item.service_id === reqBody.number_type_id
                ).tagcode
              ].features
            ).includes(outboundFeature)
          ) {
          }
        }
      });
    } else {
      features.forEach((element) => {
        let outboundFeature = "";
        if (
          data.services.features
            .find((item) => item.service_id === element)
            .tagcode.includes("out")
        ) {
          outboundFeature = data.services.features.find(
            (item) => item.service_id === element
          ).tagcode;
        } else {
          outboundFeature =
            data.services.features
              .find((item) => item.service_id === element)
              .tagcode.slice(0, -2) + "out";
        }

        const newFeature = data.services.features.find(
          (item) => item.tagcode === outboundFeature
        ).service_id;

        const index = features.indexOf(newFeature);
        if (index !== -1) {
          features.splice(index, 1);
        }
      });
      setCallingScope(false);
    }
  };

  const [pricingLoading, setPricingLoading] = useState(false);

  const handleChangeCountry = (value) => {
    setReqBody({
      quantity: 1,
      country: value,
      number_type_id: null,
      features: [],
      outbound_credit: 0,
      outbound_scope: [],
    });
    setPrices({
      setup: { price: 0, discount: 0 },
      fees: { price: 0, discount: 0 },
    });
    if (callingScope) {
      setCallingScope(false);
    }
  };

  useEffect(() => {
    if (callingScope) {
      setCallingScope(false);
    }
    if (scopeCountries.length > 0) {
      setScopeCountries([]);
    }
  }, [reqBody.number_type_id]);

  const [callingScope, setCallingScope] = useState(false);

  const [scopeCountries, setScopeCountries] = useState([]);

  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const handleCreateNumber = async () => {
    try {
      setSpinnerLoad(true);
      const response = await createNumber(JSON.stringify(reqBody));
      setSpinnerLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result =  await response.json()
      setResourceId(result.data.resource_id)
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("active", 2);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const [selectedScopeCountries, setSelectedScopeCountries] = useState([]);

  const [resourceId, setResourceId] = useState(null);

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      {(load.data || load.css) && <Spinner />}
      {spinnerLoad && <SpinnerSecondary />}
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                  <form
                    className="form"
                    action="#"
                    id="kt_subscriptions_create_new"
                  >
                    <NumberDetails
                      countries={data}
                      changeReqBody={handleChangeReqBody}
                      setScope={handleChangeOutboundCallingScope}
                      quantity={reqBody.quantity}
                      country={reqBody.country}
                      changeCountry={handleChangeCountry}
                      updateFeatures={handleChangeFeatures}
                      features={reqBody.features}
                      numberType={reqBody.number_type_id}
                      scopeButton={callingScope}
                      consecutive={reqBody.consecutive}
                      loading={pricingLoading}
                    />
                    <OutboundCallingScope
                      changeReqBody={handleChangeReqBody}
                      scope={callingScope}
                      scopeCountries={scopeCountries}
                      numberType={
                        reqBody.number_type_id &&
                        data.services.number_types.find(
                          (item) => item.service_id === reqBody.number_type_id
                        ).tagcode
                      }
                      data={data.countries && data.countries}
                      features={data.services && data.services.features}
                      selectedCountry={reqBody.country}
                      selectedFeatures={reqBody.features}
                      updateScopeCountries={(item) => {
                        setSelectedScopeCountries(item);
                      }}
                    />
                    {/* <PaymentMethods changeReqBody={handleChangeReqBody}/> */}
                  </form>
                </div>
                <NumberSummary
                  prices={prices}
                  reqBody={reqBody}
                  addons={addons}
                  createNumber={handleCreateNumber}
                  numberType={
                    reqBody.number_type_id &&
                    data.services.number_types.find(
                      (item) => item.service_id === reqBody.number_type_id
                    ).tagcode
                  }
                  features={
                    reqBody.features.length > 0 && data.services.features
                  }
                  countryData={
                    reqBody.country && data.countries[reqBody.country]
                  }
                  selectedScopeCountries={selectedScopeCountries}
                  setSpinnerLoad={(item) => setSpinnerLoad(item)}
                  openModal={() => setModalDisplay(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalDisplay && (
        <PaymentFormModal
          resourceId={resourceId}
          numberDesc={reqBody}
          addons={addons}
          selectedScopeCountries={selectedScopeCountries}
          features={reqBody.features.length > 0 && data.services.features}
          numberType={
            reqBody.number_type_id &&
            data.services.number_types.find(
              (item) => item.service_id === reqBody.number_type_id
            ).tagcode
          }
          prices={prices}
          createNumber={handleCreateNumber}
          price={
            (prices.setup.price ? parseFloat(prices.setup.price) : 0) +
            (prices.fees.price ? parseFloat(prices.fees.price) : 0) -
            (prices.setup.discount ? parseFloat(prices.setup.discount) : 0) -
            (prices.fees.discount ? parseFloat(prices.fees.discount) : 0)
          }
          closeModal={() => setModalDisplay(false)}
          setSpinner={(item) => setSpinnerLoad(item)}
        />
      )}
    </div>
  );
};

export default AddNumber;
