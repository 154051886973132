import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import React, { useEffect, useRef, useState } from "react";
import "../Style/Documents.css";
import AddDocumentModal from "../../../components/Modals/AddDocumentModal";
import UpdateDocumentModal from "../../../components/Modals/UpdateDocumentModal";
import WarningModal from "../../../components/Modals/WarningModal";

import Swal from "sweetalert2";
import { defaultDateFormat } from "../../../constants/Utils";
import ErrorSwal from "../../../components/Alerts/errorAlert";

const Documents = ({ type }) => {
  const { t } = useTranslation();

  const [data, setData] = useState([
    {
      index: 0,
      id: null,
      class: 1,
      title: t("passport"),
      document: "",
      uploaded_at: "",
      status: 0,
    },
    {
      index: 1,
      id: null,
      class: 4,
      title: t("registry"),
      document: "",
      uploaded_at: "",
      status: 0,
    },
    {
      index: 2,
      id: null,
      class: 3,
      title: t("tax_doc"),
      document: "",
      uploaded_at: "",
      status: 0,
    },
  ]);

  // SWALLS

  const secondTrySwall = () => {
    let timerInterval;

    Swal.fire({
      title: "Trying to reconnect!",
      html: "Next try in <b>0%</b>.",
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          const percentage = (
            ((1000 - Swal.getTimerLeft()) / 1000) *
            100
          ).toFixed(0);
          timer.textContent = `${percentage}%`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  };


  // FETCH DATA
  const getDocumentsSecondTry = async () => {
    {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/commerce/customers/documents?with_terms=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(response.status);
        }

        const fetchedData = await response.json();

        if (fetchedData && fetchedData.length > 0) {
          setData((prevData) =>
            prevData.map((prevItem) => {
              const newItem = fetchedData.find(
                (item) => item.class === prevItem.class
              );

              if (newItem) {
                const uploadedAtDate = new Date(newItem.date_start);
                const formattedUploadedAt = uploadedAtDate.toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                );
                return {
                  ...prevItem,
                  id: newItem.id,
                  class: newItem.class,
                  document: newItem.url,
                  uploaded_at: formattedUploadedAt,
                  status: 1,
                };
              }

              return prevItem;
            })
          );
        }
      } catch (error) {
        ErrorSwal("errors.500");
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/commerce/customers/documents?with_terms=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.status);
      } else {
        const allFetchedData = await response.json();

        const fetchedData = allFetchedData.data;

        if (fetchedData && fetchedData.length > 0) {
          setData((prevData) =>
            prevData.map((prevItem) => {
              const newItem = fetchedData.find(
                (item) => item.class === prevItem.class
              );

              if (newItem) {
                return {
                  ...prevItem,
                  id: newItem.id,
                  class: newItem.class,
                  document: newItem.url,
                  uploaded_at: newItem.date_start,
                  status: 1,
                };
              }

              return prevItem;
            })
          );
        }
      }
    } catch (error) {
      if (error.message == 401) {
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal("errors.406");
      } else {
        secondTrySwall();
        getDocumentsSecondTry();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openFileInNewWindow = (filePath) => {
    window.open(filePath, "_blank");
  };

  const columns = [
    {
      name: t("titles.title"),
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column justify-content-center">
            <span className="fs-6 text-gray-800">
              {row.title}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: t("titles.documents"),
      cell: (row) =>
        row.status > 0 ? (
          <div className="symbol symbol-35px">
            {row.document && row.document.endsWith(".pdf") ? (
              <img
                src="/assets/media/svg/files/pdf.svg"
                className="theme-light-show "
                alt=""
              />
            ) : (
              <img
                src="/assets/media/svg/files/doc.svg"
                className="theme-light-show"
                alt=""
                onClick={() => openFileInNewWindow(row.document)}
              />
            )}
          </div>
        ) : (
          <svg
            className="plus-button"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setModalShow(true);
              handleCreateDoc(row);
            }}
          >
            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"
                fill="#5c5e6e"
              />{" "}
            </g>
          </svg>
        ),
    },
    {
      name: t("titles.uploaded_at"),
      cell: (row) => (
        <span>{row.uploaded_at ? defaultDateFormat(row.uploaded_at) : ""}</span>
      ),
    },
    {
      name: t("titles.status"),
      cell: (row) => (
        <span
          className={`badge ${
            row.status == 1 ? "badge-light-success" : "badge-light-danger"
          }`}
        >
          {row.status == 1 ? "Active" : row.status == 2 ? "Rejected" : ""}
        </span>
      ),
    },
    {
      name: t("titles.options"),
      cell: (row) =>
        row.status > 0 ? (
          <div className="buttons-docs">
            <span
              title={t("open_doc")}
              className="grey-button"
              onClick={() => openFileInNewWindow(row.document)}
            >
              <svg
                className="docs-svg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                    stroke="#5c5e6e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                    stroke="#5c5e6e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </span>
            <span
              title={t("edit_doc")}
              className=" grey-button"
              onClick={() => {
                setEditModalShow(true);
                handleUpdateDoc(row);
              }}
            >
              <svg
                className="docs-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#5c5e6e"
              >
                <path d="M21 22H3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75zM19.02 3.482c-1.94-1.94-3.84-1.99-5.83 0l-1.21 1.21c-.1.1-.14.26-.1.4a8.129 8.129 0 005.53 5.53.4.4 0 00.41-.1l1.2-1.21c.99-.98 1.47-1.93 1.47-2.89.01-.99-.47-1.95-1.47-2.94zM15.61 11.53c-.29-.14-.57-.28-.84-.44a8.8 8.8 0 01-.64-.42c-.17-.11-.37-.27-.56-.43a1.22 1.22 0 01-.17-.15c-.33-.28-.7-.64-1.03-1.04-.03-.02-.08-.09-.15-.18-.1-.12-.27-.32-.42-.55a5.49 5.49 0 01-.39-.59c-.16-.27-.3-.54-.44-.82a6.88 6.88 0 01-.061-.135c-.148-.333-.583-.43-.84-.173L4.34 12.331c-.13.13-.25.38-.28.55l-.54 3.83c-.1.68.09 1.32.51 1.75.36.35.86.54 1.4.54.12 0 .24-.01.36-.03l3.84-.54c.18-.03.43-.15.55-.28l5.722-5.721c.26-.26.161-.705-.176-.85a26.852 26.852 0 01-.116-.05z" />
              </svg>
            </span>
            <span
              title={t("delete_doc")}
              className="delete-button"
              onClick={() => {
                setWarningModalShow(true);
                handleUpdateDoc(row);
              }}
            >
              <svg
                className="docs-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#f1416c"
              >
                <path d="M5.755 20.283L4 8h16l-1.755 12.283A2 2 0 0116.265 22h-8.53a2 2 0 01-1.98-1.717zM21 4h-5V3a1 1 0 00-1-1H9a1 1 0 00-1 1v1H3a1 1 0 000 2h18a1 1 0 000-2z" />
              </svg>
            </span>
          </div>
        ) : (
          ""
        ),
    },
  ];

  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [warningModalShow, setWarningModalShow] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectRowClass, setSelectRowClass] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);

  const handleCreateDoc = (row) => {
    setSelectedRowIndex(row.index);
    setSelectRowClass(row.class);
  };

  const handleUpdateDoc = (row) => {
    setSelectedRowIndex(row.index);
    setSelectRowClass(row.class);
    setSelectRowId(row.id);
  };

  const handleCloseAddModal = () => {
    setModalShow(false)
  }

  const handleCloseEditModal = () => {
    setEditModalShow(false)
  }

  const handleCloseDeleteModal = () => {
    setWarningModalShow(false)
  }



  const updateDocs = (index, newData) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };
  
  const tableCustomStyles = {
    headCells: {
      style: {
        color: "#979797",
        fontWeight: "800",
        fontSize: 12,
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0 ">
            <h3 className="fw-bold m-0">{t("documents")}</h3>
          </div>
        </div>
        <div className="collapse show card-body p-9 ">
          <div className="datatable-cover">
            <DataTable
              columns={columns}
              data={data}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>
        {modalShow && (
          <AddDocumentModal
            closeModal={handleCloseAddModal}
            updateDocs={updateDocs}
            index={selectedRowIndex}
            data={data[selectedRowIndex]}
            rowClass={selectRowClass}
          />
        )}
        {editModalShow && (
          <UpdateDocumentModal
            closeModal={handleCloseEditModal}
            updateDocs={updateDocs}
            index={selectedRowIndex}
            data={data[selectedRowIndex]}
            rowClass={selectRowClass}
          />
        )}
        {warningModalShow && (
          <WarningModal
            closeModal={handleCloseDeleteModal}
            updateDocs={updateDocs}
            index={selectedRowIndex}
            data={data[selectedRowIndex]}
            id={selectRowId}
          />
        )}
      </div>
    </div>
  );
};

export default Documents;
