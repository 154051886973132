import React, { useEffect } from "react";
import HeaderSettings from "../../Layouts/HeaderSettings";
import BillingsDatatable from "./BillingDataTable";

import { useTranslation } from "react-i18next";
import "../../Style/Billing.css"
const Billing = ({ userType }) => {


  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  const {t}=useTranslation()
  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <HeaderSettings active={3} type={userType} />
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
             
              <BillingsDatatable />
              <div className="card mb-5 mb-xl-10">
                <div className="card-header card-header-stretch pb-0">
                  <div className="card-title">
                    <h3 className="m-0">{t("payment_methods")}</h3>
                  </div>
                </div>
                <div
                  id="kt_billing_payment_tab_content"
                  className="card-body tab-content"
                >
                  <div
                    id="kt_billing_creditcard"
                    className="tab-pane fade show active"
                    role="tabpanel"
                  >
                    <div className="row gx-9 gy-6">
                      <div className="col-xl-6 width-three">
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                {t("important_note")}!
                              </h4>
                              <div className="fs-6 text-gray-700 pe-7">
                                {t("carefully_read")} <a href="#" className="fw-bold me-1">{t("product_terms")}</a> {t("before_adding_card")}
                              </div>
                            </div>
                            <span
                              className="btn enabled-button btn-sm px-6 align-self-center text-nowrap"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_new_card"
                            >
                              {t("add_card")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="kt_billing_paypal"
                    className="tab-pane fade"
                    role="tabpanel"
                    aria-labelledby="kt_billing_paypal_tab"
                  >
                    <h3 className="mb-5">My Paypal</h3>
                    <div className="text-gray-600 fs-6 fw-semibold mb-5">
                      To use PayPal as your payment method, you will need to
                      make pre-payments each month before your bill is due.
                    </div>
                    <form className="form">
                      <div className="mb-7 mw-350px">
                        <select
                          name="timezone"
                          data-control="select2"
                          data-placeholder="Select an option"
                          data-hide-search="true"
                          className="form-select form-select-solid form-select-lg fw-semibold fs-6 text-gray-700"
                        >
                          <option>Select an option</option>
                          <option value={25}>US $25.00</option>
                          <option value={50}>US $50.00</option>
                          <option value={100}>US $100.00</option>
                          <option value={125}>US $125.00</option>
                          <option value={150}>US $150.00</option>
                        </select>
                      </div>
                      <button type="submit" className="btn btn-primary btn-sm">
                        Pay with Paypal
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
