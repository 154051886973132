export const editFriendlyName = async(body)=>{
    return await fetch(`${process.env.REACT_APP_BASE_URL}/interface/numbers`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body
    });
  }