import React from "react";
import Flatpickr from "react-flatpickr";

function DatePicker({
  onChange,
  date,
  min_value,
  max_value,
  time
}) {
  return (
    <div className="date-picker-btn">
      <Flatpickr
        options={{
          maxDate: max_value ? max_value:new Date(),
          closeOnSelect: true,
          mode:"range" ,
          minDate: min_value,
          ...(time ? { enableTime: true, time_24hr: true } : {})
        }}
        onChange={onChange}
        value={date}
      
      />
    </div>
  );

  
}

export default DatePicker;
