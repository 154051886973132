import Menu from "./Menu";

import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRef } from "react";
import SpinnerSecondary from "../components/SpinnerSecondary";
import fetchData from "../services/changeLang.service";
import { updateLang } from "../constants/Utils";
import ErrorSwal from "../components/Alerts/errorAlert";
import ErrorExpiredSession from "../components/Alerts/errorExpiredSession";
// import { getSmsService } from "../modules/Listing/Services/getSmsService";
// import getConversations from "../modules/SMS_Conversations/Services/getConversations";
import getTotalConversations from "../modules/SMS_Conversations/Services/getTotalSms";
import InternalErrorSwal from "../components/Alerts/internalErrorAlert";

const Header = () => {
  const [dropDown, setDropDown] = useState(false);
  const [langMenu, setLangMenu] = useState(false);

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const [logoutLoad, setLogoutLoad] = useState(false);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLangMenu(false);
  };

  const [totalSms, setTotalSms] = useState(0);
  const [profilePic, setProfilePic] = useState(localStorage.getItem("avatar"));
  const [firstname, setFirstname] = useState(localStorage.getItem("firstname"));
  const [lastname, setLastname] = useState(localStorage.getItem("lastname"));
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  const role = localStorage.getItem("role");

  document.addEventListener("avatarChange", () => {
    setProfilePic(localStorage.getItem("avatar"));
  });

  document.addEventListener("profileDetailsChange", () => {
    setFirstname(localStorage.getItem("firstname"));
    setLastname(localStorage.getItem("lastname"));
  });

  const { t } = useTranslation();

  const handleMenu = () => {
    if (!dropDown) {
      setDropDown(true);
    } else {
      setDropDown(false);
      setLangMenu(false);
    }
  };

  const handleLanguageMenu = () => {
    if (!langMenu) {
      setLangMenu(true);
    } else {
      setLangMenu(false);
    }
  };

  const token = localStorage.getItem("token");

  const [active, setActive] = useState(
    parseInt(localStorage.getItem("active"))
  );

  document.addEventListener("activePage", () => {
    setActive(parseInt(localStorage.getItem("active")));
  });

  const handleLogout = async () => {
    try {
      setLogoutLoad(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/users/logout`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        setLogoutLoad(false);
        throw new Error(response.status);
      }

      const fetchedData = await response.json();
      if (fetchedData) {
        var lang = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        window.location.href = `${process.env.REACT_APP_LOCAL}/login`;
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const setShow = () => {
    setDropDown(false);
    setLangMenu(false);
  };

  const dropMenuRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropMenuRef.current &&
        !dropMenuRef.current.contains(e.target) &&
        imgRef.current !== e.target
      ) {
        setDropDown(false);
        setLangMenu(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropMenuRef, imgRef]);

  // useEffect(()=>{

  //   fetchSMS()

  // },[])

  useEffect(() => {
    fetchSMS();
  }, []);

  const [createUserBtn, setCreateUserBtn] = useState(false);

  const createUserRef = useRef();

  const notifRef = useRef();

  const [notifDropDown, setNotifDropDwon] = useState(false);

  // const

  const handleChangeLang = async (lang) => {
    setLogoutLoad(true);
    try {
      const response = await fetchData(
        JSON.stringify({
          id: JSON.parse(localStorage.getItem("data")).id,
          language: lang,
        })
      );
      setLogoutLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const fetchSMS = async () => {
    try {
      const response = await getTotalConversations();

      if (!response.ok) {
        throw new Error(response.status);
      }

      const smsSeenValue = getCookieValue("sms_seen");

      const result = await response.json();

      result.total > smsSeenValue && setTotalSms(result.total);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        refetchSMS();
      }
    }
  };

  const refetchSMS = async () => {
    try {
      const response = await getTotalConversations();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const smsSeenValue = getCookieValue("sms_seen");

      const result = await response.json();

      result.total > smsSeenValue && setTotalSms(result.total);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const checkSms = () => {
    if(totalSms){
      document.cookie = `sms_seen=${totalSms}; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/`;

    } 
    setTotalSms(0);
  };

  function getCookieValue(cookieName) {
    const name = cookieName + "=";

    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }

    return null;
  }

  const [menuClicked, setMenuClicked] = useState(false)

  return (
    <div>
      {logoutLoad && <SpinnerSecondary />}
      <div
        id="kt_body"
        data-kt-app-header-stacked="true"
        className="app-default"
      >
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            <div id="kt_app_header" className="app-header">
              <div className="app-header-primary">
                <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
                  <div className="d-flex flex-stack flex-grow-1 flex-lg-grow-0">
                    <div className="d-flex align-items-center me-7" >
                      <button
                        className="d-lg-none btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 ms-n2 me-2"
                        id="kt_header_secondary_toggle"
                        onClick={()=>setMenuClicked(!menuClicked)}
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                              fill="currentColor"
                            />
                            <path
                              opacity="0.3"
                              d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                      <Link
                        to="/dashboard"
                        className="d-flex align-items-center"
                      >
                        <img
                          alt="Logo"
                          src="/assets/media/myxtel.png"
                          className="h-25px h-lg-30px"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="app-navbar gap-2 ">
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        to="/sms_conversations"
                        className="d-flex align-items-center"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        onClick={checkSms}
                      >
                        <span className="btn btn-icon btn-body position-relative me-5 border border-gray-300 w-35px h-35px">
                          <i className="fs-1">
                            <svg
                              fill="#000000"
                              height="15px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 512 512"
                              enableBackground="new 0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  fill="white"
                                  d="M256,0C114.6,0,0,85.9,0,192c0,75,57.5,139.8,141.1,171.4L85.3,512l160.5-128.4c3.4,0.1,6.7,0.4,10.2,0.4 c141.4,0,256-85.9,256-192C512,85.9,397.4,0,256,0z M128,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S151.5,234.7,128,234.7z M256,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S279.5,234.7,256,234.7z M384,234.7c-23.5,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7 c23.5,0,42.7,19.1,42.7,42.7S407.5,234.7,384,234.7z"
                                />{" "}
                              </g>
                            </svg>
                          </i>{" "}
                          <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger">
                            {totalSms}
                          </span>
                        </span>
                        <span className="d-flex flex-column">
                          <span className="fs-6 fw-bold">
                            <span className="d-none d-lg-inline me-1 c-white">
                              {t("sms_inbox")}
                            </span>
                          </span>
                          <span className="text-muted fw-bold fs-7">
                            <span className="d-none d-lg-inline me-1">
                              {t("view_conversations")}
                            </span>
                          </span>
                        </span>
                      </Link>
                    </div>
                    <div className="create-new-button ms-6">
                      <div
                        ref={createUserRef}
                        className={`app-header-menu app-header-mobile-drawer align-items-stretch create-user-container`}
                        onClick={() => setCreateUserBtn(!createUserBtn)}
                        onMouseLeave={() => setCreateUserBtn(false)}
                      >
                        <span className="btn btn-sm btn-light btn-active-light-primary">
                          {t("add")}
                          <span className="svg-icon svg-icon-5 m-0">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <div
                          className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4  ${
                            createUserBtn && "create-user-block"
                          }`}
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            <Link to="/numbers_buy" className="menu-link px-3">
                              {t("number")}
                            </Link>
                          </div>
                          <div className="menu-item px-3">
                            <Link
                              to="/users_create"
                              className="menu-link px-3 disabled-event"
                            >
                              {t("user")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip id="my-tooltip" />

                    <div
                      className="app-navbar-item position-relative ms-1 ms-lg-3"
                      ref={notifRef}
                      onMouseLeave={() => setNotifDropDwon(false)}
                    >
                      <div
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("header.icons.notification")}
                        className="btn btn-icon btn-custom btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
                        data-kt-menu-trigger="click"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        onClick={() => setNotifDropDwon(!notifDropDown)}
                      >
                        <span className="svg-icon svg-icon-1 svg-icon-white">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                      <div
                        className={`menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px ${
                          notifDropDown && "notification-show"
                        }`}
                        data-kt-menu="true"
                      >
                        <div
                          className="d-flex flex-column bgi-no-repeat rounded-top"
                          style={{
                            backgroundImage:
                              'url("/assets/media/misc/menu-header-bg.jpg")',
                          }}
                        >
                          <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
                            {t("notifications")}
                            <span className="fs-8 opacity-75 ps-3"></span>
                          </h3>
                        </div>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade"
                            id="kt_topbar_notifications_1"
                            role="tabpanel"
                          >
                            <div className="py-3 text-center border-top">
                              <span className="btn btn-color-gray-600 btn-active-color-primary">
                                <span className="svg-icon svg-icon-5">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="18"
                                      y="13"
                                      width="13"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-180 18 13)"
                                      fill="currentColor"
                                    ></rect>
                                    <path
                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade show active"
                            id="kt_topbar_notifications_2"
                            role="tabpanel"
                          >
                            <div className="d-flex flex-column px-9">
                              {/* <div className="pt-10 pb-0">
                                <h3 className="text-dark text-center fw-bold">
                                  {t("pro_access")}
                                </h3>
                                <div className="text-center text-gray-600 fw-semibold pt-1">
                                  {t("notif_desc")}
                                </div>
                                <div className="text-center mt-5 mb-9">
                                  <a
                                    href="#"
                                    className="btn btn-sm btn-flex btn-light-primary fw-bold me-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_upgrade_plan"
                                  >
                                    {t("upgrade")}
                                  </a>
                                </div>
                              </div> */}
                              <div className="p-6 d-flex justify-content-center align-items-center">
                                <span className="text-9 text-white bg-danger d-inline-block fw-700 rounded px-3 py-2 ">
                                  {t("coming_next_release")}!
                                </span>
                              </div>
                              <div className="text-center px-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="app-navbar-item">
                      <Link to="/support">
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={t("header.icons.tech_support")}
                          className={`btn btn-icon btn-custom btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px ${
                            active == 12 ? "active show" : ""
                          }`}
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                          onClick={() => setActive(12)}
                        >
                          <span className="svg-icon svg-icon-1 svg-icon-white">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M16 0.200012H4C1.8 0.200012 0 2.00001 0 4.20001V16.2C0 18.4 1.8 20.2 4 20.2H16C18.2 20.2 20 18.4 20 16.2V4.20001C20 2.00001 18.2 0.200012 16 0.200012ZM15 10.2C15 10.9 14.8 11.6 14.6 12.2H18V16.2C18 17.3 17.1 18.2 16 18.2H12V14.8C11.4 15.1 10.7 15.2 10 15.2C9.3 15.2 8.6 15 8 14.8V18.2H4C2.9 18.2 2 17.3 2 16.2V12.2H5.4C5.1 11.6 5 10.9 5 10.2C5 9.50001 5.2 8.80001 5.4 8.20001H2V4.20001C2 3.10001 2.9 2.20001 4 2.20001H8V5.60001C8.6 5.30001 9.3 5.20001 10 5.20001C10.7 5.20001 11.4 5.40001 12 5.60001V2.20001H16C17.1 2.20001 18 3.10001 18 4.20001V8.20001H14.6C14.8 8.80001 15 9.50001 15 10.2Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12 1.40002C15.4 2.20002 18 4.80003 18.8 8.20003H14.6C14.1 7.00003 13.2 6.10003 12 5.60003V1.40002ZM5.40001 8.20003C5.90001 7.00003 6.80001 6.10003 8.00001 5.60003V1.40002C4.60001 2.20002 2.00001 4.80003 1.20001 8.20003H5.40001ZM14.6 12.2C14.1 13.4 13.2 14.3 12 14.8V19C15.4 18.2 18 15.6 18.8 12.2H14.6ZM8.00001 14.8C6.80001 14.3 5.90001 13.4 5.40001 12.2H1.20001C2.00001 15.6 4.60001 18.2 8.00001 19V14.8Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </span>
                      </Link>
                    </div>
                    <div className="app-navbar-item">
                      <Link to="/pricing">
                        <span
                          className={`btn btn-icon btn-custom btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px ${
                            active === 11 ? "active show" : ""
                          }`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={t("header.icons.pricing")}
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                          onClick={() => setActive(11)}
                        >
                          <span className="svg-icon svg-icon-1 svg-icon-white">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
                                fill="currentColor"
                              />
                              <path
                                d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </span>
                      </Link>
                    </div>
                    <div
                      className="app-navbar-item"
                      id="kt_header_user_menu_toggle"
                    >
                      <div
                        className="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3"
                        onClick={handleMenu}
                      >
                        <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
                          <span className="text-white fs-8 fw-bold lh-1 mb-1">
                            {username}
                          </span>
                          <span className="text-white fs-8 opacity-75 fw-semibold lh-1">
                            {role === "USER" ? t("user") : "admin"}
                          </span>
                        </div>
                        <div className="symbol symbol-30px symbol-md-40px">
                          <img
                            ref={imgRef}
                            src={
                              !profilePic || profilePic == "null"
                                ? "/assets/media/avatars/blank.png"
                                : profilePic
                            }
                            alt="image"
                          />
                        </div>
                      </div>
                      <div
                        ref={dropMenuRef}
                        className={`menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                          dropDown && "show div-positionning"
                        } `}
                        onMouseLeave={() => {
                          setDropDown(false);
                          setLangMenu(false);
                        }}
                      >
                        <div className="menu-item px-3">
                          <div className="menu-content d-flex align-items-center px-3">
                            <div className="symbol symbol-50px me-5">
                              <img
                                src={
                                  !profilePic || profilePic == "null"
                                    ? "/assets/media/avatars/blank.png"
                                    : profilePic
                                }
                                alt="image"
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="fw-bold d-flex align-items-center fs-5">
                                {firstname} {lastname}
                              </div>
                              <div
                                className="fw-semibold text-muted fs-7 word-break"
                                data-bs-toggle={
                                  email.length > 22 ? "tooltip" : undefined
                                }
                                data-bs-placement="left"
                                data-tooltip-id="my-tooltip"
                                data-kt-menu-placement="bottom-end"
                                data-tooltip-content={
                                  email.length > 22 ? email : undefined
                                }
                              >
                                {email.length <= 22
                                  ? email
                                  : `${email
                                      .split("@")[0]
                                      .substring(
                                        0,
                                        19 - email.split("@")[1].length
                                      )}..@${email.split("@")[1]}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="menu-item px-5"
                          onClick={() => {
                            setDropDown(false);
                            setLangMenu(false);
                          }}
                        >
                          <Link
                            to="/settings_security"
                            className="menu-link px-5"
                          >
                            {t("my_profile")}
                          </Link>
                        </div>

                        <div
                          className="menu-item px-5"
                          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-offset="-15px, 0"
                          onMouseLeave={() => setLangMenu(false)}
                        >
                          {selectedLang.includes("en") ? (
                            <span className="menu-link px-5">
                              <span className="menu-title position-relative">
                                {t("language")}
                                <span
                                  className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0 display-flex"
                                  onClick={handleLanguageMenu}
                                >
                                  {t("english")}
                                  <img
                                    className="w-15px h-15px rounded-1 ms-2"
                                    src="/assets/media/flags/gb.svg"
                                    alt=""
                                  />
                                </span>
                              </span>
                            </span>
                          ) : (
                            <a href="#" className="menu-link px-5">
                              <span className="menu-title position-relative">
                                {t("language")}
                                <span
                                  className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0 display-flex"
                                  onClick={handleLanguageMenu}
                                >
                                  {t("french")}
                                  <img
                                    className="w-15px h-15px rounded-1 ms-2"
                                    src="/assets/media/flags/fr.svg"
                                    alt=""
                                  />
                                </span>
                              </span>
                            </a>
                          )}

                          <div
                            className={`menu-sub menu-sub-dropdown w-175px py-4 ${
                              langMenu && "show-menu"
                            }`}
                          >
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className={`menu-link d-flex px-5 ${
                                  selectedLang == "en" ? "active" : ""
                                } `}
                                onClick={async () => {
                                  await handleChangeLang("EN");
                                  updateLang("en");
                                  changeLanguage("en");
                                  setSelectedLang("en");
                                }}
                              >
                                <span className="symbol symbol-20px me-4">
                                  <img
                                    className="rounded-1"
                                    src="/assets/media/flags/gb.svg"
                                    alt=""
                                  />
                                </span>
                                {t("english")}
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className={`menu-link d-flex px-5 ${
                                  selectedLang.includes("fr") ? "active" : ""
                                }`}
                                onClick={async () => {
                                  await handleChangeLang("FR");
                                  updateLang("fr");
                                  changeLanguage("fr");
                                  setSelectedLang("fr");
                                }}
                              >
                                <span className="symbol symbol-20px me-4">
                                  <img
                                    className="rounded-1"
                                    src="/assets/media/flags/fr.svg"
                                    alt=""
                                  />
                                </span>
                                {t("french")}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="menu-item px-5">
                          <a className="menu-link px-5" onClick={handleLogout}>
                            {t("sign_out")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Menu setShow={setShow} selectedLang={selectedLang} menuClicked={menuClicked} closeMenu={()=>setMenuClicked(false)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
