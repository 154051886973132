import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const AddNew = ({title, path, disabled}) => {
    const {t}=useTranslation()
  return (
    <Link className={`col-md-6 col-xl-4 cursor-pointer min-h-21rem ${disabled&&"disabled-event"}`} to={path}>

    <div className="card border-hover-primary card-dim position-relative">

      <div className="h-100 d-flex justify-content-center align-items-center position-absolute w-100 h-100 t-300 c-add flex-column">

        <svg
          fill="currentColor"
          height={60}
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >

          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <title>plus</title>{" "}
            <path d="M15.5 29.5c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.938 15.938c0-0.552-0.448-1-1-1h-4v-4c0-0.552-0.447-1-1-1h-1c-0.553 0-1 0.448-1 1v4h-4c-0.553 0-1 0.448-1 1v1c0 0.553 0.447 1 1 1h4v4c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1v-4h4c0.552 0 1-0.447 1-1v-1z"></path>{" "}
          </g>
        </svg>

        <span className='text-gray-600 fw-bold la-1-2x'>{t(title)}</span>
        
      </div>
    </div>
  </Link>
  )
}

export default AddNew