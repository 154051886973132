const fetchData = async (body) => {
  return await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/dashboard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body,
  });
};

export default fetchData;
